import { Roboto_Mono } from "next/font/google";

import styles from "./BetaSticker.module.css";

import { useModalContext } from "@/context/ModalContext";
import BetaModal from "../BetaModal/BetaModal";

const font = Roboto_Mono({ subsets: ["latin"] });

export default function BetaSticker() {
  const { setModalComponent } = useModalContext();
  return (
    <div
      onClick={() => setModalComponent(<BetaModal />)}
      className={styles.main}
    >
      <p>BETA</p>
    </div>
  );
}

export function BetaStickerFeature() {
  return (
    <div className={`${styles.mainFeature} ${font.className}`}>
      <p>Feature in Beta</p>
    </div>
  );
}
