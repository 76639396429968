export default function CompareIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <rect x="3" y="19" width="5" height="10" rx="2.5" fill="#E6E1DF" />
      <rect x="17" y="4" width="5" height="25" rx="2.5" fill="#E6E1DF" />
      <rect x="10" y="11" width="5" height="18" rx="2.5" fill="#E6E1DF" />
      <rect x="24" y="15" width="5" height="14" rx="2.5" fill="#E6E1DF" />
    </svg>
  );
}
