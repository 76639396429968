import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/redux/store";

import { setNumberOfMessagesRemaining } from "@/redux/slices/chatbotSlice";

export default function useGetNumberOfMessagesRemaining() {
  const dispatch = useDispatch();

  const isPremium = useSelector((state: RootState) => state.user.isPremium);
  const readOnlyPrivateProfile = useSelector(
    (state: RootState) => state.chatbot
  ).readOnlyPrivateProfile;

  const numGPTMessagesPurchased =
    readOnlyPrivateProfile?.numGPTMessagesPurchased;
  const lifetimeGPTMessagesSent =
    readOnlyPrivateProfile?.lifetimeGPTMessagesSent || 0;
  const lifetimeNonPremiumGPTMessagesSent =
    readOnlyPrivateProfile?.lifetimeNonPremiumGPTMessagesSent || 0;

  useEffect(() => {
    if (isPremium) {
      //unlimited messages
      dispatch(setNumberOfMessagesRemaining("unlimited"));
    } else if (numGPTMessagesPurchased) {
      //check for consumables
      dispatch(
        setNumberOfMessagesRemaining(
          numGPTMessagesPurchased - lifetimeGPTMessagesSent
        )
      );
    } else {
      //free usage of 10 messages
      const difference = 10 - lifetimeNonPremiumGPTMessagesSent;
      if (difference < 0) {
        dispatch(setNumberOfMessagesRemaining(0));
      } else {
        dispatch(setNumberOfMessagesRemaining(difference));
      }
    }
  }, [
    isPremium,
    numGPTMessagesPurchased,
    lifetimeGPTMessagesSent,
    lifetimeNonPremiumGPTMessagesSent,
  ]);
}
