import { createSlice } from "@reduxjs/toolkit";
import { enableMapSet } from "immer";

//This keeps tracks of modal profiles uids in the modal stack

// Enable support for Set in Immer
enableMapSet();

type UserPollSlice = {
  uid: Set<string>;
  currentUserUid: string | null;
};

const initialState: UserPollSlice = {
  uid: new Set(),
  currentUserUid: null,
};

export const profileModalUid = createSlice({
  name: "profileModalUid",
  initialState,
  reducers: {
    addProfileModalUid: (state, action: { payload: string }) => {
      state.uid = new Set(state.uid).add(action.payload);
      state.currentUserUid = action.payload;
    },
    removeProfileModalUid: (state) => {
      if (!state.currentUserUid) return; // If no current UID, do nothing

      const newSet = new Set(state.uid);
      newSet.delete(state.currentUserUid); // Remove the latest one
      state.uid = newSet;

      // Update currentUserUid to the last added one, or null if empty
      state.currentUserUid =
        newSet.size > 0 ? Array.from(newSet).pop() ?? null : null;
    },
  },
});

export const { addProfileModalUid, removeProfileModalUid } =
  profileModalUid.actions;
export default profileModalUid.reducer;
