import Lottie from "lottie-react";

import lottieData from "../../assets/lotties/3dotsloader.json";

export default function GoDeeperIcon() {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ opacity: "0.4" }}
    >
      <path
        d="M15.7159 11.9408C15.7159 11.7204 15.5382 11.5427 15.3178 11.5427C11.7082 11.5427 8.77415 8.06621 8.77415 3.79051C8.77415 3.57012 8.59642 3.3924 8.37604 3.3924C8.15565 3.3924 7.97792 3.57013 7.97792 3.79051C7.97792 8.06621 5.0407 11.5427 1.43424 11.5427C1.21385 11.5427 1.03613 11.7204 1.03613 11.9408C1.03613 12.1612 1.21387 12.3389 1.43424 12.3389C5.0407 12.3389 7.97792 15.8154 7.97792 20.0911C7.97792 20.3114 8.15566 20.4892 8.37604 20.4892C8.59643 20.4892 8.77415 20.3114 8.77415 20.0911C8.77415 15.8154 11.7093 12.3389 15.3178 12.3389C15.5372 12.3389 15.7159 12.1612 15.7159 11.9408Z"
        fill="#FFFFDE"
      />
      <path
        d="M14.2585 4.70332C15.5676 4.70332 16.633 5.98605 16.633 7.56124C16.633 7.78163 16.8107 7.95935 17.0311 7.95935C17.2515 7.95935 17.4292 7.78162 17.4292 7.56124C17.4292 5.986 18.4946 4.70332 19.8037 4.70332C20.0241 4.70332 20.2019 4.52558 20.2019 4.30521C20.2019 4.08483 20.0241 3.90709 19.8037 3.90709C18.4946 3.90709 17.4292 2.62436 17.4292 1.04917C17.4292 0.828782 17.2515 0.651062 17.0311 0.651062C16.8107 0.651062 16.633 0.828795 16.633 1.04917C16.633 2.62441 15.5676 3.90709 14.2585 3.90709C14.0381 3.90709 13.8604 4.08483 13.8604 4.30521C13.8604 4.52458 14.0381 4.70332 14.2585 4.70332Z"
        fill="#E0CC65"
      />
      <path
        d="M24.5719 19.4351C22.3884 19.4351 20.6121 17.3185 20.6121 14.7184C20.6121 14.498 20.4344 14.3203 20.214 14.3203C19.9937 14.3203 19.8159 14.498 19.8159 14.7184C19.8159 17.3184 18.0396 19.4351 15.8561 19.4351C15.6357 19.4351 15.458 19.6159 15.458 19.8332C15.458 20.0536 15.6357 20.2313 15.8561 20.2313C18.0397 20.2313 19.8159 22.3479 19.8159 24.9508C19.8159 25.1712 19.9937 25.3489 20.214 25.3489C20.4344 25.3489 20.6121 25.1712 20.6121 24.9508C20.6121 22.3477 22.3885 20.2313 24.5719 20.2313C24.7923 20.2313 24.9701 20.0536 24.9701 19.8332C24.9701 19.6159 24.7923 19.4351 24.5719 19.4351Z"
        fill="#F493AC"
      />
    </svg>
  );
}

export function GoDeeperModalIcon(props: {
  onClick: () => void;
  empty?: boolean;
}) {
  return (
    <svg
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => props.onClick()}
    >
      <path
        d="M42 21C42 32.598 32.598 42 21 42C9.40202 42 0 32.598 0 21C0 9.40202 9.40202 0 21 0C32.598 0 42 9.40202 42 21ZM5.53937 21C5.53937 29.5387 12.4613 36.4606 21 36.4606C29.5387 36.4606 36.4606 29.5387 36.4606 21C36.4606 12.4613 29.5387 5.53937 21 5.53937C12.4613 5.53937 5.53937 12.4613 5.53937 21Z"
        fill="#55504F"
      />
      <path
        d="M42 21C42 32.598 32.598 42 21 42C9.40202 42 0 32.598 0 21C0 9.40202 9.40202 0 21 0C32.598 0 42 9.40202 42 21ZM5.53937 21C5.53937 29.5387 12.4613 36.4606 21 36.4606C29.5387 36.4606 36.4606 29.5387 36.4606 21C36.4606 12.4613 29.5387 5.53937 21 5.53937C12.4613 5.53937 5.53937 12.4613 5.53937 21Z"
        fill={props.empty ? "#55504F" : "#FFFFDE"}
      />
      <g clipPath="url(#clip0_68995_3070)">
        <path
          d="M22.3173 20.8628C22.3173 20.7057 22.1907 20.5791 22.0336 20.5791C19.4612 20.5791 17.3701 18.1015 17.3701 15.0543C17.3701 14.8973 17.2435 14.7706 17.0864 14.7706C16.9293 14.7706 16.8027 14.8973 16.8027 15.0543C16.8027 18.1015 14.7094 20.5791 12.1392 20.5791C11.9821 20.5791 11.8555 20.7057 11.8555 20.8628C11.8555 21.0198 11.9821 21.1465 12.1392 21.1465C14.7094 21.1465 16.8027 23.6241 16.8027 26.6712C16.8027 26.8283 16.9293 26.9549 17.0864 26.9549C17.2435 26.9549 17.3701 26.8283 17.3701 26.6712C17.3701 23.6241 19.4619 21.1465 22.0336 21.1465C22.1899 21.1465 22.3173 21.0198 22.3173 20.8628Z"
          fill="#FFFFDE"
        />
        <path
          d="M21.2779 15.7048C22.2109 15.7048 22.9701 16.619 22.9701 17.7416C22.9701 17.8987 23.0968 18.0253 23.2538 18.0253C23.4109 18.0253 23.5376 17.8987 23.5376 17.7416C23.5376 16.619 24.2968 15.7048 25.2298 15.7048C25.3869 15.7048 25.5135 15.5782 25.5135 15.4211C25.5135 15.2641 25.3869 15.1374 25.2298 15.1374C24.2968 15.1374 23.5376 14.2232 23.5376 13.1006C23.5376 12.9436 23.4109 12.8169 23.2538 12.8169C23.0968 12.8169 22.9701 12.9436 22.9701 13.1006C22.9701 14.2233 22.2108 15.1374 21.2779 15.1374C21.1208 15.1374 20.9941 15.2641 20.9941 15.4211C20.9941 15.5775 21.1208 15.7048 21.2779 15.7048Z"
          fill="#E0CC65"
        />
        <path
          d="M28.628 26.2037C27.0719 26.2037 25.806 24.6953 25.806 22.8423C25.806 22.6852 25.6793 22.5586 25.5223 22.5586C25.3652 22.5586 25.2386 22.6853 25.2386 22.8423C25.2386 24.6953 23.9726 26.2037 22.4165 26.2037C22.2595 26.2037 22.1328 26.3326 22.1328 26.4875C22.1328 26.6445 22.2595 26.7712 22.4165 26.7712C23.9727 26.7712 25.2386 28.2796 25.2386 30.1346C25.2386 30.2917 25.3652 30.4184 25.5223 30.4184C25.6793 30.4184 25.806 30.2917 25.806 30.1346C25.806 28.2795 27.0719 26.7712 28.628 26.7712C28.7851 26.7712 28.9118 26.6445 28.9118 26.4875C28.9118 26.3326 28.7851 26.2037 28.628 26.2037Z"
          fill="#FFA0B9"
        />
      </g>
      <defs>
        <clipPath id="clip0_68995_3070">
          <rect
            width="18.5294"
            height="18.5294"
            fill="white"
            transform="translate(11.1172 12.3529)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export function GoDeeperMenuIcon(props: { onClick: () => void }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="17"
      viewBox="0 0 19 17"
      fill="none"
      onClick={() => props.onClick()}
      style={{ zIndex: 2 }}
    >
      <path
        d="M18.875 14.75C18.875 15.6133 18.1758 16.3125 17.3125 16.3125H1.6875C0.824225 16.3125 0.125 15.6133 0.125 14.75C0.125 13.8867 0.824225 13.1875 1.6875 13.1875H17.3125C18.1758 13.1875 18.875 13.8867 18.875 14.75ZM17.3125 6.9375H1.6875C0.824225 6.9375 0.125 7.63673 0.125 8.5C0.125 9.36328 0.824225 10.0625 1.6875 10.0625H17.3125C18.1758 10.0625 18.875 9.36328 18.875 8.5C18.875 7.63673 18.1758 6.9375 17.3125 6.9375ZM1.6875 3.8125H17.3125C18.1758 3.8125 18.875 3.11328 18.875 2.25C18.875 1.38673 18.1758 0.6875 17.3125 0.6875H1.6875C0.824225 0.6875 0.125 1.38673 0.125 2.25C0.125 3.11328 0.824225 3.8125 1.6875 3.8125Z"
        fill="#FFFFF2"
      />
    </svg>
  );
}

export function GoDeeperIconLarge(props: { full: boolean }) {
  if (props.full) {
    return (
      <svg
        width="120"
        height="120"
        viewBox="0 0 120 120"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M120 60C120 93.1371 93.1371 120 60 120C26.8629 120 0 93.1371 0 60C0 26.8629 26.8629 0 60 0C93.1371 0 120 26.8629 120 60ZM15.8268 60C15.8268 84.3962 35.6038 104.173 60 104.173C84.3962 104.173 104.173 84.3962 104.173 60C104.173 35.6038 84.3962 15.8268 60 15.8268C35.6038 15.8268 15.8268 35.6038 15.8268 60Z"
          fill="#55504F"
        />
        <path
          d="M120 60C120 93.1371 93.1371 120 60 120C26.8629 120 0 93.1371 0 60C0 26.8629 26.8629 0 60 0C93.1371 0 120 26.8629 120 60ZM15.8268 60C15.8268 84.3962 35.6038 104.173 60 104.173C84.3962 104.173 104.173 84.3962 104.173 60C104.173 35.6038 84.3962 15.8268 60 15.8268C35.6038 15.8268 15.8268 35.6038 15.8268 60Z"
          fill="#FFFFDE"
        />
        <path
          d="M63.766 59.6079C63.766 59.1592 63.4041 58.7973 62.9554 58.7973C55.6056 58.7973 49.6311 51.7185 49.6311 43.0124C49.6311 42.5636 49.2692 42.2017 48.8205 42.2017C48.3717 42.2017 48.0099 42.5636 48.0099 43.0124C48.0099 51.7185 42.0291 58.7973 34.6856 58.7973C34.2369 58.7973 33.875 59.1592 33.875 59.6079C33.875 60.0567 34.2369 60.4186 34.6856 60.4186C42.0291 60.4186 48.0099 67.4973 48.0099 76.2035C48.0099 76.6523 48.3718 77.0141 48.8205 77.0141C49.2693 77.0141 49.6311 76.6522 49.6311 76.2035C49.6311 67.4973 55.6077 60.4186 62.9554 60.4186C63.4021 60.4186 63.766 60.0567 63.766 59.6079Z"
          fill="#FFFFDE"
        />
        <path
          d="M60.797 44.871C63.4627 44.871 65.632 47.4829 65.632 50.6903C65.632 51.1391 65.9939 51.5009 66.4426 51.5009C66.8913 51.5009 67.2532 51.139 67.2532 50.6903C67.2532 47.4828 69.4226 44.871 72.0883 44.871C72.537 44.871 72.8989 44.5091 72.8989 44.0604C72.8989 43.6116 72.537 43.2497 72.0883 43.2497C69.4226 43.2497 67.2532 40.6378 67.2532 37.4304C67.2532 36.9817 66.8913 36.6198 66.4426 36.6198C65.9939 36.6198 65.632 36.9817 65.632 37.4304C65.632 40.6379 63.4626 43.2497 60.797 43.2497C60.3482 43.2497 59.9863 43.6116 59.9863 44.0604C59.9863 44.5071 60.3482 44.871 60.797 44.871Z"
          fill="#E0CC65"
        />
        <path
          d="M81.798 74.8678C77.3518 74.8678 73.7351 70.5581 73.7351 65.2638C73.7351 64.815 73.3732 64.4531 72.9244 64.4531C72.4757 64.4531 72.1138 64.815 72.1138 65.2638C72.1138 70.5579 68.4969 74.8678 64.0509 74.8678C63.6021 74.8678 63.2402 75.2359 63.2402 75.6785C63.2402 76.1272 63.6021 76.4891 64.0509 76.4891C68.4971 76.4891 72.1138 80.7988 72.1138 86.099C72.1138 86.5477 72.4757 86.9096 72.9244 86.9096C73.3732 86.9096 73.7351 86.5477 73.7351 86.099C73.7351 80.7985 77.352 76.4891 81.798 76.4891C82.2468 76.4891 82.6087 76.1272 82.6087 75.6785C82.6087 75.2359 82.2468 74.8678 81.798 74.8678Z"
          fill="#FFA0B9"
        />
      </svg>
    );
  }

  return (
    <svg
      width="120"
      height="120"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M120 60C120 93.1371 93.1371 120 60 120C26.8629 120 0 93.1371 0 60C0 26.8629 26.8629 0 60 0C93.1371 0 120 26.8629 120 60ZM15.8268 60C15.8268 84.3962 35.6038 104.173 60 104.173C84.3962 104.173 104.173 84.3962 104.173 60C104.173 35.6038 84.3962 15.8268 60 15.8268C35.6038 15.8268 15.8268 35.6038 15.8268 60Z"
        fill="#55504F"
      />
      <path
        d="M63.766 59.6079C63.766 59.1592 63.4041 58.7973 62.9554 58.7973C55.6056 58.7973 49.6311 51.7185 49.6311 43.0124C49.6311 42.5636 49.2692 42.2017 48.8205 42.2017C48.3717 42.2017 48.0099 42.5636 48.0099 43.0124C48.0099 51.7185 42.0291 58.7973 34.6856 58.7973C34.2369 58.7973 33.875 59.1592 33.875 59.6079C33.875 60.0567 34.2369 60.4186 34.6856 60.4186C42.0291 60.4186 48.0099 67.4973 48.0099 76.2035C48.0099 76.6523 48.3718 77.0141 48.8205 77.0141C49.2693 77.0141 49.6311 76.6522 49.6311 76.2035C49.6311 67.4973 55.6077 60.4186 62.9554 60.4186C63.4021 60.4186 63.766 60.0567 63.766 59.6079Z"
        fill="#FFFFDE"
      />
      <path
        d="M60.797 44.871C63.4627 44.871 65.632 47.4829 65.632 50.6903C65.632 51.1391 65.9939 51.5009 66.4426 51.5009C66.8913 51.5009 67.2532 51.139 67.2532 50.6903C67.2532 47.4828 69.4226 44.871 72.0883 44.871C72.537 44.871 72.8989 44.5091 72.8989 44.0604C72.8989 43.6116 72.537 43.2497 72.0883 43.2497C69.4226 43.2497 67.2532 40.6378 67.2532 37.4304C67.2532 36.9817 66.8913 36.6198 66.4426 36.6198C65.9939 36.6198 65.632 36.9817 65.632 37.4304C65.632 40.6379 63.4626 43.2497 60.797 43.2497C60.3482 43.2497 59.9863 43.6116 59.9863 44.0604C59.9863 44.5071 60.3482 44.871 60.797 44.871Z"
        fill="#E0CC65"
      />
      <path
        d="M81.798 74.8678C77.3518 74.8678 73.7351 70.5581 73.7351 65.2638C73.7351 64.815 73.3732 64.4531 72.9244 64.4531C72.4757 64.4531 72.1138 64.815 72.1138 65.2638C72.1138 70.5579 68.4969 74.8678 64.0509 74.8678C63.6021 74.8678 63.2402 75.2359 63.2402 75.6785C63.2402 76.1272 63.6021 76.4891 64.0509 76.4891C68.4971 76.4891 72.1138 80.7988 72.1138 86.099C72.1138 86.5477 72.4757 86.9096 72.9244 86.9096C73.3732 86.9096 73.7351 86.5477 73.7351 86.099C73.7351 80.7985 77.352 76.4891 81.798 76.4891C82.2468 76.4891 82.6087 76.1272 82.6087 75.6785C82.6087 75.2359 82.2468 74.8678 81.798 74.8678Z"
        fill="#FFA0B9"
      />
    </svg>
  );
}

export function GoDeeperMessageLoadingIcon() {
  return (
    <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
      <svg
        width="35"
        height="35"
        viewBox="0 0 35 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M35 17.5C35 27.165 27.165 35 17.5 35C7.83502 35 0 27.165 0 17.5C0 7.83502 7.83502 0 17.5 0C27.165 0 35 7.83502 35 17.5ZM4.61615 17.5C4.61615 24.6156 10.3844 30.3839 17.5 30.3839C24.6156 30.3839 30.3839 24.6156 30.3839 17.5C30.3839 10.3844 24.6156 4.61615 17.5 4.61615C10.3844 4.61615 4.61615 10.3844 4.61615 17.5Z"
          fill="#55504F"
        />
        <g clipPath="url(#clip0_61653_61445)">
          <path
            d="M18.5991 17.3856C18.5991 17.2548 18.4935 17.1492 18.3626 17.1492C16.2189 17.1492 14.4764 15.0846 14.4764 12.5453C14.4764 12.4144 14.3708 12.3088 14.24 12.3088C14.1091 12.3088 14.0035 12.4144 14.0035 12.5453C14.0035 15.0846 12.2591 17.1492 10.1173 17.1492C9.98641 17.1492 9.88086 17.2548 9.88086 17.3856C9.88086 17.5165 9.98641 17.6221 10.1173 17.6221C12.2591 17.6221 14.0035 19.6867 14.0035 22.226C14.0035 22.3569 14.1091 22.4625 14.24 22.4625C14.3709 22.4625 14.4764 22.3569 14.4764 22.226C14.4764 19.6867 16.2196 17.6221 18.3626 17.6221C18.4929 17.6221 18.5991 17.5165 18.5991 17.3856Z"
            fill="#FFFFDE"
          />
          <path
            d="M17.7325 13.0874C18.51 13.0874 19.1427 13.8492 19.1427 14.7847C19.1427 14.9156 19.2483 15.0211 19.3792 15.0211C19.5101 15.0211 19.6156 14.9156 19.6156 14.7847C19.6156 13.8492 20.2483 13.0874 21.0258 13.0874C21.1567 13.0874 21.2623 12.9818 21.2623 12.851C21.2623 12.7201 21.1567 12.6145 21.0258 12.6145C20.2483 12.6145 19.6156 11.8527 19.6156 10.9172C19.6156 10.7863 19.5101 10.6808 19.3792 10.6808C19.2483 10.6808 19.1427 10.7863 19.1427 10.9172C19.1427 11.8527 18.51 12.6145 17.7325 12.6145C17.6016 12.6145 17.4961 12.7201 17.4961 12.851C17.4961 12.9812 17.6016 13.0874 17.7325 13.0874Z"
            fill="#E0CC65"
          />
          <path
            d="M23.858 21.8364C22.5612 21.8364 21.5063 20.5794 21.5063 19.0353C21.5063 18.9044 21.4008 18.7988 21.2699 18.7988C21.139 18.7988 21.0334 18.9044 21.0334 19.0353C21.0334 20.5794 19.9785 21.8364 18.6817 21.8364C18.5509 21.8364 18.4453 21.9438 18.4453 22.0729C18.4453 22.2038 18.5509 22.3093 18.6817 22.3093C19.9786 22.3093 21.0334 23.5663 21.0334 25.1122C21.0334 25.2431 21.139 25.3486 21.2699 25.3486C21.4008 25.3486 21.5063 25.2431 21.5063 25.1122C21.5063 23.5662 22.5613 22.3093 23.858 22.3093C23.9889 22.3093 24.0944 22.2038 24.0944 22.0729C24.0944 21.9438 23.9889 21.8364 23.858 21.8364Z"
            fill="#FFA0B9"
          />
        </g>
        <defs>
          <clipPath id="clip0_61653_61445">
            <rect
              width="15.4412"
              height="15.4412"
              fill="white"
              transform="translate(9.26562 10.2941)"
            />
          </clipPath>
        </defs>
      </svg>
      <Lottie animationData={lottieData} loop={true} />
    </div>
  );
}

export function GoDeeperConsumableIcon() {
  return (
    <svg
      width="90"
      height="90"
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M90 45C90 69.8528 69.8528 90 45 90C20.1472 90 0 69.8528 0 45C0 20.1472 20.1472 0 45 0C69.8528 0 90 20.1472 90 45ZM11.8701 45C11.8701 63.2971 26.7029 78.1299 45 78.1299C63.2971 78.1299 78.1299 63.2971 78.1299 45C78.1299 26.7029 63.2971 11.8701 45 11.8701C26.7029 11.8701 11.8701 26.7029 11.8701 45Z"
        fill="#55504F"
      />
      <path
        d="M90 45C90 69.8528 69.8528 90 45 90C20.1472 90 0 69.8528 0 45C0 20.1472 20.1472 0 45 0C69.8528 0 90 20.1472 90 45ZM11.8701 45C11.8701 63.2971 26.7029 78.1299 45 78.1299C63.2971 78.1299 78.1299 63.2971 78.1299 45C78.1299 26.7029 63.2971 11.8701 45 11.8701C26.7029 11.8701 11.8701 26.7029 11.8701 45Z"
        fill="#FFFFDE"
      />
      <path
        d="M47.8226 44.706C47.8226 44.3695 47.5511 44.098 47.2146 44.098C41.7022 44.098 37.2214 38.789 37.2214 32.2593C37.2214 31.9228 36.95 31.6514 36.6134 31.6514C36.2769 31.6514 36.0054 31.9228 36.0054 32.2593C36.0054 38.789 31.5199 44.098 26.0123 44.098C25.6757 44.098 25.4043 44.3695 25.4043 44.706C25.4043 45.0426 25.6757 45.314 26.0123 45.314C31.5199 45.314 36.0054 50.6231 36.0054 57.1527C36.0054 57.4893 36.2769 57.7607 36.6134 57.7607C36.95 57.7607 37.2214 57.4893 37.2214 57.1527C37.2214 50.6231 41.7038 45.314 47.2146 45.314C47.5496 45.314 47.8226 45.0426 47.8226 44.706Z"
        fill="#FFFFDE"
      />
      <path
        d="M45.5982 33.6531C47.5975 33.6531 49.2245 35.6121 49.2245 38.0176C49.2245 38.3542 49.4959 38.6256 49.8324 38.6256C50.169 38.6256 50.4404 38.3541 50.4404 38.0176C50.4404 35.612 52.0675 33.6531 54.0667 33.6531C54.4033 33.6531 54.6747 33.3817 54.6747 33.0451C54.6747 32.7086 54.4032 32.4372 54.0667 32.4372C52.0674 32.4372 50.4404 30.4782 50.4404 28.0727C50.4404 27.7361 50.169 27.4647 49.8324 27.4647C49.4959 27.4647 49.2245 27.7361 49.2245 28.0727C49.2245 30.4783 47.5974 32.4372 45.5982 32.4372C45.2616 32.4372 44.9902 32.7086 44.9902 33.0451C44.9902 33.3802 45.2617 33.6531 45.5982 33.6531Z"
        fill="#E0CC65"
      />
      <path
        d="M61.348 56.1509C58.0134 56.1509 55.3008 52.9186 55.3008 48.9478C55.3008 48.6112 55.0294 48.3398 54.6928 48.3398C54.3563 48.3398 54.0849 48.6113 54.0849 48.9478C54.0849 52.9184 51.3722 56.1509 48.0377 56.1509C47.7011 56.1509 47.4297 56.4269 47.4297 56.7588C47.4297 57.0954 47.7011 57.3668 48.0377 57.3668C51.3723 57.3668 54.0849 60.5991 54.0849 64.5742C54.0849 64.9108 54.3563 65.1822 54.6928 65.1822C55.0294 65.1822 55.3008 64.9108 55.3008 64.5742C55.3008 60.5989 58.0135 57.3668 61.348 57.3668C61.6846 57.3668 61.956 57.0954 61.956 56.7588C61.956 56.4269 61.6846 56.1509 61.348 56.1509Z"
        fill="#FFA0B9"
      />
    </svg>
  );
}

export function ScrollToBottomIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="68"
      height="68"
      viewBox="0 0 68 68"
      fill="none"
    >
      <g filter="url(#filter0_d_63723_30641)">
        <rect x="14" y="14" width="40" height="40" rx="20" fill="#55504F" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.9545 25C34.7226 25 35.346 25.6205 35.346 26.385V38.2725L38.5344 35.0974C39.0782 34.5575 39.9584 34.5575 40.5022 35.0974C41.0447 35.6387 41.0447 36.5149 40.5022 37.0562L34.9378 42.5937C34.677 42.8533 34.3236 43 33.9545 43C33.5855 43 33.2321 42.8533 32.9713 42.5937L27.4069 37.0562C26.8644 36.5149 26.8644 35.6387 27.4069 35.0974C27.9507 34.5575 28.8309 34.5575 29.3747 35.0974L32.5631 38.2725V26.385C32.5631 25.6205 33.1864 25 33.9545 25Z"
          fill="#FFFFF2"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_63723_30641"
          x="0"
          y="0"
          width="68"
          height="68"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2"
            operator="dilate"
            in="SourceAlpha"
            result="effect1_dropShadow_63723_30641"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="6" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0941176 0 0 0 0 0.0901961 0 0 0 0 0.0862745 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_63723_30641"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_63723_30641"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
