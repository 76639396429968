import { useEffect, useState } from "react";
import { RootState } from "@/redux/store";
import { useDispatch, useSelector } from "react-redux";
import { ContentType } from "@dimensional-engineering/dimensional-models";
import { useRouter } from "next/router";

import styles from "./ConnectedDimensionSummaryModal/ConnectedDimensionSummaryModal.module.css";

import {
  dimensionData,
  dimensionIcons,
} from "@/assets/traitInfo/dimensionData";
import { ScoreAndDescriptorFullWidth } from "./ScoreAndDescriptor/ScoreAndDescriptor";
import { Mixpanel } from "@/helpers/mixpanel";
import LoadingScreen from "@/components/assessments-new/views/AsessmentOutroScreen/loadingScreen/LoadingScreen";
import ModalWrapper from "@/components/shared/ModalWrapper/ModalWrapper";
import LargeProfileImage from "@/components/shared/SmallProfileImage/LargeProfileImage";
import Spectrums from "@/components/shared/Spectrum/Spectrums";
import SignatureNonSignatureNew from "./SignatureNonSignatureNew/SignatureNonSignatureNew";
import {
  PeriodicQuizInstagramIcon,
  PeriodicQuizSnapchatIcon,
} from "@/components/appHome/PeriodicQuizzes/PeriodicQuizShareIcons";
import { DimensionResultInterractionBar } from "../Snippets/SnippetInterractionBar/SnippetInterractionBar";
import HalfWayModal from "@/components/shared/HalfWayModal/HalfWayModal";
import { useModalContext } from "@/context/ModalContext";
import LoadingModal from "@/components/shared/LoadingModal/LoadingModal";
import { callFirebaseFunctions } from "@/_firebase/callFirebaseFunctions";
import DimensionSummariesShareView from "./DimensionSummariesShareView/DimensionSummariesShareView";
import { TwoByTwoNewNoData } from "@/components/shared/TwoByTwoNew/TwoByTwoNew";
import GoDeeperModal from "@/components/GoDeeper/GoDeeperModal/GoDeeperModal";
import { getAllCurrentChatbotConversations } from "@/context/utils";
import { useAuth } from "@/context/AuthContext";
import {
  setConversations,
  setCurrentConversation,
} from "@/redux/slices/chatbotSlice";

type Props = {
  slug: string;
};

export default function DimensionSummariesModal(props: Props) {
  const router = useRouter();
  const dispatch = useDispatch();

  const { user } = useAuth();

  const [halfwayModalOpen, setHalfWayModalOpen] = useState<boolean>(false);

  const { setModalComponent } = useModalContext();

  const userState = useSelector((state: RootState) => state.user);

  const dimension = dimensionData?.find((d) => d.slug === props.slug);
  const summary = userState.dimensionSummaries?.find(
    (s) => s.dimensionSlug === props.slug
  );

  useEffect(() => {
    Mixpanel?.track("Dimension Summary Viewed", {
      dimension_slug: props.slug,
    });
  }, []);

  useEffect(() => {
    if (halfwayModalOpen) {
      const main = document.getElementById("halfway");
      if (main) {
        setTimeout(() => {
          main.style.bottom = "0px";
        }, 5);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [halfwayModalOpen]);

  const summaryMapper = summary?.summaryViews.map((summ, index) => {
    if (summ.summaryType === "dichotomyArchetype") {
      return (
        <div className={styles.spectrumsDiv} key={index}>
          <Spectrums
            contentIdentifier={{
              contentType: ContentType.dimensionSummary,
              contentSlug: props.slug,
            }}
            otherPublicProfile={null}
            noMargin
          />
        </div>
      );
    }
    if (summ.summaryType === "scoreAndDescriptor") {
      return (
        <div className={styles.scoreAndDescDiv} key={index}>
          <ScoreAndDescriptorFullWidth {...summ.summaryData} />
        </div>
      );
    }
    if (summ.summaryType === "signatureNonSignature") {
      return (
        <SignatureNonSignatureNew
          key={index}
          slug={props.slug}
          data={summ.summaryData}
        />
      );
    }
    if (summ.summaryType === "twoByTwoGrid") {
      return (
        <div className={styles.twoByTwoDiv} key={index}>
          <TwoByTwoNewNoData
            imageUrl={userState.imageURL ?? ""}
            publicProfile={userState.publicProfileData}
            contentIdentifier={{
              contentSlug: props.slug,
              contentType: ContentType.dimensionSummary,
            }}
          />
        </div>
      );
    }
  });

  const mappedDescription = dimension?.summaryDescription.map((s, i) => {
    return (
      <p className={styles.dimensionDetailsDesc} key={i}>
        {s}
      </p>
    );
  });

  const mappedSuggestedMessages = dimension?.suggestedMessages.map(
    (message, i) => {
      return (
        <div
          onClick={() => {
            Mixpanel?.track("“Go deeper” initial message selected", {
              body: message,
              content_type: "dimension summary",
              slug: props.slug,
            });
            setModalComponent(
              <GoDeeperModal
                dimensionSummaryData={{
                  dimensionSlug: props.slug,
                  title: dimension?.title ?? "",
                  suggestedMessage: message,
                }}
              />
            );
          }}
          className={styles.suggestedMessageDiv}
          key={i}
        >
          <p className={styles.suggestedMessage}>{message}</p>
        </div>
      );
    }
  );

  if (!summary) {
    return (
      <ModalWrapper noHeader noLine title="">
        <LoadingScreen message="" />
      </ModalWrapper>
    );
  }

  function handleUpdateGPTCononversations() {
    getAllCurrentChatbotConversations(user?.uid).then((res) => {
      const sortedRes = res.sort(
        (a, b) =>
          (b.latestSystemMessageTime || 0) - (a.latestSystemMessageTime || 0)
      );
      dispatch(setCurrentConversation(sortedRes.length ? sortedRes[0] : null));
      dispatch(setConversations(sortedRes));
    });
  }

  return (
    <ModalWrapper title={dimension?.title ?? ""} subTitle="Dimension Results">
      <main className={styles.main}>
        <section className={styles.top}>
          <LargeProfileImage
            publicProfile={userState.publicProfileData}
            imageUrl={userState.imageURL as string}
            noLink
          />
          <div className={styles.iconDiv}>
            {dimensionIcons[props.slug].icon}
          </div>
        </section>

        <section className={styles.titleAndDescSection}>
          <h1 className={styles.title}>
            Dimension Results: <br /> {dimension?.title}
          </h1>
          <p className={styles.desc}>{dimension?.description}</p>
        </section>

        <section className={styles.summarySection}>{summaryMapper}</section>

        <section className={styles.socialSection}>
          <div className={styles.socialButtonsDiv}>
            <PeriodicQuizInstagramIcon
              onClick={() => {
                setModalComponent(
                  <DimensionSummariesShareView
                    slug={props.slug}
                    data={summary}
                    type="instagram"
                  />
                );
                Mixpanel?.track("Shared to Social", {
                  type: "dimensionSummary",
                  slug: props.slug,
                  platform: "Instagram",
                });
              }}
            />
            <PeriodicQuizSnapchatIcon
              onClick={() => {
                setModalComponent(
                  <DimensionSummariesShareView
                    slug={props.slug}
                    data={summary}
                    type="snapchat"
                  />
                );
                Mixpanel?.track("Shared to Social", {
                  type: "dimensionSummary",
                  slug: props.slug,
                  platform: "Snapchat",
                });
              }}
            />
          </div>
          <p className={styles.shareToText}>Share to</p>
        </section>

        <section className={styles.descriptionSection}>
          <h2 className={styles.dimensionDetailsTitle}>Dimension details</h2>
          {mappedDescription}
        </section>
        {mappedSuggestedMessages?.length ? (
          <section className={styles.suggestedMessagesSection}>
            <div className={styles.suggestedMessagesTitleDiv}>
              <h1 className={styles.suggestedMessagesTitle}>Go deeper</h1>
              <GoDeeperIcon />
            </div>
            <div className={styles.suggestedMessagesWrapper}>
              {mappedSuggestedMessages}
            </div>
          </section>
        ) : null}
        <div className={styles.interractionBarDiv}>
          <DimensionResultInterractionBar
            onGoDeeper={
              props.slug !== "coping-style" &&
              props.slug !== "political-ideology"
                ? () => {
                    Mixpanel?.track("Go deeper” CTA pressed", {
                      content_type: "dimension summary",
                      slug: props.slug,
                    });
                    setModalComponent(
                      <GoDeeperModal
                        dimensionSummaryData={{
                          dimensionSlug: props.slug,
                          title: dimension?.title ?? "",
                        }}
                      />
                    );
                  }
                : undefined
            }
            onRetake={() => {
              setHalfWayModalOpen(true);
            }}
            onShare={(v) => {
              setModalComponent(
                <DimensionSummariesShareView
                  slug={props.slug}
                  data={summary}
                  type={v}
                />
              );
              Mixpanel?.track("Shared to Social", {
                type: "dimensionSummary",
                slug: props.slug,
                platform: v === "snapchat" ? "Snapchat" : "Instagram",
              });
            }}
          />
        </div>
      </main>
      {halfwayModalOpen && (
        <HalfWayModal
          title="Are you sure?"
          message={`Retaking the ${dimension?.title} assessment will reset all of your responses in the Dimension. Associated trait scores, reports, stories, and insights might change.`}
          buttonText="Yes - retake the assessment"
          onClick={(action) => {
            if (action === true) {
              Mixpanel?.track("Dimension reset", {
                dimension_slug: dimension?.slug,
              });

              setModalComponent(<LoadingModal elementName="LoadingModal" />);
              const resetCall = callFirebaseFunctions("resetDimensionData");
              resetCall({ dimensionSlug: props.slug }).then(() => {
                handleUpdateGPTCononversations();
                if (router.asPath === `/app/assessment/${props.slug}`) {
                  localStorage.setItem(
                    "assessmentLoadedSource",
                    "dimension summary"
                  );
                  router.reload();
                } else {
                  router.push(`/app/assessment/${props.slug}`);
                }
              });
            } else {
              setHalfWayModalOpen(false);
            }
          }}
        />
      )}
    </ModalWrapper>
  );
}

function GoDeeperIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
    >
      <path
        d="M15.6113 11.9813C15.6113 11.7694 15.4404 11.5985 15.2285 11.5985C11.7577 11.5985 8.93649 8.25577 8.93649 4.14452C8.93649 3.9326 8.7656 3.76172 8.55369 3.76172C8.34178 3.76172 8.17089 3.93262 8.17089 4.14452C8.17089 8.25577 5.34664 11.5985 1.87889 11.5985C1.66698 11.5985 1.49609 11.7694 1.49609 11.9813C1.49609 12.1932 1.66699 12.3641 1.87889 12.3641C5.34664 12.3641 8.17089 15.7069 8.17089 19.8181C8.17089 20.03 8.34179 20.2009 8.55369 20.2009C8.76561 20.2009 8.93649 20.03 8.93649 19.8181C8.93649 15.7069 11.7587 12.3641 15.2285 12.3641C15.4394 12.3641 15.6113 12.1932 15.6113 11.9813Z"
        fill="#FFFFDE"
      />
      <path
        d="M14.2095 5.02238C15.4683 5.02238 16.4927 6.25578 16.4927 7.77038C16.4927 7.98229 16.6636 8.15318 16.8755 8.15318C17.0874 8.15318 17.2583 7.98228 17.2583 7.77038C17.2583 6.25573 18.2827 5.02238 19.5415 5.02238C19.7534 5.02238 19.9243 4.85148 19.9243 4.63958C19.9243 4.42767 19.7534 4.25678 19.5415 4.25678C18.2827 4.25678 17.2583 3.02338 17.2583 1.50878C17.2583 1.29686 17.0874 1.12598 16.8755 1.12598C16.6636 1.12598 16.4927 1.29687 16.4927 1.50878C16.4927 3.02343 15.4682 4.25678 14.2095 4.25678C13.9975 4.25678 13.8267 4.42767 13.8267 4.63958C13.8267 4.85051 13.9976 5.02238 14.2095 5.02238Z"
        fill="#FFA0B9"
      />
      <path
        d="M24.1267 19.1876C22.0271 19.1876 20.3192 17.1524 20.3192 14.6523C20.3192 14.4404 20.1483 14.2695 19.9364 14.2695C19.7245 14.2695 19.5536 14.4404 19.5536 14.6523C19.5536 17.1523 17.8456 19.1876 15.7461 19.1876C15.5342 19.1876 15.3633 19.3614 15.3633 19.5704C15.3633 19.7823 15.5342 19.9532 15.7461 19.9532C17.8457 19.9532 19.5536 21.9883 19.5536 24.4912C19.5536 24.7031 19.7245 24.874 19.9364 24.874C20.1483 24.874 20.3192 24.7031 20.3192 24.4912C20.3192 21.9882 22.0272 19.9532 24.1267 19.9532C24.3386 19.9532 24.5095 19.7823 24.5095 19.5704C24.5095 19.3614 24.3386 19.1876 24.1267 19.1876Z"
        fill="#E0CC65"
      />
    </svg>
  );
}
