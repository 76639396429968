import { useEffect, useState } from "react";
import Link from "next/link";

import styles from "./OppositeElement.module.css";

import elementsJsonData from "../../../../../assets/traitInfo/elements.json";
import { ExtendedScale } from "@/models/sharedModels";
import Element from "@/components/traits/element/Element/Element";
import TraitsHeading from "../../Heading/TraitsHeading";
import TraitsWrapper from "../../Wrapper/Wrapper";
import { useModalContext } from "@/context/ModalContext";
import ElementModal from "@/components/traits/TraitsModals/Element/ElementModal";

type OppositeElementProps = {
  element: ExtendedScale;
  authenticated?: boolean;
  uid?: string | undefined;
};

export default function OppositeElement(props: OppositeElementProps) {
  const [oppositeElement, setOppositeElement] = useState<null | ExtendedScale>(
    null
  );
  const { setModalComponent } = useModalContext();

  useEffect(() => {
    if (props.element.traitContent.oppositeDetails?.oppositeTrait.slug) {
      let slug = props.element.traitContent.oppositeDetails?.oppositeTrait.slug;
      elementsJsonData.forEach((el) => {
        if (el.slug === slug) {
          //@ts-ignore
          setOppositeElement(el);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TraitsWrapper>
      <TraitsHeading title="Opposite" />

      {oppositeElement !== null && (
        <>
          <h3 className={styles.h3}>
            {props.element.name} is the opposite of {oppositeElement?.name}
          </h3>
          <div className={styles.wrapper}>
            <div className="elBody">
              <Element slug={props.element.slug} />
            </div>
            <div className={styles.arrowsDiv}>
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_32725_36632)">
                  <path
                    d="M26.0896 19.7048L34.9976 12.3688L26.0896 5.03601L26.0896 9.22521L5.00356 9.22521L5.00355 15.5128L26.0896 15.5128L26.0896 19.7048Z"
                    fill="#FFFFF2"
                  />
                  <path
                    d="M13.9084 34.964L5.00355 27.6296L13.9084 20.2952L13.9084 24.486L34.9976 24.486L34.9976 30.7736L13.9084 30.7736L13.9084 34.964Z"
                    fill="#FFFFF2"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_32725_36632">
                    <rect
                      width="40"
                      height="40"
                      fill="white"
                      transform="translate(40) rotate(90)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
            {props.authenticated ? (
              <div
                onClick={() => {
                  setModalComponent(
                    <ElementModal uid={props.uid} slug={oppositeElement.slug} />
                  );
                }}
                key={oppositeElement.slug}
                className="elBody"
                style={{ cursor: "pointer" }}
              >
                <Element slug={oppositeElement.slug} />
              </div>
            ) : (
              <Link
                href={`/traits/elements/${oppositeElement.slug}`}
                key={oppositeElement.slug}
                className="elBody"
              >
                <Element slug={oppositeElement.slug} />
              </Link>
            )}
          </div>
        </>
      )}
    </TraitsWrapper>
  );
}
