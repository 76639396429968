import { useEffect, useState } from "react";

import styles from "./Elements.module.css";

import elementsJsonData from "../../../../assets/traitInfo/elements.json";
import { natureData } from "@/assets/traitInfo/natureData";
import { ExtendedScale } from "@/models/sharedModels";
import { iconMapper } from "@/components/shared/dimensions/icons/iconMapper";
import TraitScore from "../../shared/TraitScore/TraitScore";
import PremiumPillIcon from "@/components/premium/PremiumPillIcon/PremiumPillIcon";
// import useShowPremiumPill from "@/helpers/useShowPremiumPill";

type ElementProps = {
  slug: string;
  score?: number | null;
  showPremiumPill?: boolean;
};

export default function Element(props: ElementProps) {
  const [color, setColor] = useState<string>("gray");
  const [element, setElelemt] = useState<ExtendedScale | null>(null);
  const [icon, setIcon] = useState<JSX.Element | null>(null);
  // const showPremium = useShowPremiumPill(element?.isPremium, element?.slug);

  useEffect(() => {
    elementsJsonData.forEach((el) => {
      if (el.slug === props.slug) {
        setElelemt(el as ExtendedScale);
        if (el.nature) {
          setColor(
            natureData?.find((n) => n.slug === el.nature)?.color ?? "gray"
          );
        }

        setIcon(iconMapper[el.dimensionSlug as keyof typeof iconMapper]);
      }
    });
  }, [props.slug]);

  const name = element?.alias ?? element?.name ?? "";

  if (props.showPremiumPill) {
    return (
      <div className={styles.elementContainer}>
        <div className={styles.elementTop}>
          <div className={styles.iconDiv}>{icon}</div>
        </div>
        <div className={styles.wrapperCircle}>
          <div
            className={styles.mainCircle}
            style={{ border: `${color} 15px solid` }}
          ></div>
          {element?.isPremium && props.showPremiumPill && (
            <div className={styles.premiumIcon}>
              <PremiumPillIcon />
            </div>
          )}
        </div>

        <div className={styles.elementNameContainer}>
          <p
            className={styles.elName}
            //keeping the last part in case we wanna chage it
            style={
              name?.length >= 20
                ? { fontSize: "10px" }
                : name?.length >= 15
                ? { fontSize: "12px" }
                : {}
            }
          >
            {element?.alias ?? element?.name}
          </p>
        </div>
        {!!props.score && <TraitScore score={props.score * 100} />}
      </div>
    );
  }

  return (
    <div className={styles.elementContainer}>
      <div className={styles.elementTop}>
        <div className={styles.iconDiv}>{icon}</div>
      </div>
      <div className={styles.wrapperCircle}>
        <div
          className={styles.mainCircle}
          style={{ border: `${color} 15px solid` }}
        ></div>
        {element?.isPremium && (
          <div className={styles.premiumIcon}>
            <PremiumPillIcon />
          </div>
        )}
      </div>

      <div className={styles.elementNameContainer}>
        <p
          className={styles.elName}
          //keeping the last part in case we wanna chage it
          style={
            name?.length >= 20
              ? { fontSize: "10px" }
              : name?.length >= 15
              ? { fontSize: "12px" }
              : {}
          }
        >
          {element?.alias ?? element?.name}
        </p>
      </div>
      {!!props.score && <TraitScore score={props.score * 100} />}
    </div>
  );
}
