import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useRouter } from "next/router";

import styles from "../../../styles/app/AppHome.module.css";

import RewardTab from "@/components/appHome/rewards/RewardTab";
import RewardsBar from "@/components/appHome/rewards/RewardsBar";
import ContinueAssessmentsBar from "@/components/appHome/ContinueAssessmentsBar/ContinueAssessmentsBar";
import LatestResultsBar from "@/components/appHome/LatestResultsBar/LatestResultsBar";
import { RootState } from "@/redux/store";
import SecureYourAccountBlock from "@/components/appHome/SecureYourAccountBlock/SecureYourAccountBlock";
import { useAuth } from "@/context/AuthContext";
import { isDateExpired } from "@/components/appHome/utils";
import { dimensionData } from "@/assets/traitInfo/dimensionData";
import AddConnectionsBlock from "@/components/appHome/AddConnectionsBlock/AddConnectionsBlock";
import SocialLinks from "@/components/appHome/SocialLinks/SocialLinks";
import { PeerAssessmentEventStatus } from "@dimensional-engineering/dimensional-models";
import NotificationWarningPill from "@/components/NotificationModule/NotificationWarningPill/NotificationWarningPill";
import NotificationBar from "@/components/NotificationModule/NotificationBar/NotificationBar";
import DailyStoriesHomeCTA from "@/components/appHome/DailyStories/DailyStoriesHomeCTA";
import PeriodicQuizHomeCta from "@/components/appHome/PeriodicQuizzes/PeriodicQuizHomeCta/PeriodicQuizHomeCta";
import DailyInsightsCTABlock from "@/components/appHome/DailyInsights/DailyInsightsCTABlock/DailyInsightsCTABlock";
import { useGetDimensionStatusAndTraitCount } from "@/pages/app/assessment";
import CommunityChatter from "../CommunityChatter/CommunityChatter";
import BetterHelpCTA from "../BetterHelpCTA/BetterHelpCTA";
import { NotificationLevel } from "@/models/sharedModels";
import { useModalContext } from "@/context/ModalContext";
import CommunityQuestionsModal from "../CommunityQuestions/CommunityQuestionsModal";

export default function AppHomeForYouTab() {
  const router = useRouter();
  const communityQuestionRef = useRef<HTMLDivElement | null>(null);

  const { setModalComponent } = useModalContext();

  const userState = useSelector((state: RootState) => state.user);
  const peerAssessmentEvents = useSelector(
    (state: RootState) => state.peerAssessment.events
  );
  const dailyStory = useSelector((state: RootState) => state.dailyStory);
  const quizState = useSelector((state: RootState) => state.periodicQuiz);
  const dailyInsight = useSelector(
    (state: RootState) => state.dailyInsight
  ).deliveredDailyInsight;
  const userPoll = useSelector((state: RootState) => state.userPoll).userPoll;

  const communityQuestion = useSelector(
    (state: RootState) => state.communityQuestion
  ).communityQuestion;

  const peerAssessmentEventsStarted = peerAssessmentEvents.filter(
    (event) => event.event === PeerAssessmentEventStatus.started
  );

  const { showSecurityBlock, setNoShow } = usePhoneNumberLocalStorage();
  const { showConnectionsBlock, setNoShowConnection } =
    useConnectionsBlockLocalStorage();
  const { showPeerAssessmentBlock, hidePeerAssessmentBlock } =
    usePeerAssessmentBlockLocalStorage();
  const allDimensionsComplete = useAreAllAssessmentsComplete();
  const notificationStatus = useNotificationBarStatus();

  const [showRewardsBar, setShowRewardsBar] = useState<boolean>(false);
  const [showFeedBackBar, setShowFeedBackBar] = useState<boolean>(false);
  const [showNotificationBar, setShowNotificationBar] =
    useState<boolean>(false);

  const [mappedComponents, setMappedComponents] = useState<JSX.Element[]>([]);
  const [componentReady, setComponentReady] = useState<boolean>(false);

  useEffect(() => {
    if (
      notificationStatus === "systemOff" ||
      notificationStatus === "noSelection"
    ) {
      //notification bar takes priority
      setShowNotificationBar(true);
      setShowFeedBackBar(false);
      setShowRewardsBar(false);
    } else if (userState.inviteLevel !== 4) {
      setShowRewardsBar(true);
      setShowFeedBackBar(false);
      setShowNotificationBar(false);
    } else {
      if (userState.peerFeedbackLevel !== 4) {
        setShowFeedBackBar(true);
        setShowNotificationBar(false);
        setShowRewardsBar(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationStatus, userState.inviteLevel, userState.peerFeedbackLevel]);

  const dailyStoryComponent = (
    <div key={0} className={styles.assessmentDiv}>
      <DailyStoriesHomeCTA />
    </div>
  );

  const periodicQuizComponent = quizState.availableQuiz && (
    <div key={2} className={styles.assessmentDiv}>
      <PeriodicQuizHomeCta />
    </div>
  );

  const dailyInsightComponent = (
    <div key={3} className={styles.assessmentDiv}>
      <DailyInsightsCTABlock />
    </div>
  );

  useEffect(() => {
    const mapped = [
      dailyStoryComponent,
      periodicQuizComponent,
      dailyInsightComponent,
    ]
      .filter((c): c is JSX.Element => !!c) // Filter out falsy values
      .sort((a: JSX.Element, b: JSX.Element) => {
        // Retrieve viewed dates from localStorage
        const LSDailyInsightViewed = localStorage.getItem(
          "dailyInsightViewedCreatedOn"
        );
        const LSPeriodicQuizViewed = localStorage.getItem(
          "periodicQuizViewedCreatedOn"
        );
        const LSDailyStoryViewed = localStorage.getItem(
          "dailyStoryViewedCreatedOn"
        );

        // Check whether each item has been viewed
        const isViewedDailyInsight =
          dailyInsight === null
            ? true
            : LSDailyInsightViewed
            ? LSDailyInsightViewed === dailyInsight?.deliveredOn?.toString()
            : false;

        const isViewedPeriodicQuiz = LSPeriodicQuizViewed
          ? LSPeriodicQuizViewed ===
            quizState.availableQuiz?.deliveredOn?.toString()
          : false;

        const isViewedDailyStory = !dailyStory.dailyStory
          ? true
          : LSDailyStoryViewed
          ? LSDailyStoryViewed ===
            dailyStory.dailyStory?.deliveredOn?.toString()
          : false;

        // Determine if 'a' and 'b' are viewed or not
        const isAViewed =
          a.key === "3"
            ? isViewedDailyInsight
            : a.key === "2"
            ? isViewedPeriodicQuiz
            : isViewedDailyStory;
        const isBViewed =
          b.key === "3"
            ? isViewedDailyInsight
            : b.key === "2"
            ? isViewedPeriodicQuiz
            : isViewedDailyStory;

        // Sort by "unread" (false) first, then "read" (true)
        if (isAViewed !== isBViewed) {
          return isAViewed ? 1 : -1;
        }

        // Get creation dates from localStorage for sorting
        const getCreatedOnDate = (key: string) => {
          if (key === "3") {
            return dailyInsight?.deliveredOn
              ? parseInt(dailyInsight.deliveredOn?.toString(), 10)
              : 0;
          } else if (key === "2") {
            return quizState.availableQuiz?.deliveredOn
              ? parseInt(quizState.availableQuiz.deliveredOn?.toString(), 10)
              : 0;
          } else {
            return dailyStory.dailyStory?.deliveredOn
              ? parseInt(dailyStory.dailyStory.deliveredOn?.toString(), 10)
              : 0;
          }
        };

        const dateA = getCreatedOnDate(a.key?.toString() || "");
        const dateB = getCreatedOnDate(b.key?.toString() || "");

        // Sort by creation date if available
        if (dateA !== dateB) {
          return dateB - dateA; // More recent first
        }

        // If still equal, fall back to sorting by key
        const keyA = parseInt(a.key?.toString() || "0");
        const keyB = parseInt(b.key?.toString() || "0");

        return keyA - keyB;
      })
      .map((c) => {
        return c;
      });
    setMappedComponents(mapped);
    setComponentReady(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communityQuestion]);

  const LSViewCommunityQuestion = localStorage.getItem("viewCommunityQuestion");
  const LSViewCommunityQuestionCommentId = localStorage.getItem(
    "communityQuestionCommentId"
  );
  useEffect(() => {
    //This handles scrolling to community question cta from notification click
    if (
      communityQuestion &&
      communityQuestionRef.current &&
      LSViewCommunityQuestion &&
      componentReady
    ) {
      communityQuestionRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      communityQuestionRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      communityQuestionRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      communityQuestionRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      communityQuestionRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      if (LSViewCommunityQuestionCommentId) {
        setTimeout(() => {
          setModalComponent(
            <CommunityQuestionsModal
              elementName="CommunityQuestionsModal"
              commentId={LSViewCommunityQuestionCommentId}
            />
          );
          return localStorage.removeItem("communityQuestionCommentId");
        }, 1000);
      }
      localStorage.removeItem("viewCommunityQuestion");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    communityQuestion,
    LSViewCommunityQuestion,
    componentReady,
    communityQuestionRef.current,
    LSViewCommunityQuestionCommentId,
  ]);

  return (
    <>
      <section className={styles.wrapper}>
        <div className={styles.topIconDiv}>
          <RewardTab
            inActive={showRewardsBar === false}
            onClick={() => {
              setShowRewardsBar(!showRewardsBar);
              if (showFeedBackBar) {
                setShowFeedBackBar(false);
              }
              if (showNotificationBar) {
                setShowNotificationBar(false);
              }
            }}
          />
          {/* <PeerFeedbackPill
            inActive={showFeedBackBar === false}
            numOfPeerAssessmentsReceived={
              userState.memberProfile?.peerAssesseeUids?.length
            }
            onClick={() => {
              setShowFeedBackBar(!showFeedBackBar);
              if (showRewardsBar) {
                setShowRewardsBar(false);
              }
              if (showNotificationBar) {
                setShowNotificationBar(false);
              }
            }}
          /> */}
          <NotificationWarningPill
            onClick={() => {
              setShowNotificationBar(!showNotificationBar);
              if (showRewardsBar) {
                setShowRewardsBar(false);
              }
              if (showFeedBackBar) {
                setShowFeedBackBar(false);
              }
            }}
            active={showNotificationBar}
            notificationStatus={notificationStatus}
          />
        </div>
        {showRewardsBar && (
          <div className={styles.rewardsDiv}>
            <RewardsBar />
          </div>
        )}
        {/* {showFeedBackBar && (
          <div className={styles.rewardsDiv}>
            <FeedBackBar />
          </div>
        )} */}
        {showNotificationBar && (
          <div className={styles.rewardsDiv}>
            <NotificationBar
              notificationStatus={notificationStatus}
              source="Home warning pill"
              onSelection={() => {
                setShowNotificationBar(false);
                if (userState.inviteLevel !== 4) {
                  setShowRewardsBar(true);
                  setShowFeedBackBar(false);
                } else {
                  if (userState.peerFeedbackLevel !== 4) {
                    setShowFeedBackBar(true);
                  }
                }
              }}
            />
          </div>
        )}
        {allDimensionsComplete && !peerAssessmentEventsStarted.length ? null : (
          <div className={styles.assessmentDiv}>
            <ContinueAssessmentsBar />
          </div>
        )}

        <div className={styles.assessmentDiv}>
          <LatestResultsBar />
        </div>

        <h2 className={styles.homeDailyTitle}>For you today</h2>
        <p className={styles.homeDailyDesc}>
          New content each day!{" "}
          <span
            onClick={() => router.push(`/app/assessment`)}
            className={styles.homeDailyDescLink}
          >
            Complete all Dimensions
          </span>{" "}
          to improve content accuracy.
        </p>

        {mappedComponents}

        <div className={styles.assessmentDiv}>
          <BetterHelpCTA />
        </div>

        <h2
          ref={communityQuestionRef}
          style={{ marginTop: "50px" }}
          className={styles.homeDailyTitle}
        >
          Community chatter
        </h2>
        <CommunityChatter />

        {showSecurityBlock && (
          <div className={styles.assessmentDiv}>
            <SecureYourAccountBlock onNotNow={() => setNoShow()} />
          </div>
        )}
        {showConnectionsBlock && !showSecurityBlock && (
          <div className={styles.assessmentDiv}>
            <AddConnectionsBlock
              onNotNow={() => {
                setNoShowConnection();
              }}
            />
          </div>
        )}
        {/* {showPeerAssessmentBlock &&
          !showConnectionsBlock &&
          !showSecurityBlock && (
            <div className={styles.assessmentDiv}>
              <PeerAssessmentCTABlock
                onNotNow={() => {
                  hidePeerAssessmentBlock();
                }}
              />
            </div>
          )} */}
        <div className={styles.assessmentDiv}>
          <SocialLinks />
        </div>
      </section>
    </>
  );
}

function usePhoneNumberLocalStorage() {
  const [showSecurityBlock, setShowSecurityBlock] = useState<boolean>(true);
  const { user } = useAuth();
  const localStoragePhoneExpiry = localStorage.getItem(
    "securityBlockDismissal"
  );

  function setNoShow() {
    //called to immediately hide
    setShowSecurityBlock(false);
  }

  useEffect(() => {
    //if user has phone number, don't show
    setShowSecurityBlock(true);
    if (user?.phoneNumber) {
      setShowSecurityBlock(false);
    } else {
      //if they don't have a phone number
      if (localStoragePhoneExpiry) {
        //has clicked on not now
        const parsed: number = JSON.parse(localStoragePhoneExpiry);
        const expired = isDateExpired(parsed);
        if (expired) {
          //expired
          setShowSecurityBlock(true);
        } else {
          setShowSecurityBlock(false);
        }
      } else {
        //not clicked not now
        setShowSecurityBlock(true);
      }
    }
  }, [localStoragePhoneExpiry, user?.phoneNumber]);

  return { showSecurityBlock, setNoShow };
}

function useConnectionsBlockLocalStorage() {
  const [showConnectionsBlock, setShowConnectionsBlock] =
    useState<boolean>(true);
  const friendUidList = useSelector(
    (state: RootState) => state.user.friendUidList
  );
  const localStorageConnectionExpiry = localStorage.getItem(
    "connectionDismissal"
  );

  function setNoShowConnection() {
    //called to immediately hide
    setShowConnectionsBlock(false);
  }

  useEffect(() => {
    //if user has phone number, don't show
    setShowConnectionsBlock(true);
    if (friendUidList.length > 0) {
      setShowConnectionsBlock(false);
    } else {
      if (localStorageConnectionExpiry) {
        //has clicked on not now
        const parsed: number = JSON.parse(localStorageConnectionExpiry);
        const expired = isDateExpired(parsed);
        if (expired) {
          //expired
          setShowConnectionsBlock(true);
        } else {
          setShowConnectionsBlock(false);
        }
      } else {
        //not clicked not now
        setShowConnectionsBlock(true);
      }
    }
  }, [localStorageConnectionExpiry, friendUidList.length]);

  return { showConnectionsBlock, setNoShowConnection };
}

function usePeerAssessmentBlockLocalStorage() {
  const [showPeerAssessmentBlock, setShowPeerAssessmentBlock] =
    useState<boolean>(true);
  const recomendationList = useSelector(
    (state: RootState) => state.peerAssessment.ctaRecommendationList
  );

  const localStoragePeerAssessmentExpiry = localStorage.getItem(
    "peerAssessmentDismissal"
  );

  function hidePeerAssessmentBlock() {
    setShowPeerAssessmentBlock(false);
  }

  useEffect(() => {
    if (recomendationList.length === 0) {
      setShowPeerAssessmentBlock(false);
    } else if (localStoragePeerAssessmentExpiry) {
      //has clicked on not now
      const parsed: number = JSON.parse(localStoragePeerAssessmentExpiry);
      const expired = isDateExpired(parsed);
      if (expired) {
        //expired
        setShowPeerAssessmentBlock(true);
      } else {
        setShowPeerAssessmentBlock(false);
      }
    } else {
      setShowPeerAssessmentBlock(true);
    }
  }, [localStoragePeerAssessmentExpiry, recomendationList.length]);

  return { showPeerAssessmentBlock, hidePeerAssessmentBlock };
}

export function useAreAllAssessmentsComplete() {
  const { dimensionsComplete } = useGetDimensionStatusAndTraitCount();

  if (dimensionData.length === dimensionsComplete) {
    return true;
  }

  return false;
}

export function useNotificationBarStatus() {
  const systemNotificationStatus = useSelector(
    (state: RootState) => state.user
  ).notificationPermission;
  const selectedNotification = useSelector((state: RootState) => state.user)
    .memberProfile?.selectedNotificationLevel;

  const [notificationStatus, setnotificationStatus] = useState<
    "systemOff" | "noSelection" | NotificationLevel
  >("noSelection");

  useEffect(() => {
    if (!systemNotificationStatus) {
      setnotificationStatus("systemOff");
    } else {
      if (!selectedNotification) {
        setnotificationStatus("noSelection");
      } else {
        setnotificationStatus(selectedNotification);
      }
    }
  }, [systemNotificationStatus, selectedNotification]);

  return notificationStatus;
}
