import { useSelector } from "react-redux";
import { DimensionSummaryData } from "@dimensional-engineering/dimensional-models";

import styles from "./SignatureNonSignatureNew.module.css";

import elementsJsonData from "../../../../assets/traitInfo/elements.json";

import { dimensionData } from "@/assets/traitInfo/dimensionData";
import { natureData } from "@/assets/traitInfo/natureData";
import { useModalContext } from "@/context/ModalContext";
import ElementModal from "@/components/traits/TraitsModals/Element/ElementModal";
import GetPlusButton from "@/components/premium/GetPlusButton/GetPlusButton";
import { RootState } from "@/redux/store";

type Props = {
  data: DimensionSummaryData;
  slug: string;
};

export default function SignatureNonSignatureNew(props: Props) {
  const { setModalComponent } = useModalContext();

  const isUserPremium = useSelector((state: RootState) => state.user).isPremium;

  const dimension = dimensionData?.find((d) => d.slug === props.slug);

  const mappedTop = props.data.signatureElements?.map((el, index) => {
    const element = elementsJsonData.find((e) => e.slug === el.slug);

    if (element) {
      const backgroundColor = natureData?.find(
        (n) => n.slug == element.nature
      )?.color;

      return (
        <div
          style={
            backgroundColor
              ? { border: `2px solid ${backgroundColor}` }
              : { border: `2px solid grey` }
          }
          className={styles.elementDiv}
          key={el.slug}
          onClick={() => {
            setModalComponent(<ElementModal slug={element.slug} />);
          }}
        >
          <p>{index + 1}.</p>
          <p>{element.alias ?? element.name}</p>
        </div>
      );
    }
  });

  const mappedOther = props.data.nonSignatureElements?.map((el, index) => {
    const element = elementsJsonData.find((e) => e.slug === el.slug);
    const topLength = mappedTop?.length ?? 0;
    if (element) {
      const backgroundColor = natureData?.find(
        (n) => n.slug == element.nature
      )?.color;

      return (
        <div
          style={
            backgroundColor
              ? { border: `2px solid ${backgroundColor}` }
              : { border: `2px solid grey` }
          }
          className={styles.elementDiv}
          key={el.slug}
          onClick={() => {
            setModalComponent(<ElementModal slug={element.slug} />);
          }}
        >
          <p>{topLength + index + 1}.</p>
          <p>{element.alias ?? element.name}</p>
        </div>
      );
    }
  });

  if (dimension?.isPremium === true && !isUserPremium) {
    return <SignatureNonSignatureNewPremiumGhostbox {...props} />;
  }

  return (
    <main className={styles.main}>
      <h1 className={styles.title}>Ranking of {dimension?.title}</h1>
      <div className={styles.wrapper}>
        <p className={styles.label}>TOP {dimension?.title.toUpperCase()}</p>
        {mappedTop}
        <p className={styles.label}>OTHER {dimension?.title.toUpperCase()}</p>
        {mappedOther}
      </div>
    </main>
  );
}

function SignatureNonSignatureNewPremiumGhostbox(props: Props) {
  const dimension = dimensionData?.find((d) => d.slug === props.slug);

  return (
    <main className={styles.main}>
      <h1 className={styles.title}>Ranking of {dimension?.title}</h1>
      <div className={styles.wrapperGhostbox}>
        <div className={styles.ghostboxDiv}>
          <p className={styles.ghostboxText}>
            Unlocking traits in this Dimension requires Plus.
          </p>
          <GetPlusButton source="Dimension Results" />
        </div>
      </div>
    </main>
  );
}
