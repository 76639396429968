import { useEffect } from "react";
import { db } from "@/_firebase/firebaseConfig";
import { dimensionData } from "@/assets/traitInfo/dimensionData";
import {
  ConnectionAvailability,
  DimensionEvent,
  DiscoveredPeerStory,
  ListicleEmojiReaction,
  ListicleStatement,
  PeerStoryContent,
  PublicProfile,
} from "@dimensional-engineering/dimensional-models";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/redux/store";

import { checkConnectionDimensionDiscoveryGate } from "../Compatibility/utils";
import {
  ExtendedPeerStoryGated,
  PeerStoriesGating,
} from "@/models/sharedModels";
import { Mixpanel } from "@/helpers/mixpanel";
import { setCurrentPeerStories } from "@/redux/slices/peerStoriesSlice";

export function getRequiredConnectionLevel(
  connectionAvailabilities: ConnectionAvailability[],
  connectionLevel:
    | "connected"
    | "close"
    | "deep"
    | "sent"
    | "unconnected"
    | "received"
    | null
): ConnectionAvailability | null {
  //null means permission required connection is active.

  if (connectionLevel === "unconnected") {
    return ConnectionAvailability.basic;
  }

  if (connectionAvailabilities.includes(ConnectionAvailability.private)) {
    return ConnectionAvailability.private;
  }

  if (connectionAvailabilities.includes(ConnectionAvailability.unconnected)) {
    return ConnectionAvailability.unconnected;
  }

  if (connectionAvailabilities.includes(ConnectionAvailability.basic)) {
    if (
      connectionLevel !== "deep" &&
      connectionLevel !== "close" &&
      connectionLevel !== "connected"
    ) {
      return ConnectionAvailability.basic;
    }
    if (
      connectionLevel !== "deep" &&
      connectionLevel !== "close" &&
      connectionLevel === "connected"
    ) {
      return null;
    }
  }

  if (connectionAvailabilities.includes(ConnectionAvailability.close)) {
    if (connectionLevel !== "deep" && connectionLevel !== "close") {
      return ConnectionAvailability.close;
    }
    if (connectionLevel !== "deep" && connectionLevel === "close") {
      return null;
    }
  }

  if (
    connectionAvailabilities.includes(ConnectionAvailability.deep) &&
    connectionLevel !== "deep"
  ) {
    return ConnectionAvailability.deep;
  }

  return null;
}

export function hasCompletedRequiredDimensions(
  requiredDimensionSlugs: string[],
  userDimensionEvents: DimensionEvent[] | null
): boolean {
  let data = dimensionData.map((item) => ({ ...item }));

  for (let i = 0; i < data.length; i++) {
    if (requiredDimensionSlugs.includes(data[i].slug)) {
      data[i].isNeeded = true;
    }
  }

  if (userDimensionEvents && userDimensionEvents?.length > 0) {
    let finishedSlugs: string[] = [];
    let startedSlugs: string[] = [];
    userDimensionEvents.forEach((userDimEvent) => {
      if (userDimEvent.event === "complete") {
        finishedSlugs.push(userDimEvent.dimensionSlug);
      } else {
        startedSlugs.push(userDimEvent.dimensionSlug);
      }
    });

    for (let i = 0; i < data.length; i++) {
      if (finishedSlugs.includes(data[i].slug)) {
        data[i].event = "Retake";
      } else if (startedSlugs.includes(data[i].slug)) {
        data[i].event = "Continue";
      } else {
        data[i].event = "Start";
      }
    }
  }

  for (let i = 0; i < data.length; i++) {
    if (data[i].isNeeded === true && data[i].event !== "Retake") {
      return false;
    }
  }

  return true;
}

export async function checkConnectionDimensionCompletion(
  publicProfile: PublicProfile | null | undefined,
  requiredDimensionSlugs: string[]
): Promise<null | "Nudge view"> {
  let dimensionEventRef = collection(
    db,
    `members/${publicProfile?.ownerUUID}/dimensionEvents`
  );
  return await getDocs(dimensionEventRef).then((querySnap) => {
    let dimSlugsComplete: string[] = [];

    querySnap.forEach((doc) => {
      const d = doc.data() as DimensionEvent;
      if (d.event === "complete") {
        dimSlugsComplete.push(d.dimensionSlug);
      }
    });

    const connectionCompletedAllDimentsions =
      checkConnectionDimensionDiscoveryGate(
        dimSlugsComplete,
        requiredDimensionSlugs
      );

    if (!connectionCompletedAllDimentsions) {
      return "Nudge view";
    } else {
      return null;
    }
  });
}

export function handleUiChangesOnEmoji(id: string) {
  const element = document.getElementById(id) as HTMLDivElement | null;
  if (element) {
    element.style.width = "190px";
    setTimeout(() => {
      element.style.opacity = "1";
    }, 50);
  }
}

export function handleClosureOfEmojiDiv(id: string) {
  const element = document.getElementById(id) as HTMLDivElement | null;
  if (element) {
    element.style.width = "0";
    // element.style.opacity = "0";
  }
}

export const emojiMap: Record<ListicleEmojiReaction, string> = {
  laugh: "😂",
  heart: "❤️️",
  thumbsDown: "👎️",
};

export function updatePeerStoriesEmojiInDatabase(
  uid: string | undefined,
  connectionUid: string,
  storySlug: string,
  updatedStatements: ListicleStatement[]
) {
  const d = doc(
    db,
    `/members/${uid}/discoveredPeerStories/${storySlug}-${connectionUid}`
  );
  return updateDoc(d, {
    "peerStoryContent.listicleStatements": updatedStatements,
  }).then((res) => {});
}

export function updateStoryFeedback(
  uid: string | undefined,
  connectionUid: string,
  storySlug: string,
  value: number
) {
  const d = doc(
    db,
    `/members/${uid}/discoveredPeerStories/${storySlug}-${connectionUid}`
  );

  return setDoc(d, { feedbackScore: value }, { merge: true });
}

export function useFetchPeerStoryContent(props: {
  uid: string | undefined;
  connectionUid: string;
  connectionUsername: string | undefined;
  story: ExtendedPeerStoryGated;
  setBody: (content: PeerStoryContent) => void;
}) {
  ///listener for when the user generates a story

  const dispatch = useDispatch();

  const myUsername = useSelector(
    (state: RootState) => state.user.publicProfileData
  )?.userName;

  const currentPeerStories = useSelector(
    (state: RootState) => state.peerStories
  ).currentPeerStories;

  const foundStory = currentPeerStories.find(
    (s) => s.slug === props.story.slug
  );

  useEffect(() => {
    const ref = collection(db, `/members/${props.uid}/discoveredPeerStories`);
    const q = query(
      ref,
      where("connectionUid", "==", props.connectionUid),
      where("slug", "==", props.story.slug),
      limit(1)
    );

    if (!props.story.peerStoryContent && !foundStory?.peerStoryContent) {
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = doc.data() as DiscoveredPeerStory;
          props.setBody(data.peerStoryContent);
          const story = currentPeerStories.find((s) => s.slug === data.slug);
          if (story) {
            const updatedStory = {
              ...story,
              peerStoryContent: data.peerStoryContent,
              feedbackScore: data.feedbackScore,
              connectionUid: data.connectionUid,
            };
            dispatch(
              setCurrentPeerStories(
                currentPeerStories.map((s) => {
                  if (s.slug === updatedStory.slug) {
                    return updatedStory;
                  } else {
                    return s;
                  }
                })
              )
            );
          }
          Mixpanel?.track("Peer Story Viewed", {
            slug: props.story.slug,
            state: "generated",
            triggeree: props.connectionUsername,
            triggerer: myUsername,
          });
        });
      });

      return () => unsubscribe();
    }
  }, [props.story.peerStoryContent, foundStory?.peerStoryContent]);
}

export function useRegeneratePeerStoryContent(props: {
  uid: string | undefined;
  connectionUid: string;
  connectionUsername: string | undefined;
  story: ExtendedPeerStoryGated;
  regenerateLoading: boolean;
  isLoading: boolean;
  setBody: (content: PeerStoryContent) => void;
  setRegenerateLoading: (value: boolean) => void;
}) {
  ///listener for when the user regenerates a story
  const dispatch = useDispatch();

  const myUsername = useSelector(
    (state: RootState) => state.user.publicProfileData
  )?.userName;

  const currentPeerStories = useSelector(
    (state: RootState) => state.peerStories
  ).currentPeerStories;

  const foundStory = currentPeerStories.find(
    (s) => s.slug === props.story.slug
  );

  useEffect(() => {
    const ref = collection(db, `/members/${props.uid}/discoveredPeerStories`);
    const q = query(
      ref,
      where("connectionUid", "==", props.connectionUid),
      where("slug", "==", props.story.slug),
      limit(1)
    );

    if (
      props.regenerateLoading === true &&
      props.isLoading === false &&
      !foundStory?.peerStoryContent
    ) {
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        querySnapshot.forEach((d) => {
          const data = d.data() as DiscoveredPeerStory;
          props.setBody(data.peerStoryContent);
          props.setRegenerateLoading(false);
          const story = currentPeerStories.find((s) => s.slug === data.slug);
          if (story) {
            const updatedStory = {
              ...story,
              peerStoryContent: data.peerStoryContent,
              feedbackScore: data.feedbackScore,
              connectionUid: data.connectionUid,
            };
            dispatch(
              setCurrentPeerStories(
                currentPeerStories.map((s) => {
                  if (s.slug === updatedStory.slug) {
                    return updatedStory;
                  } else {
                    return s;
                  }
                })
              )
            );
          }

          Mixpanel?.track("Peer Story Viewed", {
            slug: props.story.slug,
            state: "generated",
            triggeree: props.connectionUsername,
            triggerer: myUsername,
          });
        });
      });

      return () => {
        unsubscribe();
      };
    }
  }, [props.regenerateLoading, props.isLoading, foundStory?.peerStoryContent]);
}

export async function getSinglePeerStoryForNotificationAndLatestResults(
  userUid: string | undefined,
  peerUid: string | undefined,
  slug: string | undefined
) {
  const ref = doc(
    db,
    `/members/${userUid}/discoveredPeerStories/${slug}-${peerUid}`
  );
  return await getDoc(ref)
    .then((res) => {
      if (res.exists()) {
        return res.data() as DiscoveredPeerStory;
      } else {
        return null;
      }
    })
    .catch((err) => {
      return null;
    });
}

export function handleWhichSideClickHappened(
  event: MouseEvent
): "left" | "right" {
  const div = event.currentTarget as HTMLDivElement;
  const rect = div.getBoundingClientRect();
  const clickX = event.clientX;

  const isLeftSide = clickX < rect.left + rect.width / 2;

  if (isLeftSide) {
    return "left";
  } else {
    return "right";
  }
}

export function handleGatingEnumForMixpanel(gating: PeerStoriesGating) {
  if (gating === false) {
    return "generated";
  } else if (gating === "discoveryGate") {
    return "dimension-gated";
  } else if (gating === "nudge") {
    return "connection dimension-gated";
  } else {
    return "connection-level gated";
  }
}
