import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useRouter } from "next/router";
import {
  PeerAssessmentEvent,
  PeerAssessmentEventStatus,
} from "@dimensional-engineering/dimensional-models";
import { Roboto_Mono } from "next/font/google";

import styles from "./ContinueAssessmentsBar.module.css";

import {
  DimensionDataType,
  dimensionData as dimensionInitialData,
  dimensionIcons,
} from "@/assets/traitInfo/dimensionData";
import { RootState } from "@/redux/store";
import { sortAssessmentBarData, sortFlattenedAssessmentBar } from "../utils";
import SmallProfileImage from "@/components/shared/SmallProfileImage/SmallProfileImage";
import { Mixpanel } from "@/helpers/mixpanel";
import { transformLongUsername } from "@/components/shared/utils";
import ForwardArrow from "@/components/shared/buttons/ForwardArrow";

const font = Roboto_Mono({ subsets: ["latin"] });

export default function ContinueAssessmentsBar() {
  const router = useRouter();

  const dimensionEvents = useSelector(
    (state: RootState) => state.user.dimensionEvents
  );

  const peerAssessmentEvents = useSelector(
    (state: RootState) => state.peerAssessment.events
  );

  const peerAssessmentEventsStarted = peerAssessmentEvents.filter(
    (event) => event.event === PeerAssessmentEventStatus.started
  );

  const connectionsPublicProfiles = useSelector(
    (state: RootState) => state.connections.publicProfiles
  );

  const displayData = useGetDimensionStatus();

  const mapped = [
    displayData.sort((a, b) => sortAssessmentBarData(a, b, dimensionEvents)),
    // peerAssessmentEventsStarted.sort((a, b) => {
    //   if (a.createdOn && b.createdOn) {
    //     return a.createdOn - b.createdOn;
    //   }
    //   return 0;
    // }),
  ]
    .flat()
    .sort((a, b) => sortFlattenedAssessmentBar(a, b))
    .filter((a) => {
      if ("assesseeUid" in a) {
        return true;
      } else {
        if (a.isPremium === true) {
          return false;
        }
        if (a.event === "Retake") {
          return false;
        }
        return true;
      }
    })
    .map((dim, i) => {
      // if ("assesseeUid" in dim) {
      //   const event = dim as PeerAssessmentEvent;
      //   const publicProfile = connectionsPublicProfiles?.find(
      //     (p) => p.ownerUUID === event.assesseeUid
      //   );
      //   if (!publicProfile) {
      //     return <div key={i}></div>;
      //   }
      //   return (
      //     <div
      //       className={styles.assessmentDiv}
      //       key={`${event.peerAssessmentSlug}${i}`}
      //       onClick={() => {
      //         Mixpanel?.track("Peer Assessment Intro Viewed", {
      //           source: "dashboard - Continue",
      //         });
      //         return router.push(
      //           `/app/peer-assessment/peer-core/${publicProfile.ownerUUID}`
      //         );
      //       }}
      //     >
      //       <p className={styles.dimensionText}>PEER ASSESSMENT</p>
      //       <div className={styles.iconDiv}>
      //         <SmallProfileImage
      //           noLink
      //           medium
      //           publicProfile={publicProfile ?? null}
      //           imageUrl={publicProfile?.imageUrl ?? "/default-image.png"}
      //         />
      //       </div>
      //       <h4 className={styles.dimTitle}>{`@${transformLongUsername(
      //         publicProfile
      //       )}`}</h4>
      //       <button className={styles.btn}>Continue</button>
      //     </div>
      //   );
      // }
      const progress =
        dimensionEvents?.find((d) => d.dimensionSlug === dim.slug)?.progress ??
        0;
      return (
        <div
          style={{
            backgroundImage: `url(/dimensions/backgrounds/${dim.slug}.jpg)`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          className={styles.assessmentDiv}
          key={`${dim.slug}${i}`}
          onClick={() => {
            localStorage.setItem(
              "assessmentLoadedSource",
              "dashboard continue dimensions section"
            );
            return router.push(`/app/assessment/${dim.slug}`);
          }}
        >
          <div className={styles.iconDiv}>{dimensionIcons[dim.slug].icon}</div>
          <h4 className={styles.dimTitle}>{dim.title}</h4>
          <p className={styles.dimDesc}>{dim.description}</p>
          <p className={`${styles.dimDetailsText} ${font.className}`}>
            {dim.estimatedTime} minutes - {dim.numOfElements} Elements
          </p>
          <button className={styles.btn}>
            {dim.event === "Continue"
              ? progress === 0
                ? "Start"
                : `Continue - ${(progress * 100).toFixed(0)}% complete`
              : "Start"}
          </button>
        </div>
      );
    });

  if (!mapped.length) {
    return <div style={{ marginTop: "-20px" }}></div>;
  }

  return (
    <section className={styles.main}>
      <h2 className={styles.heading}>Complete Dimensions</h2>
      <div id="continueWrapper" className={styles.wrapper}>
        {mapped}
      </div>
      <div
        onClick={() => router.push("/app/assessment")}
        className={styles.viewAllDiv}
      >
        <p className={`${styles.viewAllText} ${font.className}`}>
          View all Dimensions
        </p>
        <ForwardArrow />
      </div>
    </section>
  );
}

function useGetDimensionStatus() {
  const dimensionEvents = useSelector(
    (state: RootState) => state.user.dimensionEvents
  );

  const [displayData, setDimensionData] = useState<DimensionDataType[]>([]);

  useEffect(() => {
    let data: DimensionDataType[] = JSON.parse(
      JSON.stringify(dimensionInitialData)
    );
    if (dimensionEvents && dimensionEvents?.length > 0) {
      dimensionEvents.forEach((dimEvent) => {
        for (let i = 0; i < data.length; i++) {
          if (data[i].slug === dimEvent.dimensionSlug) {
            if (dimEvent.event === "complete") {
              data[i].event = "Retake";
            } else {
              data[i].event = "Continue";
            }
          }
        }
      });
    }
    setDimensionData(data);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dimensionEvents]);

  return displayData;
}
