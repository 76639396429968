export default function SecurityIcon() {
  return (
    <svg
      width="94"
      height="95"
      viewBox="0 0 94 95"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="47.3672"
        y1="0.0600586"
        x2="47.3672"
        y2="94.0601"
        stroke="#FFFFF2"
        strokeWidth="0.734375"
      />
      <line
        x1="94"
        y1="47.4272"
        x2="-3.21005e-08"
        y2="47.4272"
        stroke="#FFFFF2"
        strokeWidth="0.734375"
      />
      <path
        d="M37.43 17.7004V16.9899C37.4337 16.4879 37.06 16.0635 36.5616 16.0031C34.5942 15.6934 32.6933 15.057 30.9369 14.1182C28.8622 13.1585 26.9477 11.8831 25.2627 10.3386C24.8852 9.99324 24.3079 9.99324 23.9304 10.3386C22.2504 11.8929 20.3397 13.1782 18.266 14.1476C16.5094 15.0863 14.6086 15.7228 12.6413 16.0324C12.1429 16.0929 11.7692 16.5172 11.7729 17.0192V17.7298C11.2239 22.2211 12.3588 26.7567 14.9604 30.4595C17.3312 33.69 20.5532 36.1966 24.2661 37.7029C24.503 37.7991 24.7694 37.7991 25.0062 37.7029C28.7192 36.1968 31.9411 33.6903 34.312 30.4595C36.8962 26.7393 38.0076 22.1924 37.4303 17.7001L37.43 17.7004ZM29.2099 22.6345L24.2758 27.5686C23.891 27.9509 23.2693 27.9509 22.8844 27.5686L19.924 24.6081C19.5885 24.2171 19.6107 23.6324 19.9758 23.2685C20.3397 22.9034 20.9244 22.8812 21.3154 23.2167L23.6147 25.4963L27.848 21.2629C28.2391 20.9274 28.8237 20.9496 29.1877 21.3147C29.5528 21.6786 29.575 22.2633 29.2395 22.6543L29.2099 22.6345Z"
        fill="#FFFFF2"
      />
      <path
        d="M23.133 55.1382C19.3051 55.1382 16.1562 58.1577 16.1562 61.9122C16.1562 64.7382 17.9503 67.1773 20.501 68.1825V71.7827C20.501 71.8992 20.5414 72.0156 20.6209 72.0927L21.9368 73.3704C22.0956 73.5246 22.0956 73.7968 21.9368 73.9903L20.6209 75.268C20.5414 75.3451 20.501 75.4616 20.501 75.578V76.8164C20.501 76.9328 20.5414 77.0492 20.6209 77.1263L21.9368 78.404C22.0956 78.5582 22.0956 78.8304 21.9368 79.024L20.6209 80.3016C20.5414 80.3788 20.501 80.4952 20.501 80.6116V82.547C20.501 82.6635 20.5414 82.7799 20.6209 82.857L22.8136 85.1024C22.9724 85.2959 23.2916 85.2959 23.4521 85.1024L25.6448 82.857C25.7242 82.7799 25.7647 82.6635 25.7647 82.547V68.1433C28.3155 67.1362 30.1094 64.6988 30.1094 61.8729C30.1094 58.1578 26.9998 55.1382 23.133 55.1382ZM23.133 61.6416C22.0164 61.6416 21.0991 60.751 21.0991 59.6668C21.0991 58.5827 22.0164 57.6921 23.133 57.6921C24.2496 57.6921 25.1668 58.5827 25.1668 59.6668C25.1668 60.751 24.2496 61.6416 23.133 61.6416Z"
        fill="#FFFFF2"
      />
      <path
        d="M76.9185 66.3074H75.4008V61.8669C75.4008 58.5365 72.6994 55.8726 69.4068 55.8726C66.0766 55.8726 63.4129 58.5742 63.4129 61.8669V66.2697H61.8952C60.1564 66.2697 58.75 67.6761 58.75 69.415V81.3678C58.75 83.1067 60.1564 84.5132 61.8952 84.5132H76.9188C78.6576 84.5132 80.064 83.1067 80.064 81.3678V69.415C80.064 67.7137 78.6573 66.3074 76.9185 66.3074ZM66.0765 61.8669C66.0765 60.0543 67.5566 58.5365 69.4067 58.5365C71.2568 58.5365 72.7369 60.0167 72.7369 61.8669V66.2697H66.0765V61.8669ZM71.1832 79.7026C71.2207 79.9252 71.0719 80.1103 70.8507 80.1103H68.0018C67.7793 80.1103 67.6318 79.9252 67.6694 79.7026L68.5569 74.9657C67.7792 74.6332 67.2242 73.8556 67.2242 72.9305C67.2242 71.709 68.223 70.7102 69.4443 70.7102C70.6657 70.7102 71.6644 71.709 71.6644 72.9305C71.6644 73.8556 71.1094 74.6332 70.3318 74.9657L71.1832 79.7026Z"
        fill="#FFFFF2"
      />
      <path
        d="M79.9991 32.0375L73.4762 25.5134C74.4795 24.1961 75.044 22.5024 75.044 20.6829C75.044 16.1665 71.4062 12.5287 66.8898 12.5287C62.3734 12.5287 58.7344 16.1677 58.7344 20.6841C58.7344 25.2005 62.3722 28.8383 66.8886 28.8383C68.7081 28.8383 70.3382 28.2738 71.719 27.2705L78.2431 33.7946C78.4937 34.0452 78.8077 34.171 79.1217 34.171C79.4358 34.171 79.7486 34.0452 80.0003 33.7946C80.5014 33.2924 80.5013 32.5396 79.9991 32.0375ZM66.8898 26.3296C63.753 26.3296 61.2443 23.8209 61.2443 20.6841C61.2443 17.5473 63.753 15.0386 66.8898 15.0386C70.0266 15.0386 72.5353 17.5473 72.5353 20.6841C72.5353 23.8197 70.0254 26.3296 66.8898 26.3296Z"
        fill="#FFFFF2"
      />
    </svg>
  );
}
