import { useState } from "react";

import styles from "./MappedUserSuggestedMessages.module.css";

import {
  suggestedCategoryNameMapper,
  SuggestedMessageCategory,
  suggestedMessagesMapper,
} from "../utils";
import ForwardArrow from "@/components/shared/buttons/ForwardArrow";

export default function MappedUserSuggestedMessages(props: {
  onSelect: (message: string) => void;
}) {
  const [category, setCategory] = useState<SuggestedMessageCategory>(
    SuggestedMessageCategory.All
  );

  const mappedCategories = [
    SuggestedMessageCategory.All,
    SuggestedMessageCategory.SelfAwareness,
    SuggestedMessageCategory.Love,
    SuggestedMessageCategory.Purpose,
    SuggestedMessageCategory.ForFun,
    SuggestedMessageCategory.Life,
  ].map((c) => {
    return (
      <p
        style={
          category === c
            ? { color: "#FFFFF2", textDecoration: "underline" }
            : {}
        }
        className={styles.category}
        onClick={() => setCategory(c)}
        key={c}
      >
        {suggestedCategoryNameMapper[c]}
      </p>
    );
  });

  const mapped = suggestedMessagesMapper[category].map((m, i) => {
    return (
      <div
        className={styles.mappedsuggestionsDiv}
        onClick={() => props.onSelect(m)}
        key={i}
      >
        <p className={styles.mappedsuggestionsText}>{m}</p>
        <ForwardArrow />
      </div>
    );
  });

  return (
    <section className={styles.suggestedMessagesSection}>
      <h1 className={styles.suggestedMessagesTitle}>Suggested messages</h1>
      <div className={styles.mappedCategoriesWrapper}>{mappedCategories}</div>
      <div className={styles.mappedsuggestionsWrapper}>{mapped}</div>
    </section>
  );
}
