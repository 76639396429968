import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import styles from "./DiscoveryGate.module.css";

import SnippetsRegenerateButton from "@/components/results/Snippets/SnippetsRegenerateButton";
import WarningIcon from "../GhostBox/WarningIcon";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import { callFirebaseFunctions } from "@/_firebase/callFirebaseFunctions";
import Alert from "../Alerts/Alert";
import { RootState } from "@/redux/store";
import { useAlertContext } from "@/context/AlertContext";

export default function ReportRetryGate(props: {
  slug: string;
  onClose: () => void;
}) {
  const [disabled, setDisabled] = useState(false);
  const therapistReportLlmRequest = useSelector(
    (state: RootState) => state.report.therapistReportLlmRequest
  );
  const loveReportLlmRequest = useSelector(
    (state: RootState) => state.report.loveReportLlmRequest
  );
  const careerReportLlmRequest = useSelector(
    (state: RootState) => state.report.careerReportLlmRequest
  );

  const { setAlertComponent } = useAlertContext();

  const reportName =
    props.slug === "love"
      ? "Love"
      : props.slug === "career"
      ? "Career"
      : "Therapist";

  useEffect(() => {
    if (props.slug === "therapist") {
      if (therapistReportLlmRequest?.status === "processing") {
        setDisabled(true);
      }
    }
    if (props.slug === "love") {
      if (loveReportLlmRequest?.status === "processing") {
        setDisabled(true);
      }
    }

    if (props.slug === "career") {
      if (careerReportLlmRequest?.status === "processing") {
        setDisabled(true);
      }
    }
  }, [
    props.slug,
    therapistReportLlmRequest?.status,
    loveReportLlmRequest?.status,
    careerReportLlmRequest?.status,
  ]);

  return (
    <ModalWrapper title="" noHeader backgroundColor="#2d2927">
      <div
        onClick={() => {
          props.onClose();
        }}
        className={styles.closeButton}
        style={{ top: "9px" }}
      >
        <svg
          width="35"
          height="35"
          viewBox="0 0 35 35"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle opacity="0.15" cx="17.5" cy="17.5" r="17.5" fill="#FFFFF2" />
          <g opacity="0.7">
            <path
              d="M23.8192 11.9618C23.9309 11.8559 23.986 11.7253 23.986 11.5715C23.986 11.4177 23.9309 11.2842 23.8192 11.1725C23.7133 11.0666 23.5827 11.0143 23.4289 11.0143C23.2751 11.0143 23.1416 11.0666 23.0299 11.1725L17.8574 16.3535L12.6763 11.1725C12.5704 11.0666 12.4398 11.0143 12.286 11.0143C12.1322 11.0143 11.9987 11.0666 11.887 11.1725C11.7811 11.2842 11.7289 11.4177 11.7289 11.5715C11.7289 11.7253 11.7811 11.8558 11.887 11.9618L17.0681 17.1428L11.887 22.3153C11.7811 22.4271 11.7289 22.5605 11.7289 22.7143C11.7289 22.8681 11.7811 22.9987 11.887 23.1046C11.9987 23.2164 12.1322 23.2715 12.286 23.2715C12.4398 23.2715 12.5704 23.2164 12.6763 23.1046L17.8574 17.9321L23.0299 23.1046C23.1416 23.2164 23.2751 23.2715 23.4289 23.2715C23.5827 23.2715 23.7132 23.2164 23.8192 23.1046C23.9309 22.9987 23.986 22.8681 23.986 22.7143C23.986 22.5605 23.9309 22.4271 23.8192 22.3153L18.6467 17.1428L23.8192 11.9618Z"
              fill="#FFFFF2"
            />
          </g>
        </svg>
      </div>
      <div style={{ marginTop: "10%" }} className={styles.gatedParent}>
        <div className={styles.soonIcon}>
          <WarningIcon active />
        </div>
        <h1 className={styles.soonText}>
          An error occurred generating your {reportName} Report.
        </h1>
        <SnippetsRegenerateButton
          disabled={disabled}
          message={`Report computing. Check back later.`}
          onClick={() => {
            const call = callFirebaseFunctions("updateReportData");
            call({ reportSlug: props.slug })
              .then((res) => {
                setDisabled(true);
              })
              .catch((err) => {
                if (err.message === "deadline-exceeded") {
                  setAlertComponent(
                    <Alert
                      type="fail"
                      message={`Report computing. Check back later.`}
                      elementName="Alert"
                    />,
                    7000
                  );
                } else {
                  setAlertComponent(
                    <Alert
                      type="fail"
                      message={`An error occurred generating your ${reportName} Report.`}
                      elementName="Alert"
                    />,
                    7000
                  );
                }
                setDisabled(true);
              });
          }}
        />
      </div>
    </ModalWrapper>
  );
}
