import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import styles from "./DimensionSummaries.module.css";

import {
  dimensionData,
  DimensionDataType,
  dimensionIcons,
} from "@/assets/traitInfo/dimensionData";
import { useModalContext } from "@/context/ModalContext";
import DimensionSummariesModal from "./DimensionSummariesModal";
import { RootState } from "@/redux/store";
import { DimensionEventStatus } from "@dimensional-engineering/dimensional-models";
import DiscoveryGate from "@/components/shared/DiscoveryGate/DiscoveryGate";
import { summonProfileLink } from "@/components/shared/utils";
import { useAuth } from "@/context/AuthContext";
import PremiumPillIcon from "@/components/premium/PremiumPillIcon/PremiumPillIcon";

type Props = {
  summary: DimensionDataType;
  unknown: boolean;
  onUnknownClick?: (slug: string) => void;
  updatedOn?: number;
};

export default function DimensionSummaryBlock(props: Props) {
  const { setModalComponent } = useModalContext();

  if (props.unknown) {
    return (
      <div
        onClick={() => {
          props.onUnknownClick && props.onUnknownClick(props.summary.slug);
        }}
        className={styles.row}
      >
        <div className={styles.iconDiv}>
          {dimensionIcons[props.summary.slug].iconInactive}
        </div>
        <h2 className={styles.rowTitle} style={{ opacity: 0.3 }}>
          {props.summary.title}
        </h2>
      </div>
    );
  }

  return (
    <div
      onClick={() =>
        setModalComponent(<DimensionSummariesModal slug={props.summary.slug} />)
      }
    >
      <div className={styles.row}>
        <div className={styles.iconDiv}>
          {dimensionIcons[props.summary.slug].icon}
        </div>

        <h2 className={styles.rowTitle}>{props.summary.title}</h2>
      </div>
    </div>
  );
}

export function DimensionSummaryResultsTabBlock(props: Props) {
  const { setModalComponent } = useModalContext();

  if (props.unknown) {
    return (
      <div
        onClick={() => {
          props.onUnknownClick && props.onUnknownClick(props.summary.slug);
        }}
        className={styles.resultsBlock}
      >
        <div className={styles.iconDiv}>
          {dimensionIcons[props.summary.slug].iconInactive}
        </div>
        <div style={{ opacity: 0.3 }}>
          <h3 className={styles.resultsDimensionText}>DIMENSION</h3>
          <h2 className={styles.resultTitle}>{props.summary.title}</h2>
        </div>
      </div>
    );
  }

  return (
    <div
      onClick={() =>
        setModalComponent(<DimensionSummariesModal slug={props.summary.slug} />)
      }
      className={styles.resultsBlock}
    >
      {props.summary.slug === "coping-style" && (
        <div className={styles.premiumLogo}>
          <PremiumPillIcon />
        </div>
      )}
      <div className={styles.iconDiv}>
        {dimensionIcons[props.summary.slug].icon}
      </div>
      <div>
        <h3 className={styles.resultsDimensionText}>DIMENSION</h3>
        <h2 className={styles.resultTitle}>{props.summary.title}</h2>
      </div>
    </div>
  );
}

export function DimensionSummaryRenderer(props: { slug: string }) {
  const { setModalComponent, setModalOpen } = useModalContext();
  const { user } = useAuth();

  const dimension = dimensionData?.find((d) => d.slug === props.slug);
  const completedSlugs = useGetCompletedDimensionSlugs();
  if (!dimension) {
    return <></>;
  }

  return (
    <DimensionSummaryResultsTabBlock
      unknown={!completedSlugs.includes(props.slug)}
      onUnknownClick={() => {
        setModalComponent(
          <DiscoveryGate
            onClose={() => setModalOpen(false)}
            requiredDimensionSlugs={[props.slug]}
            onOpenShareLink={() => {
              summonProfileLink(
                user?.uid,
                "gating modal",
                "Invite to Dimensional"
              );
            }}
          />
        );
      }}
      summary={dimension}
    />
  );
}

function useGetCompletedDimensionSlugs() {
  const dimensionEvents = useSelector(
    (state: RootState) => state.user.dimensionEvents
  );
  const [completedSlugs, setCompletedSlugs] = useState<string[]>([]);

  useEffect(() => {
    let finishedSlugs: string[] = [];
    dimensionEvents?.forEach((e) => {
      if (e.event === DimensionEventStatus.complete) {
        finishedSlugs.push(e.dimensionSlug);
      }
    });
    setCompletedSlugs(finishedSlugs);
  }, [dimensionEvents]);

  return completedSlugs;
}
