/* eslint-disable @next/next/no-img-element */
import { useSelector } from "react-redux";
import {
  DiscoverablePersonalityAnalysis,
  DiscoverableSnippet,
  DiscoverableStory,
} from "@dimensional-engineering/dimensional-models";
import { DM_Sans } from "next/font/google";

import styles from "./Rewards.module.css";

import { RootState } from "@/redux/store";
import ModalWrapper from "@/components/shared/ModalWrapper/ModalWrapper";
import RewardsScoreBar from "./RewardsScoreBar";
import RewardsIcon from "@/components/shared/icons/RewardsIcon";
import SnippetBlock from "@/components/results/Snippets/SnippetBlock";
import { StoryCardSmall } from "@/components/results/stories/StoryCard";
import { storyColorMap } from "@/components/results/stories/storyColorMap";
import PersonalitAnalysisBlockSmall from "@/components/results/personalityAnalysis/PersonalitAnalysisBlockSmall";
import RewardsCheckmark from "./RewardsCheckmark";
import { useModalContext } from "@/context/ModalContext";
import { useGetDiscoveredSnippetSlugs } from "@/components/results/Snippets/Snippets";
import RewardsMappedImages from "./RewardsMappedImages";
import SnippetsModal from "@/components/results/Snippets/SnippetsModal";
import SnippetDiscoveryGate from "@/components/shared/DiscoveryGate/SnippetDiscoveryGate";
import useGetDiscoveredStoriesSlugsHook from "@/helpers/useGetDiscoveredStoriesSlugsHook";
import DiscoveryGate from "@/components/shared/DiscoveryGate/DiscoveryGate";
import AnalysisModal from "@/components/results/personalityAnalysis/AnalysisModal/AnalysisModal";
import { computableSnippetsObject } from "@/components/results/Snippets/snippetsMap";
import { summonProfileLink } from "@/components/shared/utils";
import { useAuth } from "@/context/AuthContext";
import SwipableStoriesModal from "@/components/results/stories/SwipableStoriesModal";
import { useGetDiscoveredPersonalityAnalysisSlugs } from "@/components/results/personalityAnalysis/useGetDiscoveredPersonalityAnalysisSlugs";

const font = DM_Sans({ weight: "500", subsets: ["latin"] });

export default function RewardsModal() {
  const { setModalComponent, setModalOpen } = useModalContext();
  const { user } = useAuth();
  const userState = useSelector((state: RootState) => state.user);
  const analysisState = useSelector(
    (state: RootState) => state.personalityAnalyis
  );

  const discoveredSnippetSlugs = useGetDiscoveredSnippetSlugs();
  const discoveredStorySlugs = useGetDiscoveredStoriesSlugsHook();
  const discoveredPersonalityAnalysisSlugs =
    useGetDiscoveredPersonalityAnalysisSlugs();

  function onSnippetClick(snippet: DiscoverableSnippet) {
    if (discoveredSnippetSlugs.includes(snippet?.snippetSlug)) {
      return setModalComponent(
        <SnippetsModal discoveredSnippetSlug={snippet?.snippetSlug} />
      );
    }

    return setModalComponent(
      <SnippetDiscoveryGate
        snippetSlug={snippet?.snippetSlug}
        snippetType={snippet.snippetType}
        requiredDimensionSlugs={snippet.discoveryGate.requiredDimensionSlugs}
        numFriendsRequired={snippet.discoveryGate.numFriendsRequired}
        numSuccessfulInvitesRequired={
          snippet.discoveryGate.numSuccessfulInvitesRequired
        }
        onClose={() => {
          setModalOpen(false);
        }}
        onOpenShareLink={() => {
          summonProfileLink(
            user?.uid,
            "rewards modal",
            "Invite to Dimensional"
          );
        }}
        snippetConnection={
          snippet.discoveryGate.numFriendsRequired ? true : false
        }
        snippetDimension={
          snippet.discoveryGate.requiredDimensionSlugs ? true : false
        }
      />
    );
  }

  function onStoryClick(story: DiscoverableStory | undefined) {
    if (
      story &&
      story?.storySlug &&
      discoveredStorySlugs.includes(story?.storySlug)
    ) {
      const discoveredStory = userState.discoveredStories?.find(
        (s) => s.storySlug === story.storySlug
      );
      if (discoveredStory) {
        return setModalComponent(
          <SwipableStoriesModal storySlug={discoveredStory.storySlug} />
        );
      }
    } else {
      return setModalComponent(
        <DiscoveryGate
          {...story?.discoveryGate}
          onClose={() => setModalOpen(false)}
          onOpenShareLink={() => {
            summonProfileLink(
              user?.uid,
              "rewards modal",
              "Invite to Dimensional"
            );
          }}
          fromStory
        />
      );
    }
  }

  function onPersonalityAnalysisClick(
    analysis: DiscoverablePersonalityAnalysis | undefined
  ) {
    if (
      analysis?.analysisSlug &&
      discoveredPersonalityAnalysisSlugs.includes(analysis?.analysisSlug)
    ) {
      return setModalComponent(
        <AnalysisModal
          onClose={() => setModalOpen(false)}
          slug={analysis.analysisSlug}
        />
      );
    } else {
      setModalComponent(
        <DiscoveryGate
          onClose={() => setModalOpen(false)}
          requiredDimensionSlugs={
            analysis?.discoveryGate.requiredDimensionSlugs
          }
          numSuccessfulInvitesRequired={
            analysis?.discoveryGate.numSuccessfulInvitesRequired
          }
          onOpenShareLink={() => {
            summonProfileLink(
              user?.uid,
              "rewards modal",
              "Invite to Dimensional"
            );
          }}
        />
      );
    }
  }

  const levelMapper: Record<number, JSX.Element[]> = {
    1: [
      <SnippetBlock
        greyedOut={false}
        snippet={computableSnippetsObject["sp-snippet-fun-friend-type"]}
        onClick={() => {
          onSnippetClick(
            computableSnippetsObject["sp-snippet-fun-friend-type"]
          );
        }}
        key={0}
        showInvitePill
      />,
      <SnippetBlock
        greyedOut={false}
        snippet={computableSnippetsObject["sp-snippet-fun-sitcom-character"]}
        onClick={() => {
          onSnippetClick(
            computableSnippetsObject["sp-snippet-fun-sitcom-character"]
          );
        }}
        key={1}
        showInvitePill
      />,
      <SnippetBlock
        greyedOut={false}
        snippet={computableSnippetsObject["sp-snippet-fun-star-sign"]}
        onClick={() => {
          onSnippetClick(computableSnippetsObject["sp-snippet-fun-star-sign"]);
        }}
        key={2}
        showInvitePill
      />,
      <SnippetBlock
        greyedOut={false}
        snippet={computableSnippetsObject["sp-snippet-fun-recs-fiction-books"]}
        onClick={() => {
          onSnippetClick(
            computableSnippetsObject["sp-snippet-fun-recs-fiction-books"]
          );
        }}
        key={3}
        showInvitePill
      />,
      <SnippetBlock
        greyedOut={false}
        snippet={computableSnippetsObject["sp-snippet-fun-recs-tv-shows"]}
        onClick={() => {
          onSnippetClick(
            computableSnippetsObject["sp-snippet-fun-recs-tv-shows"]
          );
        }}
        key={4}
        showInvitePill
      />,
      <StoryCardSmall
        onClick={() => {
          onStoryClick(
            userState.discoverableStories?.find(
              (s) => s.storySlug === "seeks-attention-by"
            )
          );
        }}
        key={5}
        title={
          userState.discoverableStories?.find(
            (s) => s.storySlug === "seeks-attention-by"
          )?.name ?? ""
        }
        imageSlug={`${
          userState.discoverableStories?.find(
            (s) => s.storySlug === "seeks-attention-by"
          )?.storySlug
        }.png`}
        color={`${
          storyColorMap[
            userState.discoverableStories?.find(
              (s) => s.storySlug === "seeks-attention-by"
            )?.storySlug as keyof typeof storyColorMap
          ] ?? "#fffff2"
        }`}
        numSuccessfulInvitesRequired={1}
        showInvitePill
      />,
      <StoryCardSmall
        key={6}
        onClick={() =>
          onStoryClick(
            userState.discoverableStories?.find(
              (s) => s.storySlug === "crush-on"
            )
          )
        }
        title={
          userState.discoverableStories?.find((s) => s.storySlug === "crush-on")
            ?.name ?? ""
        }
        imageSlug={`${
          userState.discoverableStories?.find((s) => s.storySlug === "crush-on")
            ?.storySlug
        }.png`}
        color={`${
          storyColorMap[
            userState.discoverableStories?.find(
              (s) => s.storySlug === "crush-on"
            )?.storySlug as keyof typeof storyColorMap
          ] ?? "#fffff2"
        }`}
        numSuccessfulInvitesRequired={1}
        showInvitePill
      />,
      <StoryCardSmall
        onClick={() =>
          onStoryClick(
            userState.discoverableStories?.find(
              (s) => s.storySlug === "hate-talking-about"
            )
          )
        }
        key={7}
        title={
          userState.discoverableStories?.find(
            (s) => s.storySlug === "hate-talking-about"
          )?.name ?? ""
        }
        imageSlug={`${
          userState.discoverableStories?.find(
            (s) => s.storySlug === "hate-talking-about"
          )?.storySlug
        }.png`}
        color={`${
          storyColorMap[
            userState.discoverableStories?.find(
              (s) => s.storySlug === "hate-talking-about"
            )?.storySlug as keyof typeof storyColorMap
          ] ?? "#fffff2"
        }`}
        numSuccessfulInvitesRequired={1}
        showInvitePill
      />,
      <StoryCardSmall
        key={8}
        onClick={() =>
          onStoryClick(
            userState.discoverableStories?.find(
              (s) => s.storySlug === "story-secretly-wishing-for"
            )
          )
        }
        title={
          userState.discoverableStories?.find(
            (s) => s.storySlug === "story-secretly-wishing-for"
          )?.name ?? ""
        }
        imageSlug={`${
          userState.discoverableStories?.find(
            (s) => s.storySlug === "story-secretly-wishing-for"
          )?.storySlug
        }.png`}
        color={`${
          storyColorMap[
            userState.discoverableStories?.find(
              (s) => s.storySlug === "story-secretly-wishing-for"
            )?.storySlug as keyof typeof storyColorMap
          ] ?? "#fffff2"
        }`}
        numSuccessfulInvitesRequired={1}
        showInvitePill
      />,
    ],
    2: [
      <SnippetBlock
        greyedOut={false}
        snippet={computableSnippetsObject["sp-snippet-fun-disney-character"]}
        onClick={() => {
          onSnippetClick(
            computableSnippetsObject["sp-snippet-fun-disney-character"]
          );
        }}
        key={1}
        showInvitePill
      />,
      <SnippetBlock
        greyedOut={false}
        snippet={computableSnippetsObject["sp-snippet-fun-superhero"]}
        onClick={() => {
          onSnippetClick(computableSnippetsObject["sp-snippet-fun-superhero"]);
        }}
        key={2}
        showInvitePill
      />,
      <SnippetBlock
        greyedOut={false}
        snippet={computableSnippetsObject["sp-snippet-fun-winnie-the-pooh"]}
        onClick={() => {
          onSnippetClick(
            computableSnippetsObject["sp-snippet-fun-winnie-the-pooh"]
          );
        }}
        key={3}
        showInvitePill
      />,
      <SnippetBlock
        greyedOut={false}
        snippet={computableSnippetsObject["sp-snippet-fun-historical-leader"]}
        onClick={() => {
          onSnippetClick(
            computableSnippetsObject["sp-snippet-fun-historical-leader"]
          );
        }}
        key={4}
        showInvitePill
      />,
      <SnippetBlock
        greyedOut={false}
        snippet={
          computableSnippetsObject["sp-snippet-fun-video-game-character"]
        }
        onClick={() => {
          onSnippetClick(
            computableSnippetsObject["sp-snippet-fun-video-game-character"]
          );
        }}
        key={5}
        showInvitePill
      />,
      <SnippetBlock
        greyedOut={false}
        snippet={computableSnippetsObject["sp-snippet-fun-recs-foreign-films"]}
        onClick={() => {
          onSnippetClick(
            computableSnippetsObject["sp-snippet-fun-recs-foreign-films"]
          );
        }}
        key={6}
        showInvitePill
      />,
      <SnippetBlock
        greyedOut={false}
        snippet={
          computableSnippetsObject["sp-snippet-fun-recs-lesser-known-movies"]
        }
        onClick={() => {
          onSnippetClick(
            computableSnippetsObject["sp-snippet-fun-recs-lesser-known-movies"]
          );
        }}
        key={7}
        showInvitePill
      />,
      <StoryCardSmall
        key={8}
        onClick={() =>
          onStoryClick(
            userState.discoverableStories?.find(
              (s) => s.storySlug === "story-your-heaven"
            )
          )
        }
        title={
          userState.discoverableStories?.find(
            (s) => s.storySlug === "story-your-heaven"
          )?.name ?? ""
        }
        imageSlug={`${
          userState.discoverableStories?.find(
            (s) => s.storySlug === "story-your-heaven"
          )?.storySlug
        }.png`}
        color={`${
          storyColorMap[
            userState.discoverableStories?.find(
              (s) => s.storySlug === "story-your-heaven"
            )?.storySlug as keyof typeof storyColorMap
          ] ?? "#fffff2"
        }`}
        numSuccessfulInvitesRequired={2}
        showInvitePill
      />,
      <StoryCardSmall
        key={9}
        onClick={() =>
          onStoryClick(
            userState.discoverableStories?.find(
              (s) => s.storySlug === "your-hell"
            )
          )
        }
        title={
          userState.discoverableStories?.find(
            (s) => s.storySlug === "your-hell"
          )?.name ?? ""
        }
        imageSlug={`${
          userState.discoverableStories?.find(
            (s) => s.storySlug === "your-hell"
          )?.storySlug
        }.png`}
        color={`${
          storyColorMap[
            userState.discoverableStories?.find(
              (s) => s.storySlug === "your-hell"
            )?.storySlug as keyof typeof storyColorMap
          ] ?? "#fffff2"
        }`}
        numSuccessfulInvitesRequired={2}
        showInvitePill
      />,
      <StoryCardSmall
        key={10}
        onClick={() =>
          onStoryClick(
            userState.discoverableStories?.find(
              (s) => s.storySlug === "obstacles-to-happiness"
            )
          )
        }
        title={
          userState.discoverableStories?.find(
            (s) => s.storySlug === "obstacles-to-happiness"
          )?.name ?? ""
        }
        imageSlug={`${
          userState.discoverableStories?.find(
            (s) => s.storySlug === "obstacles-to-happiness"
          )?.storySlug
        }.png`}
        color={`${
          storyColorMap[
            userState.discoverableStories?.find(
              (s) => s.storySlug === "obstacles-to-happiness"
            )?.storySlug as keyof typeof storyColorMap
          ] ?? "#fffff2"
        }`}
        numSuccessfulInvitesRequired={2}
        showInvitePill
      />,
    ],
    3: [
      <SnippetBlock
        greyedOut={false}
        snippet={computableSnippetsObject["sp-snippet-fun-planet"]}
        onClick={() => {
          onSnippetClick(computableSnippetsObject["sp-snippet-fun-planet"]);
        }}
        key={1}
        showInvitePill
      />,
      <SnippetBlock
        greyedOut={false}
        snippet={computableSnippetsObject["sp-snippet-fun-x-men-character"]}
        onClick={() => {
          onSnippetClick(
            computableSnippetsObject["sp-snippet-fun-x-men-character"]
          );
        }}
        key={2}
        showInvitePill
      />,
      <SnippetBlock
        greyedOut={false}
        snippet={computableSnippetsObject["sp-snippet-fun-game-of-thrones"]}
        onClick={() => {
          onSnippetClick(
            computableSnippetsObject["sp-snippet-fun-game-of-thrones"]
          );
        }}
        key={3}
        showInvitePill
      />,
      <SnippetBlock
        greyedOut={false}
        snippet={computableSnippetsObject["sp-snippet-fun-generation"]}
        onClick={() => {
          onSnippetClick(computableSnippetsObject["sp-snippet-fun-generation"]);
        }}
        key={4}
        showInvitePill
      />,
      <SnippetBlock
        greyedOut={false}
        snippet={
          computableSnippetsObject["sp-snippet-fun-recs-non-fiction-books"]
        }
        onClick={() => {
          onSnippetClick(
            computableSnippetsObject["sp-snippet-fun-recs-non-fiction-books"]
          );
        }}
        key={5}
        showInvitePill
      />,
      <SnippetBlock
        greyedOut={false}
        snippet={computableSnippetsObject["snippet-obstacles-to-love"]}
        onClick={() => {
          onSnippetClick(computableSnippetsObject["snippet-obstacles-to-love"]);
        }}
        key={6}
        showInvitePill
      />,
      <StoryCardSmall
        key={7}
        onClick={() =>
          onStoryClick(
            userState.discoverableStories?.find(
              (s) => s.storySlug === "story-how-you-flirt"
            )
          )
        }
        title={
          userState.discoverableStories?.find(
            (s) => s.storySlug === "story-how-you-flirt"
          )?.name ?? ""
        }
        imageSlug={`${
          userState.discoverableStories?.find(
            (s) => s.storySlug === "story-how-you-flirt"
          )?.storySlug
        }.png`}
        color={`${
          storyColorMap[
            userState.discoverableStories?.find(
              (s) => s.storySlug === "story-how-you-flirt"
            )?.storySlug as keyof typeof storyColorMap
          ] ?? "#fffff2"
        }`}
        numSuccessfulInvitesRequired={3}
        showInvitePill
      />,
    ],
    4: [
      <SnippetBlock
        greyedOut={false}
        snippet={computableSnippetsObject["sp-snippet-fun-numerology"]}
        onClick={() => {
          onSnippetClick(computableSnippetsObject["sp-snippet-fun-numerology"]);
        }}
        key={1}
        showInvitePill
      />,
      <SnippetBlock
        greyedOut={false}
        snippet={computableSnippetsObject["sp-snippet-fun-tarot-card"]}
        onClick={() => {
          onSnippetClick(computableSnippetsObject["sp-snippet-fun-tarot-card"]);
        }}
        key={2}
        showInvitePill
      />,
      <SnippetBlock
        greyedOut={false}
        snippet={computableSnippetsObject["sp-snippet-fun-greek-god"]}
        onClick={() => {
          onSnippetClick(computableSnippetsObject["sp-snippet-fun-greek-god"]);
        }}
        key={3}
        showInvitePill
      />,
      <SnippetBlock
        greyedOut={false}
        snippet={computableSnippetsObject["sp-snippet-fun-recs-bosses-work"]}
        onClick={() => {
          onSnippetClick(
            computableSnippetsObject["sp-snippet-fun-recs-bosses-work"]
          );
        }}
        key={4}
        showInvitePill
      />,
      <SnippetBlock
        greyedOut={false}
        snippet={computableSnippetsObject["sp-snippet-fun-fictional-villain"]}
        onClick={() => {
          onSnippetClick(
            computableSnippetsObject["sp-snippet-fun-fictional-villain"]
          );
        }}
        key={5}
        showInvitePill
      />,
      <SnippetBlock
        greyedOut={false}
        snippet={computableSnippetsObject["sp-snippet-fun-enneagram"]}
        onClick={() => {
          onSnippetClick(computableSnippetsObject["sp-snippet-fun-enneagram"]);
        }}
        key={6}
        showInvitePill
      />,
      <SnippetBlock
        greyedOut={false}
        snippet={computableSnippetsObject["sp-snippet-fun-jungian-archetype"]}
        onClick={() => {
          onSnippetClick(
            computableSnippetsObject["sp-snippet-fun-jungian-archetype"]
          );
        }}
        key={7}
        showInvitePill
      />,
      <SnippetBlock
        greyedOut={false}
        snippet={computableSnippetsObject["sp-snippet-fun-recs-people-date"]}
        onClick={() => {
          onSnippetClick(
            computableSnippetsObject["sp-snippet-fun-recs-people-date"]
          );
        }}
        key={8}
        showInvitePill
      />,
      <PersonalitAnalysisBlockSmall
        key={9}
        onClick={() => {
          onPersonalityAnalysisClick(
            analysisState.discoverablePersonalAnalysis?.find(
              (a) => a.analysisSlug === "pa-boundaries-and-people-pleasing"
            )
          );
        }}
        analysis={analysisState.discoverablePersonalAnalysis?.find(
          (a) => a.analysisSlug === "pa-boundaries-and-people-pleasing"
        )}
        showInvitePill
      />,
      <PersonalitAnalysisBlockSmall
        key={10}
        onClick={() => {
          onPersonalityAnalysisClick(
            analysisState.discoverablePersonalAnalysis?.find(
              (a) => a.analysisSlug === "pa-how-you-process-information"
            )
          );
        }}
        analysis={analysisState.discoverablePersonalAnalysis?.find(
          (a) => a.analysisSlug === "pa-how-you-process-information"
        )}
        showInvitePill
      />,
      <PersonalitAnalysisBlockSmall
        key={11}
        onClick={() => {
          onPersonalityAnalysisClick(
            analysisState.discoverablePersonalAnalysis?.find(
              (a) => a.analysisSlug === "pa-your-ideal-love-life"
            )
          );
        }}
        analysis={analysisState.discoverablePersonalAnalysis?.find(
          (a) => a.analysisSlug === "pa-your-ideal-love-life"
        )}
        showInvitePill
      />,
      <PersonalitAnalysisBlockSmall
        key={12}
        onClick={() => {
          onPersonalityAnalysisClick(
            analysisState.discoverablePersonalAnalysis?.find(
              (a) => a.analysisSlug === "pa-kink-and-intimacy"
            )
          );
        }}
        analysis={analysisState.discoverablePersonalAnalysis?.find(
          (a) => a.analysisSlug === "pa-kink-and-intimacy"
        )}
        showInvitePill
      />,
    ],
  };

  return (
    <ModalWrapper title="Invite rewards">
      <div className={styles.modalMain}>
        <div style={{ cursor: "auto" }} className={styles.rewardsBarMain}>
          <p className={styles.rewardLevelTitle}>
            Invite reward level {userState.inviteLevel}
          </p>
          <div className={styles.rewardsLevelParent}>
            <RewardsScoreBar level={userState.inviteLevel} />
          </div>
          <div className={styles.rewardsMidDiv}>
            <RewardsIcon />
            <p className={styles.rewardsMidDivText}>
              SUCCESSFUL INVITES:{" "}
              {userState.memberProfile?.recommendeeUids?.length ?? 0}
            </p>
          </div>
          <div className={styles.imageWrapper}>
            {
              <RewardsMappedImages
                uidList={userState.memberProfile?.recommendeeUids}
              />
            }
          </div>
          <button
            onClick={() => {
              summonProfileLink(
                user?.uid,
                "rewards modal",
                "Invite to Dimensional"
              );
            }}
            className={styles.modalInviteLinkButton}
          >
            Send invite link
          </button>
        </div>
        <p className={styles.modalInfoText}>
          To earn rewards, friends must sign up with your invite link or give
          you credit upon signup or in settings.
        </p>
        <h2 className={styles.modalTitle}>Reward levels</h2>
        <p className={styles.rewardsLevelInfo}>
          A reward for every user you successfully invite to Dimensional. All
          rewards are also unlocked with{" "}
          <span style={{ color: "#2CB0FF" }}>Plus</span>.
        </p>
        <div className={styles.levelHeaderWrapper}>
          <h3 className={styles.levelHeading}>Level 1</h3>
          <RewardsCheckmark
            checked={userState.inviteLevel >= 1 || userState.isPremium}
          />
        </div>
        <div className={styles.levelWrapper}>
          {levelMapper[1].map((c) => {
            return c;
          })}
        </div>
        <div className={styles.levelHeaderWrapper}>
          <h3 className={styles.levelHeading}>Level 2</h3>
          <RewardsCheckmark
            checked={userState.inviteLevel >= 2 || userState.isPremium}
          />
        </div>
        <div className={styles.levelWrapper}>
          {levelMapper[2].map((c) => {
            return c;
          })}
        </div>
        <div className={styles.levelHeaderWrapper}>
          <h3 className={styles.levelHeading}>Level 3</h3>
          <RewardsCheckmark
            checked={userState.inviteLevel >= 3 || userState.isPremium}
          />
        </div>
        <div className={styles.levelWrapper}>
          {levelMapper[3].map((c) => {
            return c;
          })}
        </div>
        <div className={styles.levelHeaderWrapper}>
          <h3 className={styles.levelHeading}>Level 4</h3>
          <RewardsCheckmark checked={userState.inviteLevel >= 4} />
        </div>
        <div className={styles.levelWrapper}>
          {levelMapper[4].map((c) => {
            return c;
          })}
        </div>
        {/* <div className={styles.premiumDiv}>
          <h4 className={styles.premiumTopText}>Save on</h4>
          <p className={`${styles.premiumBottomText} ${font.className}`}>
            Dimensional <span className={styles.plus}>Plus</span>
          </p>
          <p className={styles.comingSoonText}>Coming soon to web</p>
        </div> */}
      </div>
    </ModalWrapper>
  );
}
