import { GPTMessage } from "@dimensional-engineering/dimensional-models";

import styles from "./EmbeddedMessages..module.css";

import { useGetDailyInsightIconColorsWithData } from "@/components/appHome/DailyInsights/DailyInsightsCTABlock/DailyInsightsCTABlock";
import { DailyInsightIconActiveVariantSmall } from "@/components/appHome/DailyInsights/DailyInsightsIcon";
import {
  dimensionData,
  dimensionIcons,
} from "@/assets/traitInfo/dimensionData";

export function DailyInisghtEmbeddedMessage(props: { message: GPTMessage }) {
  const colors = useGetDailyInsightIconColorsWithData(
    props.message.dailyInsightMessageDetail?.deliveredDailyInsight
  );
  return (
    <div className={styles.messageDiv}>
      <div className={styles.dailyInsightsDiv}>
        <div className={styles.iconDiv}>
          <DailyInsightIconActiveVariantSmall colors={colors} />
        </div>

        <p className={styles.dailyInsightText}>
          {
            props.message.dailyInsightMessageDetail?.deliveredDailyInsight
              .assignmentRuleset.body
          }
        </p>
      </div>
    </div>
  );
}

export function DimensionSummaryEmbeddedMessage(props: {
  message: GPTMessage;
}) {
  const dimension = dimensionData.find(
    (d) => d.slug === props.message.dimensionSummaryMessageDetail?.dimensionSlug
  );
  if (dimension) {
    const icon = dimensionIcons[dimension?.slug].icon;

    return (
      <div className={styles.messageDiv}>
        <div className={styles.dimensionSummaryDiv}>
          <div className={styles.dimensionIconDiv}>{icon}</div>
          <div className={styles.dimensionDescDiv}>
            <h4 className={styles.dimensionTitle}>{dimension.title}</h4>
            <p className={styles.dimensionText}>DIMENSION</p>
          </div>
        </div>
      </div>
    );
  }

  return <></>;
}
