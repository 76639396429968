import { useEffect } from "react";
import { getDownloadURL, ref } from "firebase/storage";

import styles from "../../../styles/app/AppProfile.module.css";

import { useAuth } from "@/context/AuthContext";
import { useModalContext } from "@/context/ModalContext";
import AuthLeftBar from "../AuthLeftBar/AuthLeftBar";
import AuthRightBar from "../AuthRightBar/AuthRightBar";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import { ProfileColorMap } from "@/components/shared/ColorMap";
import { DynamicProfileResultData } from "@/models/sharedModels";
import useOwnDynamicProfileData from "@/helpers/useOwnDynamicProfileData";
import { firebaseStorage } from "@/_firebase/firebaseConfig";
import { setImageURL } from "@/redux/slices/userSlice";
import { getDefaultProfilePhoto } from "@/context/utils";
import ResultsHeader from "@/components/shared/AuthenticatedHeader/ResultsHeader";

export default function OwnProfileModal() {
  const { modalComponent } = useModalContext();

  const userState = useSelector((state: RootState) => state.user);
  const connectedProfiles = useSelector(
    (state: RootState) => state.connections
  );
  const natureColor = userState.publicProfileData?.primaryNature
    ? ProfileColorMap[userState.publicProfileData.primaryNature?.nature]
    : "#55504F";

  useOwnDynamicProfileData();
  useGetPorfilePhoto();

  useEffect(() => {
    const fromStories = localStorage.getItem("fromStories");
    const summariesDiv = document.getElementById("dimension-summaries");
    if (fromStories && summariesDiv) {
      summariesDiv.scrollIntoView({ behavior: "auto" });
      localStorage.removeItem("fromStories");
    }
  }, []);

  const ownProfileData: DynamicProfileResultData = {
    summaryTableData: userState.summaryTableData,
    summaryLoading: false,
    publicProfile: userState.publicProfileData,
    archetypeData: userState.archetypeData,
    archetypeLoading: false,
    highestScoringTraits: userState.highestScroingTraits,
    nutshell: userState.nutshell,
    nutshellLoading: false,
  };

  return (
    <>
      <div className={styles.main}>
        <ResultsHeader
          showSettingIcon
          underline
          title={
            userState.publicProfileData?.name
              ? `${userState.publicProfileData?.name ?? ""}`
              : `@${userState.publicProfileData?.userName ?? ""}`
          }
          downArrow={modalComponent.length < 2 ? true : false}
        />
        <div className={styles.barContainerOwn}>
          <AuthLeftBar
            connectionList={connectedProfiles.publicProfiles}
            imageUrl={userState.imageURL}
            publicProfile={userState.publicProfileData}
            ownProfile={true}
          />
          <AuthRightBar
            data={ownProfileData}
            ownProfile={true}
            natureColor={natureColor}
            imageUrl={undefined}
          />
        </div>
      </div>
    </>
  );
}

function useGetPorfilePhoto() {
  const { user } = useAuth();
  const dispatch = useDispatch();
  useEffect(() => {
    if (user?.uid) {
      getDownloadURL(
        ref(
          firebaseStorage,
          `gs://${process.env.NEXT_PUBLIC_STORAGEBUCKET}/profileImages/${user.uid}/cropped.jpg`
        )
      )
        .then((url) => {
          dispatch(setImageURL(url));
        })
        .catch((err) => {
          console.log(err);
          getDefaultProfilePhoto(user.uid).then((value) => {
            dispatch(setImageURL(value));
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.uid]);
}
