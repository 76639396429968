import { useEffect, useState } from "react";
import {
  FriendRequest,
  PublicProfile,
} from "@dimensional-engineering/dimensional-models";
import { useSelector } from "react-redux";

import styles from "./ConnectionRequestModal.module.css";

import { useModalContext } from "@/context/ModalContext";
import { RootState } from "@/redux/store";
import useGetProfilePhoto from "@/helpers/useGetProfilePhoto";
import {
  acceptFriendRequest,
  declineFriendRequest,
  sendFriendRequest,
} from "../utils";
import { Mixpanel } from "@/helpers/mixpanel";
import SmallProfileImage from "@/components/shared/SmallProfileImage/SmallProfileImage";
import Alert from "@/components/shared/Alerts/Alert";
import CloseConnectionIcon from "@/components/shared/icons/CloseConnectionIcon";
import { useAlertContext } from "@/context/AlertContext";
import { useSlideUpFriendRequestWrapper } from "./ConnectionRequestModal";

type Props = {
  publicProfile: PublicProfile;
  slug: string;
};

export default function CloseConnectionBlock(props: Props) {
  const { removeModalByName } = useModalContext();
  const { setAlertComponent } = useAlertContext();

  const userState = useSelector((state: RootState) => state.user);
  const imageUrl = useGetProfilePhoto(props.publicProfile.ownerUUID);
  const [buttonText, setButtonText] = useState<string>(
    "Send connection request"
  );
  const status = useCloseConnectionStatus({
    publicProfile: props.publicProfile,
  });

  useSlideUpFriendRequestWrapper();

  function handleAcceptRequest() {
    let request: FriendRequest | undefined;
    userState.friendRequestsRecieved?.forEach((req) => {
      if (req.sender.ownerUUID === props.publicProfile.ownerUUID) {
        request = req;
      }
    });
    if (request) {
      acceptFriendRequest(request)
        .then(() => {
          removeModalByName("ConnectionModal");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  function handleDeclineRequest() {
    let request: FriendRequest | undefined;
    userState.friendRequestsRecieved?.forEach((req) => {
      if (req.sender.ownerUUID === props.publicProfile.ownerUUID) {
        request = req;
      }
    });
    if (request) {
      declineFriendRequest(request)
        .then(() => {
          removeModalByName("ConnectionModal");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  function handleClick() {
    setButtonText("Loading...");
    if (userState.publicProfileData) {
      sendFriendRequest(
        userState.publicProfileData,
        props.publicProfile,
        true,
        false,
        undefined
      )
        .then((res) => {
          removeModalByName("ConnectionModal");
          Mixpanel?.track("Friend request sent", {
            source: "peer story",
            type: "close",
            slug: props.slug,
          });
          setTimeout(() => {
            setAlertComponent(
              <Alert
                elementName="Alert"
                type="success"
                message="Successfully sent close friend request"
              />,
              5000
            );
          }, 200);
        })
        .catch((err) => {
          console.log(err);
          setButtonText("Send connection request");
          removeModalByName("ConnectionModal");
          setTimeout(() => {
            setAlertComponent(
              <Alert
                elementName="Alert"
                type="fail"
                message="Close friend request is already in progress."
              />,
              7000
            );
          }, 200);
        });
    }
  }

  if (status === null) {
    return (
      <main
        style={{ transform: "none" }}
        className={styles.wrapper}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={styles.images}>
          <SmallProfileImage
            publicProfile={userState.publicProfileData as PublicProfile}
            imageUrl={userState.imageURL as string}
          />
          <div className={styles.svg}>
            <CloseConnectionIcon active />
          </div>
          <SmallProfileImage
            publicProfile={props.publicProfile}
            imageUrl={imageUrl as string}
          />
        </div>
        <h3 className={styles.title}>Requires close connection</h3>
        <p className={styles.unlock}>
          Unlocks personal content about love, emotions, and vulnerabilities.
        </p>
        <button
          disabled={buttonText === "Loading..."}
          onClick={() => handleClick()}
          className={styles.button}
        >
          {buttonText}
        </button>
      </main>
    );
  }

  if (status === "recieved") {
    return (
      <main
        style={{ transform: "none" }}
        className={styles.wrapper}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={styles.images}>
          <SmallProfileImage
            publicProfile={userState.publicProfileData as PublicProfile}
            imageUrl={userState.imageURL as string}
          />
          <div className={styles.svg}>
            <CloseConnectionIcon active />
          </div>
          <SmallProfileImage
            publicProfile={props.publicProfile}
            imageUrl={imageUrl as string}
          />
        </div>
        <h3 className={styles.title}>Close connection request</h3>
        <p className={styles.unlock}>
          Unlocks personal content about love, emotions, and vulnerabilities.
        </p>

        <button onClick={() => handleAcceptRequest()} className={styles.button}>
          Accept
        </button>
        <button
          onClick={() => handleDeclineRequest()}
          className={styles.buttonPending}
        >
          Decline
        </button>
      </main>
    );
  }

  if (status === "sent") {
    return (
      <main
        style={{ transform: "none" }}
        className={styles.wrapper}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={styles.images}>
          <SmallProfileImage
            publicProfile={userState.publicProfileData as PublicProfile}
            imageUrl={userState.imageURL as string}
          />
          <div className={styles.svg}>
            <CloseConnectionIcon active />
          </div>
          <SmallProfileImage
            publicProfile={props.publicProfile}
            imageUrl={imageUrl as string}
          />
        </div>
        <h3 className={styles.title}>Requires close connection</h3>
        <p className={styles.unlock}>
          Unlocks personal content about love, emotions, and vulnerabilities.
        </p>

        <button disabled={true} className={styles.buttonPending}>
          Pending
        </button>
      </main>
    );
  }

  return <></>;
}

function useCloseConnectionStatus(props: { publicProfile: PublicProfile }) {
  const [status, setStatus] = useState<"recieved" | "sent" | null>(null);
  const userState = useSelector((state: RootState) => state.user);

  useEffect(() => {
    let s: "recieved" | "sent" | null = null;
    if (
      userState.isCloseRequestSentUidList.includes(
        props.publicProfile.ownerUUID
      )
    ) {
      s = "sent";
    }
    if (
      userState.isDeepRequestSentUidList.includes(props.publicProfile.ownerUUID)
    ) {
      s = "sent";
    }
    userState.friendRequestsRecieved?.forEach((req) => {
      if (
        req.isClose === true &&
        !req.isDeep &&
        req.sender.ownerUUID === props.publicProfile.ownerUUID
      ) {
        s = "recieved";
      }
    });
    setStatus(s);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.publicProfile.ownerUUID]);

  return status;
}
