import { ExtendedPattern } from "@/models/sharedModels";
import styles from "./PatternComponents.module.css";
import { RuleDirection } from "@dimensional-engineering/dimensional-models";
import { Roboto_Mono } from "next/font/google";

const font = Roboto_Mono({ subsets: ["latin"] });

export default function PatternComponents(props: { pattern: ExtendedPattern }) {
  const mapped = props.pattern.patternDetails.componentTraits.map((row) => {
    return (
      <div key={row.slug} className={styles.row}>
        <p className={`${font.className} ${styles.rowText}`}>{row.name}</p>
        <p className={`${font.className} ${styles.rowText}`}>
          {row.direction === RuleDirection.up
            ? "Positive"
            : row.direction === RuleDirection.down
            ? "Negative"
            : "?"}
        </p>
      </div>
    );
  });

  return (
    <main className={styles.main}>
      <div className={styles.wrapper}>
        <h3 className={styles.title}>Pattern Components</h3>
        <div className={styles.row}>
          <p className={`${font.className} ${styles.rowTextHeader}`}>TRAIT:</p>
          <p className={`${font.className} ${styles.rowTextHeader}`}>
            ASSOCIATION:
          </p>
        </div>
        {mapped}
      </div>
    </main>
  );
}
