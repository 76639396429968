/* eslint-disable @next/next/no-img-element */
import { useEffect, useState } from "react";
import { useRouter } from "next/router";

import styles from "../Snippets.module.css";
import innerStyles from "./HighestScoringTraitsSnippetModal.module.css";

import { useModalContext } from "@/context/ModalContext";
import { fetchHighestScoringTraits, updateSnippetsFeedback } from "../utils";
import SnippetInterractionBar from "../SnippetInterractionBar/SnippetInterractionBar";
import { AnalysisFeedbackSnippets } from "../../personalityAnalysis/AnalysisFeedback/AnalysisFeedback";
import LargeProfileImage from "@/components/shared/SmallProfileImage/LargeProfileImage";
import ModalWrapper from "@/components/shared/ModalWrapper/ModalWrapper";
import { computableSnippetsObject, snippetsIconMap } from "../snippetsMap";
import useGetSnippetModalDataHook from "@/helpers/useGetSnippetModalDataHook";
import { ExtendedScale, HighestScoringTraitsData } from "@/models/sharedModels";
import { getElementFromTraitIdentifier } from "@/components/authenticatedProfile/tabs/AuthCompareTab/utils";
import { natureData } from "@/assets/traitInfo/natureData";
import { isNotDiscoverable } from "@/assets/traitInfo/isNotDiscoverable";
import { TraitIdentifier } from "@dimensional-engineering/dimensional-models";
import { TraitType } from "@dimensional-engineering/dimensional-models/lib/models/traits/details/TraitType";
import ElementModal from "@/components/traits/TraitsModals/Element/ElementModal";
import PatternModal from "@/components/traits/TraitsModals/Pattern/PatternModal";
import PeerIcon from "@/components/premium/reports/blindspot/PeerIcon";
import PeerAssessmentIcon from "@/components/shared/icons/PeerAssessmentIcon";
import Loader from "@/components/shared/Loader";
import WarningIcon from "@/components/shared/GhostBox/WarningIcon";
import PeerFeedbackModal from "@/components/appHome/PeerFeedBackModule/PeerFeedbackModal/PeerFeedbackModal";
import useGetPublicProfile from "@/helpers/useGetPublicProfile";
import useGetProfilePhoto from "@/helpers/useGetProfilePhoto";

export default function HighestScoringTraitsSnippetModal(props: {
  uid: string | undefined;
  nonOwner?: boolean;
}) {
  const [showAnlaysis, setShowAnalysis] = useState<boolean>(false);

  const { setModalComponent } = useModalContext();

  const { loading, data } = useGetHIghestScoringTraitsSnippetData(props.uid);
  const publicProfile = useGetPublicProfile(props.uid);
  const imageUrl = useGetProfilePhoto(props.uid);

  const discoverableSnippet =
    computableSnippetsObject["snippet-highest-scoring-traits"];

  const { userState, feedbackScore } = useGetSnippetModalDataHook(
    "snippet-highest-scoring-traits"
  );

  const isConnection =
    props.uid !== undefined && userState.friendUidList.includes(props.uid);

  function handleTraitClick(traitIdentifier: TraitIdentifier) {
    if (traitIdentifier.type === TraitType.element) {
      return setModalComponent(
        <ElementModal uid={props.uid} slug={traitIdentifier.slug} />
      );
    } else {
      return setModalComponent(
        <PatternModal uid={props.uid} slug={traitIdentifier.slug} />
      );
    }
  }

  const selfRatedMapped = data?.selfRated.map((traitIdentifier) => {
    const element = getElementFromTraitIdentifier(
      traitIdentifier
    ) as ExtendedScale;
    const color =
      natureData?.find((n) => n.slug === element.nature)?.color ?? "gray";
    if (!isNotDiscoverable.includes(traitIdentifier.slug)) {
      return (
        <div
          onClick={() => handleTraitClick(traitIdentifier)}
          style={{ cursor: "pointer" }}
          className={innerStyles.elDiv}
          key={traitIdentifier.slug}
        >
          <p style={{ borderColor: color }} className={innerStyles.elementName}>
            {element.alias ?? element.name}
          </p>
        </div>
      );
    }
  });

  const peerRatedMapped = data?.peerRated.map((traitIdentifier) => {
    const element = getElementFromTraitIdentifier(
      traitIdentifier
    ) as ExtendedScale;
    const color =
      natureData?.find((n) => n.slug === element.nature)?.color ?? "gray";
    if (!isNotDiscoverable.includes(traitIdentifier.slug)) {
      return (
        <div
          onClick={() => handleTraitClick(traitIdentifier)}
          style={{ cursor: "pointer" }}
          className={innerStyles.elDiv}
          key={traitIdentifier.slug}
        >
          <p style={{ borderColor: color }} className={innerStyles.elementName}>
            {element.alias ?? element.name}
          </p>
        </div>
      );
    }
  });

  function checkScroll(e: React.UIEvent<HTMLElement>) {
    const target = e.currentTarget;
    const isScrollable = target.scrollHeight > target.clientHeight;
    const isScrolledToBottom =
      target.scrollTop + target.clientHeight >= target.scrollHeight - 4;

    if (!isScrollable || isScrolledToBottom) {
      if (feedbackScore === null) {
        setShowAnalysis(true);
      }
    }
  }

  return (
    <ModalWrapper
      onScroll={(e) => {
        checkScroll(e);
      }}
      title={discoverableSnippet.name}
      subTitle="Snippet"
    >
      <section style={{ paddingBottom: "300px" }} className={styles.modalMain}>
        <div className={styles.modalWrapper}>
          <div className={styles.top}>
            <LargeProfileImage
              publicProfile={
                props.nonOwner ? publicProfile : userState.publicProfileData
              }
              imageUrl={
                props.nonOwner ? imageUrl : (userState.imageURL as string)
              }
            />
            <div className={styles.snippetIcon}>
              {snippetsIconMap["snippet-highest-scoring-traits"]}
            </div>
          </div>

          <h2 style={{ fontSize: "36px" }} className={styles.llmListName}>
            Highest scoring traits
          </h2>
          <h3 className={styles.llmListSubtitle}>
            Your highest scoring traits according to you.
          </h3>

          <div className={innerStyles.parent}>
            <div className={innerStyles.titleDiv}>
              <h3 className={innerStyles.title}>Self rated</h3>
              <PeerIcon light />
            </div>
            <p className={innerStyles.desc}>Based on your self-assessments.</p>
            {loading ? (
              <Loader height="100px" mobileHeight="100px" />
            ) : selfRatedMapped?.length ? (
              <div className={innerStyles.wrapper}>{selfRatedMapped}</div>
            ) : (
              <HighestScoringTraitsSelfRatedGhostbox nonwOwn={props.nonOwner} />
            )}
          </div>

          {/* <div style={{ marginTop: "25px" }} className={innerStyles.parent}>
            <div className={innerStyles.titleDiv}>
              <h3 className={innerStyles.title}>Peer rated</h3>
              <PeerAssessmentIcon />
            </div>
            <p className={innerStyles.desc}>Based on your peer assessments.</p>
            {loading ? (
              <Loader height="100px" mobileHeight="100px" />
            ) : peerRatedMapped?.length ? (
              <div className={innerStyles.wrapper}>{peerRatedMapped}</div>
            ) : (
              <HighestScoringTraitsPeerRatedGhostBox
                nonwOwn={props.nonOwner}
                uid={props.uid}
                isConnection={isConnection}
              />
            )}
          </div> */}

          {!props.nonOwner && showAnlaysis && (
            <div className={styles.feedbackDiv}>
              <AnalysisFeedbackSnippets
                onAnswer={(value) => {
                  if (props.uid) {
                    updateSnippetsFeedback(
                      "snippet-highest-scoring-traits",
                      props.uid,
                      value
                    );
                  }
                }}
                title="How did you like this content?"
                subtitle="Feedback helps us improve"
                currentAnswer={feedbackScore}
                onClose={() => setShowAnalysis(false)}
              />
            </div>
          )}
        </div>
        {!props.nonOwner && (
          <div className={styles.interactionBarDiv}>
            <SnippetInterractionBar
              hideRegenerate={true}
              hideShare
              onRegenerate={() => {}}
              disabledRegenerate={true}
              type="SP snippet"
              slug={"snippet-highest-scoring-traits"}
              onRateClick={() => {
                setShowAnalysis(!showAnlaysis);
              }}
            />
          </div>
        )}
      </section>
    </ModalWrapper>
  );
}

function useGetHIghestScoringTraitsSnippetData(uid: string | undefined) {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<HighestScoringTraitsData | null>();

  useEffect(() => {
    if (uid) {
      setLoading(true);
      fetchHighestScoringTraits(uid)
        .then((res) => {
          setLoading(false);
          setData(res.data as HighestScoringTraitsData);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [uid]);

  return { loading, data };
}

function HighestScoringTraitsSelfRatedGhostbox(props: { nonwOwn?: boolean }) {
  return (
    <div className={innerStyles.ghostboxParent}>
      <div className={innerStyles.iconDiv}>
        <WarningIcon active />
      </div>
      <p className={innerStyles.ghostBoxText}>
        {props.nonwOwn
          ? "They must complete more Dimensions to unlock this."
          : "Complete more Dimensions to unlock."}
      </p>
    </div>
  );
}

function HighestScoringTraitsPeerRatedGhostBox(props: {
  nonwOwn?: boolean;
  uid: string | undefined;
  isConnection: boolean;
}) {
  const { setModalComponent } = useModalContext();
  const router = useRouter();

  return (
    <div className={innerStyles.ghostboxParent}>
      <div className={innerStyles.iconDiv}>
        <WarningIcon active />
      </div>
      <p style={{ maxWidth: "201px" }} className={innerStyles.ghostBoxText}>
        {props.nonwOwn
          ? "More connections need to complete their peer assessment"
          : "More connections need to complete your peer assessment"}
      </p>
      {props.isConnection && (
        <button
          onClick={() => {
            router.push(`/app/peer-assessment/peer-core/${props.uid}`);
          }}
          className={innerStyles.ghostBoxButton}
        >
          Assess
        </button>
      )}
      {!props.nonwOwn && (
        <button
          onClick={() => {
            setModalComponent(<PeerFeedbackModal />);
          }}
          className={innerStyles.ghostBoxButton}
        >
          Request
        </button>
      )}
    </div>
  );
}
