import { TraitIdentifier } from "@dimensional-engineering/dimensional-models";

import styles from "./AnalysisAssociatedTraits.module.css";

import Element from "@/components/traits/element/Element/Element";
import Pattern from "@/components/traits/pattern/Pattern/Pattern";
import Archetype from "@/components/traits/arcehtype/Archetypes/Archetype";
import archetypesJsonData from "../../../../assets/traitInfo/archetypes.json";
import { Archetype as ArchetypeModelNPM } from "@dimensional-engineering/dimensional-models";
import { useModalContext } from "@/context/ModalContext";
import ElementModal from "@/components/traits/TraitsModals/Element/ElementModal";
import PatternModal from "@/components/traits/TraitsModals/Pattern/PatternModal";
import ArchetypeModal from "@/components/traits/TraitsModals/Archetype/ArchetypeModal";

export default function AnalysisAssociatedTraits(props: {
  data: TraitIdentifier[];
}) {
  const { setModalComponent } = useModalContext();

  const mapped = props.data.map((el, index) => {
    if (el.type === "element") {
      return (
        <div
          style={
            index === 0
              ? { marginLeft: "0", cursor: "pointer" }
              : { cursor: "pointer" }
          }
          onClick={() => setModalComponent(<ElementModal slug={el.slug} />)}
          key={el.slug}
          className="elBodyFiveMArgin"
        >
          <Element slug={el.slug} />
        </div>
      );
    } else if (el.type === "pattern") {
      return (
        <div
          style={
            index === 0
              ? { marginLeft: "0", cursor: "pointer" }
              : { cursor: "pointer" }
          }
          onClick={() => setModalComponent(<PatternModal slug={el.slug} />)}
          key={el.slug}
          className="elBodyFiveMArgin"
        >
          <Pattern patternSlug={el.slug} />
        </div>
      );
    } else {
      let arch: ArchetypeModelNPM | null = null;
      archetypesJsonData.forEach((arc) => {
        if (arc.slug === el.slug) {
          arch = arc as unknown as ArchetypeModelNPM;
        }
      });
      if (arch !== null) {
        return (
          <div
            style={
              index === 0
                ? { marginLeft: "0", cursor: "pointer" }
                : { cursor: "pointer" }
            }
            onClick={() => setModalComponent(<ArchetypeModal slug={el.slug} />)}
            key={el.slug}
            className="elBodyFiveMArgin"
          >
            <Archetype archetype={arch} />
          </div>
        );
      }
    }
  });

  return <div className={styles.main}>{mapped}</div>;
}
