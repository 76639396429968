import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useRouter } from "next/router";
import {
  FriendRequest,
  PublicProfile,
} from "@dimensional-engineering/dimensional-models";

import styles from "./ProfileConnectButton.module.css";

import useConnectionStatus from "@/helpers/useConnectionStatus";
import {
  acceptFriendRequest,
  declineFriendRequest,
} from "@/components/connections/utils";
import { RootState } from "@/redux/store";
import { Mixpanel } from "@/helpers/mixpanel";
import { useModalContext } from "@/context/ModalContext";
import HalfWayModalConnections from "@/components/shared/HalfWayModal/HalfWayModalConnections";
import ConnectionRequestModal from "@/components/connections/ConnectionRequestModal/ConnectionRequestModal";
import RateIcon from "@/components/shared/icons/RateIcon";

type Props = {
  publicProfile: PublicProfile;
};

export default function ProfileConnectButton(props: Props) {
  const router = useRouter();

  const { setModalComponent } = useModalContext();

  const friendStatus = useConnectionStatus(props.publicProfile.ownerUUID);

  const userState = useSelector((state: RootState) => state.user);

  const [currentFriendRequest, setCurrentFriendRequest] =
    useState<FriendRequest | null>(null);
  const [buttonText, setButtonText] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (friendStatus === "received") {
      userState.friendRequestsRecieved?.forEach((req) => {
        if (req.sender.ownerUUID === props.publicProfile.ownerUUID) {
          setCurrentFriendRequest(req);
        }
      });
      setButtonText("Accept");
    }

    if (
      friendStatus === "connected" ||
      friendStatus === "close" ||
      friendStatus === "deep"
    ) {
      setButtonText("...");
    }

    if (friendStatus === "sent") {
      setButtonText("Pending");
    }

    if (friendStatus === "unconnected") {
      setButtonText("Connect");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.publicProfile.ownerUUID, friendStatus]);

  function handleClick() {
    if (friendStatus !== "sent") {
      setLoading(true);
    }
    if (friendStatus === "received") {
      if (currentFriendRequest) {
        acceptFriendRequest(currentFriendRequest)
          .then((res) => {
            setButtonText("...");
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
        Mixpanel?.track("Friend request accepted", {
          type: currentFriendRequest.isDeep
            ? "deep"
            : currentFriendRequest.isClose
            ? "close"
            : "basic",
        });
      }
    }

    if (
      friendStatus === "connected" ||
      friendStatus === "close" ||
      friendStatus === "deep"
    ) {
      setModalComponent(
        <HalfWayModalConnections
          uid={props.publicProfile.ownerUUID}
          elementName="HalfwayModal"
        />
      );

      setLoading(false);
    }

    if (friendStatus === "unconnected") {
      if (userState.publicProfileData) {
        setModalComponent(
          <ConnectionRequestModal
            publicProfile={props.publicProfile}
            elementName="ConnectionModal"
          />
        );

        setLoading(false);
      }
    }
  }

  if (loading) {
    return (
      <div className={styles.main}>
        <p className={styles.text}>Loading...</p>
      </div>
    );
  }

  return (
    <div className={styles.main}>
      {/* {friendStatus !== "unconnected" &&
        friendStatus !== "sent" &&
        friendStatus !== "received" && (
          <div
            onClick={() => {
              Mixpanel?.track("Peer Assessment Intro Viewed", {
                source: "profile header",
              });
              return router.push(
                `/app/peer-assessment/peer-core/${props.publicProfile.ownerUUID}`
              );
            }}
            className={styles.rateBackground}
          >
            <p className={styles.assess}>Assess</p>
            <div className={styles.rateIconDiv}>
              <RateIcon />
            </div>
          </div>
        )} */}
      {friendStatus !== "unconnected" &&
        friendStatus !== "sent" &&
        friendStatus !== "received" && (
          <button
            onClick={() =>
              router.push(`/app/messaging/${props.publicProfile.ownerUUID}`)
            }
            className={styles.messageButton}
          >
            Message
          </button>
        )}
      {friendStatus !== "unconnected" &&
        friendStatus !== "sent" &&
        friendStatus !== "received" && (
          <div
            className={styles.mainConnected}
            onClick={() => {
              handleClick();
            }}
            style={{ minWidth: "63px" }}
          >
            <p className={styles.textConnected}>{buttonText}</p>
          </div>
        )}

      {friendStatus === "unconnected" && (
        <div
          className={styles.mainConnected}
          onClick={() => {
            handleClick();
          }}
        >
          <p className={styles.text}>{buttonText}</p>
        </div>
      )}

      {friendStatus === "sent" && (
        <div className={styles.pending}>{buttonText}</div>
      )}

      {friendStatus === "received" && (
        <div
          onClick={() => {
            handleClick();
          }}
          className={styles.mainConnected}
        >
          <p className={styles.text}>{buttonText}</p>
        </div>
      )}
      {friendStatus === "received" && (
        <div
          onClick={() => {
            if (currentFriendRequest) {
              declineFriendRequest(currentFriendRequest).then((res) => {
                console.log(res);
              });
            }
          }}
          className={styles.declineButton}
        >
          Decline
        </div>
      )}
    </div>
  );
}
