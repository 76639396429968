import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { PublicProfile } from "@dimensional-engineering/dimensional-models";

import styles from "./ElementTraitsPageLeftBar.module.css";

import { LargeElementModal } from "@/components/traits/element/Element/largeElement/LargeElement";
import { ExtendedScale } from "@/models/sharedModels";
import { iconMapper } from "@/components/shared/dimensions/icons/iconMapper";
import { traitsMapper } from "../../shared/traitsMap";
import { RootState } from "@/redux/store";
import TraitScoreBar from "../../shared/TraitScoreBar/TraitScoreBar";
import { getUserScaleScore, traitPermissionStatus } from "../../utils";
import useConnectionStatus from "@/helpers/useConnectionStatus";
import TraitAccessDeniedBar from "../../shared/TraitAccessDeniedBar/TraitAccessDeniedBar";
import TraitDescription from "../../shared/TraitScoreTable/TraitDescription";
import useGetIsPremium from "@/helpers/useGetIsPremium";
import PremiumTraitAccessDeniedBar from "@/components/premium/PremiumTraitAccessDeniedBar/PremiumTraitAccessDeniedBar";
import PrivateTraitAccessDeniedBar from "../../shared/TraitAccessDeniedBar/PrivateTraitAccessDeniedBar";
import { AvailableToComponentAvailibility } from "@/components/shared/AvailableToComponent/AvailableToComponent";
import { natureData, natureMap } from "@/assets/traitInfo/natureData";

type LeftBarProps = {
  element: ExtendedScale;
  nonOwnProfile?: boolean;
  friendScore?: number | null | undefined;
  publicProfile?: PublicProfile | null;
};

export default function ElementTraitsPageLeftBarModal(props: LeftBarProps) {
  const [score, setScore] = useState<number | null>();
  const userState = useSelector((state: RootState) => state.user);
  const friendStatus = useConnectionStatus(props.publicProfile?.ownerUUID);
  const isUserPremium = useGetIsPremium(props.publicProfile?.ownerUUID);

  useEffect(() => {
    if (props.nonOwnProfile) {
      setScore(getUserScaleScore(props.friendScore, props.element.slug));
    } else {
      userState.elementsDiscovered?.forEach((el) => {
        if (el.scaleSlug === props.element.slug) {
          setScore(
            getUserScaleScore(el.scaleScore?.percentScore, el.scaleSlug)
          );
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.friendScore, props.nonOwnProfile]);

  if (!props.nonOwnProfile) {
    // own profile
    return (
      <div className={styles.leftBarModal}>
        <LargeElementModal
          color={
            natureData?.find((n) => n.slug === props.element.nature)?.color ??
            "gray"
          }
          name={props.element.alias ?? props.element.name}
          // @ts-ignore
          icon={iconMapper[props.element.dimensionSlug]}
          dimensionName={traitsMapper[props.element.dimensionSlug]}
          isPremium={props.element.isPremium}
        />
        <div className={styles.defDivModal}>
          <div className={styles.defTitleDivModal}>
            <h3>Quick definition</h3>
          </div>
          <p>{props.element.shortDescription}</p>
        </div>
        {props.element.isPremium && !userState.isPremium ? (
          <div className={styles.premiumDeniedDiv}>
            <PremiumTraitAccessDeniedBar />
          </div>
        ) : (
          <div className={styles.scoreDiv}>
            <TraitScoreBar score={score ? score * 100 : null} />
          </div>
        )}
        <TraitDescription
          type="Element"
          dimension={props.element.dimensionSlug}
          nature={
            props.element.nature
              ? natureMap[props.element.nature].name
              : undefined
          }
          color={
            props.element.nature
              ? natureMap[props.element.nature].color
              : undefined
          }
          plasticity={props.element.plasticity}
          oppositeTraitSlug={
            props.element.traitContent.oppositeDetails?.oppositeTrait.slug
          }
        />
        <div className={styles.availability}>
          <AvailableToComponentAvailibility
            traits
            connectionAvailability={props.element.connectionAvailabilities}
          />
        </div>
      </div>
    );
  } else {
    ///connected profile
    const status = traitPermissionStatus(
      friendStatus,
      props.element.connectionAvailabilities
    );
    return (
      <div className={styles.leftBarModal}>
        <LargeElementModal
          color={
            natureData?.find((n) => n.slug === props.element.nature)?.color ??
            "gray"
          }
          name={props.element.alias ?? props.element.name}
          // @ts-ignore
          icon={iconMapper[props.element.dimensionSlug]}
          dimensionName={traitsMapper[props.element.dimensionSlug]}
          isPremium={props.element.isPremium}
        />
        <div className={styles.defDivModal}>
          <div className={styles.defTitleDivModal}>
            <h3>Quick definition</h3>
          </div>
          <p>{props.element.shortDescription}</p>
        </div>
        <div className={styles.scoreDiv}>
          {status === "private" ? (
            <PrivateTraitAccessDeniedBar />
          ) : props.element.isPremium && !isUserPremium ? (
            <div className={styles.premiumDeniedDiv}>
              <PremiumTraitAccessDeniedBar
                notOwn
                traitName={props.element.alias ?? props.element.name}
              />
            </div>
          ) : status === null ? (
            <TraitScoreBar
              friendPublicProfile={props.publicProfile}
              nonOwner
              score={score ? score * 100 : null}
            />
          ) : (
            <TraitAccessDeniedBar
              publicProfile={props.publicProfile}
              connectionTypeNeeded={status}
            />
          )}
        </div>
        <TraitDescription
          type="Element"
          dimension={props.element.dimensionSlug}
          nature={
            props.element.nature
              ? natureMap[props.element.nature].name
              : undefined
          }
          color={
            props.element.nature
              ? natureMap[props.element.nature].color
              : undefined
          }
          plasticity={props.element.plasticity}
          oppositeTraitSlug={
            props.element.traitContent.oppositeDetails?.oppositeTrait.slug
          }
        />
        <div className={styles.availability}>
          <AvailableToComponentAvailibility
            traits
            connectionAvailability={props.element.connectionAvailabilities}
          />
        </div>
      </div>
    );
  }
}
