import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Roboto_Mono } from "next/font/google";
import { RootState } from "@/redux/store";

import domtoimage from "dom-to-image";

import styles from "./ElementAndPatternSharedModal.module.css";

import elementsJsonData from "../../../../assets/traitInfo/elements.json";
import patternsJsonData from "../../../../assets/traitInfo/patterns.json";

import ModalWrapper from "@/components/shared/ModalWrapper/ModalWrapper";
import { useModalContext } from "@/context/ModalContext";
import { getUserPatternScore, getUserScaleScore } from "../../utils";
import Element from "../../element/Element/Element";
import Pattern from "../../pattern/Pattern/Pattern";

const font = Roboto_Mono({ subsets: ["latin"] });

export default function ElementAndPatternSharedModal(props: {
  traitType: "element" | "pattern" | "archetype";
  traitSlug: string;
  type: "instagram" | "snapchat";
}) {
  const imageRef = useRef<HTMLDivElement>(null);
  const [score, setScore] = useState<number | null | undefined>(null);
  const [shortDescription, setShortDescription] = useState<string>("");
  const [scoreText, setScoreText] = useState<
    "very low" | "low" | "average" | "high" | "very high"
  >();
  const [name, setName] = useState<string>("");

  const elementDiscoveries = useSelector(
    (state: RootState) => state.user
  ).elementsDiscovered;

  const patternDiscoveries = useSelector(
    (state: RootState) => state.user
  ).patternsDiscovered;

  const { setModalOpen } = useModalContext();

  useEffect(() => {
    if (props.traitType === "element") {
      const element = elementDiscoveries?.find(
        (e) => e.scaleSlug === props.traitSlug
      );

      setScore(
        getUserScaleScore(element?.scaleScore?.percentScore, props.traitSlug)
      );
      setShortDescription(
        elementsJsonData.find((e) => e.slug === props.traitSlug)
          ?.shortDescription ?? ""
      );
      setName(
        elementsJsonData.find((e) => e.slug === props.traitSlug)?.alias ??
          elementsJsonData.find((e) => e.slug === props.traitSlug)?.name ??
          ""
      );
    } else {
      const pattern = patternDiscoveries?.find(
        (e) => e.patternSlug === props.traitSlug
      );

      setScore(getUserPatternScore(pattern?.patternScore, props.traitSlug));
      setShortDescription(
        patternsJsonData.find((e) => e.slug === props.traitSlug)
          ?.shortDescription ?? ""
      );
      setName(
        patternsJsonData.find((e) => e.slug === props.traitSlug)?.name ?? ""
      );
    }
  }, [props.traitType, props.traitSlug]);

  useEffect(() => {
    if (score !== null && score !== undefined) {
      let multiplied = score * 100;
      if (multiplied <= 12) {
        setScoreText("very low");
      } else if (multiplied > 12 && multiplied <= 29) {
        setScoreText("low");
      } else if (multiplied > 29 && multiplied <= 69) {
        setScoreText("average");
      } else if (multiplied > 69 && multiplied <= 88) {
        setScoreText("high");
      } else {
        setScoreText("very high");
      }
    }
  }, [score]);

  useEffect(() => {
    const handleDownloadImage = async () => {
      if (imageRef.current) {
        const dataUrl = await domtoimage.toJpeg(imageRef.current, {
          quality: 0.95,
        });
        // const link = document.createElement("a");
        // link.href = dataUrl;
        // link.download = "somefilename.jpg";
        // link.click();
        if (window.Android) {
          window.Android.onHaptic();

          if (props.type === "instagram") {
            window.Android.shareToInstagramQuiz(dataUrl);
          } else {
            window.Android.shareToSnapChat(dataUrl);
          }
        }
      }
    };

    setTimeout(() => {
      handleDownloadImage().then(() => {
        setModalOpen(false);
      });
    }, 110);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ModalWrapper noHeader noLine title="">
      <main ref={imageRef} className={styles.main}>
        <section className={styles.top}>
          {props.traitType === "element" ? (
            <Element slug={props.traitSlug} score={score} />
          ) : (
            <Pattern patternSlug={props.traitSlug} score={score} />
          )}
          <p className={styles.description}>
            <span style={{ fontStyle: "italic" }}>def.</span> {shortDescription}
          </p>
        </section>

        <section className={styles.mid}>
          <h1 className={styles.score}>
            {score ? (score * 100).toFixed(0) : "??"}
          </h1>
          <p className={`${styles.yourScore} ${font.className}`}>Your score</p>
          <p className={`${styles.outOf} ${font.className}`}>(out of 100)</p>
        </section>

        <section>
          <p className={styles.scoreText}>You score {scoreText} on</p>
          <p className={styles.scoreText}> {name}</p>
        </section>
      </main>
    </ModalWrapper>
  );
}
