import { useState } from "react";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "@/_firebase/firebaseConfig";
import { RootState } from "@/redux/store";

import styles from "./NotificationBar.module.css";

import { Mixpanel } from "@/helpers/mixpanel";
import { NotificationLevel } from "@/models/sharedModels";
import RoundedCheckbox from "@/components/shared/inputs/RoundedCheckbox/RoundedCheckbox";
import { useAuth } from "@/context/AuthContext";
import { SmallLoader } from "@/components/shared/Loader";

export default function NotificationBar(props: {
  source: "Notification feed" | "Home warning pill";
  notificationStatus:
    | NotificationLevel
    | "systemOff"
    | "noSelection"
    | undefined;
  onSelection: () => void;
}) {
  const router = useRouter();
  const { user } = useAuth();

  const selectedNotificationLevel = useSelector(
    (state: RootState) => state.user
  ).memberProfile?.selectedNotificationLevel;

  const [essentials, setEssentials] = useState<boolean>(
    selectedNotificationLevel === NotificationLevel.essentials
  );
  const [basics, setBasics] = useState<boolean>(
    selectedNotificationLevel === NotificationLevel.basic ||
      !selectedNotificationLevel
  );
  const [everything, setEverything] = useState<boolean>(
    selectedNotificationLevel === NotificationLevel.everything
  );

  const [loading, setLoading] = useState<boolean>(false);

  const ref = doc(db, `/members/${user?.uid}`);

  async function handleSelection() {
    setLoading(true);
    if (everything) {
      updateDoc(ref, {
        selectedNotificationLevel: NotificationLevel.everything,
      });
      Mixpanel?.track("Notification Level Manually Set", {
        level: "everything",
        source: "dashboard",
      });
    } else if (basics) {
      await updateDoc(ref, {
        selectedNotificationLevel: NotificationLevel.basic,
      });
      Mixpanel?.track("Notification Level Manually Set", {
        level: "basic",
        source: "dashboard",
      });
    } else if (essentials) {
      await updateDoc(ref, {
        selectedNotificationLevel: NotificationLevel.essentials,
      });
      Mixpanel?.track("Notification Level Manually Set", {
        level: "essentials",
        source: "dashboard",
      });
    } else {
      await updateDoc(ref, {
        selectedNotificationLevel: NotificationLevel.off,
      });
      Mixpanel?.track("Notification Level Manually Set", {
        level: "off",
        source: "dashboard",
      });
    }
    props.onSelection();
    setLoading(false);
  }

  if (props.notificationStatus === "systemOff") {
    return (
      <section className={styles.main}>
        <p className={styles.title}>Your notifications are off</p>
        <p className={styles.subtitle}>
          {"Don't miss results, insights, and important updates!"}
        </p>
        <div className={styles.buttonsDiv}>
          <button
            onClick={() => router.push("/app/settings/notifications")}
            className={styles.customizeButton}
          >
            Customize
          </button>
          <button
            onClick={async () => {
              Mixpanel?.track("Enable notification cta clicked", {
                source: props.source,
              });
              if (window.Android) {
                window.Android.onGoToNotificationSettings();
              }
            }}
            className={styles.enableButton}
          >
            Enable
          </button>
        </div>
      </section>
    );
  }
  return (
    <section
      style={{ background: "#4CBC86", paddingBottom: "16px" }}
      className={styles.main}
    >
      <p className={styles.title}>Set notification levels</p>
      <div className={styles.activeRowWrapper}>
        <div className={styles.activeRow}>
          <div className={styles.activeRowTextDiv}>
            <h4 className={styles.activeRowTitle}>Essential only</h4>
            <p className={styles.activeRowDesc}>
              Connection messages. Essential updates.
            </p>
          </div>
          <RoundedCheckbox
            setValue={async (e) => {
              setEssentials(e);
              if (e) {
                setBasics(false);
                setEverything(false);
              }
            }}
            currentValue={essentials}
          />
        </div>

        <div className={styles.activeRow}>
          <div className={styles.activeRowTextDiv}>
            <h4 className={styles.activeRowTitle}>Basic (default)</h4>
            <p className={styles.activeRowDesc}>
              Insights and Stories. Newly unlocked content.
            </p>
          </div>
          <RoundedCheckbox
            setValue={async (e) => {
              setBasics(e);
              if (e) {
                setEssentials(false);
                setEverything(false);
              }
            }}
            currentValue={basics}
          />
        </div>

        <div className={styles.activeRow}>
          <div className={styles.activeRowTextDiv}>
            <h4 className={styles.activeRowTitle}>Everything</h4>
            <p className={styles.activeRowDesc}>
              All daily content and connection updates.
            </p>
          </div>
          <RoundedCheckbox
            setValue={async (e) => {
              setEverything(e);
              if (e) {
                setBasics(false);
                setEssentials(false);
              }
            }}
            currentValue={everything}
          />
        </div>
      </div>
      <div className={styles.buttonDiv}>
        <button onClick={() => handleSelection()} className={styles.saveButton}>
          {loading ? <SmallLoader /> : "Save selection"}
        </button>
      </div>
    </section>
  );
}

export function SettingsNotificationBar() {
  return (
    <section className={styles.mainSettings}>
      <p className={styles.settingsInfo}>
        Notifications are off. Would you like to receive Dimensional
        notifications?
      </p>
      <button
        onClick={async () => {
          if (window.Android) {
            window.Android.onGoToNotificationSettings();
            Mixpanel?.track("Enable notification cta clicked", {
              source: "Settings",
            });
          }
        }}
        className={styles.enableButtonSettings}
      >
        Enable Notifications
      </button>
    </section>
  );
}
