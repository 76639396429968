import { useSelector } from "react-redux";

import styles from "./Rewards.module.css";

import { RootState } from "@/redux/store";
import RewardsIcon from "@/components/shared/icons/RewardsIcon";

export default function RewardTab(props: {
  onClick: () => void;
  inActive?: boolean;
}) {
  const inviteCount = useSelector(
    (state: RootState) => state.user.memberProfile?.recommendeeUids?.length
  );
  return (
    <div
      style={props.inActive === true ? { background: "#2D2927" } : {}}
      onClick={() => {
        if (window.Android) {
          window.Android.onHaptic();
        }
        props.onClick();
      }}
      className={styles.rewardsTabDiv}
    >
      <RewardsIcon />
      <p className={styles.rewardsTabCount}>{inviteCount ?? 0}</p>
    </div>
  );
}
