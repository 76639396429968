import { useEffect, useState } from "react";
import {
  ContentType,
  DimensionEventStatus,
  DiscoverableSnippet,
  DiscoveredSnippet,
  PublicProfile,
} from "@dimensional-engineering/dimensional-models";
import { useSelector } from "react-redux";

import styles from "./Snippets.module.css";

import { snippetsIconMap } from "./snippetsMap";
import PremiumPillIcon from "@/components/premium/PremiumPillIcon/PremiumPillIcon";
import { RootState } from "@/redux/store";
import InvitePill from "@/components/appHome/InvitePill/InvitePill";
import useShowPremiumPill from "@/helpers/useShowPremiumPill";
import { PeerFeedbackPillVerySmall } from "@/components/appHome/PeerFeedBackModule/PeerFeedbackPill/PeerFeedbackPillElement";
import { computableSnippetsObject } from "@/components/results/Snippets/snippetsMap";
import { useModalContext } from "@/context/ModalContext";
import SnippetsModal, { SnippetsModalNonOwner } from "./SnippetsModal";
import SnippetDiscoveryGate from "@/components/shared/DiscoveryGate/SnippetDiscoveryGate";
import { summonProfileLink } from "@/components/shared/utils";
import { useAuth } from "@/context/AuthContext";
import ConversionModal from "@/components/premium/ConversionModal/ConversionModal";
import DiscoveryGate from "@/components/shared/DiscoveryGate/DiscoveryGate";
import HighestScoringTraitsSnippetModal from "./HighestScoringTraitsSnippetModal/HighestScoringTraitsSnippetModal";
import NudgeModal from "@/components/shared/NudgeModal/NudgeModal";
import ConnectionRequestModal from "@/components/connections/ConnectionRequestModal/ConnectionRequestModal";

type Props = {
  snippet: DiscoverableSnippet;
  onClick: () => void;
  greyedOut: boolean;
  updatedOn?: number;
  showInvitePill?: boolean;
};

export default function SnippetBlock(props: Props) {
  const inviteLevel = useSelector((state: RootState) => state.user.inviteLevel);

  const peerAssessmentsRequired = useShowPeerAssessmentPill({
    numSuccessfulInvitesRequired:
      props.snippet?.discoveryGate.numPeerAssessorsRequired,
  });
  // const showPremium = useShowPremiumPill(
  //   props.snippet?.isPremium,
  //   props.snippet?.snippetSlug
  // );

  if (props.showInvitePill) {
    return (
      <div style={{ position: "relative" }}>
        {props.snippet?.discoveryGate.numSuccessfulInvitesRequired && (
          <div className={styles.premiumLogo}>
            <InvitePill
              invitesRequired={
                props.snippet?.discoveryGate.numSuccessfulInvitesRequired
              }
            />
          </div>
        )}
        <div
          onClick={() => {
            props.onClick();
          }}
          className={
            props.greyedOut ? styles.snippetBlockGrey : styles.snippetBlock
          }
          key={props.snippet?.snippetSlug}
        >
          {props.snippet?.emojiString ? (
            <h2
              style={{ marginTop: "5px" }}
              className={styles.emojiStringSnippetBlock}
            >
              {props.snippet?.emojiString}
            </h2>
          ) : (
            <div style={{ marginTop: "5px" }}>
              {snippetsIconMap[props.snippet?.snippetSlug]}
            </div>
          )}
          <p className={styles.snippetName}>{props.snippet?.name}</p>
        </div>
      </div>
    );
  }

  return (
    <div style={{ position: "relative" }}>
      {props.snippet?.isPremium &&
        !props.snippet?.discoveryGate.numSuccessfulInvitesRequired && (
          <div className={styles.premiumLogo}>
            <PremiumPillIcon />
          </div>
        )}
      {props.snippet?.discoveryGate.numSuccessfulInvitesRequired &&
        inviteLevel <
          props.snippet?.discoveryGate.numSuccessfulInvitesRequired && (
          <div className={styles.premiumLogo}>
            <InvitePill
              invitesRequired={
                props.snippet?.discoveryGate.numSuccessfulInvitesRequired
              }
            />
          </div>
        )}

      {peerAssessmentsRequired > 0 && props.greyedOut && (
        <div className={styles.premiumLogo}>
          <PeerFeedbackPillVerySmall
            numOfPeerAssessmentsReceived={peerAssessmentsRequired}
          />
        </div>
      )}
      <div
        onClick={() => {
          props.onClick();
        }}
        className={
          props.greyedOut ? styles.snippetBlockGrey : styles.snippetBlock
        }
        key={props.snippet?.snippetSlug}
      >
        {props.snippet?.emojiString ? (
          <h2 className={styles.emojiStringSnippetBlock}>
            {props.snippet?.emojiString}
          </h2>
        ) : (
          <div style={{ marginTop: "5px" }}>
            {snippetsIconMap[props.snippet?.snippetSlug]}
          </div>
        )}
        <p className={styles.snippetName}>{props.snippet?.name}</p>
      </div>
    </div>
  );
}

export function SnippetBlockResultsTab(props: { slug: string }) {
  const { setModalComponent, setModalOpen } = useModalContext();
  const { user } = useAuth();

  const recommendeeUids = useSelector(
    (state: RootState) => state.user.memberProfile?.recommendeeUids
  );
  const numberOfInvites = recommendeeUids ? recommendeeUids.length : 0;
  const snippetState = useSelector((state: RootState) => state.snippet);
  const isUserPremium = useSelector(
    (state: RootState) => state.user
  )?.isPremium;

  const snippet = computableSnippetsObject[props.slug];
  const foundSnippet = snippetState.snippets?.find(
    (s) => s?.snippetSlug === props.slug
  );

  const peerAssessmentsRequired = useShowPeerAssessmentPill({
    numSuccessfulInvitesRequired:
      snippet?.discoveryGate?.numPeerAssessorsRequired,
  });
  // const showPremium = useShowPremiumPill(
  //   snippet?.isPremium,
  //   snippet?.snippetSlug
  // );

  function onSnippetClick() {
    if (foundSnippet) {
      //has snippet
      return setModalComponent(
        <SnippetsModal discoveredSnippetSlug={props.slug} />
      );
    } else {
      if (snippet.discoveryGate.numSuccessfulInvitesRequired) {
        return setModalComponent(
          <SnippetDiscoveryGate
            snippetSlug={snippet?.snippetSlug}
            snippetType={snippet?.snippetType}
            requiredDimensionSlugs={
              snippet?.discoveryGate.requiredDimensionSlugs
            }
            numFriendsRequired={snippet?.discoveryGate.numFriendsRequired}
            numSuccessfulInvitesRequired={
              snippet?.discoveryGate.numSuccessfulInvitesRequired
            }
            numPeerAssessorsRequired={
              snippet.discoveryGate.numPeerAssessorsRequired
            }
            onClose={() => {
              setModalOpen(false);
            }}
            onOpenShareLink={() => {
              summonProfileLink(
                user?.uid,
                "dashboard",
                "Invite to Dimensional"
              );
            }}
            snippetConnection={
              snippet.discoveryGate.numFriendsRequired ? true : false
            }
            snippetDimension={
              snippet.discoveryGate.requiredDimensionSlugs ? true : false
            }
          />
        );
      }
      //discovery gate
      if (snippet?.isPremium && !isUserPremium) {
        return setModalComponent(<ConversionModal initialSlide={2} />);
      }
      return setModalComponent(
        <SnippetDiscoveryGate
          snippetSlug={snippet?.snippetSlug}
          snippetType={snippet?.snippetType}
          requiredDimensionSlugs={snippet?.discoveryGate.requiredDimensionSlugs}
          numFriendsRequired={snippet?.discoveryGate.numFriendsRequired}
          numSuccessfulInvitesRequired={
            snippet?.discoveryGate.numSuccessfulInvitesRequired
          }
          numPeerAssessorsRequired={
            snippet.discoveryGate.numPeerAssessorsRequired
          }
          onClose={() => {
            setModalOpen(false);
          }}
          onOpenShareLink={() => {
            summonProfileLink(user?.uid, "dashboard", "Invite to Dimensional");
          }}
          snippetConnection={
            snippet.discoveryGate.numFriendsRequired ? true : false
          }
          snippetDimension={
            snippet.discoveryGate.requiredDimensionSlugs ? true : false
          }
        />
      );
    }
  }

  return (
    <div style={{ position: "relative" }}>
      {snippet?.isPremium &&
        !snippet.discoveryGate.numSuccessfulInvitesRequired && (
          <div className={styles.premiumLogo}>
            <PremiumPillIcon />
          </div>
        )}
      {snippet?.discoveryGate?.numSuccessfulInvitesRequired &&
        numberOfInvites < snippet.discoveryGate.numSuccessfulInvitesRequired &&
        !foundSnippet && (
          <div className={styles.premiumLogo}>
            <InvitePill
              invitesRequired={
                snippet.discoveryGate.numSuccessfulInvitesRequired
              }
            />
          </div>
        )}

      {peerAssessmentsRequired > 0 && !foundSnippet && (
        <div className={styles.premiumLogo}>
          <PeerFeedbackPillVerySmall numOfPeerAssessmentsReceived={4} />
        </div>
      )}
      <div
        onClick={() => {
          onSnippetClick();
        }}
        className={
          !foundSnippet ? styles.snippetBlockGrey : styles.snippetBlock
        }
        key={props.slug}
        style={{ width: "auto", minWidth: "0", maxWidth: "none" }}
      >
        {snippet?.emojiString ? (
          <h2 className={styles.emojiStringSnippetBlock}>
            {snippet?.emojiString}
          </h2>
        ) : (
          <div style={{ marginTop: "5px" }}>{snippetsIconMap[props.slug]}</div>
        )}
        <p className={styles.snippetName}>{snippet?.name}</p>
      </div>
    </div>
  );
}

export function SnippetBlockNonOwner(props: {
  publicProfile: PublicProfile | null;
  discoveredSnippet: DiscoveredSnippet | undefined;
  imageUrl: string;
  slug: string;
  connectionState: "connected" | "unconnected";
  noWidth?: boolean;
}) {
  const { setModalComponent } = useModalContext();
  const snippet = computableSnippetsObject[props.slug];

  function onSnippetClick() {
    if (props.discoveredSnippet) {
      ///show snippet modal
      setModalComponent(
        <SnippetsModalNonOwner
          publicProfile={props.publicProfile}
          imageUrl={props.imageUrl}
          discoveredSnippet={props.discoveredSnippet}
        />
      );
    } else {
      if (props.connectionState === "connected") {
        return setModalComponent(
          <NudgeModal
            publicProfile={props.publicProfile}
            content={{
              contentSlug: props.slug,
              contentType: ContentType.resultSnippet,
            }}
          />
        );
      } else {
        if (props.publicProfile) {
          return setModalComponent(
            <ConnectionRequestModal
              publicProfile={props.publicProfile}
              noMessage
              text="Requires basic connection"
              elementName="ConnectionModal"
            />
          );
        }
      }
    }
  }

  return (
    <div>
      <div
        onClick={() => {
          onSnippetClick();
        }}
        className={
          !props.discoveredSnippet
            ? styles.snippetBlockGrey
            : styles.snippetBlock
        }
        key={props.slug}
        style={
          props.noWidth
            ? { width: "auto", minWidth: "0", maxWidth: "none" }
            : {}
        }
      >
        <div style={{ marginTop: "5px" }}>{snippetsIconMap[props.slug]}</div>
        <p className={styles.snippetName}>{snippet?.name}</p>
      </div>
    </div>
  );
}

export function SnippetBlockHighestScoringTraits(props: { noWidth?: boolean }) {
  const { setModalComponent, setModalOpen } = useModalContext();
  const { user } = useAuth();

  const dimensionEventStatus = useSelector(
    (state: RootState) => state.user
  ).dimensionEvents?.find((d) => d.dimensionSlug === "personality-II")?.event;

  const snippet = computableSnippetsObject["snippet-highest-scoring-traits"];

  const foundSnippet = dimensionEventStatus === DimensionEventStatus.complete;

  function onSnippetClick() {
    if (foundSnippet) {
      //has snippet
      return setModalComponent(
        <HighestScoringTraitsSnippetModal uid={user?.uid} />
      );
    } else {
      return setModalComponent(
        <DiscoveryGate
          requiredDimensionSlugs={snippet?.discoveryGate.requiredDimensionSlugs}
          numFriendsRequired={snippet?.discoveryGate.numFriendsRequired}
          numSuccessfulInvitesRequired={
            snippet?.discoveryGate.numSuccessfulInvitesRequired
          }
          onClose={() => {
            setModalOpen(false);
          }}
          onOpenShareLink={() => {
            summonProfileLink(user?.uid, "dashboard", "Invite to Dimensional");
          }}
        />
      );
    }
  }

  return (
    <div
      style={
        foundSnippet
          ? { position: "relative" }
          : { position: "relative", opacity: "0.4" }
      }
      key={"snippet-highest-scoring-traits"}
    >
      <div
        onClick={() => {
          onSnippetClick();
        }}
        className={
          !foundSnippet ? styles.snippetBlockGrey : styles.snippetBlock
        }
        style={
          props.noWidth
            ? { width: "auto", minWidth: "0", maxWidth: "none" }
            : {}
        }
      >
        <div style={{ marginTop: "5px" }}>
          {snippetsIconMap["snippet-highest-scoring-traits"]}
        </div>
        <p className={styles.snippetName}>{snippet?.name}</p>
      </div>
    </div>
  );
}

export function SnippetBlockHighestScoringTraitsNonOwner(props: {
  uid: string | undefined;
  discovered: boolean;
  connectionStatus: "connected" | "unconnected";
  publicProfile?: PublicProfile | null;
  noWidth?: boolean;
}) {
  const { setModalComponent, setModalOpen } = useModalContext();

  const connection = useSelector(
    (state: RootState) => state.connections
  ).publicProfiles?.find((p) => p.ownerUUID === props.uid);

  const snippet = computableSnippetsObject["snippet-highest-scoring-traits"];

  function onSnippetClick() {
    if (props.discovered) {
      //has snippet
      return setModalComponent(
        <HighestScoringTraitsSnippetModal uid={props.uid} nonOwner />
      );
    } else {
      if (props.connectionStatus === "connected" && connection) {
        return setModalComponent(
          <NudgeModal
            publicProfile={connection}
            content={{
              contentType: ContentType.resultSnippet,
              contentSlug: "snippet-highest-scoring-traits",
            }}
          />
        );
      } else {
        if (props.publicProfile) {
          setModalComponent(
            <ConnectionRequestModal
              noMessage
              publicProfile={props.publicProfile}
              text="Requires basic connection"
              elementName="ConnectionModal"
            />
          );
        }
      }
    }
  }

  return (
    <div style={{ position: "relative" }}>
      <div
        onClick={() => {
          onSnippetClick();
        }}
        className={
          !props.discovered ? styles.snippetBlockGrey : styles.snippetBlock
        }
        key={"snippet-highest-scoring-traits"}
        style={
          props.noWidth
            ? { width: "auto", minWidth: "0", maxWidth: "none" }
            : {}
        }
      >
        <div style={{ marginTop: "5px" }}>
          {snippetsIconMap["snippet-highest-scoring-traits"]}
        </div>
        <p className={styles.snippetName}>{snippet?.name}</p>
      </div>
    </div>
  );
}

function useShowPeerAssessmentPill(props: {
  numSuccessfulInvitesRequired?: number | null | undefined;
}) {
  const [peerAssessmentsRequired, setpeerAssessmentsRequired] =
    useState<number>(0);
  const numPeerAssessmentsReceived = useSelector(
    (state: RootState) => state.user.memberProfile?.peerAssesseeUids
  );
  useEffect(() => {
    if (props.numSuccessfulInvitesRequired) {
      //requires peer assessments
      if (numPeerAssessmentsReceived) {
        //has received peer assessments
        if (
          numPeerAssessmentsReceived.length < props.numSuccessfulInvitesRequired
        ) {
          setpeerAssessmentsRequired(props.numSuccessfulInvitesRequired);
        } else {
          setpeerAssessmentsRequired(0);
        }
      } else {
        //hasn't received any peer assessments
        setpeerAssessmentsRequired(props.numSuccessfulInvitesRequired);
      }
    } else {
      //doesn;t require peer assessments
      setpeerAssessmentsRequired(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numPeerAssessmentsReceived?.length, props.numSuccessfulInvitesRequired]);

  return peerAssessmentsRequired;
}
