import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "@/_firebase/firebaseConfig";
import { GPTMessage } from "@dimensional-engineering/dimensional-models";

import { useAuth } from "@/context/AuthContext";
import { setConversationLoading } from "@/redux/slices/chatbotSlice";

export function useListenForCurrentConversation() {
  const { user } = useAuth();
  const dispatch = useDispatch();

  const currentConversation = useSelector(
    (state: RootState) => state.chatbot
  ).currentConversation;

  const [messages, setMessages] = useState<GPTMessage[]>([]);
  const [removedMessages, setRemovedMessages] = useState<string[]>([]);

  useEffect(() => {
    dispatch(setConversationLoading(true));
    const ref = query(
      collection(
        db,
        `/members/${user?.uid}/gPTConversations/${currentConversation?.id}/gPTMessages`
      ),
      orderBy("createdOn", "asc")
    );

    const unsubscribe = onSnapshot(ref, (querySnapshot) => {
      const tempMessages: GPTMessage[] = [];
      querySnapshot.forEach((doc) => {
        const d = doc.data() as GPTMessage;
        if (!removedMessages.includes(d.id)) {
          tempMessages.push(d);
        }
      });

      setMessages(tempMessages);
      dispatch(setConversationLoading(false));
    });

    return () => {
      unsubscribe();
    };
  }, [currentConversation?.id, removedMessages.length]);

  function removeLastMessage(message: GPTMessage | undefined) {
    if (message) {
      setRemovedMessages((m) => [...m, message.id]);
    }
    setMessages((prev) => (prev.length > 0 ? prev.slice(0, -1) : prev));
  }

  return { messages, removeLastMessage };
}
