import {
  DimensionSummaryData,
  TraitIdentifierDescriptor,
} from "@dimensional-engineering/dimensional-models";

import styles from "./ScoreAndDescriptor.module.css";

import elementsJsonData from "../../../../assets/traitInfo/elements.json";
import { ExtendedPattern, ExtendedScale } from "@/models/sharedModels";
import { getUserScaleScore } from "@/components/traits/utils";
import DownArrowIcon from "@/components/shared/icons/DownArrowIcon";
import UpArrowIcon from "@/components/shared/icons/UpArrowIcon";
import { natureData } from "@/assets/traitInfo/natureData";
import { getElementFromTraitIdentifier } from "@/components/authenticatedProfile/tabs/AuthCompareTab/utils";
import { TraitType } from "@dimensional-engineering/dimensional-models/lib/models/traits/details/TraitType";
import { PatternWithoutBorder } from "@/components/traits/pattern/Pattern/Pattern";
import { useModalContext } from "@/context/ModalContext";
import ElementModal from "@/components/traits/TraitsModals/Element/ElementModal";
import PatternModal from "@/components/traits/TraitsModals/Pattern/PatternModal";

export default function ScoreAndDescriptor(props: DimensionSummaryData) {
  const mapped = props.scoreAndDescriptorData?.map((el, index) => {
    let element = getElementFromTraitIdentifier(el) as
      | ExtendedScale
      | ExtendedPattern
      | null;
    let score = getUserScaleScore(el.score, el.slug);
    let scoreText = "";
    if (score) {
      score = score * 100;
      if (score <= 12) {
        scoreText = "Very low";
      } else if (score > 12 && score <= 29) {
        scoreText = "Low";
      } else if (score > 29 && score <= 69) {
        scoreText = "Average";
      } else if (score > 69 && score <= 88) {
        scoreText = "High";
      } else {
        scoreText = "Very high";
      }
    }
    if (element) {
      return (
        <div className={styles.row} key={index}>
          {el.type === TraitType.pattern ? (
            <div className={styles.patternDiv}>
              <PatternWithoutBorder patternSlug={el.slug} />
            </div>
          ) : (
            <div className={styles.wrapperCircle}>
              <div
                className={styles.mainCircle}
                style={{
                  border: `${
                    natureData?.find((n) => n.slug === element?.nature)
                      ?.color ?? "gray"
                  } 15px solid`,
                }}
              ></div>
            </div>
          )}
          <div className={styles.details}>
            <div className={styles.top}>
              <h3>{element.name}</h3>
            </div>
            <div className={styles.mid}>
              <div className={styles.scoreWrapper}>
                <div
                  style={{ marginLeft: "4" }}
                  className={styles.selected}
                ></div>
                <div
                  className={
                    score && score > 12 ? styles.selected : styles.unselected
                  }
                ></div>
                <div
                  className={
                    score && score > 30 ? styles.selected : styles.unselected
                  }
                ></div>
                <div
                  className={
                    score && score > 70 ? styles.selected : styles.unselected
                  }
                ></div>
                <div
                  className={
                    score && score > 88 ? styles.selected : styles.unselected
                  }
                ></div>
              </div>
              <div className={styles.scoreTextDiv}>
                <p className={styles.scoreText}>{scoreText}</p>
                <div
                  style={scoreText === "Average" ? { top: "0" } : {}}
                  className={styles.iconDiv}
                >
                  {scoreText === "Very low" && (
                    <>
                      <div className={styles.arrow}>
                        <DownArrowIcon />
                      </div>
                      <div className={styles.arrow}>
                        <DownArrowIcon />
                      </div>
                    </>
                  )}
                  {scoreText === "Low" && (
                    <div className={styles.arrow}>
                      <DownArrowIcon />
                    </div>
                  )}
                  {scoreText === "Average" && (
                    <div className={styles.neutral}></div>
                  )}
                  {scoreText === "High" && (
                    <div className={styles.arrow}>
                      <UpArrowIcon />
                    </div>
                  )}
                  {scoreText === "Very high" && (
                    <>
                      <div className={styles.arrow}>
                        <UpArrowIcon />
                      </div>
                      <div className={styles.arrow}>
                        <UpArrowIcon />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>

            <p className={styles.caption}>{el.caption}</p>
          </div>
        </div>
      );
    }
  });

  return <div className={styles.parent}>{mapped}</div>;
}

export function ScoreAndDescriptorFullWidth(props: DimensionSummaryData) {
  const { setModalComponent } = useModalContext();

  function handleClick(el: TraitIdentifierDescriptor) {
    if (el.type === TraitType.element) {
      setModalComponent(<ElementModal slug={el.slug} />);
    } else {
      setModalComponent(<PatternModal slug={el.slug} />);
    }
  }

  const mapped = props.scoreAndDescriptorData?.map((el, index) => {
    let element = getElementFromTraitIdentifier(el) as
      | ExtendedScale
      | ExtendedPattern
      | null;

    let score = getUserScaleScore(el.score, el.slug);
    let scoreText = "";
    if (score) {
      score = score * 100;
      if (score <= 12) {
        scoreText = "Very low";
      } else if (score > 12 && score <= 29) {
        scoreText = "Low";
      } else if (score > 29 && score <= 69) {
        scoreText = "Average";
      } else if (score > 69 && score <= 88) {
        scoreText = "High";
      } else {
        scoreText = "Very high";
      }
    }

    if (element && element.isDiscoverable) {
      return (
        <div
          onClick={() => handleClick(el)}
          style={{ width: "100%" }}
          className={styles.row}
          key={index}
        >
          {el.type === TraitType.pattern ? (
            <div className={styles.patternDiv}>
              <PatternWithoutBorder patternSlug={el.slug} />
            </div>
          ) : (
            <div className={styles.wrapperCircle}>
              <div
                className={styles.mainCircle}
                style={{
                  border: `${
                    natureData?.find((n) => n.slug === element?.nature)
                      ?.color ?? "gray"
                  } 15px solid`,
                }}
              ></div>
            </div>
          )}
          <div className={styles.details}>
            <div className={styles.top}>
              <h3>{element?.name}</h3>
            </div>

            <div className={styles.mid}>
              <div className={styles.scoreWrapper}>
                <div
                  style={{ marginLeft: "4" }}
                  className={styles.selected}
                ></div>
                <div
                  className={
                    score && score > 12 ? styles.selected : styles.unselected
                  }
                ></div>
                <div
                  className={
                    score && score > 30 ? styles.selected : styles.unselected
                  }
                ></div>
                <div
                  className={
                    score && score > 70 ? styles.selected : styles.unselected
                  }
                ></div>
                <div
                  className={
                    score && score > 88 ? styles.selected : styles.unselected
                  }
                ></div>
              </div>
              <div className={styles.scoreTextDiv}>
                <p className={styles.scoreText}>{scoreText}</p>
                <div
                  style={scoreText === "Average" ? { top: "0" } : {}}
                  className={styles.iconDiv}
                >
                  {scoreText === "Very low" && (
                    <>
                      <div className={styles.arrow}>
                        <DownArrowIcon />
                      </div>
                      <div className={styles.arrow}>
                        <DownArrowIcon />
                      </div>
                    </>
                  )}
                  {scoreText === "Low" && (
                    <div className={styles.arrow}>
                      <DownArrowIcon />
                    </div>
                  )}
                  {scoreText === "Average" && (
                    <div className={styles.neutral}></div>
                  )}
                  {scoreText === "High" && (
                    <div className={styles.arrow}>
                      <UpArrowIcon />
                    </div>
                  )}
                  {scoreText === "Very high" && (
                    <>
                      <div className={styles.arrow}>
                        <UpArrowIcon />
                      </div>
                      <div className={styles.arrow}>
                        <UpArrowIcon />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>

            <p className={styles.caption}>{el.caption}</p>
          </div>
        </div>
      );
    }
  });

  return (
    <div style={{ width: "100%" }} className={styles.parent}>
      {mapped}
    </div>
  );
}
