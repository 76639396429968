/* eslint-disable @next/next/no-img-element */
import { PublicProfile } from "@dimensional-engineering/dimensional-models";

import styles from "./SmallProfileImage.module.css";
import { useModalContext } from "@/context/ModalContext";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import { getConnectionProfileColor } from "../utils";
import ProfileModalHandler from "@/components/authenticatedProfile/Abstracts/ProfileModal/ProfileModalHandler";
import Shimmer from "../Shimmer/Shimmer";

type Props = {
  publicProfile: PublicProfile | null;
  imageUrl: string;
  noLink?: boolean;
  large?: boolean;
};

export default function ComparedMediumProfileImage(props: Props) {
  const { setModalComponent } = useModalContext();
  const userState = useSelector((state: RootState) => state.user);
  const connectionColor = getConnectionProfileColor(
    userState.publicProfileData?.primaryNature?.nature,
    props.publicProfile?.primaryNature?.nature
  );

  if (props.noLink) {
    return (
      <div
        className={
          props.large ? styles.largeImageContainer : styles.mediumImageContainer
        }
        style={{ borderRadius: "50%", border: `4px solid ${connectionColor}` }}
      >
        {props.imageUrl === "" && <Shimmer rounded />}
        <img
          src={props.imageUrl}
          className={props.large ? styles.largeImage : styles.mediumImage}
          style={props.imageUrl === "" ? { display: "none" } : {}}
        />
      </div>
    );
  }
  return (
    <div
      onClick={() =>
        setModalComponent(
          <ProfileModalHandler
            uid={props.publicProfile?.ownerUUID}
            elementName="profileModal"
          />
        )
      }
      className={
        props.large ? styles.largeImageContainer : styles.mediumImageContainer
      }
      style={{ borderRadius: "50%", border: `4px solid ${connectionColor}` }}
    >
      {props.imageUrl === "" && <Shimmer rounded />}
      <img
        alt=""
        src={props.imageUrl}
        className={props.large ? styles.largeImage : styles.mediumImage}
        style={props.imageUrl === "" ? { display: "none" } : {}}
      />
    </div>
  );
}
