import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/redux/store";

import styles from "./EmbeddedFeedbackBar.module.css";

import { FeedbackScoreBar } from "@/components/results/personalityAnalysis/AnalysisFeedback/AnalysisFeedback";
import { useAuth } from "@/context/AuthContext";
import {
  getCurrentConversationToUpdateStore,
  submitOverallChatFeedback,
} from "../utils";
import { setCurrentConversation } from "@/redux/slices/chatbotSlice";
import { Mixpanel } from "@/helpers/mixpanel";

export default function EmbeddedFeedbackBar() {
  const dispatch = useDispatch();

  const [feedback, setFeedback] = useState<number | null | undefined>(null);
  const [text, setText] = useState<string | null | undefined>(null);

  const [showFeedbackBar, setShowFeedbackBar] = useState<boolean>(true);

  const { user } = useAuth();

  const currentConversation = useSelector(
    (state: RootState) => state.chatbot
  ).currentConversation;

  useEffect(() => {
    setFeedback(currentConversation?.conversationFeedback?.feedbackScore);
    setText(currentConversation?.conversationFeedback?.feedbackText);
    if (currentConversation?.conversationFeedback?.feedbackScore) {
      setShowFeedbackBar(false);
    }
  }, [
    currentConversation?.conversationFeedback?.feedbackScore,
    currentConversation?.conversationFeedback?.feedbackText,
  ]);

  const feedbackMap = {
    0: "😠",
    1: "😒",
    2: "😊",
    3: "😍",
  };

  if (!showFeedbackBar && feedback) {
    return (
      <div
        onClick={() => setShowFeedbackBar(true)}
        className={styles.mainGiven}
      >
        <p style={{ marginTop: "0" }} className={styles.yourFeedback}>
          Your feedback:
        </p>
        <p style={{ marginTop: "0" }} className={styles.emoji}>
          {feedbackMap[feedback as keyof typeof feedbackMap]}
        </p>
      </div>
    );
  }

  async function handleFeedbackScore(score: number) {
    Mixpanel?.track("Feedback given", {
      content_type: "chatbot_conversation",
      score: score,
      body: undefined,
    });
    await submitOverallChatFeedback(
      user?.uid,
      currentConversation?.id,
      score,
      undefined
    ).then(() => {
      getCurrentConversationToUpdateStore(
        user?.uid,
        currentConversation?.id
      ).then((res) => {
        if (res) {
          dispatch(setCurrentConversation(res));
        }
      });
    });
  }

  async function handleFeedbackText() {
    if (text) {
      Mixpanel?.track("Feedback given", {
        content_type: "chatbot_conversation",
        score: feedback,
        body: text,
      });
      await submitOverallChatFeedback(
        user?.uid,
        currentConversation?.id,
        undefined,
        text
      ).then(() => {
        getCurrentConversationToUpdateStore(
          user?.uid,
          currentConversation?.id
        ).then((res) => {
          if (res) {
            dispatch(setCurrentConversation(res));
          }
        });
      });
    }
  }

  return (
    <div className={styles.main}>
      <h3 className={styles.title}>How are you liking this so far?</h3>
      <p style={{ marginTop: "0" }} className={styles.improveText}>
        Feedback helps us improve
      </p>
      <div className={styles.buttonWrapper}>
        <div
          onClick={() => {
            setFeedback(0);
            handleFeedbackScore(0);
          }}
          className={feedback === 0 ? styles.emojiBoxSelected : styles.emojiBox}
        >
          <p style={{ marginTop: "0" }} className={styles.emoji}>
            😠
          </p>
          <p style={{ marginTop: "0" }} className={styles.desc}>
            It sucks
          </p>
          <FeedbackScoreBar score={0} />
        </div>

        <div
          onClick={() => {
            setFeedback(1);
            handleFeedbackScore(1);
          }}
          className={feedback === 1 ? styles.emojiBoxSelected : styles.emojiBox}
        >
          <p style={{ marginTop: "0" }} className={styles.emoji}>
            😒
          </p>
          <p style={{ marginTop: "0" }} className={styles.desc}>
            {"It's okay"}
          </p>
          <FeedbackScoreBar score={1} />
        </div>

        <div
          onClick={() => {
            setFeedback(2);
            handleFeedbackScore(2);
          }}
          className={feedback === 2 ? styles.emojiBoxSelected : styles.emojiBox}
        >
          <p style={{ marginTop: "0" }} className={styles.emoji}>
            😊
          </p>
          <p style={{ marginTop: "0" }} className={styles.desc}>
            Love it
          </p>
          <FeedbackScoreBar score={2} />
        </div>

        <div
          onClick={() => {
            setFeedback(3);
            handleFeedbackScore(3);
          }}
          className={feedback === 3 ? styles.emojiBoxSelected : styles.emojiBox}
        >
          <p style={{ marginTop: "0" }} className={styles.emoji}>
            😍
          </p>
          <p style={{ marginTop: "0" }} className={styles.desc}>
            OMG
          </p>
          <FeedbackScoreBar score={3} />
        </div>
      </div>
      <input
        className={styles.input}
        value={text ?? ""}
        onChange={(e) => setText(e.target.value)}
        placeholder="Tell us more (optional)"
      />
      <div className={styles.submitBtnDiv}>
        <button
          onClick={() => {
            handleFeedbackText();
          }}
          className={styles.submitBtn}
        >
          Submit
        </button>
      </div>
    </div>
  );
}
