import { useEffect, useRef } from "react";
import { PeerStoryContent } from "@dimensional-engineering/dimensional-models";
import { Roboto_Mono } from "next/font/google";
import html2canvas from "html2canvas";

import styles from "./PeerStoriesModal.module.css";

import ModalWrapper from "@/components/shared/ModalWrapper/ModalWrapper";
import { ExtendedPeerStoryGated } from "@/models/sharedModels";
import { useModalContext } from "@/context/ModalContext";

const font = Roboto_Mono({ subsets: ["latin"] });

export default function PeerStoriesShareView(props: {
  body: PeerStoryContent;
  story: ExtendedPeerStoryGated;
}) {
  const { setModalOpen } = useModalContext();

  const imageRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleDownloadImage = async () => {
      if (imageRef.current) {
        const canvas = await html2canvas(imageRef.current);
        // var a = document.createElement("a");
        // a.href = canvas
        //   .toDataURL("image/jpeg")
        //   .replace("image/jpeg", "image/octet-stream");
        // a.download = "somefilename.jpg";
        // a.click();

        const data = canvas.toDataURL("image/jpg");
        if (window.Android) {
          window.Android.onHaptic();

          if (window.Android.onSystemShareImage) {
            window.Android.onSystemShareImage(data);
          }
        }
      }
    };

    setTimeout(() => {
      handleDownloadImage();
      setModalOpen(false);
    }, 50);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mappedBody = props.body?.listicleStatements?.map((statement, index) => {
    return (
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        key={statement.body}
        className={styles.statementDiv}
      >
        <div className={styles.bodyDiv}>
          <p className={styles.mappedBody}>{statement.body}</p>
        </div>
      </div>
    );
  });

  return (
    <ModalWrapper title="" noHeader noLine>
      <div
        ref={imageRef}
        style={{
          backgroundImage: `linear-gradient(rgba(37, 0, 0, 0) 54.69%, rgba(6, 0, 0, 0.5) 100%), url(/peer-stories/${props.story.slug}.jpg)`,
        }}
        className={styles.storyMain}
      >
        <h3
          onClick={(e) => {
            e.stopPropagation();
          }}
          className={`${styles.name} ${font.className}`}
          style={{ marginTop: "70px" }}
        >
          {props.story.longName.toUpperCase()}:
        </h3>
        <div style={{ marginTop: "80px" }} className={styles.bodyWrapper}>
          {mappedBody}
        </div>
      </div>
    </ModalWrapper>
  );
}
