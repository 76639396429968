export default function PersonalityAnalyisIcon() {
  return (
    <svg
      width="96"
      height="96"
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M47.9 95.9994C46.0215 95.9994 44.5 94.478 44.5 92.5994V4.19979C44.5 2.32126 46.0215 0.799805 47.9 0.799805C49.7785 0.799805 51.3 2.32126 51.3 4.19979V92.5994C51.3 94.478 49.7785 95.9994 47.9 95.9994Z"
        fill="#FFFFF2"
      />
      <path
        d="M92.0965 51.7995H3.69686C1.81833 51.7995 0.296875 50.278 0.296875 48.3995C0.296875 46.521 1.81833 44.9995 3.69686 44.9995H92.0965C93.975 44.9995 95.4965 46.521 95.4965 48.3995C95.4965 50.278 93.975 51.7995 92.0965 51.7995Z"
        fill="#FFFFF2"
      />
      <path
        d="M83.5 21.001C83.5 33.001 65.5 33.001 65.5 21.001C65.5 9.00098 83.5 9.00098 83.5 21.001Z"
        fill="#E0CC65"
      />
      <path
        d="M29.5 69.001C29.5 81.001 11.5 81.001 11.5 69.001C11.5 57.001 29.5 57.001 29.5 69.001Z"
        fill="#FFA0B9"
      />
    </svg>
  );
}

export function PersonalityAnalyisIconSmall() {
  return (
    <svg
      width="37"
      height="37"
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.65 36.4997C17.9455 36.4997 17.375 35.9291 17.375 35.2247V2.0748C17.375 1.37035 17.9455 0.799805 18.65 0.799805C19.3544 0.799805 19.925 1.37035 19.925 2.0748V35.2247C19.925 35.9291 19.3544 36.4997 18.65 36.4997Z"
        fill="#FFFFF2"
      />
      <path
        d="M35.2237 19.925H2.07382C1.36937 19.925 0.798828 19.3544 0.798828 18.65C0.798828 17.9455 1.36937 17.375 2.07382 17.375H35.2237C35.9281 17.375 36.4987 17.9455 36.4987 18.65C36.4987 19.3544 35.9281 19.925 35.2237 19.925Z"
        fill="#FFFFF2"
      />
      <path
        d="M32 8.375C32 12.875 25.25 12.875 25.25 8.375C25.25 3.875 32 3.875 32 8.375Z"
        fill="#E0CC65"
      />
      <path
        d="M11.75 26.375C11.75 30.875 5 30.875 5 26.375C5 21.875 11.75 21.875 11.75 26.375Z"
        fill="#FFA0B9"
      />
    </svg>
  );
}
