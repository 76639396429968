import {
  ContentType,
  DimensionSummaryData,
} from "@dimensional-engineering/dimensional-models";

import styles from "./DimensionDichotomy.module.css";

import archertypesJsonData from "../../../../assets/traitInfo/archetypes.json";
import { ExtendedArchetype } from "@/models/sharedModels";
import Archetype from "@/components/traits/arcehtype/Archetypes/Archetype";
import { SpectrumsNoDescriptions } from "@/components/shared/Spectrum/Spectrums";

export default function DimensionDichotomy(props: {
  summary: DimensionSummaryData;
  slug: string;
  onLoad: () => void;
}) {
  const archetype = props.summary.signatureTrait?.slug
    ? archertypesJsonData?.find(
        (arch) => arch.slug === props.summary.signatureTrait?.slug
      )
    : null;

  return (
    <div className={styles.parent}>
      {archetype !== null && (
        <div className={styles.archParent}>
          <div>
            <h3 className={styles.acro}>{archetype?.acronym}</h3>
            <h4 className={styles.nameArch}>{archetype?.name}</h4>
          </div>
          {archetype && (
            <Archetype archetype={archetype as unknown as ExtendedArchetype} />
          )}
        </div>
      )}
      <SpectrumsNoDescriptions
        contentIdentifier={{
          contentType: ContentType.dimensionSummary,
          contentSlug: props.slug,
        }}
        otherPublicProfile={null}
        noMargin
        onLoad={() => props.onLoad()}
      />
    </div>
  );
}
