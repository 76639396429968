import { useState } from "react";
import { useSelector } from "react-redux";
import {
  DiscoveredCompatibilitySnippet,
  TraitIdentifier,
} from "@dimensional-engineering/dimensional-models";
import { Swiper, SwiperSlide } from "swiper/react";

import styles from "../CompatibilitySnippets.module.css";
import "swiper/css";

import { getElementFromTraitIdentifier } from "../utils";
import { useModalContext } from "@/context/ModalContext";
import { ExtendedScale, FriendsPublicProfile } from "@/models/sharedModels";
import ElementModal from "@/components/traits/TraitsModals/Element/ElementModal";
import PatternModal from "@/components/traits/TraitsModals/Pattern/PatternModal";
import { natureData } from "@/assets/traitInfo/natureData";
import { RootState } from "@/redux/store";
import SmallProfileImage from "@/components/shared/SmallProfileImage/SmallProfileImage";
import ThickForwardArrow from "@/components/shared/buttons/ThickForwardArrow";
import ComparedMediumProfileImage from "@/components/shared/SmallProfileImage/ComparedMediumProfileImage";

type Props = {
  snippet: DiscoveredCompatibilitySnippet | null;
  connection: FriendsPublicProfile | null;
};

export default function CompatibilityRankedListDetailView(props: Props) {
  const { setModalComponent } = useModalContext();

  function onTraitClick(trait: TraitIdentifier, connection: boolean) {
    if (trait.type === "element") {
      if (connection) {
        setModalComponent(
          <ElementModal slug={trait.slug} uid={props.connection?.ownerUUID} />
        );
      } else {
        setModalComponent(<ElementModal slug={trait.slug} />);
      }
    } else {
      if (connection) {
        setModalComponent(
          <PatternModal slug={trait.slug} uid={props.connection?.ownerUUID} />
        );
      } else {
        setModalComponent(<PatternModal slug={trait.slug} />);
      }
    }
  }

  const mappedMyTraits = [
    props.snippet?.snippetDetail.rankedListDetail?.myTopTraitIdentifiers,
    props.snippet?.snippetDetail.rankedListDetail?.myOtherTraitIdentifiers,
  ]
    .flat()
    .map((t, i) => {
      const element = getElementFromTraitIdentifier(
        t as TraitIdentifier
      ) as ExtendedScale;

      const borderColor = natureData?.find(
        (n) => n.slug === element.nature
      )?.color;

      return (
        <p
          onClick={() => {
            onTraitClick(t as TraitIdentifier, false);
          }}
          className={styles.yourTopTraitName}
          key={t?.slug}
          style={borderColor ? { borderColor: borderColor } : {}}
        >
          {i + 1}. {element.alias ?? element.name}
        </p>
      );
    });

  const mappedConnectionTraits = [
    props.snippet?.snippetDetail.rankedListDetail
      ?.connectionTopTraitIdentifiers,
    props.snippet?.snippetDetail.rankedListDetail
      ?.connectionOtherTraitIdentifiers,
  ]
    .flat()
    .map((t, i) => {
      const element = getElementFromTraitIdentifier(
        t as TraitIdentifier
      ) as ExtendedScale;

      const borderColor = natureData?.find(
        (n) => n.slug === element.nature
      )?.color;

      return (
        <p
          onClick={() => {
            onTraitClick(t as TraitIdentifier, true);
          }}
          className={styles.yourTopTraitName}
          key={t?.slug}
          style={borderColor ? { borderColor: borderColor } : {}}
        >
          {i + 1}. {element.alias ?? element.name}
        </p>
      );
    });

  return (
    <div className={styles.spectrumsParent}>
      <div className={styles.listViewHeader}>
        <h3 className={styles.listViewHeaderText}>Full rankings</h3>
      </div>
      <div className={styles.listViewSection}>
        <div>
          <h4 className={styles.yourTopHeading}>YOURS</h4>
          {mappedMyTraits}
        </div>
        <div>
          <h4 style={{ textAlign: "end" }} className={styles.yourTopHeading}>
            THEIRS
          </h4>
          <div className={styles.theirTraitDiv}>{mappedConnectionTraits}</div>
        </div>
      </div>
    </div>
  );
}

export function CompatibilityRankedListsDetailView(props: Props) {
  const [mySwiperRef, setMySwiperRef] = useState<any>(null);
  const [slideIndex, setSlideIndex] = useState<number>(0);

  const { setModalComponent } = useModalContext();

  const username = useSelector((state: RootState) => state.user)
    .publicProfileData?.userName;

  const publicProfile = useSelector(
    (state: RootState) => state.user
  ).publicProfileData;

  const imageUrl = useSelector((state: RootState) => state.user).imageURL;

  function onTraitClick(trait: TraitIdentifier, connection: boolean) {
    if (trait.type === "element") {
      if (connection) {
        setModalComponent(
          <ElementModal slug={trait.slug} uid={props.connection?.ownerUUID} />
        );
      } else {
        setModalComponent(<ElementModal slug={trait.slug} />);
      }
    } else {
      if (connection) {
        setModalComponent(
          <PatternModal slug={trait.slug} uid={props.connection?.ownerUUID} />
        );
      } else {
        setModalComponent(<PatternModal slug={trait.slug} />);
      }
    }
  }

  const mappedMyTraitsTop =
    props.snippet?.snippetDetail?.rankedListsDetails &&
    props.snippet?.snippetDetail?.rankedListsDetails[0].myTopTraitIdentifiers.map(
      (t, i) => {
        const element = getElementFromTraitIdentifier(
          t as TraitIdentifier
        ) as ExtendedScale;

        const borderColor = natureData?.find(
          (n) => n.slug === element.nature
        )?.color;

        return (
          <p
            onClick={() => {
              onTraitClick(t as TraitIdentifier, false);
            }}
            className={styles.yourTopTraitName}
            key={t?.slug}
            style={
              borderColor
                ? { borderColor: borderColor, fontSize: "12px", margin: "0" }
                : { fontSize: "12px", margin: "0" }
            }
          >
            {i + 1}.{" "}
            {element.highFunLabel ?? element.name.replace("Desiring", "")}
          </p>
        );
      }
    );

  const mappedTheirTraitsTop =
    props.snippet?.snippetDetail?.rankedListsDetails &&
    props.snippet?.snippetDetail?.rankedListsDetails[0].connectionTopTraitIdentifiers.map(
      (t, i) => {
        const element = getElementFromTraitIdentifier(
          t as TraitIdentifier
        ) as ExtendedScale;

        const borderColor = natureData?.find(
          (n) => n.slug === element.nature
        )?.color;

        return (
          <p
            onClick={() => {
              onTraitClick(t as TraitIdentifier, true);
            }}
            className={styles.yourTopTraitName}
            key={t?.slug}
            style={
              borderColor
                ? { borderColor: borderColor, fontSize: "12px", margin: "0" }
                : { fontSize: "12px", margin: "0" }
            }
          >
            {i + 1}.{" "}
            {element.highFunLabel ?? element.name.replace("Desiring", "")}
          </p>
        );
      }
    );

  const mappedMyTraitsBottom =
    props.snippet?.snippetDetail?.rankedListsDetails &&
    props.snippet?.snippetDetail?.rankedListsDetails[1].myTopTraitIdentifiers.map(
      (t, i) => {
        const element = getElementFromTraitIdentifier(
          t as TraitIdentifier
        ) as ExtendedScale;

        const borderColor = natureData?.find(
          (n) => n.slug === element.nature
        )?.color;

        return (
          <p
            onClick={() => {
              onTraitClick(t as TraitIdentifier, false);
            }}
            className={styles.yourTopTraitName}
            key={t?.slug}
            style={
              borderColor
                ? { borderColor: borderColor, fontSize: "12px", margin: "0" }
                : { fontSize: "12px", margin: "0" }
            }
          >
            {i + 1}.{" "}
            {element.highFunLabel ?? element.name.replace("Giving", "")}
          </p>
        );
      }
    );

  const mappedTheirTraitsBottom =
    props.snippet?.snippetDetail?.rankedListsDetails &&
    props.snippet?.snippetDetail?.rankedListsDetails[1].connectionTopTraitIdentifiers.map(
      (t, i) => {
        const element = getElementFromTraitIdentifier(
          t as TraitIdentifier
        ) as ExtendedScale;

        const borderColor = natureData?.find(
          (n) => n.slug === element.nature
        )?.color;

        return (
          <p
            onClick={() => {
              onTraitClick(t as TraitIdentifier, true);
            }}
            className={styles.yourTopTraitName}
            key={t?.slug}
            style={
              borderColor
                ? { borderColor: borderColor, fontSize: "12px", margin: "0" }
                : { fontSize: "12px", margin: "0" }
            }
          >
            {i + 1}.{" "}
            {element.highFunLabel ?? element.name.replace("Giving", "")}
          </p>
        );
      }
    );

  const firstSlide = (
    <SwiperSlide key={0}>
      <div className={styles.listViewParent}>
        <div className={styles.listViewTopImagesParent}>
          <div className={styles.listViewImagesContainer}>
            <SmallProfileImage
              medium
              publicProfile={publicProfile}
              imageUrl={imageUrl ?? ""}
              noLink
            />
            <ThickForwardArrow />
            <ComparedMediumProfileImage
              noLink
              publicProfile={props.connection}
              imageUrl={props.connection?.imageUrl ?? ""}
            />
          </div>
          <p
            className={styles.listViewTopImagesText}
          >{`How @${username} gives love vs. how @${props.connection?.userName} needs love.`}</p>
        </div>

        <div
          style={{ border: "0", padding: "0" }}
          className={styles.listViewSection}
        >
          <div>
            <p className={styles.yourTopHeadingNew}>@{username}</p>
            <p className={styles.yourTopHeadingNew}>gives</p>
            <div className={styles.listViewTraitsWrapper}>
              {mappedMyTraitsTop}
            </div>
          </div>
          <div>
            <p
              style={{ textAlign: "end" }}
              className={styles.yourTopHeadingNew}
            >
              @{props.connection?.userName}
            </p>
            <p
              style={{ textAlign: "end" }}
              className={styles.yourTopHeadingNew}
            >
              needs
            </p>
            <div className={styles.listViewTraitsWrapperRight}>
              {mappedTheirTraitsTop}
            </div>
          </div>
        </div>
      </div>
    </SwiperSlide>
  );

  const secondSlide = (
    <SwiperSlide key={1}>
      <div className={styles.listViewParent}>
        <div className={styles.listViewTopImagesParent}>
          <div className={styles.listViewImagesContainer}>
            <ComparedMediumProfileImage
              noLink
              publicProfile={props.connection}
              imageUrl={props.connection?.imageUrl ?? ""}
            />
            <ThickForwardArrow />
            <SmallProfileImage
              medium
              publicProfile={publicProfile}
              imageUrl={imageUrl ?? ""}
              noLink
            />
          </div>
          <p
            className={styles.listViewTopImagesText}
          >{`How @${props.connection?.userName} gives love vs. how @${username} needs love.`}</p>
        </div>

        <div
          style={{ border: "0", padding: "0" }}
          className={styles.listViewSection}
        >
          <div>
            <p className={styles.yourTopHeadingNew}>
              @{props.connection?.userName}
            </p>
            <p className={styles.yourTopHeadingNew}>gives</p>
            <div className={styles.listViewTraitsWrapper}>
              {mappedTheirTraitsBottom}
            </div>
          </div>
          <div>
            <p
              style={{ textAlign: "end" }}
              className={styles.yourTopHeadingNew}
            >
              @{username}
            </p>
            <p
              style={{ textAlign: "end" }}
              className={styles.yourTopHeadingNew}
            >
              needs
            </p>
            <div className={styles.listViewTraitsWrapperRight}>
              {mappedMyTraitsBottom}
            </div>
          </div>
        </div>
      </div>
    </SwiperSlide>
  );

  return (
    <div
      style={{ marginTop: "50px", width: "100%" }}
      className={styles.spectrumsParent}
    >
      <Swiper
        autoHeight={true}
        slidesPerView={1}
        touchReleaseOnEdges={true}
        scrollbar={{ draggable: true }}
        threshold={1}
        onSwiper={setMySwiperRef}
        onSlideChange={(e) => {
          const index = e.activeIndex;
          setSlideIndex(index);
        }}
      >
        {firstSlide}
        {secondSlide}
      </Swiper>
      <div className={styles.listViewIndexCirclesDiv}>
        <div
          onClick={() => {
            mySwiperRef.slideTo(0);
          }}
          style={slideIndex === 0 ? { opacity: "1" } : {}}
          className={styles.listViewIndexCircles}
        ></div>
        <div
          onClick={() => {
            mySwiperRef.slideTo(1);
          }}
          style={slideIndex === 1 ? { opacity: "1" } : {}}
          className={styles.listViewIndexCircles}
        ></div>
      </div>
    </div>
  );
}
