import { GPTMessage } from "@dimensional-engineering/dimensional-models";

import styles from "./GoDeeperFeedbackBar.module.css";

type Props = {
  message: GPTMessage;
  onCopy: () => void;
  onDownVote: () => void;
  onUpvote: () => void;
  onRegenerate: () => void;
};

export default function GoDeeperFeedbackBar(props: Props) {
  return (
    <div className={styles.main}>
      <CopyIcon
        onClick={() => {
          props.onCopy();
        }}
      />
      <DownVoteIcon
        onClick={() => {
          props.onDownVote();
        }}
        active={props.message.feedbackScore === 0}
      />
      <UpVoteIcon
        onClick={() => {
          props.onUpvote();
        }}
        active={props.message.feedbackScore === 1}
      />
      <RegenerateIcon
        onClick={() => {
          props.onRegenerate();
        }}
      />
    </div>
  );
}

function CopyIcon(props: { onClick: () => void }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      onClick={() => {
        props.onClick();
      }}
    >
      <path
        d="M16.5172 0.249207H8.2274C7.61646 0.249207 7.06256 0.498427 6.661 0.899987C6.25944 1.30155 6.01022 1.85623 6.01022 2.46639V4.10701H3.48362C2.87268 4.10701 2.31878 4.35623 1.91722 4.75779C1.51566 5.15935 1.26644 5.71403 1.26644 6.32419V17.5336C1.26644 18.1437 1.51566 18.6984 1.91722 19.1C2.31878 19.5015 2.87346 19.7508 3.48362 19.7508H11.7734C12.3844 19.7508 12.9383 19.5015 13.3398 19.1C13.7414 18.6976 13.9906 18.1437 13.9906 17.5336V15.893H16.5172C17.1281 15.893 17.682 15.6437 18.0836 15.2422C18.4852 14.8406 18.7344 14.2859 18.7344 13.6758V2.46639C18.7344 1.85545 18.4852 1.30155 18.0836 0.899987C17.682 0.498427 17.1274 0.249207 16.5172 0.249207ZM6.0102 5.41481V13.6766C6.0102 14.2868 6.25942 14.8414 6.66098 15.243C7.06254 15.6446 7.61722 15.8938 8.22738 15.8938H12.6828V17.5344C12.6828 17.7836 12.5804 18.0117 12.4148 18.1774C12.2492 18.3422 12.0219 18.4453 11.7719 18.4453H3.48206C3.23284 18.4453 3.00472 18.343 2.8391 18.1774C2.67347 18.0117 2.57114 17.7844 2.57114 17.5344V6.32501C2.57114 6.07579 2.67348 5.84767 2.8391 5.68205C3.00472 5.5172 3.23206 5.41409 3.48206 5.41409H6.00866L6.0102 5.41481ZM8.2282 1.55701H16.518C16.7672 1.55701 16.9953 1.65935 17.161 1.82497C17.3266 1.99059 17.4289 2.21793 17.4289 2.46793V13.6773C17.4289 13.9265 17.3266 14.1547 17.161 14.3195C16.9953 14.4851 16.768 14.5875 16.518 14.5875H8.229C7.97978 14.5875 7.75166 14.4851 7.58604 14.3195C7.42119 14.1547 7.31808 13.9273 7.31808 13.6773V2.46793C7.31808 2.21871 7.42042 1.99059 7.58604 1.82497C7.75166 1.65934 7.979 1.55701 8.229 1.55701H8.2282Z"
        fill="#928E8C"
      />
    </svg>
  );
}

function DownVoteIcon(props: { onClick: () => void; active?: boolean }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      onClick={() => {
        props.onClick();
      }}
    >
      <path
        d="M18 11.875C17.5258 12.4836 16.7969 12.8383 16.025 12.8375H12.1C12.6812 13.8 13.4375 15.2937 13.4375 16.5875C13.457 17.2563 13.1992 17.9031 12.7266 18.3766C12.2531 18.8492 11.6062 19.107 10.9375 19.0875C10.7023 19.0867 10.4875 18.9539 10.3813 18.7438L9.1625 16.25L6.1125 12.625C5.86406 12.7476 5.58984 12.8117 5.3125 12.8125H3.4375C2.40234 12.8125 1.5625 11.9726 1.5625 10.9375V4.68747C1.5625 3.65231 2.40234 2.81247 3.4375 2.81247H5.3125C5.68594 2.81013 6.05156 2.91872 6.3625 3.12497C6.91954 2.16013 7.94844 1.56481 9.0625 1.56247H13.9563C14.6594 1.56169 15.343 1.79763 15.8954 2.23357C16.4477 2.66873 16.8368 3.27811 17 3.96247L18.45 9.70007C18.6477 10.4555 18.4813 11.2602 18 11.875ZM5.3124 4.06263H3.4374C3.09208 4.06263 2.8124 4.34231 2.8124 4.68763V10.9376C2.8124 11.2829 3.09208 11.5626 3.4374 11.5626H5.3124C5.47802 11.5626 5.6374 11.497 5.75458 11.3798C5.87177 11.2626 5.93739 11.1033 5.93739 10.9376V4.68764C5.93739 4.52201 5.87177 4.36264 5.75458 4.24546C5.63739 4.12827 5.47802 4.06263 5.3124 4.06263ZM17.2374 10.0002L15.7812 4.25023C15.5812 3.40413 14.8257 2.80647 13.9562 2.80647H9.06236C8.0272 2.80647 7.18736 3.64631 7.18736 4.68147V11.9627L10.1686 15.5377C10.1967 15.5635 10.2225 15.5931 10.2436 15.6252L11.2998 17.7376C11.8482 17.6235 12.2271 17.1212 12.1873 16.5626C12.1873 15.2001 10.9373 13.2126 10.4373 12.5626C10.2951 12.3735 10.2725 12.1196 10.3779 11.9079C10.4842 11.6962 10.7006 11.5626 10.9373 11.5626H16.0249C16.4116 11.5633 16.7772 11.3844 17.0147 11.079C17.2522 10.7735 17.3343 10.3752 17.2374 10.0002Z"
        fill={props.active ? "#FFFFF2" : "#928E8C"}
      />
    </svg>
  );
}

function UpVoteIcon(props: { onClick: () => void; active?: boolean }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      onClick={() => {
        props.onClick();
      }}
    >
      <path
        d="M17 8.12497C16.5258 7.51637 15.7969 7.16169 15.025 7.16247H11.1C11.6812 6.19997 12.4375 4.70627 12.4375 3.41247C12.457 2.74371 12.1992 2.09685 11.7266 1.62341C11.2531 1.15075 10.6062 0.892951 9.9375 0.912471C9.70234 0.913252 9.4875 1.04607 9.38126 1.25623L8.1625 3.75003L5.1125 7.37503C4.86406 7.25238 4.58984 7.18831 4.3125 7.18753H2.4375C1.40234 7.18753 0.5625 8.02737 0.5625 9.06253V15.3125C0.5625 16.3477 1.40234 17.1875 2.4375 17.1875H4.3125C4.68594 17.1899 5.05156 17.0813 5.3625 16.875C5.91954 17.8399 6.94844 18.4352 8.0625 18.4375H12.9563C13.6594 18.4383 14.343 18.2024 14.8954 17.7664C15.4477 17.3313 15.8368 16.7219 16 16.0375L17.45 10.2999C17.6477 9.54447 17.4813 8.73981 17 8.12497ZM4.3124 15.9374H2.4374C2.09208 15.9374 1.8124 15.6577 1.8124 15.3124V9.06237C1.8124 8.71705 2.09208 8.43737 2.4374 8.43737H4.3124C4.47802 8.43737 4.6374 8.503 4.75458 8.62018C4.87177 8.73737 4.93739 8.89674 4.93739 9.06236V15.3124C4.93739 15.478 4.87177 15.6374 4.75458 15.7545C4.63739 15.8717 4.47802 15.9374 4.3124 15.9374ZM16.2374 9.99977L14.7812 15.7498C14.5812 16.5959 13.8257 17.1935 12.9562 17.1935H8.06236C7.0272 17.1935 6.18736 16.3537 6.18736 15.3185V8.03733L9.16856 4.46233C9.19668 4.43655 9.22247 4.40686 9.24356 4.37483L10.2998 2.26243C10.8482 2.37649 11.2271 2.87883 11.1873 3.43743C11.1873 4.79993 9.9373 6.78743 9.4373 7.43743C9.29511 7.62649 9.27246 7.88039 9.37792 8.09211C9.48417 8.30383 9.70058 8.43743 9.9373 8.43743H15.0249C15.4116 8.43665 15.7772 8.61556 16.0147 8.92103C16.2522 9.22649 16.3343 9.62477 16.2374 9.99977Z"
        fill={props.active ? "#FFFFF2" : "#928E8C"}
      />
    </svg>
  );
}

function RegenerateIcon(props: { onClick: () => void }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      onClick={() => {
        props.onClick();
      }}
    >
      <g clipPath="url(#clip0_62291_17933)">
        <path
          d="M15.1137 7.59973C16.6328 10.2309 15.7274 13.6062 13.0967 15.125C11.5372 16.0254 9.71966 16.0725 8.17967 15.4164L8.78293 13.1628L4.48866 15.6421L6.96796 19.9364L7.60393 17.5629C9.69416 18.3552 12.1102 18.2385 14.1982 17.033C17.887 14.9033 19.1512 10.1869 17.0212 6.49766L15.1131 7.59926L15.1137 7.59973Z"
          fill="#928E8C"
        />
        <path
          d="M13.0813 3.14645C10.9906 2.35349 8.57376 2.46882 6.48577 3.67432C2.79703 5.80402 1.53364 10.5218 3.66324 14.2103L5.57196 13.1083C4.05286 10.4772 4.95721 7.10156 7.58854 5.58236C9.14671 4.68275 10.9656 4.63484 12.5057 5.29L11.9022 7.54461L16.1965 5.06531L13.718 0.772426L13.0813 3.14645Z"
          fill="#928E8C"
        />
      </g>
      <defs>
        <clipPath id="clip0_62291_17933">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
