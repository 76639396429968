import Link from "next/link";

import styles from "./SocialLinks.module.css";
import { openInNewTab } from "@/components/shared/utils";

export default function SocialLinks() {
  return (
    <div className={styles.main}>
      <h4 className={styles.title}>
        Stay connected with everything happening at Dimensional.
      </h4>
      <div className={styles.linksDiv}>
        <div
          onClick={() => {
            openInNewTab("https://www.linkedin.com/company/dimensionalme");
          }}
        >
          <LinkedIn />
        </div>
        <div
          onClick={() => {
            openInNewTab("https://www.tiktok.com/@dimensional.me/");
          }}
        >
          <TickTok />
        </div>
        <div
          onClick={() => {
            openInNewTab("https://www.instagram.com/dimensional.me");
          }}
        >
          <Instagram />
        </div>
        <div
          onClick={() => {
            openInNewTab("https://discord.gg/DWEVMnZPSJ");
          }}
        >
          <Discord />
        </div>
        <div
          onClick={() => {
            openInNewTab("https://www.reddit.com/r/dimensional");
          }}
        >
          <Reddit />
        </div>
      </div>
    </div>
  );
}

function LinkedIn() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <g clipPath="url(#clip0_51514_24479)">
        <path
          d="M19.9482 0H5.94824C3.18724 0 0.948242 2.239 0.948242 5V19C0.948242 21.761 3.18724 24 5.94824 24H19.9482C22.7102 24 24.9482 21.761 24.9482 19V5C24.9482 2.239 22.7102 0 19.9482 0ZM8.94824 19H5.94824V8H8.94824V19ZM7.44824 6.732C6.48224 6.732 5.69824 5.942 5.69824 4.968C5.69824 3.994 6.48224 3.204 7.44824 3.204C8.41424 3.204 9.19824 3.994 9.19824 4.968C9.19824 5.942 8.41524 6.732 7.44824 6.732ZM20.9482 19H17.9482V13.396C17.9482 10.028 13.9482 10.283 13.9482 13.396V19H10.9482V8H13.9482V9.765C15.3442 7.179 20.9482 6.988 20.9482 12.241V19Z"
          fill="#FFFFF2"
        />
      </g>
      <defs>
        <clipPath id="clip0_51514_24479">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

function TickTok() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <g clipPath="url(#clip0_51514_24481)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.7016 0.00366211H3.20207C1.96354 0.00366211 0.948242 1.01896 0.948242 2.25029V21.7498C0.948242 22.9884 1.96354 23.9965 3.20207 23.9965H22.7016C23.9401 23.9965 24.9554 22.9812 24.9554 21.7498V2.25029C24.9554 1.01176 23.9401 0.00366211 22.7016 0.00366211ZM15.8897 4.48971C16.1418 6.62112 17.3299 7.89565 19.3965 8.03246V10.4303C18.194 10.5455 17.1427 10.1567 15.9185 9.415V13.9011C15.9185 19.604 9.70432 21.3898 7.20567 17.2998C5.59991 14.6715 6.57921 10.0559 11.7349 9.86865V12.4033C11.3461 12.4681 10.9212 12.5689 10.5396 12.6913C9.39469 13.0802 8.74662 13.8074 8.92664 15.082C9.27227 17.5374 13.7799 18.2575 13.3983 13.4618V4.48251H15.8825L15.8897 4.48971Z"
          fill="#FFFFF2"
        />
      </g>
      <defs>
        <clipPath id="clip0_51514_24481">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

function Instagram() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <g clipPath="url(#clip0_51514_24483)">
        <path
          d="M12.9551 2.163C16.1591 2.163 16.5391 2.175 17.8051 2.233C21.0571 2.381 22.5761 3.924 22.7241 7.152C22.7821 8.417 22.7931 8.797 22.7931 12.001C22.7931 15.206 22.7811 15.585 22.7241 16.85C22.5751 20.075 21.0601 21.621 17.8051 21.769C16.5391 21.827 16.1611 21.839 12.9551 21.839C9.75108 21.839 9.37108 21.827 8.10608 21.769C4.84608 21.62 3.33508 20.07 3.18708 16.849C3.12908 15.584 3.11708 15.205 3.11708 12C3.11708 8.796 3.13008 8.417 3.18708 7.151C3.33608 3.924 4.85108 2.38 8.10608 2.232C9.37208 2.175 9.75108 2.163 12.9551 2.163ZM12.9551 0C9.69608 0 9.28808 0.014 8.00808 0.072C3.65008 0.272 1.22808 2.69 1.02808 7.052C0.969078 8.333 0.955078 8.741 0.955078 12C0.955078 15.259 0.969078 15.668 1.02708 16.948C1.22708 21.306 3.64508 23.728 8.00708 23.928C9.28808 23.986 9.69608 24 12.9551 24C16.2141 24 16.6231 23.986 17.9031 23.928C22.2571 23.728 24.6851 21.31 24.8821 16.948C24.9411 15.668 24.9551 15.259 24.9551 12C24.9551 8.741 24.9411 8.333 24.8831 7.053C24.6871 2.699 22.2661 0.273 17.9041 0.073C16.6231 0.014 16.2141 0 12.9551 0V0ZM12.9551 5.838C9.55208 5.838 6.79308 8.597 6.79308 12C6.79308 15.403 9.55208 18.163 12.9551 18.163C16.3581 18.163 19.1171 15.404 19.1171 12C19.1171 8.597 16.3581 5.838 12.9551 5.838ZM12.9551 16C10.7461 16 8.95508 14.21 8.95508 12C8.95508 9.791 10.7461 8 12.9551 8C15.1641 8 16.9551 9.791 16.9551 12C16.9551 14.21 15.1641 16 12.9551 16ZM19.3611 4.155C18.5651 4.155 17.9201 4.8 17.9201 5.595C17.9201 6.39 18.5651 7.035 19.3611 7.035C20.1561 7.035 20.8001 6.39 20.8001 5.595C20.8001 4.8 20.1561 4.155 19.3611 4.155Z"
          fill="#FFFFF2"
        />
      </g>
      <defs>
        <clipPath id="clip0_51514_24483">
          <rect x="0.5" width="24" height="24" rx="4" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

function Discord() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <g clipPath="url(#clip0_51514_24485)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.5 0C19.1274 0 24.5 5.37258 24.5 12C24.5 18.6274 19.1274 24 12.5 24C5.87258 24 0.5 18.6274 0.5 12C0.5 5.37258 5.87258 0 12.5 0Z"
          fill="#FFFFF2"
        />
        <path
          d="M17.4183 7.52913C16.487 7.10317 15.5039 6.80088 14.4941 6.62998C14.3565 6.87713 14.2318 7.13127 14.1207 7.39137C13.5835 7.31036 13.0411 7.26959 12.4979 7.2694C11.9556 7.2694 11.4106 7.3106 10.875 7.39034C10.7656 7.1321 10.6385 6.87602 10.5006 6.62988C9.49081 6.80241 8.50764 7.10528 7.57578 7.53091C5.72502 10.2688 5.22346 12.9383 5.47424 15.5698C6.55797 16.3703 7.77087 16.9791 9.06027 17.3697C9.34944 16.9794 9.60847 16.5638 9.82836 16.1314C9.40877 15.975 9.00386 15.7818 8.61838 15.554C8.71986 15.4804 8.81905 15.4046 8.91486 15.3311C10.0357 15.8575 11.2604 16.1321 12.4995 16.1321C13.7387 16.1321 14.9634 15.8575 16.0843 15.331C16.1813 15.4101 16.2805 15.4859 16.3808 15.5539C15.9946 15.7822 15.5889 15.9759 15.1686 16.1329C15.3893 16.5663 15.6463 16.9802 15.9367 17.3702C17.2273 16.9808 18.4412 16.372 19.5252 15.5706L19.5249 15.5709C19.8192 12.5192 19.0222 9.87424 17.4183 7.52913ZM10.1455 13.9514C9.44671 13.9514 8.8693 13.3172 8.8693 12.537C8.8693 11.7568 9.4266 11.117 10.1433 11.117C10.86 11.117 11.4329 11.7568 11.4206 12.537C11.4084 13.3172 10.8578 13.9514 10.1455 13.9514ZM14.8536 13.9514C14.1536 13.9514 13.5785 13.3172 13.5785 12.537C13.5785 11.7568 14.1358 11.117 14.8536 11.117C15.5714 11.117 16.1398 11.7568 16.1276 12.537C16.1153 13.3172 15.5658 13.9514 14.8536 13.9514Z"
          fill="#181716"
        />
      </g>
      <defs>
        <clipPath id="clip0_51514_24485">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

function Reddit() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M12.5 24C19.1274 24 24.5 18.6274 24.5 12C24.5 5.37258 19.1274 0 12.5 0C5.87258 0 0.5 5.37258 0.5 12C0.5 18.6274 5.87258 24 12.5 24Z"
        fill="#FFFFF2"
      />
      <path
        d="M20.5028 12C20.467 11.0335 19.6557 10.2789 18.6863 10.3117C18.2568 10.3266 17.8481 10.5026 17.5379 10.7979C16.1717 9.86725 14.567 9.35718 12.9175 9.32139L13.696 5.57791L16.2642 6.11781C16.3358 6.77702 16.9264 7.25427 17.5856 7.18269C18.2448 7.1111 18.7221 6.52049 18.6505 5.86128C18.5789 5.20207 17.9883 4.72482 17.3291 4.79641C16.9503 4.83518 16.6102 5.05591 16.4223 5.38403L13.4812 4.79641C13.2814 4.75166 13.0815 4.87694 13.0368 5.07978C13.0368 5.08276 13.0368 5.08276 13.0368 5.08574L12.1509 9.2498C10.4805 9.27665 8.85482 9.7897 7.47078 10.7263C6.76682 10.0641 5.6572 10.0969 4.99501 10.8039C4.33282 11.5078 4.36563 12.6174 5.07257 13.2796C5.20978 13.4079 5.36787 13.5183 5.54385 13.5958C5.53192 13.7718 5.53192 13.9478 5.54385 14.1238C5.54385 16.8113 8.67585 18.9977 12.5386 18.9977C16.4014 18.9977 19.5334 16.8143 19.5334 14.1238C19.5453 13.9478 19.5453 13.7718 19.5334 13.5958C20.136 13.2945 20.5148 12.6741 20.5028 12ZM8.50284 13.2021C8.50284 12.5399 9.04274 12 9.70493 12C10.3671 12 10.907 12.5399 10.907 13.2021C10.907 13.8643 10.3671 14.4042 9.70493 14.4042C9.03975 14.3982 8.50284 13.8643 8.50284 13.2021ZM15.4738 16.5011C14.6207 17.1424 13.5767 17.4705 12.5088 17.4258C11.4409 17.4705 10.3969 17.1424 9.54386 16.5011C9.43051 16.3639 9.45139 16.1581 9.5886 16.0447C9.70791 15.9463 9.87793 15.9463 10.0002 16.0447C10.7221 16.5727 11.602 16.8411 12.4969 16.7994C13.3917 16.8471 14.2747 16.5906 15.0055 16.0686C15.1367 15.9403 15.3515 15.9433 15.4797 16.0746C15.608 16.2058 15.605 16.4206 15.4738 16.5488V16.5011ZM15.259 14.4459C14.5968 14.4459 14.0569 13.906 14.0569 13.2438C14.0569 12.5816 14.5968 12.0417 15.259 12.0417C15.9212 12.0417 16.4611 12.5816 16.4611 13.2438C16.4879 13.906 15.9719 14.4638 15.3097 14.4907C15.2888 14.4907 15.2709 14.4907 15.25 14.4907L15.259 14.4459Z"
        fill="#181716"
      />
    </svg>
  );
}
