import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import { Roboto_Mono } from "next/font/google";

import styles from "./DimensionalAssistantModal.module.css";

import DraggableModalWrapper from "@/components/shared/ModalWrapper/DraggableModalWrapper/DraggableModalWrapper";
import { GoDeeperIconLarge } from "../GoDeeperIcon";
import { useGetInsightPower } from "../useGetInsightPower";
import {
  HighArrow,
  LowArrow,
  MediumArrow,
  VeryHighArrows,
  VeryLowArrows,
} from "./InsightPowerArrows/InsightPowerArrows";
import {
  dimensionData,
  dimensionIcons,
} from "@/assets/traitInfo/dimensionData";
import { useAlertContext } from "@/context/AlertContext";
import Alert from "@/components/shared/Alerts/Alert";
import ConsumableBoxes from "./ConsumableBoxes/ConsumableBoxes";
import { Mixpanel } from "@/helpers/mixpanel";
import { useAuth } from "@/context/AuthContext";

const font = Roboto_Mono({ subsets: ["latin"] });

export default function DimensionalAssistantModal(props: {
  onClose: () => void;
}) {
  const { user } = useAuth();
  const { setAlertComponent } = useAlertContext();

  const numberOfMessagesRemaining = useSelector(
    (state: RootState) => state.chatbot
  ).numberOfMessagesRemaining;

  const isPremium = useSelector((state: RootState) => state.user).isPremium;

  const { power, finishedSlugs } = useGetInsightPower();

  useEffect(() => {
    Mixpanel?.people.update({ "Chatbot | insight power": power }, user?.uid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [power]);

  const messageRemainingText =
    numberOfMessagesRemaining === "unlimited" ? (
      <p className={styles.messageRemainingText}>
        Unlimited messages with <span className={styles.plusText}>Plus</span>
      </p>
    ) : numberOfMessagesRemaining === 0 ? (
      <p className={styles.messageRemainingText}>
        {"You're out of messages. Get more:"}
      </p>
    ) : (
      <p className={styles.messageRemainingText}>
        {numberOfMessagesRemaining} messages remaining
      </p>
    );

  const mappedBars = [0, 1, 2, 3, 4, 5].map((bar) => {
    return (
      <div
        className={styles.bar}
        style={power >= bar ? {} : { opacity: "0.2" }}
        key={bar}
      ></div>
    );
  });

  const insightPowerTextMap = {
    0: "None",
    1: "Very low",
    2: "Low",
    3: "Medium",
    4: "High",
    5: "Very high",
  };

  const insightPowerArrowMap = {
    0: <></>,
    1: <VeryLowArrows />,
    2: <LowArrow />,
    3: <MediumArrow />,
    4: <HighArrow />,
    5: <VeryHighArrows />,
  };

  const mappedDimensions = dimensionData
    .sort((a, b) => {
      const aFinished = finishedSlugs.includes(a.slug);
      const bFinished = finishedSlugs.includes(b.slug);

      if (aFinished && !bFinished) return -1;
      if (!aFinished && bFinished) return 1;
      return 0;
    })
    .map((d) => {
      const finished = finishedSlugs.includes(d.slug);
      if (finished) {
        return (
          <div className={styles.dimensionIcon} key={d.slug}>
            {dimensionIcons[d.slug].icon}
          </div>
        );
      }
      return (
        <div className={styles.dimensionIcon} key={d.slug}>
          {dimensionIcons[d.slug].iconInactive}
        </div>
      );
    });

  function handleBuy() {
    Mixpanel?.track("Purchase CTA clicked", { source: "chatbot credits" });
    setAlertComponent(
      <Alert
        buttonText="Notify me"
        onButtonClick={() => {
          Mixpanel?.track("Chatbot | Notify me CTA clicked", {});
        }}
        elementName="Alert"
        type="warning"
        message="Coming soon! Get notified when it's available?"
      />,
      5000
    );
  }

  return (
    <DraggableModalWrapper
      onClose={() => {
        props.onClose();
      }}
    >
      <main className={styles.main}>
        <h1 className={styles.title}>Dimensional Assistant</h1>
        <div className={styles.iconDiv}>
          <GoDeeperIconLarge full={numberOfMessagesRemaining !== 0} />
        </div>

        {messageRemainingText}

        {numberOfMessagesRemaining !== 0 && (
          <div className={styles.insightPowerDiv}>
            <h4 className={styles.insightPowerTitle}>Insight power</h4>

            <div className={styles.insightPowerBarParent}>
              <div className={styles.insightPowerBarWrapper}>{mappedBars}</div>
              <div className={styles.textAndArrowDiv}>
                <p className={styles.textAndArrowText}>
                  {
                    insightPowerTextMap[
                      power as keyof typeof insightPowerTextMap
                    ]
                  }
                </p>
                {
                  insightPowerArrowMap[
                    power as keyof typeof insightPowerArrowMap
                  ]
                }
              </div>
            </div>
            <p className={styles.completingText}>
              Completing more Dimensions improves the quality of insights and
              guidance.
            </p>

            <div className={styles.dimensionIconParent}>
              <p
                className={`${styles.dimensionsCompleteText} ${font.className}`}
              >
                DIMENSION COMPLETION:{" "}
                {finishedSlugs.filter((s) => s !== "coping-style").length}/14
              </p>
              <div className={styles.dimensionIconWrapper}>
                {mappedDimensions}
              </div>
            </div>
          </div>
        )}

        {numberOfMessagesRemaining === 0 && (
          <div className={styles.consumablesMapper}>
            <ConsumableBoxes />
          </div>
        )}

        <div className={styles.buttonDiv}>
          {!isPremium && (
            <button
              onClick={() => {
                Mixpanel?.track("Get more messages CTA pressed", {});
                handleBuy();
              }}
              className={styles.messageButton}
            >
              Get more messages
            </button>
          )}
          <button className={styles.closeBtn} onClick={() => props.onClose()}>
            Close
          </button>
        </div>
      </main>
    </DraggableModalWrapper>
  );
}
