import { TabIconModel } from "@/models/sharedModels";

export default function TabResultsIcon(props: TabIconModel) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="22"
      viewBox="0 0 18 22"
      fill="none"
    >
      <path
        d="M3.52872 21.9997H14.4713C16.0041 21.9978 17.2462 20.7556 17.249 19.2219V2.77807C17.2462 1.24432 16.004 0.00222518 14.4713 0.000305176H8.98224C8.92225 0.000305176 8.86131 0.00593 8.80225 0.0181177H8.78443C8.73287 0.0303051 8.68225 0.0453049 8.63349 0.0640561L8.58193 0.0865561C8.54349 0.104368 8.50599 0.124995 8.47131 0.148431L8.42725 0.174682L8.42631 0.175619C8.37943 0.208432 8.33443 0.245932 8.29318 0.286245L1.04254 7.54241C0.99848 7.58647 0.960042 7.63428 0.926291 7.68678L0.915041 7.70272L0.915978 7.70178C0.886917 7.74865 0.860665 7.79647 0.838165 7.84615C0.833478 7.85459 0.829728 7.86397 0.826915 7.87334C0.778166 7.98959 0.752853 8.11428 0.750977 8.23989V19.2218C0.753789 20.7556 1.99596 21.9977 3.52872 21.9997ZM7.99969 3.41383V6.47094C7.99969 6.6772 7.91812 6.87501 7.77187 7.02127C7.62656 7.16658 7.42874 7.24908 7.22249 7.24908H4.16441L7.99969 3.41383ZM2.7516 9.24871H7.22257C8.75631 9.24777 9.99841 8.00464 10.0003 6.47094V1.99999H14.4713C14.9007 1.99999 15.2485 2.34779 15.2485 2.77811V19.222C15.2485 19.4282 15.1669 19.626 15.0207 19.7723C14.8754 19.9185 14.6775 20.0001 14.4713 20.0001H3.52872C3.09934 20.0001 2.75153 19.6523 2.75153 19.222L2.7516 9.24871Z"
        fill={props.active ? "#FFFFF2" : "#928E8C"}
      />
    </svg>
  );
}
