import styles from "./MoreButton.module.css";

import ForwardArrow from "../ForwardArrow";

export default function MoreButton(props: { onClick: () => void }) {
  return (
    <button onClick={() => props.onClick()} className={styles.moreBtn}>
      More <ForwardArrow />
    </button>
  );
}
