import { useEffect, useState } from "react";

import styles from "./RoundedCheckbox.module.css";

export default function RoundedCheckbox(props: {
  currentValue: boolean;
  setValue: (value: boolean) => void;
}) {
  const [value, setValue] = useState<boolean>(false);

  useEffect(() => {
    setValue(props.currentValue);
  }, [props.currentValue]);

  if (!value) {
    return (
      <div
        onClick={() => {
          props.setValue(!value);
          setValue(!value);
        }}
        className={styles.main}
      ></div>
    );
  } else {
    return (
      <div
        onClick={() => {
          props.setValue(!value);
          setValue(!value);
        }}
        className={styles.main}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <g clipPath="url(#clip0_68312_277)">
            <path
              d="M0.5 8.58369L2.16635 6.41694L6.5 10.0837L13.5002 2.25024L15.5 4.08369L6.66635 13.7498L0.5 8.58369Z"
              fill="#FFFFF2"
            />
          </g>
          <defs>
            <clipPath id="clip0_68312_277">
              <rect
                width="15"
                height="15"
                fill="white"
                transform="translate(0.5 0.5)"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
    );
  }
}
