import { useEffect, useState } from "react";
import {
  FriendRequest,
  PublicProfile,
} from "@dimensional-engineering/dimensional-models";
import { useSelector } from "react-redux";

import styles from "./ConnectionRequestModal.module.css";

import { useModalContext } from "@/context/ModalContext";
import { RootState } from "@/redux/store";
import useGetProfilePhoto from "@/helpers/useGetProfilePhoto";
import {
  acceptFriendRequest,
  declineFriendRequest,
  sendFriendRequest,
} from "../utils";
import { Mixpanel } from "@/helpers/mixpanel";
import SmallProfileImage from "@/components/shared/SmallProfileImage/SmallProfileImage";
import Alert from "@/components/shared/Alerts/Alert";
import DeepConnectionIcon from "@/components/shared/icons/DeepConnectionIcon";
import { useAlertContext } from "@/context/AlertContext";
import { useSlideUpFriendRequestWrapper } from "./ConnectionRequestModal";

type Props = {
  publicProfile: PublicProfile;
  friendRequest?: FriendRequest;
  fromContentLock?: boolean;
  elementName: "ConnectionModal";
};

export default function DeepConnectionModal(props: Props) {
  const { removeModalByName } = useModalContext();
  const { setAlertComponent } = useAlertContext();
  useSlideUpFriendRequestWrapper();

  const userState = useSelector((state: RootState) => state.user);
  const imageUrl = useGetProfilePhoto(props.publicProfile?.ownerUUID ?? "");
  const [buttonText, setButtonText] = useState<string>(
    "Send connection request"
  );
  const status = useDeepConnectionStatus({
    publicProfile: props.publicProfile,
  });

  function handleAcceptRequest() {
    if (props.friendRequest) {
      acceptFriendRequest(props.friendRequest)
        .then(() => {
          removeModalByName("ConnectionModal");
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      let request: FriendRequest | undefined;
      userState.friendRequestsRecieved?.forEach((req) => {
        if (req.sender.ownerUUID === props.publicProfile.ownerUUID) {
          request = req;
        }
      });
      if (request) {
        acceptFriendRequest(request)
          .then(() => {
            removeModalByName("ConnectionModal");
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }

  function handleDeclineRequest() {
    if (props.friendRequest) {
      declineFriendRequest(props.friendRequest)
        .then(() => {
          removeModalByName("ConnectionModal");
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      let request: FriendRequest | undefined;
      userState.friendRequestsRecieved?.forEach((req) => {
        if (req.sender.ownerUUID === props.publicProfile.ownerUUID) {
          request = req;
        }
      });
      if (request) {
        declineFriendRequest(request)
          .then(() => {
            removeModalByName("ConnectionModal");
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }

  function handleClick() {
    setButtonText("Loading...");
    if (
      userState.friendRequestRecievedUidsList.includes(
        props.publicProfile.ownerUUID
      )
    ) {
      ///accept close friend request before sending deep friend request
      userState.friendRequestsRecieved?.forEach((req) => {
        if (req.sender.ownerUUID === props.publicProfile.ownerUUID) {
          acceptFriendRequest(req).then(() => {
            if (userState.publicProfileData) {
              sendFriendRequest(
                userState.publicProfileData,
                props.publicProfile,
                true,
                true,
                undefined
              )
                .then((res) => {
                  removeModalByName("ConnectionModal");
                  Mixpanel?.track("Friend request sent", {
                    source: "Profile",
                    type: "deep",
                  });
                  setAlertComponent(
                    <Alert
                      type="success"
                      message="Successfully sent deep connection request"
                      elementName="Alert"
                    />,
                    5000
                  );
                })
                .catch((err) => {
                  console.log(err);
                  setButtonText("Send connection request");
                  removeModalByName("ConnectionModal");
                  setAlertComponent(
                    <Alert
                      type="fail"
                      message="Failed to send deep connection request. Note: You're limited to 150 connections."
                      elementName="Alert"
                    />,
                    7000
                  );
                });
            }
          });
        }
      });
    } else {
      if (userState.publicProfileData) {
        sendFriendRequest(
          userState.publicProfileData,
          props.publicProfile,
          true,
          true,
          undefined
        )
          .then((res) => {
            removeModalByName("ConnectionModal");
            Mixpanel?.track("Friend request sent", { source: "Profile" });
            setTimeout(() => {
              setAlertComponent(
                <Alert
                  type="success"
                  message="Successfully sent deep connection request"
                  elementName="Alert"
                />,
                5000
              );
            }, 200);
          })
          .catch((err) => {
            console.log(err);
            setButtonText("Send connection request");
            removeModalByName("ConnectionModal");
            setTimeout(() => {
              setAlertComponent(
                <Alert
                  type="fail"
                  message="Failed to send deep connection request."
                  elementName="Alert"
                />,
                7000
              );
            }, 200);
          });
      }
    }
  }

  if (status === null) {
    return (
      <dialog
        onClick={() => {
          removeModalByName("ConnectionModal");
        }}
        className={styles.main}
      >
        <main
          id="dialogconnection"
          className={styles.wrapper}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={styles.images}>
            <SmallProfileImage
              publicProfile={userState.publicProfileData as PublicProfile}
              imageUrl={userState.imageURL as string}
            />
            <div className={styles.svg}>
              <DeepConnectionIcon active />
            </div>
            <SmallProfileImage
              publicProfile={props.publicProfile}
              imageUrl={imageUrl as string}
            />
          </div>
          <h3 className={styles.title}>
            {props.fromContentLock
              ? "Requires deep connection"
              : "Deep connection request"}
          </h3>
          <p className={styles.unlock}>
            Unlocks your deepest content: your attachment styles, sexuality, and
            more.
          </p>
          <button
            disabled={buttonText === "Loading..."}
            onClick={() => handleClick()}
            className={styles.button}
          >
            {buttonText}
          </button>
        </main>
      </dialog>
    );
  }

  if (status === "recieved") {
    return (
      <dialog
        onClick={() => {
          removeModalByName("ConnectionModal");
        }}
        className={styles.main}
      >
        <main
          id="dialogconnection"
          className={styles.wrapper}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={styles.images}>
            <SmallProfileImage
              publicProfile={userState.publicProfileData as PublicProfile}
              imageUrl={userState.imageURL as string}
            />
            <div className={styles.svg}>
              <DeepConnectionIcon active />
            </div>
            <SmallProfileImage
              publicProfile={props.publicProfile}
              imageUrl={imageUrl as string}
            />
          </div>
          <h3 className={styles.title}>Deep connection request</h3>
          <p className={styles.unlock}>
            Unlocks your deepest content: your attachment styles, sexuality, and
            more.
          </p>

          <button
            onClick={() => handleAcceptRequest()}
            className={styles.button}
          >
            Accept
          </button>
          <button
            onClick={() => handleDeclineRequest()}
            className={styles.buttonPending}
          >
            Decline
          </button>
        </main>
      </dialog>
    );
  }

  if (status === "sent") {
    return (
      <dialog
        onClick={() => {
          removeModalByName("ConnectionModal");
        }}
        className={styles.main}
      >
        <main
          id="dialogconnection"
          className={styles.wrapper}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={styles.images}>
            <SmallProfileImage
              publicProfile={userState.publicProfileData as PublicProfile}
              imageUrl={userState.imageURL as string}
            />
            <div className={styles.svg}>
              <DeepConnectionIcon active />
            </div>
            <SmallProfileImage
              publicProfile={props.publicProfile}
              imageUrl={imageUrl as string}
            />
          </div>
          <h3 className={styles.title}>
            {props.fromContentLock
              ? "Requires Deep Connection"
              : "Deep Connection Request"}
          </h3>
          <p className={styles.unlock}>
            Unlocks your deepest content: your attachment styles, sexuality, and
            more.
          </p>

          <button disabled={true} className={styles.buttonPending}>
            Pending
          </button>
        </main>
      </dialog>
    );
  }

  return <></>;
}

function useDeepConnectionStatus(props: { publicProfile: PublicProfile }) {
  const [status, setStatus] = useState<"recieved" | "sent" | null>(null);
  const userState = useSelector((state: RootState) => state.user);

  useEffect(() => {
    let s: "recieved" | "sent" | null = null;
    if (
      userState?.isDeepRequestSentUidList?.includes(
        props?.publicProfile?.ownerUUID
      )
    ) {
      s = "sent";
    }
    userState?.friendRequestsRecieved?.forEach((req) => {
      if (req?.isDeep === true) {
        s = "recieved";
      }
    });
    setStatus(s);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.publicProfile?.ownerUUID]);

  return status;
}
