import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/redux/store";

import styles from "./ChatbotSideBar.module.css";
import { GPTConversationType } from "@dimensional-engineering/dimensional-models";

import {
  setConversations,
  setCurrentConversation,
} from "@/redux/slices/chatbotSlice";
import { useModalContext } from "@/context/ModalContext";
import {
  SystemDialogModal,
  SystemDialogModalRenameChat,
} from "@/components/shared/SystemDialog/SystemDialog";
import { useAuth } from "@/context/AuthContext";
import { deleteConversation, renameConversation } from "../utils";
import { getAllCurrentChatbotConversations } from "@/context/utils";
import LoadingModal from "@/components/shared/LoadingModal/LoadingModal";

type Props = {
  onClose: () => void;
  onNew: () => void;
};

export default function ChatbotSideBar(props: Props) {
  const { user } = useAuth();

  const {
    setModalComponentNoUi,
    setModalOpenNoUI,
    setModalComponent,
    removeModalByName,
  } = useModalContext();

  const dispatch = useDispatch();

  const conversations = useSelector(
    (state: RootState) => state.chatbot
  ).conversations;
  const activeConversation = useSelector(
    (state: RootState) => state.chatbot
  ).currentConversation;

  const ref = useRef<HTMLDivElement>(null);
  const startX = useRef<number | null>(null);

  const [selectionId, setSelectionId] = useState<string | null | undefined>(
    null
  );

  useEffect(() => {
    const handleTouchStart = (e: TouchEvent) => {
      startX.current = e.touches[0].clientX;
    };

    const handleTouchMove = (e: TouchEvent) => {
      if (!startX.current || !ref.current) return;
      const deltaX = e.touches[0].clientX - startX.current;
      if (deltaX < -50) handleClose();
    };

    const handleTouchEnd = () => {
      startX.current = null;
    };

    ref.current?.addEventListener("touchstart", handleTouchStart);
    ref.current?.addEventListener("touchmove", handleTouchMove);
    ref.current?.addEventListener("touchend", handleTouchEnd);

    return () => {
      ref.current?.removeEventListener("touchstart", handleTouchStart);
      ref.current?.removeEventListener("touchmove", handleTouchMove);
      ref.current?.removeEventListener("touchend", handleTouchEnd);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (ref.current) {
      setTimeout(() => {
        if (ref.current) {
          ref.current.style.marginLeft = "0";
        }
      }, 200);
    }
  }, []);

  function handleClose() {
    if (ref.current) {
      ref.current.style.marginLeft = "-100%";
    }

    setTimeout(() => {
      props.onClose();
    }, 500);
  }

  const mappedConversations = conversations.map((c) => {
    const title =
      c.title === "Generic Conversation Title"
        ? "New conversation"
        : c.title ?? "Your counselor";

    const prependedTitle =
      c.conversationType === GPTConversationType.dimensionSummary
        ? `Dimension: ${title}`
        : c.conversationType === GPTConversationType.dailyInsight
        ? `Insight: ${title}`
        : title;

    const slicedTitle =
      prependedTitle.length >= 30
        ? `${prependedTitle.slice(0, 30)}...`
        : prependedTitle;

    let touchTimeout: ReturnType<typeof setTimeout>;

    const handleTouchStart = () => {
      touchTimeout = setTimeout(() => {
        setSelectionId(c.id);
      }, 500);
    };

    const handleTouchEnd = () => {
      clearTimeout(touchTimeout);
    };

    return (
      <div
        onClick={() => {
          if (selectionId) {
            setSelectionId(null);
          } else {
            dispatch(setCurrentConversation(c));
            handleClose();
          }
        }}
        style={activeConversation?.id === c.id ? { background: "#55504F" } : {}}
        className={styles.conversationDiv}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onContextMenu={(e) => e.preventDefault()}
        key={c.createdOn}
      >
        <p className={styles.conversationTitle}>{slicedTitle}</p>
        {c.id === selectionId && c.id && (
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className={styles.conversationSelectorDiv}
          >
            <p
              onClick={() => {
                setModalComponentNoUi(
                  <SystemDialogModalRenameChat
                    title="Rename Chat"
                    cancelText="Cancel"
                    approveText="OK"
                    onCancel={() => {
                      setModalOpenNoUI(false);
                    }}
                    onApprove={(text) => {
                      setModalComponent(
                        <LoadingModal noHeader elementName="LoadingModal" />
                      );
                      renameConversation(user?.uid, selectionId, text).then(
                        () => {
                          getAllCurrentChatbotConversations(user?.uid).then(
                            (res) => {
                              const sortedRes = res.sort(
                                (a, b) =>
                                  (b.latestSystemMessageTime || 0) -
                                  (a.latestSystemMessageTime || 0)
                              );
                              dispatch(
                                setCurrentConversation(
                                  sortedRes.length ? sortedRes[0] : null
                                )
                              );
                              dispatch(setConversations(sortedRes));
                              removeModalByName("LoadingModal");
                              setModalOpenNoUI(false);
                              setSelectionId(null);
                            }
                          );
                        }
                      );
                    }}
                  />
                );
              }}
              className={styles.conversationSelectorOption}
            >
              Rename
            </p>
            {activeConversation?.conversationType !==
              GPTConversationType.default && (
              <p
                onClick={() => {
                  setModalComponentNoUi(
                    <SystemDialogModal
                      body="Are you sure you want to delete this chat?"
                      cancelText="Cancel"
                      approveText="Confirm"
                      onCancel={() => {
                        setModalOpenNoUI(false);
                      }}
                      onApprove={() => {
                        setModalComponent(
                          <LoadingModal noHeader elementName="LoadingModal" />
                        );
                        deleteConversation(selectionId).then(() => {
                          getAllCurrentChatbotConversations(user?.uid).then(
                            (res) => {
                              const sortedRes = res.sort(
                                (a, b) =>
                                  (b.latestSystemMessageTime || 0) -
                                  (a.latestSystemMessageTime || 0)
                              );
                              dispatch(
                                setCurrentConversation(
                                  sortedRes.length ? sortedRes[0] : null
                                )
                              );
                              dispatch(setConversations(sortedRes));
                              removeModalByName("LoadingModal");
                              setModalOpenNoUI(false);
                              setSelectionId(null);
                              props.onClose();
                            }
                          );
                        });
                      }}
                    />
                  );
                }}
                className={styles.conversationSelectorOption}
              >
                Delete
              </p>
            )}
          </div>
        )}
      </div>
    );
  });

  return (
    <main onClick={() => handleClose()} className={styles.background}>
      <section
        onClick={(e) => {
          e.stopPropagation();
          setSelectionId(null);
        }}
        ref={ref}
        className={styles.main}
      >
        <div className={styles.nav}>
          <h3 className={styles.title}>Conversations</h3>
          <div
            onClick={() => {
              props.onNew();
              handleClose();
            }}
            className={styles.navRightDiv}
          >
            <NewConversationIcon />
            <p className={styles.navNewText}>NEW</p>
          </div>
        </div>

        <div className={styles.wrapper}>{mappedConversations}</div>
      </section>
    </main>
  );
}

export function NewConversationIcon(props: { onClick?: () => void }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      onClick={() => props.onClick && props.onClick()}
    >
      <path
        d="M19.5548 0C18.6687 0 17.7846 0.335278 17.1152 1.00371L15.7924 2.29959H3.19424C1.44456 2.29959 0 3.74425 0 5.49395V19.8056C0 21.5554 1.44461 23 3.19424 23H17.5065C19.2562 23 20.7008 21.5553 20.7008 19.8056V7.20787L21.9966 5.88503C23.3345 4.54817 23.3345 2.34126 21.9966 1.00434C21.3282 0.335913 20.4409 0.000634597 19.5548 0.000634597L19.5548 0ZM19.5591 2.27803C19.8502 2.27803 20.1424 2.39339 20.3741 2.62625C20.8388 3.09092 20.8388 3.79277 20.3741 4.25744C20.3709 4.25959 20.3677 4.26175 20.3655 4.26606L11.0199 13.7986H9.20225V11.982L18.7367 2.63604C18.7388 2.6328 18.741 2.63172 18.7431 2.62741C18.9749 2.39562 19.2671 2.27919 19.5582 2.27919L19.5591 2.27803ZM3.19408 4.60028H13.4475L7.24222 10.6829C7.02553 10.8985 6.90262 11.1918 6.90262 11.498V14.9523C6.90478 15.5841 7.41686 16.0951 8.04859 16.0983H11.5047C11.8098 16.0972 12.1031 15.9754 12.3176 15.7598L18.4 9.55432V19.807C18.4 20.3223 18.0216 20.7007 17.5063 20.7007L3.19403 20.6996C2.67871 20.6996 2.30031 20.3212 2.30031 19.8059V5.49419C2.30031 4.97886 2.67871 4.60044 3.19403 4.60044L3.19408 4.60028Z"
        fill="#FFFFF2"
      />
    </svg>
  );
}
