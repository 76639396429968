import { useEffect, useState } from "react";
import { Roboto_Mono } from "next/font/google";
import { TraitPlasticity } from "@dimensional-engineering/dimensional-models";

import styles from "./TraitScoreTable.module.css";

import elementJsonData from "../../../../assets/traitInfo/elements.json";
import patternJsonData from "../../../../assets/traitInfo/patterns.json";
import archetypesJsonData from "../../../../assets/traitInfo/archetypes.json";

import {
  archetypeMapper,
  dimensionData,
  dimensionIcons,
  patternGroupMapper,
} from "@/assets/traitInfo/dimensionData";
import { useModalContext } from "@/context/ModalContext";
import InformationIcon from "@/components/premium/reports/blindspot/ReportAccuracy/InformationIcon";
import GeneralModal, {
  GeneralModalArrayParagraph,
} from "@/components/shared/GeneralModal/GeneralModal";
import ElementModal from "../../TraitsModals/Element/ElementModal";
import PatternModal from "../../TraitsModals/Pattern/PatternModal";
import ArchetypeModal from "../../TraitsModals/Archetype/ArchetypeModal";
import LinkIcon from "@/components/settings/LinkIcon";

type Props = {
  type: "Archetype" | "Pattern" | "Element";
  aboutThisTrait?: boolean;
  childElementCount?: number;
  dimension?: string | null;
  nature?: string;
  color?: string;
  plasticity?: TraitPlasticity | null | undefined;
  oppositeTraitSlug?: string | null | undefined;
  patternGroupSlug?: string | null | undefined;
  averageUserScore?: number | null | undefined;
  archetypeGroupSlug?: string | null | undefined;
  usersWithArchetypeNumber?: number | null | undefined;
};

const font = Roboto_Mono({ subsets: ["latin"] });

export default function TraitDescription(props: Props) {
  const [dimensionName, setDimensionName] = useState<string | null>(null);
  const [dimensionDesc, setDimensionDesc] = useState<string | null>(null);

  const { setModalComponent } = useModalContext();

  useEffect(() => {
    if (props.dimension) {
      dimensionData.forEach((dim) => {
        if (dim.slug === props.dimension) {
          setDimensionName(dim.title);
          setDimensionDesc(dim.description);
        }
      });
    }
  }, [props.dimension]);

  const oppositeElement = elementJsonData.find(
    (e) => e.slug === props.oppositeTraitSlug
  );

  const oppositePattern = patternJsonData.find(
    (e) => e.slug === props.oppositeTraitSlug
  );

  const oppositeArchetype = archetypesJsonData.find(
    (e) => e.slug === props.oppositeTraitSlug
  );

  if (props.type === "Element") {
    return (
      <div
        style={props.aboutThisTrait ? { marginTop: "56px" } : {}}
        className={styles.main}
      >
        <div className={styles.wrapper}>
          {props.aboutThisTrait && (
            <h1 className={styles.header}>About this trait</h1>
          )}
          <div
            onClick={() => {
              if (props.type === "Element") {
                setModalComponent(
                  <GeneralModal
                    title="Elements"
                    body="Elements are the fundamental building blocks of your personality. Patterns and Archetypes are made up of two or more elements."
                  />
                );
              } else {
                setModalComponent(
                  <GeneralModal
                    title="What are Patterns?"
                    body="Patterns are combinations of two or more elements that combine to create a new, more specific, trait."
                  />
                );
              }
            }}
            className={styles.row}
          >
            <div className={styles.rowChild}>
              <h3 style={{ marginLeft: "0" }} className={font.className}>
                Trait Type
              </h3>
            </div>
            <div className={styles.rowChild}>
              <h3
                style={{ marginLeft: "0", color: "#55504F" }}
                className={font.className}
              >
                <span
                  style={
                    props.type === "Element"
                      ? { color: "#E6E1DF" }
                      : { color: "#55504F" }
                  }
                >
                  Element
                </span>
                /<span style={{ color: "#55504F" }}>Pattern</span>/
                <span style={{ color: "#55504F" }}>Archetype</span>
                <span className={styles.dimensionIconDiv}>
                  <InformationIcon />
                </span>
              </h3>
            </div>
          </div>

          {props.dimension && (
            <div
              onClick={() => {
                if (dimensionName && dimensionDesc) {
                  setModalComponent(
                    <GeneralModal title={dimensionName} body={dimensionDesc} />
                  );
                }
              }}
              className={styles.row}
            >
              <div className={styles.rowChild}>
                <h3 style={{ marginLeft: "0" }} className={font.className}>
                  Dimension
                </h3>
              </div>
              <div className={styles.rowChild}>
                <h3 style={{ marginLeft: "0" }} className={font.className}>
                  {dimensionName}{" "}
                  {
                    <span className={styles.dimensionIconDiv}>
                      {
                        dimensionIcons[
                          props.dimension as keyof typeof dimensionIcons
                        ].icon
                      }
                    </span>
                  }
                </h3>
              </div>
            </div>
          )}

          <div
            onClick={() => {
              setModalComponent(
                <GeneralModalArrayParagraph
                  title="What is and Element's Nature?"
                  body={[
                    "An Element's nature describes its core energy, as represented by its color. All elements belong to 1 of 7 Natures:",
                    "1. Passion & Sensitivity\n2. Openness & Curiosity\n3. Warmth & Agreeableness\n4. Order & Responsibility\n5. Tranquility\n6. Energy & Excitement\n7. Ambition & Self-Enhancement",
                  ]}
                />
              );
            }}
            className={styles.row}
          >
            <div className={styles.rowChild}>
              <h3 style={{ marginLeft: "0" }} className={font.className}>
                Nature
              </h3>
            </div>
            <div className={styles.rowChild}>
              <h3
                style={
                  props.color
                    ? { color: props.color, marginLeft: "0" }
                    : { marginLeft: "0" }
                }
                className={font.className}
              >
                {props.nature ?? "None"}
              </h3>
            </div>
          </div>

          {props.plasticity && (
            <div
              onClick={() => {
                setModalComponent(
                  <GeneralModalArrayParagraph
                    title="Trait Fluidity"
                    body={[
                      "Some aspects of who we are change very slow, if at all. Other aspects can change on an almost daily basis. We call this feature of traits fluidity.",
                      "Our mood, for instance, has high fluidity. It can change from day to day and sometimes moment to moment.",
                      "Our strengths, on the other hand, have a medium level of fluidity, meaning they evolve with our circumstances. For instance, people thrown into leadership positions can improve their leadership abilities.",
                      "Other “core” personality traits like Extroversion tend to have low fluidity. They evolve very slowly, usually over the course of years. Major life events and general maturation can change our stable traits.",
                    ]}
                  />
                );
              }}
              className={styles.row}
            >
              <div className={styles.rowChild}>
                <h3 style={{ marginLeft: "0" }} className={font.className}>
                  Fluidity
                </h3>
              </div>
              <div className={styles.rowChild}>
                <h3
                  style={{ marginLeft: "0", color: "#55504F" }}
                  className={font.className}
                >
                  <span
                    style={
                      props.plasticity === TraitPlasticity.low
                        ? { color: "#E6E1DF" }
                        : { color: "#55504F" }
                    }
                  >
                    Low
                  </span>
                  /
                  <span
                    style={
                      props.plasticity === TraitPlasticity.medium
                        ? { color: "#E6E1DF" }
                        : { color: "#55504F" }
                    }
                  >
                    Medium
                  </span>
                  /
                  <span
                    style={
                      props.plasticity === TraitPlasticity.high
                        ? { color: "#E6E1DF" }
                        : { color: "#55504F" }
                    }
                  >
                    High
                  </span>
                  <span className={styles.dimensionIconDiv}>
                    <InformationIcon />
                  </span>
                </h3>
              </div>
            </div>
          )}

          {props.oppositeTraitSlug && (
            <div
              onClick={() => {
                if (oppositeElement) {
                  setModalComponent(
                    <ElementModal slug={oppositeElement?.slug} />
                  );
                }
              }}
              className={styles.row}
            >
              <div className={styles.rowChild}>
                <h3 style={{ marginLeft: "0" }} className={font.className}>
                  Opposite
                </h3>
              </div>
              <div className={styles.rowChild}>
                <h3
                  style={{ marginLeft: "0", color: "#2cb0ff" }}
                  className={font.className}
                >
                  {oppositeElement?.alias ?? oppositeElement?.name}
                  <span className={styles.dimensionIconDiv}>
                    <LinkIcon />
                  </span>
                </h3>
              </div>
            </div>
          )}

          {props.averageUserScore && (
            <div className={styles.row}>
              <div className={styles.rowChild}>
                <h3 style={{ marginLeft: "0" }} className={font.className}>
                  Average user score
                </h3>
              </div>
              <div className={styles.rowChild}>
                <h3 style={{ marginLeft: "0" }} className={font.className}>
                  {(props.averageUserScore * 100).toFixed(0)}
                </h3>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  if (props.type === "Archetype") {
    return (
      <div style={{ marginTop: "56px" }} className={styles.main}>
        <div className={styles.wrapper}>
          <h1 className={styles.header}>About this trait</h1>

          <div
            onClick={() => {
              setModalComponent(
                <GeneralModal
                  title="What are Archetypes?"
                  body="Archetypes are special Patterns that summarize who you are within a specific dimension. Unlike Patterns, you will always get one and only one Archetype per Dimension."
                />
              );
            }}
            className={styles.row}
          >
            <div className={styles.rowChild}>
              <h3 style={{ marginLeft: "0" }} className={font.className}>
                Trait Type
              </h3>
            </div>
            <div className={styles.rowChild}>
              <h3
                style={{ marginLeft: "0", color: "#55504F" }}
                className={font.className}
              >
                <span style={{ color: "#55504F" }}>Element</span>/
                <span style={{ color: "#55504F" }}>Pattern</span>/
                <span style={{ color: "#E6E1DF" }}>Archetype</span>
                <span className={styles.dimensionIconDiv}>
                  <InformationIcon />
                </span>
              </h3>
            </div>
          </div>

          <div
            onClick={() => {
              if (dimensionName && dimensionDesc) {
                setModalComponent(
                  <GeneralModal title={dimensionName} body={dimensionDesc} />
                );
              }
            }}
            className={styles.row}
          >
            <div className={styles.rowChild}>
              <h3 style={{ marginLeft: "0" }} className={font.className}>
                Dimension
              </h3>
            </div>
            <div className={styles.rowChild}>
              <h3 style={{ marginLeft: "0" }} className={font.className}>
                {dimensionName ?? "Mix"}{" "}
                {dimensionName !== null && (
                  <span className={styles.dimensionIconDiv}>
                    {
                      dimensionIcons[
                        props.dimension as keyof typeof dimensionIcons
                      ].icon
                    }
                  </span>
                )}
              </h3>
            </div>
          </div>

          {props.plasticity && (
            <div
              onClick={() => {
                setModalComponent(
                  <GeneralModalArrayParagraph
                    title="Trait Fluidity"
                    body={[
                      "Some aspects of who we are change very slow, if at all. Other aspects can change on an almost daily basis. We call this feature of traits fluidity.",
                      "Our mood, for instance, has high fluidity. It can change from day to day and sometimes moment to moment.",
                      "Our strengths, on the other hand, have a medium level of fluidity, meaning they evolve with our circumstances. For instance, people thrown into leadership positions can improve their leadership abilities.",
                      "Other “core” personality traits like Extroversion tend to have low fluidity. They evolve very slowly, usually over the course of years. Major life events and general maturation can change our stable traits.",
                    ]}
                  />
                );
              }}
              className={styles.row}
            >
              <div className={styles.rowChild}>
                <h3 style={{ marginLeft: "0" }} className={font.className}>
                  Fluidity
                </h3>
              </div>
              <div className={styles.rowChild}>
                <h3
                  style={{ marginLeft: "0", color: "#55504F" }}
                  className={font.className}
                >
                  <span
                    style={
                      props.plasticity === TraitPlasticity.low
                        ? { color: "#E6E1DF" }
                        : { color: "#55504F" }
                    }
                  >
                    Low
                  </span>
                  /
                  <span
                    style={
                      props.plasticity === TraitPlasticity.medium
                        ? { color: "#E6E1DF" }
                        : { color: "#55504F" }
                    }
                  >
                    Medium
                  </span>
                  /
                  <span
                    style={
                      props.plasticity === TraitPlasticity.high
                        ? { color: "#E6E1DF" }
                        : { color: "#55504F" }
                    }
                  >
                    High
                  </span>
                  <span className={styles.dimensionIconDiv}>
                    <InformationIcon />
                  </span>
                </h3>
              </div>
            </div>
          )}

          {props.archetypeGroupSlug && (
            <div className={styles.row}>
              <div className={styles.rowChild}>
                <h3 style={{ marginLeft: "0" }} className={font.className}>
                  Archetype Group
                </h3>
              </div>
              <div className={styles.rowChild}>
                <h3 style={{ marginLeft: "0" }} className={font.className}>
                  {archetypeMapper[props.archetypeGroupSlug]}
                </h3>
              </div>
            </div>
          )}

          {props.usersWithArchetypeNumber && (
            <div className={styles.row}>
              <div className={styles.rowChild}>
                <h3 style={{ marginLeft: "0" }} className={font.className}>
                  Users with this Archetype
                </h3>
              </div>
              <div className={styles.rowChild}>
                <h3 style={{ marginLeft: "0" }} className={font.className}>
                  {`${(props.usersWithArchetypeNumber * 100).toFixed()}%`}
                </h3>
              </div>
            </div>
          )}

          {props.oppositeTraitSlug && (
            <div
              onClick={() => {
                if (oppositeArchetype) {
                  return setModalComponent(
                    <ArchetypeModal slug={oppositeArchetype.slug} />
                  );
                }
              }}
              className={styles.row}
            >
              <div className={styles.rowChild}>
                <h3 style={{ marginLeft: "0" }} className={font.className}>
                  Opposite
                </h3>
              </div>
              <div className={styles.rowChild}>
                <h3
                  style={{ marginLeft: "0", color: "#2cb0ff" }}
                  className={font.className}
                >
                  {oppositeArchetype?.acronym ?? oppositeArchetype?.name}
                  <span className={styles.dimensionIconDiv}>
                    <LinkIcon />
                  </span>
                </h3>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div
      style={props.aboutThisTrait ? { marginTop: "56px" } : {}}
      className={styles.main}
    >
      <div className={styles.wrapper}>
        {props.aboutThisTrait && (
          <h1 className={styles.header}>About this trait</h1>
        )}
        <div
          onClick={() => {
            setModalComponent(
              <GeneralModal
                title="What are Patterns?"
                body="Patterns are combinations of two or more elements that combine to create a new, more specific, trait."
              />
            );
          }}
          className={styles.row}
        >
          <div className={styles.rowChild}>
            <h3 style={{ marginLeft: "0" }} className={font.className}>
              Trait Type
            </h3>
          </div>
          <div className={styles.rowChild}>
            <h3
              style={{ marginLeft: "0", color: "#55504F" }}
              className={font.className}
            >
              <span style={{ color: "#55504F" }}>Element</span>/
              <span style={{ color: "#E6E1DF" }}>Pattern</span>/
              <span style={{ color: "#55504F" }}>Archetype</span>
              <span className={styles.dimensionIconDiv}>
                <InformationIcon />
              </span>
            </h3>
          </div>
        </div>

        <div
          onClick={() => {
            setModalComponent(
              <GeneralModal
                title="What are child elements?"
                body="Child elements are the elements that combine to create a given pattern. Patterns are composed of two or more child elements. "
              />
            );
          }}
          className={styles.row}
        >
          <div className={styles.rowChild}>
            <h3 style={{ marginLeft: "0" }} className={font.className}>
              No. of child elements
            </h3>
          </div>
          <div className={styles.rowChild}>
            <h3 style={{ marginLeft: "0" }} className={font.className}>
              {props.childElementCount}
            </h3>
          </div>
        </div>

        <div
          onClick={() => {
            if (dimensionName && dimensionDesc) {
              setModalComponent(
                <GeneralModal title={dimensionName} body={dimensionDesc} />
              );
            }
          }}
          className={styles.row}
        >
          <div className={styles.rowChild}>
            <h3 style={{ marginLeft: "0" }} className={font.className}>
              Dimension
            </h3>
          </div>
          <div className={styles.rowChild}>
            <h3 style={{ marginLeft: "0" }} className={font.className}>
              {dimensionName ?? "Mix"}{" "}
              {dimensionName !== null && (
                <span className={styles.dimensionIconDiv}>
                  {
                    dimensionIcons[
                      props.dimension as keyof typeof dimensionIcons
                    ].icon
                  }
                </span>
              )}
            </h3>
          </div>
        </div>

        <div
          onClick={() => {
            setModalComponent(
              <GeneralModalArrayParagraph
                title="What is a Pattern's Nature?"
                body={[
                  "A Pattern's Nature describes the core energy of the underlying child elements that compose the pattern, as represented by its color(s). If all child Elements of a Pattern have the same Nature, the Pattern also has that Nature. Alternatively, a Pattern’s Nature can be a “mix” if its underlying child elements have different Natures. There are 7 Natures:",
                  "1. Passion & Sensitivity\n2. Openness & Curiosity\n3. Warmth & Agreeableness\n4. Order & Responsibility\n5. Tranquility\n6. Energy & Excitement\n7. Ambition & Self-Enhancement",
                ]}
              />
            );
          }}
          className={styles.row}
        >
          <div className={styles.rowChild}>
            <h3 style={{ marginLeft: "0" }} className={font.className}>
              Nature
            </h3>
          </div>
          <div className={styles.rowChild}>
            <h3
              style={
                props.color
                  ? { color: props.color, marginLeft: "0" }
                  : { marginLeft: "0" }
              }
              className={font.className}
            >
              {props.nature ?? "Mix"}
            </h3>
          </div>
        </div>

        {props.plasticity && (
          <div
            onClick={() => {
              setModalComponent(
                <GeneralModalArrayParagraph
                  title="Trait Fluidity"
                  body={[
                    "Some aspects of who we are change very slow, if at all. Other aspects can change on an almost daily basis. We call this feature of traits *fluidity*.",
                    "Our mood, for instance, has *high* fluidity. It can change from day to day and sometimes moment to moment.",
                    "Our strengths, on the other hand, have a *medium* level of fluidity, meaning they evolve with our circumstances. For instance, people thrown into leadership positions can improve their leadership abilities.",
                    "Other “core” personality traits like Extroversion tend to have *low* fluidity. They evolve very slowly, usually over the course of years. Major life events and general maturation can change our stable traits.",
                  ]}
                />
              );
            }}
            className={styles.row}
          >
            <div className={styles.rowChild}>
              <h3 style={{ marginLeft: "0" }} className={font.className}>
                Fluidity
              </h3>
            </div>
            <div className={styles.rowChild}>
              <h3
                style={{ marginLeft: "0", color: "#55504F" }}
                className={font.className}
              >
                <span
                  style={
                    props.plasticity === TraitPlasticity.low
                      ? { color: "#E6E1DF" }
                      : { color: "#55504F" }
                  }
                >
                  Low
                </span>
                /
                <span
                  style={
                    props.plasticity === TraitPlasticity.medium
                      ? { color: "#E6E1DF" }
                      : { color: "#55504F" }
                  }
                >
                  Medium
                </span>
                /
                <span
                  style={
                    props.plasticity === TraitPlasticity.high
                      ? { color: "#E6E1DF" }
                      : { color: "#55504F" }
                  }
                >
                  High
                </span>
                <span className={styles.dimensionIconDiv}>
                  <InformationIcon />
                </span>
              </h3>
            </div>
          </div>
        )}

        {props.patternGroupSlug && (
          <div
            onClick={() => {
              setModalComponent(
                <GeneralModalArrayParagraph
                  title="What are Pattern Groups?"
                  body={[
                    "A Pattern can belong to a Pattern Group. A Pattern group consists of Patterns that are related to one another according to a specific theme. There are 7 Pattern Groups:",
                    "1. Approach to life: Contains Patterns relating to how you approach life, situations, and decisions.\n2. Approach to people: Contains Patterns relating to how you interact with other people.\n3. Big Five: Contains the Patterns that represent the Big Five traits.\n4. Disposition: Contains Patterns relating to how your mind works in general and your dispositions.\n5. Love Archetype components: Contains the underlying Patterns that are used to compute your Love Archetype.\n6. Wellness: Contains Patterns relating to emotional wellbeing.\n7. Work and discipline: Contains Patterns relating to how you work.",
                  ]}
                />
              );
            }}
            className={styles.row}
          >
            <div className={styles.rowChild}>
              <h3 style={{ marginLeft: "0" }} className={font.className}>
                Pattern Group
              </h3>
            </div>
            <div className={styles.rowChild}>
              <h3 style={{ marginLeft: "0" }} className={font.className}>
                {patternGroupMapper[props.patternGroupSlug]}
              </h3>
            </div>
          </div>
        )}

        {props.oppositeTraitSlug && (
          <div
            onClick={() => {
              if (oppositePattern) {
                setModalComponent(
                  <PatternModal slug={oppositePattern?.slug} />
                );
              }
            }}
            className={styles.row}
          >
            <div className={styles.rowChild}>
              <h3 style={{ marginLeft: "0" }} className={font.className}>
                Opposite
              </h3>
            </div>
            <div className={styles.rowChild}>
              <h3
                style={{ marginLeft: "0", color: "#2cb0ff" }}
                className={font.className}
              >
                {oppositePattern?.name}
                <span className={styles.dimensionIconDiv}>
                  <LinkIcon />
                </span>
              </h3>
            </div>
          </div>
        )}
        {props.averageUserScore && (
          <div className={styles.row}>
            <div className={styles.rowChild}>
              <h3 style={{ marginLeft: "0" }} className={font.className}>
                Average user score
              </h3>
            </div>
            <div className={styles.rowChild}>
              <h3 style={{ marginLeft: "0" }} className={font.className}>
                {(props.averageUserScore * 100).toFixed(0)}
              </h3>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
