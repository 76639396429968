import { useEffect } from "react";

import styles from "./Alert.module.css";

import WarningIcon from "../GhostBox/WarningIcon";
import CheckMarkIcon from "../icons/CheckMarkIcon";
import ExplanationMarkIcon from "../icons/ExplanationMarkIcon";
import { useAlertContext } from "@/context/AlertContext";
import { useRouter } from "next/router";

type NonButtonProps = {
  message: string;
  type:
    | "success"
    | "warning"
    | "fail"
    | "neutral"
    | "screenshot"
    | "dailyStoryScreenshot"
    | "chat";
  buttonText?: string;
  onButtonClick?: () => void;
  elementName: "Alert";
};

export default function Alert(props: NonButtonProps) {
  const { removeAlert } = useAlertContext();
  const router = useRouter();

  let touchstartY = 0;
  let touchendY = 0;

  function checkSwipeUp() {
    if (touchendY < touchstartY) {
      return removeAlert();
    }
    return;
  }

  useEffect(() => {
    const main = document.getElementById("alertMain");
    if (main) {
      setTimeout(() => {
        main.style.top = "20px";
      }, 500);
    }
  }, []);

  if (props.type === "chat") {
    return (
      <div
        onTouchStart={(e) => {
          touchstartY = e.changedTouches[0].screenY;
        }}
        onTouchEnd={(e) => {
          touchendY = e.changedTouches[0].screenY;
          checkSwipeUp();
        }}
        onClick={() => router.push("/app/messaging")}
        style={{ backgroundColor: "#55504F" }}
        className={styles.main}
        id="alertMain"
      >
        <p style={{ color: "#FFFFF2", marginLeft: "0" }}>{props.message}</p>
      </div>
    );
  }

  if (props.type === "screenshot") {
    return (
      <div
        onTouchStart={(e) => {
          touchstartY = e.changedTouches[0].screenY;
        }}
        onTouchEnd={(e) => {
          touchendY = e.changedTouches[0].screenY;
          checkSwipeUp();
        }}
        style={{ backgroundColor: "#FFFFF2" }}
        className={styles.main}
        id="alertMain"
      >
        <p
          style={{ color: "#101010", marginLeft: "0" }}
          className={styles.message}
        >
          <strong>Posting to Instagram?</strong>{" "}
          {"Tag us! We're @dimensional.me"}
        </p>
      </div>
    );
  }

  if (props.type === "dailyStoryScreenshot") {
    return (
      <div
        onTouchStart={(e) => {
          touchstartY = e.changedTouches[0].screenY;
        }}
        onTouchEnd={(e) => {
          touchendY = e.changedTouches[0].screenY;
          checkSwipeUp();
        }}
        style={{ backgroundColor: "#FFFFF2" }}
        className={styles.main}
        id="alertMain"
      >
        <p
          style={{ color: "#101010", marginLeft: "0" }}
          className={styles.message}
        >
          <strong>Posting your story to IG?</strong> Tag @dimensional.me to be
          featured in our daily roundup and a chance to win a year of{" "}
          <span
            style={{
              background: "linear-gradient(90deg, #8bdcff 0%, #6978ff 100%)",
              WebkitBackgroundClip: "text",
              color: "transparent",
            }}
          >
            Dimensional Plus.
          </span>
        </p>
      </div>
    );
  }

  if (props.buttonText?.length) {
    return (
      <div
        onTouchStart={(e) => {
          touchstartY = e.changedTouches[0].screenY;
        }}
        onTouchEnd={(e) => {
          touchendY = e.changedTouches[0].screenY;
          checkSwipeUp();
        }}
        style={
          props.type === "success"
            ? { backgroundColor: "rgba(76, 188, 134, 1)" }
            : props.type === "fail"
            ? { backgroundColor: "rgba(184, 44, 53, 1)" }
            : props.type === "warning"
            ? { backgroundColor: "rgba(233, 171, 94, 1)" }
            : { backgroundColor: "#FFFFF2" }
        }
        className={styles.mainClickable}
        id="alertMain"
        onClick={() => {
          props.onButtonClick && props.onButtonClick();
          removeAlert();
        }}
      >
        <div className={styles.IconDiv}>
          {props.type === "success" && <CheckMarkIcon />}
          {props.type === "fail" && <ExplanationMarkIcon />}
          {props.type === "warning" && <WarningIcon active />}
        </div>
        <p
          style={
            props.type === "neutral"
              ? { color: "#101010", marginLeft: "0" }
              : {}
          }
          className={styles.message}
        >
          {props.message}
        </p>

        <button
          onClick={() => {
            props.onButtonClick && props.onButtonClick();
          }}
          className={styles.button}
          style={
            props.type === "neutral"
              ? { backgroundColor: "#55504F", color: "#FFFFF2" }
              : {}
          }
        >
          {props.buttonText}
        </button>
      </div>
    );
  }

  return (
    <div
      onTouchStart={(e) => {
        touchstartY = e.changedTouches[0].screenY;
      }}
      onTouchEnd={(e) => {
        touchendY = e.changedTouches[0].screenY;
        checkSwipeUp();
      }}
      style={
        props.type === "success"
          ? { backgroundColor: "rgba(76, 188, 134, 1)" }
          : props.type === "fail"
          ? { backgroundColor: "rgba(184, 44, 53, 1)" }
          : { backgroundColor: "rgba(233, 171, 94, 1)" }
      }
      className={styles.main}
      id="alertMain"
    >
      <div className={styles.IconDiv}>
        {props.type === "success" && <CheckMarkIcon />}
        {props.type === "fail" && <ExplanationMarkIcon />}
        {props.type === "warning" && <WarningIcon active />}
      </div>
      <p className={styles.message}>{props.message}</p>
    </div>
  );
}
