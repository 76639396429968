import { useEffect, useState } from "react";
import { ConnectionAvailability } from "@dimensional-engineering/dimensional-models";

import styles from "./InteractionBar.module.css";

import { useAuth } from "@/context/AuthContext";
import CompareIcon from "@/components/shared/icons/CompareIcon";
import SmallProfileImage from "@/components/shared/SmallProfileImage/SmallProfileImage";
import useGetProfilePhoto from "@/helpers/useGetProfilePhoto";
import useGetPublicProfile from "@/helpers/useGetPublicProfile";
import { useModalContext } from "@/context/ModalContext";
import ContextSwitcherModal from "../../ContextSwitcherModal/ContextSwitcherModal";
import ComparisonModal from "../ComparisonModal/ComparisonModal";
import useConnectionStatus from "@/helpers/useConnectionStatus";
import { getUserScaleScore, traitPermissionStatus } from "../../utils";
import {
  PeriodicQuizInstagramIcon,
  PeriodicQuizSnapchatIcon,
} from "@/components/appHome/PeriodicQuizzes/PeriodicQuizShareIcons";
import ElementAndPatternSharedModal from "../../TraitsModals/ElementAndPatternSharedModal/ElementAndPatternSharedModal";
import { Mixpanel } from "@/helpers/mixpanel";

type Props = {
  type: "element" | "pattern" | "archetype";
  ownProfile: boolean;
  friendScore?: number | null | undefined;
  histogramData?: number[] | null;
  connectionAvailabilities?: ConnectionAvailability[] | null | undefined;
  isPremium?: boolean | null | undefined;
  slug: string;
  uid: string;
  score?: number | null | undefined;
};

export default function InteractionBar(props: Props) {
  const { user } = useAuth();

  const imageUrl = useGetProfilePhoto(props.uid);
  const publicProfile = useGetPublicProfile(props.uid);
  const friendStatus = useConnectionStatus(props.uid);
  const { setModalComponent } = useModalContext();
  const [status, setStatus] = useState<
    "close" | "deep" | "private" | "basic" | null
  >(null);

  useEffect(() => {
    if (!props.ownProfile && props.connectionAvailabilities) {
      setStatus(
        traitPermissionStatus(friendStatus, props.connectionAvailabilities)
      );
    } else {
      setStatus(null);
    }
  }, [friendStatus, props.connectionAvailabilities, props.ownProfile]);

  const notOwnAndNotArchetype =
    props.uid !== user?.uid && props.type !== "archetype";

  const isOwnAndNotArchetype =
    props.uid === user?.uid && props.type !== "archetype";

  return (
    <section className={styles.main}>
      <div className={styles.wrapper}>
        <div className={styles.leftSide}>
          <div
            onClick={() => {
              setModalComponent(
                <ContextSwitcherModal
                  elementName="ContextSwitcherModal"
                  type={props.type}
                  slug={props.slug}
                />
              );
            }}
            className={styles.column}
          >
            <SmallProfileImage
              imageUrl={imageUrl}
              publicProfile={publicProfile}
              noLink
            />
            <p className={styles.columnText}>Change user</p>
          </div>

          {notOwnAndNotArchetype && status === null && (
            <div
              className={styles.columnCompare}
              onClick={() => {
                setModalComponent(
                  <ComparisonModal
                    friendUid={props.uid as string}
                    slug={props.slug as string}
                    type={props.type}
                    friendScore={getUserScaleScore(
                      props.friendScore,
                      props.slug as string
                    )}
                    histogramData={props.histogramData}
                    isPremium={props.isPremium}
                  />
                );
              }}
            >
              <CompareIcon />
              <p className={styles.columnText}>Compare</p>
            </div>
          )}
        </div>

        {isOwnAndNotArchetype && (
          <div className={styles.rightSide}>
            <div className={styles.column}>
              <div className={styles.shareDiv}>
                <PeriodicQuizInstagramIcon
                  onClick={() => {
                    Mixpanel?.track("Shared to Social", {
                      type: props.type,
                      slug: props.slug,
                      score: props.score,
                      platform: "instagram",
                    });
                    setModalComponent(
                      <ElementAndPatternSharedModal
                        traitSlug={props.slug}
                        traitType={props.type}
                        type="instagram"
                      />
                    );
                  }}
                />
                <PeriodicQuizSnapchatIcon
                  onClick={() => {
                    Mixpanel?.track("Shared to Social", {
                      type: props.type,
                      slug: props.slug,
                      score: props.score,
                      platform: "Snapchat",
                    });
                    setModalComponent(
                      <ElementAndPatternSharedModal
                        traitSlug={props.slug}
                        traitType={props.type}
                        type="snapchat"
                      />
                    );
                  }}
                />
              </div>
              <p className={styles.columnText}>Share to</p>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}
