import { useEffect, useState } from "react";
import {
  GPTMessage,
  GPTMessageStatus,
  GPTRole,
} from "@dimensional-engineering/dimensional-models";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";

import styles from "./GoDeeperModal.module.css";

import GoDeeperFeedbackBar from "../GoDeeperFeedbackBar/GoDeeperFeedbackBar";
import { handleMessageUpvoteDownvote } from "../utils";
import { useAuth } from "@/context/AuthContext";
import { useAlertContext } from "@/context/AlertContext";
import Alert from "@/components/shared/Alerts/Alert";
import {
  DailyInisghtEmbeddedMessage,
  DimensionSummaryEmbeddedMessage,
} from "./EmbeddedMessages/EmbeddedMessages";
import EmbeddedFeedbackBar from "../EmbeddedFeedbackBar/EmbeddedFeedbackBar";
import { Mixpanel } from "@/helpers/mixpanel";

type Props = {
  conversationId: string | undefined | null;
  message: GPTMessage;
  onRetry: () => void;
  hasFeedbackBar: boolean;
  onRegnerate: () => void;
  hasConversationFeedback?: boolean;
  onStreamStart: () => void;
  onStreamComplete: () => void;
  previousMessageContent?: string | null | undefined;
};

export default function SystemMessage(props: Props) {
  const { user } = useAuth();
  const { setAlertComponent } = useAlertContext();

  const [streamMessage, setStreamMessage] = useState<boolean>(false);

  useEffect(() => {
    const now = Date.now();
    const createdOn = props.message.createdOn;

    if (now - createdOn < 10000 && props.message.role === GPTRole.assistant) {
      props.onStreamStart();
      setStreamMessage(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (props.message.status === GPTMessageStatus.error) {
    return (
      <div className={styles.errorDiv}>
        <p className={styles.errorMessage}>Something went wrong!</p>
        <button onClick={() => props.onRetry()} className={styles.errorBtn}>
          Retry
        </button>
      </div>
    );
  }

  if (props.message.dailyInsightMessageDetail) {
    return <DailyInisghtEmbeddedMessage message={props.message} />;
  }

  if (props.message.dimensionSummaryMessageDetail) {
    return <DimensionSummaryEmbeddedMessage message={props.message} />;
  }

  if (!props.message.content || props.message.role === GPTRole.system) {
    return <></>;
  }

  return (
    <div className={styles.systemMessageDiv}>
      {streamMessage ? (
        <StreamedMessage
          onComplete={() => props.onStreamComplete()}
          content={props.message.content}
        />
      ) : (
        <ReactMarkdown>{props.message.content}</ReactMarkdown>
      )}
      {props.hasFeedbackBar &&
        props.message.content &&
        props.message.role === GPTRole.assistant && (
          <GoDeeperFeedbackBar
            onCopy={() => {
              if (window.Android?.copyToClipboard && props.message.content) {
                window.Android?.copyToClipboard(props.message.content);
              }
              setAlertComponent(
                <Alert
                  elementName="Alert"
                  type="success"
                  message="Copied to clipboard."
                />,
                3000
              );
            }}
            onDownVote={() => {
              handleMessageUpvoteDownvote(
                0,
                props.message,
                user?.uid,
                props.conversationId
              );
              Mixpanel?.track("Chatbot | Assistant message feedback given", {
                score: "-1",
                body: props.message.content?.slice(0, 250),
                "previous user message": props.previousMessageContent?.slice(
                  0,
                  250
                ),
              });
            }}
            onUpvote={() => {
              Mixpanel?.track("Chatbot | Assistant message feedback given", {
                score: "1",
                body: props.message.content?.slice(0, 250),
                "previous user message": props.previousMessageContent?.slice(
                  0,
                  250
                ),
              });
              handleMessageUpvoteDownvote(
                1,
                props.message,
                user?.uid,
                props.conversationId
              );
            }}
            onRegenerate={() => {
              Mixpanel?.track(
                "Chatbot | Assistant message regenerate CTA clicked",
                { body: props.message.content?.slice(0, 250) }
              );
              props.onRegnerate();
            }}
            message={props.message}
          />
        )}
      {props.hasConversationFeedback && (
        <div className={styles.conversationFeedbackDiv}>
          <EmbeddedFeedbackBar />
        </div>
      )}
    </div>
  );
}

const StreamedMessage = ({
  content,
  onComplete,
}: {
  content: string;
  onComplete?: () => void;
}) => {
  const [displayedText, setDisplayedText] = useState("");

  useEffect(() => {
    setDisplayedText("");

    const delay = 5; // Adjust speed here

    const displayText = async () => {
      for (let i = 0; i < content.length; i++) {
        await new Promise((resolve) => setTimeout(resolve, delay));
        setDisplayedText((prev) => prev + content[i]);
      }
      onComplete?.();
    };

    displayText();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  return <ReactMarkdown>{displayedText}</ReactMarkdown>;
};
