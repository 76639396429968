import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import { useModalContext } from "@/context/ModalContext";
import { RootState } from "@/redux/store";
import CloseConnectionModal from "../components/connections/ConnectionRequestModal/CloseConnectionModal";
import DeepConnectionModal from "../components/connections/ConnectionRequestModal/DeepConnectionModal";
import ConnectionRequestModal from "../components/connections/ConnectionRequestModal/ConnectionRequestModal";

///when received a connection request

export default function useFriendRequestModalHook(props: {
  uid: string | undefined;
}) {
  const userState = useSelector((state: RootState) => state.user);
  const { setModalComponent } = useModalContext();

  const hasInitialized = useRef(false);

  useEffect(() => {
    if (props.uid && !hasInitialized.current) {
      hasInitialized.current = true; // Prevent double invocation
      if (userState.friendRequestRecievedUidsList.includes(props.uid)) {
        userState.friendRequestsRecieved?.forEach((req) => {
          if (
            req.isClose === true &&
            req.isDeep !== true &&
            !userState.closeFriendUidList.includes(props.uid ?? "")
          ) {
            setTimeout(() => {
              setModalComponent(
                <CloseConnectionModal
                  publicProfile={req.sender}
                  friendRequest={req}
                  elementName="ConnectionModal"
                />
              );
            }, 1000);
          } else if (
            req.isDeep === true &&
            !userState.deepFriendUidList.includes(props.uid ?? "")
          ) {
            setTimeout(() => {
              setModalComponent(
                <DeepConnectionModal
                  publicProfile={req.sender}
                  friendRequest={req}
                  elementName="ConnectionModal"
                />
              );
            }, 1000);
          } else if (!userState.friendUidList.includes(props.uid ?? "")) {
            setTimeout(() => {
              setModalComponent(
                <ConnectionRequestModal
                  publicProfile={req.sender}
                  friendRequest={req}
                  elementName="ConnectionModal"
                />
              );
            }, 1000);
          }
        });
      }
    }
  }, []);
}
