import {
  Archetype as ArchetypeModel,
  Pattern as PatternModel,
  Scale,
  TraitContent,
  PatternDiscovery,
  ElementDiscovery,
  ArchetypeDiscovery,
  SummaryTable as SummaryTableType,
  Archetype,
  PublicProfile,
  TraitIdentifier,
  ComparisonScore,
  SnapshotScore,
  DiscoveredStory,
  LlmContent,
  ComparisonSpectrum,
  StoryDiscoveryGate,
  ConnectionAvailability,
  SpectrumData,
  ClientNotificationData,
  ClientNotificationType,
  Comment,
  CommentReply,
  SharedPost,
  CommentThread,
  DiscoverablePeerStory,
  DiscoveredPeerStory,
} from "@dimensional-engineering/dimensional-models";
import { DocumentData, QueryDocumentSnapshot } from "firebase/firestore";
import { ReactNode } from "react";

export interface ExtendedScale extends Scale {
  traitContent: TraitContent;
}

export interface ExtendedPattern extends PatternModel {
  traitContent: TraitContent;
}

export interface ExtendedArchetype extends ArchetypeModel {
  traitContent: TraitContent;
}
export type TraitElement = ExtendedScale | ExtendedPattern | ExtendedArchetype;

export type TraitElementNotExtended = Scale | PatternModel | ArchetypeModel;

export type DiscoveryElement =
  | PatternDiscovery
  | ElementDiscovery
  | ArchetypeDiscovery
  | null;

export type TabIconModel = {
  active?: boolean;
};

export interface DimensionAssessmentModel {
  slug: string;
  title: string;
  headline: string;
  description: string;
  longDescription: string;
  order: number;
  hasArchetype?: boolean;
  estimatedTime: number;
  isPrivate: boolean;
  numElements: number;
  archetypeGroupSlugs?: string[];
}

export type DimensionIconAssessmentStatus = {
  inactive?: boolean;
  dark?: boolean;
  maxWidth?: boolean;
};

type ArchetypeDataModel = {
  dimensionSlug: string;
  archetypeScore: number;
  archetypeSlug: string;
  archetype: Archetype;
};

export type UseGetProfileDataResult = {
  summaryTableData: SummaryTableType | null;
  adjectivesData: string[] | null;
  archetypeData: ArchetypeDataModel[] | null;
  nutshell: string | undefined;
};

export type PropsWithChildren<P> = P & { children?: ReactNode };

export enum NotificationConfig {
  Essentials = "essentials",
  Basic = "basic",
  Plus = "plus",
  All = "all",
  Off = "off",
}

export enum NotificationLevel {
  essentials = "essentials",
  basic = "basic",
  everything = "everything",
  off = "off",
}

export type DynamicProfileResultData = {
  summaryTableData: SummaryTableType | null;
  archetypeData: ArchetypeDataModel[] | null;
  archetypeLoading: boolean;
  summaryLoading: boolean;
  publicProfile: PublicProfile | null;
  highestScoringTraits: HighestScoringTraitsModel | undefined | null;
  stories?: DiscoveredStory[];
  storiesLoading?: boolean;
  nutshellLoading: boolean;
  nutshell: string | undefined;
};

export type PrivateMemberProfile = {
  age: number | undefined;
  birthdate: number | undefined;
  gender: string;
  shareHash: string | undefined;
  signUpDate: number;
  badgeCount: number | undefined;
  hasCompletedAllAssessments: boolean | undefined;
  hasDismissedTypeformSurveyCTA: boolean | undefined;
  isPremium: boolean | undefined;
  phoneString: string | undefined;
  verifiedPhoneNumber: string | undefined;
  recommendeeUids: string[] | undefined | [] | null;
  recommenderUids?: string[] | [] | null | undefined;
  peerAssesseeUids?: string[] | null | undefined;
  notificationConfig?: NotificationConfig;
  isAdmin?: boolean;
  contactListMap?: Record<string, boolean>;
  selectedNotificationLevel?: NotificationLevel | null | undefined;
  lifetimeGPTTokensConsumed: number | null | undefined;
};

export interface FriendsPublicProfile extends PublicProfile {
  grantedOn?: number;
  isClose?: boolean;
  isDeep?: boolean;
  imageUrl: string;
  isPremium?: boolean | undefined | null;
}

export interface UserAccessMap {
  [uid: string]: {
    isClose: boolean;
    grantedOn: number;
  };
}

export interface HighestScoringTraitsModel {
  peerRated: TraitIdentifier[];
  selfRated: TraitIdentifier[];
}

export interface SimilarDifferentTraitsModel {
  highSimilarityTraitIdentifiers: TraitIdentifier[];
  lowSimilarityTraitIdentifiers: TraitIdentifier[];
}

export type SimilarityStory = {
  isTimeGated: boolean;
  storyType: "thematicSimilarity";
  discoveryGate: {
    requiredDimensionSlugs: string[];
  };
  name: string;
  storySlug: string;
  similarityGroupSlug: string;
  contentAvailabilities: "close" | "basic";
};

type SimilarityScore = {
  currentUserScore: number;
  friendScore: number | null;
  trait: {
    traitType: "element";
    traitName: string;
    traitSlug: string;
    dimensionSlugs: string[];
  }[];
};

type DifferenceScore = {
  currentUserScore: number;
  friendScore: number | null;
  trait: {
    traitType: "element";
    traitName: string;
    traitSlug: string;
    dimensionSlugs: string[];
  }[];
};

export type DiscoveredSimilarityStory = {
  storySlug: string;
  name: string;
  storyType: "thematicSimilarity";
  isUnread: boolean;
  createdOn: number;
  updatedOn: number;
  storyDetail: {
    thematicSimilarity: {
      score: number;
      similarityNouns: string[];
      differenceNouns: string[];
      similarityScores: ComparisonScore[];
      differenceScores: ComparisonScore[];
      name: string;
    };
  };
  isTimeGated: boolean;
  discoveryGate: Record<string, never>;
};

export type SnapshotSummary = {
  snapshotSummary: {
    work: SnapshotScore[];
    motivation: SnapshotScore[];
    love: SnapshotScore[];
    identity: SnapshotScore[];
  };
};

export type LlmVennDiagram = {
  myAdjectives: string[];
  connectionAdjectives: string[];
  intersectAdjectives: string[];
};

export type RomanticCompatibilityReport = {
  compatibilityData:
    | {
        overall: number | null | undefined;
        sexuality: number | null | undefined;
        loveStyles: number | null | undefined;
        personality: number | null | undefined;
        lifestyle: number | null | undefined;
      }
    | null
    | undefined;
  summaryLlmString?: string | null | undefined;
  likelyDynamicLlmString?: string | null | undefined;
  llmVennDiagram?: LlmVennDiagram | null | undefined;

  // pt 2 data
  howYouVibeLlmContent?: LlmContent[] | null | undefined;

  // pt 3 data
  howYouClashLlmContent?: LlmContent[] | null | undefined;

  // pt 4 data
  sexAndSensualitySpectrums: ComparisonSpectrum[];
  sexAndSensualityLlmContent?: LlmContent[] | null | undefined;

  // pt 5 datya
  emotionsAndIntellectSpectrums: ComparisonSpectrum[];
  emotionsAndIntellectLlmContent?: LlmContent[] | null | undefined;

  // pt 6 data
  lifestyleAndInterestsSpectrums: ComparisonSpectrum[];
  lifestyleAndInterestsIntersect: TraitIdentifier[];
  lifestyleAndInterestsLlmContent?: LlmContent[] | null | undefined;

  // pt 7 data
  energyAndExcitementSpectrums: ComparisonSpectrum[];
  energyAndExcitementLlmContent?: LlmContent[] | null | undefined;

  // pt 8 data
  planningAndOrganizationSpectrums: ComparisonSpectrum[];
  planningAndOrganizationLlmContent?: LlmContent[] | null | undefined;

  // pt 9 data
  loveStylesAndAttitudesLlmContent?: LlmContent[] | null | undefined;

  // pt 10 data
  myTipsForSuccess?: LlmContent[];
  connectionTipsForSuccess?: LlmContent[];

  // misc data
  requesterUid: string;
  requesteeUid: string;
  connectionuid: string;
};

export interface WorkCompatibilityReport {
  // non-llm data
  spectrumData: ComparisonSpectrum[];

  // llm data
  mySummaryLlmContent: LlmContent[];
  connectionSummaryLlmContent: LlmContent[];
  likelyDynamicLlmString: string;
  llmVennDiagram: LlmVennDiagram; // see below; this model already exists
  complementaryTraitsLlmContent: LlmContent[];
  incompatibleTraitsLlmContent: LlmContent[];
  pairStrengthsLlmContent: LlmContent[];
  pairDeficienciesLlmContent: LlmContent[];
  myTipsForSuccess: LlmContent[];
  connectionTipsForSuccess: LlmContent[];

  // new field
  yourDynamicLlmContent: LlmContent[];

  // meta fields
  requesterUid: string;
  requesteeUid: string;
  connectionUid: string;
  createdOn: number;
  llmRequestId?: string | null | undefined;
}

export type GeneraFilterType = "ALL" | "IDENTITY" | "LOVE" | "WORK";

export type RevenueCatSubscription = {
  request_date: string;
  request_date_ms: number;
  subscriber: {
    entitlements: {
      premium: {
        expires_date: string;
        grace_period_expires_date: string | null;
        product_identifier: string;
        purchase_date: string;
      };
    };
    first_seen: string;
    last_seen: string;
    management_url: string | null;
    non_subscriptions: Record<string, unknown>;
    original_app_user_id: string;
    original_application_version: string | null;
    original_purchase_date: string | null;
    other_purchases: Record<string, unknown>;
    subscriptions: {
      [key: string]: {
        auto_resume_date: string | null;
        billing_issues_detected_at: string | null;
        expires_date: string;
        grace_period_expires_date: string | null;
        is_sandbox: boolean;
        original_purchase_date: string;
        period_type: string;
        purchase_date: string;
        refunded_at: string | null;
        store: string;
        store_transaction_id: string;
        unsubscribe_detected_at: string | null;
      };
    };
  };
};

export interface CompatibilityData {
  name: string;
  score?: number | null | undefined;
  discoveryGate?: StoryDiscoveryGate | null | undefined;
  connectionAvailabilities?: ConnectionAvailability[] | null | undefined;
  slug?: string | null | undefined;
  compatibilitySnippetSlug?: string | null | undefined; // Relationship to Compatibility Snippet
}

export interface ProfileCompatibilityData {
  comparisonBars: CompatibilityData[];
}

export interface SpectrumDataWithSlug {
  slug: string;
  spectrumData: SpectrumData[];
}

enum NewClientNotificationType {
  chatMessage = "chatMessage",
  viewDeliveredDailyStoryNew = "viewDeliveredDailyStoryNew",
  postLiked = "postLiked",
  connectionPostCreated = "connectionPostCreated",
  postCommented = "postCommented",
  viewSharedPost = "viewSharedPost",
  viewCommunityQuestion = "viewCommunityQuestion",
}

export type ExtendedClientNotificationType =
  | ClientNotificationType
  | NewClientNotificationType;

export interface ExtendedClientNotificationData extends ClientNotificationData {
  participantUids?: string | null | undefined;
}

export type DimensionIconProps = {
  active?: boolean;
  maxWidth?: boolean;
};

export type HighestScoringTraitsData = {
  selfRated: TraitIdentifier[];
  peerRated: TraitIdentifier[];
};

export interface CommentWithReply extends Comment {
  replies?: CommentReply[];
  lastReply?: QueryDocumentSnapshot<DocumentData> | null;
}

export interface CommentVoteSimplified {
  commentId: string;
  replyId?: string | null | undefined;
  voteScore: number;
}

export interface SharedPostWithCommentThread extends SharedPost {
  commentThread?: CommentThread | null | undefined;
}

export type ExtendedPeerStory = DiscoverablePeerStory & DiscoveredPeerStory;

export type PeerStoriesGating =
  | false
  | ConnectionAvailability.private
  | ConnectionAvailability.unconnected
  | ConnectionAvailability.basic
  | ConnectionAvailability.close
  | ConnectionAvailability.deep
  | "discoveryGate"
  | "nudge";

export interface ExtendedPeerStoryGated extends ExtendedPeerStory {
  gating: PeerStoriesGating;
}

export interface ReadOnlyPrivateProfile {
  numGPTMessagesPurchased?: number | null | undefined;
  lifetimeGPTMessagesSent?: number | null | undefined;
  lifetimeNonPremiumGPTMessagesSent: number | null | undefined;
}
