import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  DeliveryStatus,
  DimensionEventStatus,
} from "@dimensional-engineering/dimensional-models";
import { Roboto_Mono } from "next/font/google";

import styles from "./DailyStories.module.css";

import { RootState } from "@/redux/store";
import { useGetDailyInsightTimerText } from "../DailyInsights/DailyInsightsCTABlock/DailyInsightsCTABlock";
import WarningIcon from "@/components/shared/GhostBox/WarningIcon";
import DailyContentStatusBar from "../DailyContentStatusBar/DailyContentStatusBar";

const font = Roboto_Mono({ subsets: ["latin"] });

export default function DailyStoriesGhostbox() {
  const dailyStory = useSelector((state: RootState) => state.dailyStory);

  const ghostBoxText = useGetGHostboxMessage();

  const time = useGetDailyInsightTimerText(
    dailyStory.storyDelivery?.validUntil
  );

  return (
    <section
      style={{
        backgroundImage: `linear-gradient(rgba(37, 0, 0, 0) 54.69%, rgba(6, 0, 0, 0.5) 100%), url(/dailyStories/${dailyStory.storyDelivery?.computeableStoryModelSlug}.jpg)`,
      }}
      className={styles.ghostboxMain}
    >
      <p className={styles.storyDay}>DAILY STORY</p>
      <h5 className={`${styles.storyTitle} ${font.className}`}>
        {dailyStory.storyDelivery?.name.toUpperCase() + ":"}
      </h5>
      <div className={styles.ghostboxMid}>
        <div className={styles.warningIconDiv}>
          <WarningIcon active />
        </div>
        <p className={styles.ghostBoxText}>{ghostBoxText}</p>
      </div>

      <div className={styles.bottomGHostbox}>
        <DailyContentStatusBar
          time={"Next story coming soon"}
          audienceNumber={0}
          commentCount={0}
          onCommentClick={() => {}}
          inactive
        />
      </div>
    </section>
  );
}

function useGetGHostboxMessage() {
  const [text, setText] = useState<string>("");

  const userState = useSelector((state: RootState) => state.user);
  const storyDelivery = useSelector(
    (state: RootState) => state.dailyStory
  ).storyDelivery;

  useEffect(() => {
    setText("");
    let dimensionsCompleted = 0;
    userState.dimensionEvents?.forEach((e) => {
      if (
        e.event === DimensionEventStatus.complete &&
        e.dimensionSlug !== "coping-style"
      ) {
        dimensionsCompleted += 1;
      }
    });
    if (dimensionsCompleted === 14) {
      //all dimensions completed
      setText("No story available today. Check back again tomorrow!");
    } else {
      setText(
        "No story available today. Complete more Dimensions to unlock more stories."
      );
    }
    if (storyDelivery?.status === DeliveryStatus.processing) {
      setText("Story generating. We'll notify you when it's ready!");
    }
  }, [storyDelivery?.status, userState.dimensionEvents]);

  return text;
}
