import { useSelector } from "react-redux";

import styles from "./AuthHeader.module.css";

import MobileSearchPage from "@/components/publicProfiles/Header/MobileSearchPage";
import NewSearchIcon from "../icons/NewSearchIcon";
import { useModalContext } from "@/context/ModalContext";
import { NewMessageIcon } from "@/components/Messaging/MessagingIcons";
import MessageSelectorModal from "@/components/Messaging/MessageSelectorModal/MessageSelectorModal";
import { FriendsPublicProfile } from "@/models/sharedModels";
import SmallProfileImage from "../SmallProfileImage/SmallProfileImage";
import BackButton from "../buttons/BackButton";
import { RootState } from "@/redux/store";
import Link from "next/link";
import TabSettingsIcon from "../GlobalTabBar/icons/TabSettingsIcon";

type Props = {
  title: string;
  underline: boolean;
  downArrow?: boolean;
  showSettingIcon?: boolean;
};

export default function ResultsHeader(props: Props) {
  const { setModalComponent, setModalOpen } = useModalContext();
  return (
    <div
      style={props.underline ? { borderBottom: "3px solid #464542" } : {}}
      className={styles.mainResults}
    >
      {props.downArrow !== undefined && (
        <div
          className={props.downArrow ? styles.backDivDown : styles.backDivSmall}
          style={{ top: "41px" }}
        >
          <BackButton step={true} onBack={() => setModalOpen(false)} />
        </div>
      )}

      {props.showSettingIcon && (
        <Link href={"/app/settings"} className={styles.iconDiv}>
          <TabSettingsIcon active />
        </Link>
      )}

      <h1>{props.title}</h1>

      <div
        onClick={() => {
          setModalComponent(<MobileSearchPage />);
        }}
        className={styles.resultsSearchIconDiv}
      >
        <NewSearchIcon />
      </div>
    </div>
  );
}

export function MessagesHeader() {
  const { setModalComponent } = useModalContext();
  return (
    <div
      style={{ borderBottom: "3px solid #464542" }}
      className={styles.mainResults}
    >
      <h1>Messages</h1>
      <div
        onClick={() => {
          setModalComponent(<MessageSelectorModal />);
        }}
        style={{ top: "36px" }}
        className={styles.resultsSearchIconDiv}
      >
        <NewMessageIcon />
      </div>
    </div>
  );
}

export function InChatMessagesHeader(props: {
  publicProfile: FriendsPublicProfile | null;
}) {
  const userState = useSelector((state: RootState) => state.user);

  if (!props.publicProfile) {
    return <></>;
  }
  const isClose = userState.closeFriendUidList.includes(
    props.publicProfile.ownerUUID
  );
  const isDeep = userState.deepFriendUidList.includes(
    props.publicProfile.ownerUUID
  );
  return (
    <div
      style={{
        borderBottom: "2px solid #464542",
        position: "sticky",
        top: "0",
        zIndex: "20000000000",
        backgroundColor: "#181716",
      }}
      className={styles.mainResults}
    >
      <div className={styles.chatBackButtonDiv}>
        <BackButton />
      </div>
      <div className={styles.chatMessageTitleDiv}>
        <SmallProfileImage
          noLink
          imageUrl={props.publicProfile?.imageUrl ?? ""}
          publicProfile={props.publicProfile}
        />
        <div className={styles.infoDiv}>
          <div className={styles.namesDiv}>
            <p className={styles.name}>{props.publicProfile?.name}</p>
            <p className={styles.username}>@{props.publicProfile?.userName}</p>
          </div>
          <p className={styles.connectionType}>
            {isDeep
              ? "Deep connection"
              : isClose
              ? "Close connection"
              : "Basic connection"}
          </p>
        </div>
      </div>
    </div>
  );
}
