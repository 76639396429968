import { useEffect, useState } from "react";

import styles from "./AuthRightBar.module.css";

import SwipeTabs from "@/components/shared/SwipeTabs/SwipeTabs";
import { DynamicProfileResultData } from "@/models/sharedModels";
import { AuthCompareTabModal } from "../tabs/AuthCompareTab/AuthCompareTab";
import useConnectionStatus from "@/helpers/useConnectionStatus";
import { AuthAboutTabConnectedModal } from "../tabs/AuthAboutTab/AuthAboutTabConnected";

type PropsModal = {
  data: DynamicProfileResultData;
  uid: string;
  imageUrl?: string;
  peerStorySlug?: string;
  isConnectionPremium: boolean;
};

export default function AuthRightBarConnected(props: PropsModal) {
  const connectionType = useConnectionStatus(props.uid);
  const [currentTab, setCurrentTab] = useState<string | null>(null);

  useEffect(() => {
    const currentTabLS = localStorage.getItem("currentTab");
    const tabUid = localStorage.getItem("currentTabUid");
    if (
      currentTabLS &&
      tabUid &&
      tabUid === props.data.publicProfile?.ownerUUID
    ) {
      setCurrentTab(currentTabLS);
      localStorage.removeItem("currentTab");
    }
  }, [props.data.publicProfile?.ownerUUID, props.uid]);

  useEffect(() => {
    if (props.peerStorySlug) {
      setCurrentTab("Compare");
    }
  }, [props.peerStorySlug]);

  return (
    <div id="rightBarMain" className={styles.main}>
      <SwipeTabs
        tabs={["About", "Compare"]}
        content={[
          <AuthAboutTabConnectedModal
            data={props.data}
            key={1}
            imageUrl={props.imageUrl}
            connection={connectionType}
            uid={props.uid}
          />,
          <AuthCompareTabModal
            otherPublicProfile={props.data.publicProfile}
            imageUrl={props.imageUrl}
            key={2}
            data={props.data}
            uid={props.uid}
            connectionType={connectionType}
            peerStorySlug={props.peerStorySlug}
            isConnectionPremium={props.isConnectionPremium}
          />,
        ]}
        grow={true}
        onProfile
        initialTab={currentTab ?? "About"}
        tabsWithBlueDot={["Compare"]}
        sticky
      />
    </div>
  );
}
