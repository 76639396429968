import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Roboto_Mono } from "next/font/google";
import removeMarkdown from "markdown-to-text";
import moment from "moment";
import {
  DeliveredDailyInsight,
  DeliveryStatus,
  DimensionEventStatus,
} from "@dimensional-engineering/dimensional-models";

import styles from "./DailyInsightsCTABlock.module.css";

import { RootState } from "@/redux/store";
import elementsJsonData from "../../../../assets/traitInfo/elements.json";
import { natureData } from "@/assets/traitInfo/natureData";
import {
  DailtInsightIconEmptyVariant,
  DailyInsightIconActiveVariant,
} from "../DailyInsightsIcon";
import { formatDailyInsightTime } from "../utils";
import { useModalContext } from "@/context/ModalContext";
import DailyInsightsModal from "../DailyInsightsModal/DailyInsightsModal";
import WarningIcon from "@/components/shared/GhostBox/WarningIcon";
import DailyContentStatusBar from "../../DailyContentStatusBar/DailyContentStatusBar";
import { Mixpanel } from "@/helpers/mixpanel";
import Shimmer from "@/components/shared/Shimmer/Shimmer";

const font = Roboto_Mono({ subsets: ["latin"] });

export default function DailyInsightsCTABlock() {
  const { setModalComponent } = useModalContext();

  const dailyInsight = useSelector(
    (state: RootState) => state.dailyInsight
  ).deliveredDailyInsight;
  const insightDelivery = useSelector(
    (state: RootState) => state.dailyInsight
  ).insightDelivery;
  const commentThread = useSelector(
    (state: RootState) => state.dailyInsight
  ).commentThread;
  const dailyInsightLoaded = useSelector(
    (state: RootState) => state.dailyInsight
  ).dailyInsightLoaded;

  const time = useGetDailyInsightTimerText(insightDelivery?.validUntil);

  const colors = useGetDailyInsightIconColors();

  const body = removeMarkdown(dailyInsight?.assignmentRuleset?.body ?? "");

  const ghostBoxText = useGetGHostboxMessage();

  const commentCountRoot = commentThread?.rootCommentCount ?? 0;
  const commentCountReplies = commentThread?.replyCount ?? 0;

  if (!dailyInsightLoaded) {
    return (
      <section
        style={{ height: "350px", padding: "0" }}
        className={styles.main}
      >
        <Shimmer />
      </section>
    );
  }

  if (dailyInsight === null || !body) {
    return (
      <section className={styles.main}>
        <DailtInsightIconEmptyVariant />
        <h4 className={`${styles.personalInsightText} ${font.className}`}>
          PERSONAL INSIGHT
        </h4>
        <h3 className={styles.insightName}>{insightDelivery?.name}</h3>
        <div className={styles.ghostBoxDivParent}>
          <DailyInsightGhostbox message={ghostBoxText} />
        </div>
        <div className={styles.bottom}>
          <DailyContentStatusBar
            time={"Next insight coming soon!"}
            audienceNumber={commentThread?.audienceUids?.length ?? 0}
            commentCount={commentCountRoot + commentCountReplies}
            onCommentClick={() => {}}
            inactive
          />
        </div>
      </section>
    );
  }

  const LSDailyInsightViewed = localStorage.getItem(
    "dailyInsightViewedCreatedOn"
  );
  const isViewed = LSDailyInsightViewed
    ? LSDailyInsightViewed === dailyInsight.deliveredOn?.toString()
    : false;

  return (
    <section
      onClick={() => {
        localStorage.setItem(
          "dailyInsightViewedCreatedOn",
          dailyInsight.deliveredOn?.toString()
        );
        setModalComponent(
          <DailyInsightsModal elementName="DailyInsightsModal" />
        );
      }}
      className={styles.main}
      style={isViewed ? {} : { border: "1px solid #2CB0FF" }}
    >
      <h4 className={`${styles.personalInsightText} ${font.className}`}>
        INSIGHT | {dailyInsight?.name?.toUpperCase()}
      </h4>
      <DailyInsightIconActiveVariant colors={colors} />
      <p className={styles.body}>{body}</p>
      <div className={styles.bottom}>
        <DailyContentStatusBar
          inactive
          time={time === "0h:0m:0s" ? "Disappears soon!" : time}
          audienceNumber={commentThread?.audienceUids?.length ?? 0}
          commentCount={commentCountRoot + commentCountReplies}
          onCommentClick={() => {
            Mixpanel?.track("Comment modal summoned", {
              source: "daily insight",
              audience_group_size: commentThread?.audienceUids?.length ?? 0,
              type: "from dashboard",
            });
            setModalComponent(
              <DailyInsightsModal
                showComment
                elementName="DailyInsightsModal"
              />
            );
          }}
        />
      </div>
    </section>
  );
}

export function useGetDailyInsightIconColors() {
  const dailyInsight = useSelector(
    (state: RootState) => state.dailyInsight
  ).deliveredDailyInsight;
  const [colors, setColors] = useState<string[]>([]);

  useEffect(() => {
    setColors([]);
    let mappedColorHit = {
      opennessAndIntellect: {
        primary: false,
        secondary: true,
      },
      warmthAndAgreeableness: {
        primary: false,
        secondary: true,
      },
      chaos: {
        primary: false,
        secondary: true,
      },
      energyAndExcitement: {
        primary: false,
        secondary: true,
      },
      tranquility: {
        primary: false,
        secondary: true,
      },
      selfEnhancement: {
        primary: false,
        secondary: true,
      },
      orderAndStructure: {
        primary: false,
        secondary: true,
      },
      identityArchetype: {
        primary: false,
        secondary: true,
      },
    };

    dailyInsight?.assignmentRuleset.scaleRules.forEach((rule) => {
      let scale = elementsJsonData?.find(
        (extendedEl) => extendedEl.slug === rule.traitSlug
      );
      let nature = scale?.nature;
      if (scale && nature) {
        const foundNature = natureData?.find((n) => n.slug === nature);
        let scaleColor = "gray";
        if (foundNature) {
          const natureSlug = foundNature.slug;
          if (
            mappedColorHit[natureSlug as keyof typeof mappedColorHit]
              .primary === false
          ) {
            scaleColor = foundNature.color;
            mappedColorHit[natureSlug as keyof typeof mappedColorHit].primary =
              true;
            mappedColorHit[
              natureSlug as keyof typeof mappedColorHit
            ].secondary = false;
          } else {
            scaleColor = foundNature.secondaryColor;
            mappedColorHit[natureSlug as keyof typeof mappedColorHit].primary =
              false;
            mappedColorHit[
              natureSlug as keyof typeof mappedColorHit
            ].secondary = true;
          }
        }
        setColors((current) => [...current, scaleColor]);
      } else {
        setColors((current) => [...current, "gray"]);
      }
    });
    if (
      dailyInsight?.assignmentRuleset.identityArchetypeSlug &&
      dailyInsight?.assignmentRuleset.identityArchetypeSlug !== ""
    ) {
      mappedColorHit.identityArchetype.primary = true;
      setColors((current) => [...current, "#928e8c"]);
    }
    if (
      dailyInsight?.assignmentRuleset.interactionArchetypeSlug &&
      dailyInsight?.assignmentRuleset.interactionArchetypeSlug !== ""
    ) {
      if (mappedColorHit.identityArchetype.primary === false) {
        setColors((current) => [...current, "#928e8c"]);
      } else {
        setColors((current) => [...current, "#e6e1df"]);
      }
    }
  }, [
    dailyInsight?.assignmentRuleset.identityArchetypeSlug,
    dailyInsight?.assignmentRuleset.interactionArchetypeSlug,
    dailyInsight?.assignmentRuleset.scaleRules,
  ]);

  return colors;
}

export function useGetDailyInsightIconColorsWithData(
  deliveredDailyInsight: DeliveredDailyInsight | null | undefined
) {
  const dailyInsight = deliveredDailyInsight;
  const [colors, setColors] = useState<string[]>([]);

  useEffect(() => {
    setColors([]);
    let mappedColorHit = {
      opennessAndIntellect: {
        primary: false,
        secondary: true,
      },
      warmthAndAgreeableness: {
        primary: false,
        secondary: true,
      },
      chaos: {
        primary: false,
        secondary: true,
      },
      energyAndExcitement: {
        primary: false,
        secondary: true,
      },
      tranquility: {
        primary: false,
        secondary: true,
      },
      selfEnhancement: {
        primary: false,
        secondary: true,
      },
      orderAndStructure: {
        primary: false,
        secondary: true,
      },
      identityArchetype: {
        primary: false,
        secondary: true,
      },
    };

    dailyInsight?.assignmentRuleset.scaleRules.forEach((rule) => {
      let scale = elementsJsonData?.find(
        (extendedEl) => extendedEl.slug === rule.traitSlug
      );
      let nature = scale?.nature;
      if (scale && nature) {
        const foundNature = natureData?.find((n) => n.slug === nature);
        let scaleColor = "gray";
        if (foundNature) {
          const natureSlug = foundNature.slug;
          if (
            mappedColorHit[natureSlug as keyof typeof mappedColorHit]
              .primary === false
          ) {
            scaleColor = foundNature.color;
            mappedColorHit[natureSlug as keyof typeof mappedColorHit].primary =
              true;
            mappedColorHit[
              natureSlug as keyof typeof mappedColorHit
            ].secondary = false;
          } else {
            scaleColor = foundNature.secondaryColor;
            mappedColorHit[natureSlug as keyof typeof mappedColorHit].primary =
              false;
            mappedColorHit[
              natureSlug as keyof typeof mappedColorHit
            ].secondary = true;
          }
        }
        setColors((current) => [...current, scaleColor]);
      }
    });
    if (
      dailyInsight?.assignmentRuleset.identityArchetypeSlug &&
      dailyInsight?.assignmentRuleset.identityArchetypeSlug !== ""
    ) {
      mappedColorHit.identityArchetype.primary = true;
      setColors((current) => [...current, "#928e8c"]);
    }
    if (
      dailyInsight?.assignmentRuleset.interactionArchetypeSlug &&
      dailyInsight?.assignmentRuleset.interactionArchetypeSlug !== ""
    ) {
      if (mappedColorHit.identityArchetype.primary === false) {
        setColors((current) => [...current, "#928e8c"]);
      } else {
        setColors((current) => [...current, "#e6e1df"]);
      }
    }
  }, [
    dailyInsight?.assignmentRuleset.identityArchetypeSlug,
    dailyInsight?.assignmentRuleset.interactionArchetypeSlug,
    dailyInsight?.assignmentRuleset.scaleRules,
  ]);

  return colors;
}

export function useGetDailyInsightTimerText(
  validUntil: number | null | undefined
) {
  const [time, setTime] = useState<string>("");

  useEffect(() => {
    const updateTime = () => {
      const now = moment();
      const created = moment(validUntil);
      const elapsedTimeInSeconds = created.diff(now, "seconds");
      const remainingTimeInSeconds = elapsedTimeInSeconds;
      const clampedRemainingTimeInSeconds = Math.max(remainingTimeInSeconds, 0);
      const transformedTime = formatDailyInsightTime(
        clampedRemainingTimeInSeconds
      );
      setTime(transformedTime);
    };

    const intervalId = setInterval(updateTime, 1000);

    return () => clearInterval(intervalId);
  }, [validUntil]);

  return time;
}

function DailyInsightGhostbox(props: { message: string }) {
  return (
    <div className={styles.ghostboxDiv}>
      <div className={styles.warningIconDiv}>
        <WarningIcon active />
      </div>
      <p className={styles.ghostboxMessage}>{props.message}</p>
    </div>
  );
}

function useGetGHostboxMessage() {
  const [text, setText] = useState<string>("");

  const userState = useSelector((state: RootState) => state.user);
  const insightDelivery = useSelector(
    (state: RootState) => state.dailyInsight
  ).insightDelivery;

  useEffect(() => {
    setText("");
    let dimensionsCompleted = 0;
    userState.dimensionEvents?.forEach((e) => {
      if (
        e.event === DimensionEventStatus.complete &&
        e.dimensionSlug !== "coping-style"
      ) {
        dimensionsCompleted += 1;
      }
    });
    if (dimensionsCompleted === 14) {
      //all dimensions completed
      setText("No insight available today. Check back again tomorrow!");
    } else {
      setText(
        "No insight available today. Complete more Dimensions to unlock more insights."
      );
    }
    if (insightDelivery?.status === DeliveryStatus.processing) {
      setText("Insight generating. We'll notify you when it's ready!");
    }
  }, [insightDelivery?.status, userState.dimensionEvents]);

  return text;
}
