import { useEffect } from "react";
import { DimensionSummaryData } from "@dimensional-engineering/dimensional-models";

import styles from "./SignatureNonSignatureSummary.module.css";

import elementsJsonData from "../../../../assets/traitInfo/elements.json";
import { natureData } from "@/assets/traitInfo/natureData";

import NumberedElement from "@/components/traits/element/Element/NumberedElement";
import { Mixpanel } from "@/helpers/mixpanel";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import PremiumGatedResults from "@/components/premium/PremiumGatedResults/PremiumGatedResults";
import { getUserElementScore } from "@/components/authenticatedProfile/tabs/AuthTraitsTab/utils";
import Element from "@/components/traits/element/Element/Element";

type Props = {
  data: DimensionSummaryData;
  title: string;
  icon: JSX.Element;
  isPremium?: boolean;
  fromOutro?: boolean;
  nonNumbered?: boolean;
};

export default function SignatureNonSignatureSummary(props: Props) {
  const isUserPremium = useSelector((state: RootState) => state.user).isPremium;
  const discoveredElements = useSelector(
    (state: RootState) => state.user
  ).elementsDiscovered;

  useEffect(() => {
    Mixpanel?.track("Outro Signature Summary Viewed", {});
  }, []);

  if (props.isPremium && !isUserPremium && props.fromOutro) {
    return (
      <div className={styles.main}>
        <div style={{ marginTop: "130px" }}>
          <PremiumGatedResults />
        </div>
      </div>
    );
  }

  if (props.isPremium && !isUserPremium && !props.fromOutro) {
    return <></>;
  }

  return (
    <div className={styles.main}>
      {props.data.signatureElements &&
        props.data.signatureElements.length > 0 && (
          <h1 className={styles.titleSign}>
            YOUR TOP {props.title.toUpperCase()}
          </h1>
        )}
      <div
        className={
          props.nonNumbered ? styles.signatureNonNumbered : styles.signature
        }
      >
        {props.data.signatureElements?.map((el, index) => {
          let element = elementsJsonData?.find((elem) => elem.slug === el.slug);
          if (element) {
            return props.nonNumbered ? (
              <Element
                slug={element.slug}
                score={getUserElementScore(el.slug, discoveredElements)}
              />
            ) : (
              <NumberedElement
                key={index}
                name={element.alias ?? element.name}
                color={
                  natureData?.find((n) => n.slug === element?.nature)?.color ??
                  "gray"
                }
                number={index + 1}
                icon={props.icon}
                //@ts-ignore
                isPremium={element?.isPremium}
                score={getUserElementScore(el.slug, discoveredElements)}
              />
            );
          }
        })}
      </div>
      {props.data.nonSignatureElements &&
        props.data.nonSignatureElements?.length > 0 && (
          <h1 className={styles.titleOther}>
            OTHER {props.title.toUpperCase()}
          </h1>
        )}
      <div
        className={
          props.nonNumbered
            ? styles.nonSignatureNonNumbered
            : styles.nonSignature
        }
      >
        {props.data.nonSignatureElements?.map((el, index) => {
          let element = elementsJsonData?.find((elem) => elem.slug === el.slug);
          if (element) {
            return props.nonNumbered ? (
              <Element
                slug={element.slug}
                score={getUserElementScore(el.slug, discoveredElements)}
              />
            ) : (
              <NumberedElement
                key={index}
                name={element.alias ?? element.name}
                color={
                  natureData?.find((n) => n.slug === element?.nature)?.color ??
                  "gray"
                }
                number={index + 1}
                icon={props.icon}
                //@ts-ignore
                isPremium={element?.isPremium}
                score={getUserElementScore(el.slug, discoveredElements)}
              />
            );
          }
        })}
      </div>
    </div>
  );
}
