import { useEffect, useState } from "react";
import Link from "next/link";

import styles from "./OppositeArchetype.module.css";

import { ExtendedArchetype } from "@/models/sharedModels";
import archetypeJsonData from "../../../../../assets/traitInfo/archetypes.json";
import Archetype from "@/components/traits/arcehtype/Archetypes/Archetype";
import TraitsHeading from "../../Heading/TraitsHeading";
import TraitsWrapper from "../../Wrapper/Wrapper";
import { useModalContext } from "@/context/ModalContext";
import ArchetypeModal from "@/components/traits/TraitsModals/Archetype/ArchetypeModal";

type OppositeArchetypeProps = {
  archetype: ExtendedArchetype;
  authenticated?: boolean;
  uid?: string;
};

export default function OppositeArchetype(props: OppositeArchetypeProps) {
  const oppositeArchetype = useGetOppositeArchetype({
    archetype: props.archetype,
  });
  const { setModalComponent } = useModalContext();

  if (props.authenticated) {
    return (
      <TraitsWrapper>
        <TraitsHeading title="Opposite" />

        {oppositeArchetype !== null && (
          <>
            <h3 className={styles.h3}>
              {props.archetype.name} is the opposite of{" "}
              {oppositeArchetype?.name}
            </h3>
            <div className={styles.wrapper}>
              <Archetype archetype={props.archetype} />
              <div className={styles.arrowsDiv}>
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_32725_36632)">
                    <path
                      d="M26.0896 19.7048L34.9976 12.3688L26.0896 5.03601L26.0896 9.22521L5.00356 9.22521L5.00355 15.5128L26.0896 15.5128L26.0896 19.7048Z"
                      fill="#FFFFF2"
                    />
                    <path
                      d="M13.9084 34.964L5.00355 27.6296L13.9084 20.2952L13.9084 24.486L34.9976 24.486L34.9976 30.7736L13.9084 30.7736L13.9084 34.964Z"
                      fill="#FFFFF2"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_32725_36632">
                      <rect
                        width="40"
                        height="40"
                        fill="white"
                        transform="translate(40) rotate(90)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div
                onClick={() =>
                  setModalComponent(
                    <ArchetypeModal
                      slug={oppositeArchetype.slug}
                      uid={props.uid}
                    />
                  )
                }
                style={{ cursor: "pointer" }}
                key={oppositeArchetype.slug}
              >
                <div className="elBody">
                  <Archetype archetype={oppositeArchetype} />
                </div>
              </div>
            </div>
          </>
        )}
      </TraitsWrapper>
    );
  }

  return (
    <TraitsWrapper>
      <TraitsHeading title="Opposite" />

      {oppositeArchetype !== null && (
        <>
          <h3 className={styles.h3}>
            {props.archetype.name} is the opposite of {oppositeArchetype?.name}
          </h3>
          <div className={styles.wrapper}>
            <Archetype archetype={props.archetype} />
            <div className={styles.arrowsDiv}>
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_32725_36632)">
                  <path
                    d="M26.0896 19.7048L34.9976 12.3688L26.0896 5.03601L26.0896 9.22521L5.00356 9.22521L5.00355 15.5128L26.0896 15.5128L26.0896 19.7048Z"
                    fill="#FFFFF2"
                  />
                  <path
                    d="M13.9084 34.964L5.00355 27.6296L13.9084 20.2952L13.9084 24.486L34.9976 24.486L34.9976 30.7736L13.9084 30.7736L13.9084 34.964Z"
                    fill="#FFFFF2"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_32725_36632">
                    <rect
                      width="40"
                      height="40"
                      fill="white"
                      transform="translate(40) rotate(90)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <Link
              href={`/traits/archetypes/${oppositeArchetype.slug}`}
              key={oppositeArchetype.slug}
            >
              <div className="elBody">
                <Archetype archetype={oppositeArchetype} />
              </div>
            </Link>
          </div>
        </>
      )}
    </TraitsWrapper>
  );
}

function useGetOppositeArchetype(props: { archetype: ExtendedArchetype }) {
  const [oppositeArchetype, setOppositeArchetype] =
    useState<null | ExtendedArchetype>(null);

  useEffect(() => {
    if (props.archetype.traitContent.oppositeDetails?.oppositeTrait.slug) {
      let slug =
        props.archetype.traitContent.oppositeDetails?.oppositeTrait.slug;
      archetypeJsonData.forEach((arch) => {
        if (arch.slug === slug) {
          //@ts-ignore
          setOppositeArchetype(arch);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return oppositeArchetype;
}
