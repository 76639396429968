import { useEffect, useState } from "react";
import {
  ContentType,
  DiscoverablePeerStory,
  PublicProfile,
} from "@dimensional-engineering/dimensional-models";
import { AnyAction } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@/redux/store";

import styles from "./PeerStoriesModal.module.css";

import ModalWrapper from "@/components/shared/ModalWrapper/ModalWrapper";
import PeerStorySlide from "./PeerStorySlide";
import { ExtendedPeerStoryGated } from "@/models/sharedModels";
import CloseButtonSummary from "@/components/shared/CloseButtonSummary";
import { useModalContext } from "@/context/ModalContext";
import { useAlertContext } from "@/context/AlertContext";
import { callFirebaseFunctions } from "@/_firebase/callFirebaseFunctions";
import Alert from "@/components/shared/Alerts/Alert";
import DiscoveryGate from "@/components/shared/DiscoveryGate/DiscoveryGate";
import { computePeerStories } from "@/redux/slices/peerStoriesSlice";
import { Mixpanel } from "@/helpers/mixpanel";
import { handleGatingEnumForMixpanel } from "../utils";

type Props = {
  publicProfile: PublicProfile;
  story: ExtendedPeerStoryGated;
  discoverableStory: DiscoverablePeerStory;
};

export default function PeerStoriesModalSingle(props: Props) {
  const dispatch = useAppDispatch();

  const { setModalOpen, setModalComponent } = useModalContext();

  const { setAlertComponent } = useAlertContext();

  const [nudged, setNudged] = useState<boolean>(false);

  const requestTimestamps = useSelector(
    (state: RootState) => state.peerStories
  ).requestTimestamps;

  async function handleNudge(storySlug: string) {
    setNudged(true);
    const nudgeCall = callFirebaseFunctions("nudgeConnection");
    return await nudgeCall(
      JSON.stringify({
        connectionUid: props.publicProfile?.ownerUUID,
        contentIdentifier: {
          contentType: ContentType.peerStory,
          contentSlug: storySlug,
        },
      })
    )
      .then((res) => {
        return setAlertComponent(
          <Alert
            elementName="Alert"
            type="success"
            message={`Your nudge has been sent to @${props.publicProfile?.userName}.`}
          />,
          3000
        );
      })
      .catch((err) => {
        setAlertComponent(
          <Alert
            elementName="Alert"
            type="fail"
            message={`Failed to send nudge to connection. Please try again.`}
          />,
          3000
        );
        return setNudged(false);
      });
  }

  function handleIncompleteAssessments(story: ExtendedPeerStoryGated) {
    return setModalComponent(
      <DiscoveryGate
        onOpenShareLink={() => {}}
        requiredDimensionSlugs={story.requiredDimensionSlugs}
        onClose={() => setModalOpen(false)}
      />
    );
  }

  function handleGeneration(story: ExtendedPeerStoryGated) {
    const promptSlug = story.promptSlug;
    const connectionUid = props.publicProfile.ownerUUID;
    dispatch(
      computePeerStories({ promptSlug, connectionUid }) as unknown as AnyAction
    );
  }

  function handleRegenerate(story: ExtendedPeerStoryGated) {
    const currentTimestamp = Date.now();

    const lastRequestTimestamp =
      requestTimestamps[props.publicProfile.ownerUUID]?.[story.promptSlug] || 0;

    const isWithinLast24Hours =
      currentTimestamp - lastRequestTimestamp < 24 * 60 * 60 * 1000;

    if (isWithinLast24Hours) {
      return setAlertComponent(
        <Alert
          elementName="Alert"
          type="fail"
          message="You can only generate once an hour."
        />,
        3000
      );
    }

    return handleGeneration(story);
  }

  useEffect(() => {
    const state = handleGatingEnumForMixpanel(props.story.gating);
    Mixpanel?.track("Peer Story Viewed", {
      slug: props.story.slug,
      state: state,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.story.slug]);

  return (
    <ModalWrapper title="" noHeader noLine>
      <main className={styles.main}>
        <div
          onClick={() => {
            setModalOpen(false);
          }}
          className={styles.xmark}
        >
          <CloseButtonSummary />
        </div>
        <PeerStorySlide
          uid={props.publicProfile.ownerUUID}
          story={props.story}
          otherPublicProfile={props.publicProfile}
          nudged={nudged}
          handleNudge={() => handleNudge(props.story.slug)}
          handleGeneration={(s) => handleGeneration(s)}
          handleIncompleteAssessments={(s) => handleIncompleteAssessments(s)}
          handleRegenerate={(s) => handleRegenerate(s)}
          handleSlideClick={(side) => {}}
        />
      </main>
    </ModalWrapper>
  );
}
