import { useEffect, useRef, useState } from "react";

import styles from "./ChatBotTextBox.module.css";

type Props = {
  setText: (message: string) => void;
  onSend: () => void;
  disabled: boolean;
  focus?: boolean;
};

export default function ChatBotTextBox(props: Props) {
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  const [rows, setRows] = useState(1);
  const [text, setText] = useState<string>("");

  const maxRows = 4;

  const handleInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const textareaLineHeight = 24;
    const previousRows = e.target.rows;
    e.target.rows = 1;

    const currentRows = Math.floor(e.target.scrollHeight / textareaLineHeight);

    if (currentRows === previousRows) {
      e.target.rows = currentRows;
    }

    if (currentRows >= maxRows) {
      e.target.rows = maxRows;
      e.target.scrollTop = e.target.scrollHeight;
    }

    setRows(currentRows < maxRows ? currentRows : maxRows);

    const newValue = e.target.value;

    setText(newValue);
    props.setText(newValue);
  };

  useEffect(() => {
    // const timer = setTimeout(() => textAreaRef.current?.focus(), 500);
    const timer = setTimeout(() => {
      if (props.focus) {
        textAreaRef.current?.focus();
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [textAreaRef, props.focus]);

  return (
    <div className={styles.textAreaDiv}>
      <textarea
        className={styles.textArea}
        rows={rows}
        value={text}
        ref={textAreaRef}
        placeholder="Message"
        onChange={handleInput}
        disabled={props.disabled}
      />
      {text !== "" && !props.disabled && (
        <div
          onClick={() => {
            props.onSend();
            setText("");
            setRows(1);
          }}
          style={
            rows === 2
              ? { top: "16px" }
              : rows === 3
              ? { top: "25px" }
              : rows === 4
              ? { top: "34px" }
              : { top: "6px" }
          }
          className={styles.sendButtonParent}
        >
          <TextBoxSendButton />
        </div>
      )}
    </div>
  );
}

function TextBoxSendButton() {
  return (
    <div className={styles.sendButtonDiv}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
      >
        <path
          d="M14.3876 9.00002C14.0311 8.99932 13.6894 8.85729 13.437 8.60557L10.3361 5.50417V15.7361C10.3488 16.0982 10.2145 16.4505 9.96276 16.712C9.71105 16.9729 9.36371 17.1205 9.00088 17.1205C8.63805 17.1205 8.29143 16.9729 8.03972 16.712C7.788 16.4505 7.653 16.0982 7.66636 15.7361V5.50417L4.56352 8.60557C4.03688 9.13151 3.18398 9.13151 2.65804 8.60487C2.1321 8.07823 2.1321 7.22533 2.65874 6.69939L8.05874 1.31343V1.31413C8.31045 1.06172 8.65289 0.919678 9.00936 0.919678C9.36583 0.919678 9.70756 1.06171 9.95927 1.31413L15.3593 6.70009V6.69939C15.7467 7.0875 15.8606 7.6718 15.6469 8.17665C15.4338 8.68149 14.936 9.00706 14.3876 9.00002Z"
          fill="#2D2927"
        />
      </svg>
    </div>
  );
}
