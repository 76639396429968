import { useSelector } from "react-redux";
import { Roboto_Mono } from "next/font/google";

import styles from "./UserPollsHomeCTA.module.css";

import { RootState } from "@/redux/store";
import PollIcon from "../PollIcon";
import { useModalContext } from "@/context/ModalContext";
import UserPollModal from "../UserPollModal/UserPollModal";
import UserPollResponseModal from "../UserPollResponseModal/UserPollResponseModal";

const font = Roboto_Mono({ subsets: ["latin"] });

export default function UserPollsHomeCTA() {
  const { setModalComponent } = useModalContext();

  const pollState = useSelector((state: RootState) => state.userPoll);

  if (!pollState.userPoll) {
    return <></>;
  }

  const mappedOptions = [...pollState.userPoll.pollOptions]
    .sort((a, b) => a.id - b.id)
    .map((option) => {
      return (
        <div
          style={
            pollState.userCurrentPollResponse?.selectedOption?.label ===
            option.label
              ? { border: "1px solid #2CB0FF" }
              : {}
          }
          className={styles.optionDiv}
          key={option.id}
        >
          <p className={styles.option}>{option.label}</p>
        </div>
      );
    });

  return (
    <div
      onClick={() => {
        if (
          pollState.userCurrentPollResponse &&
          pollState.userCurrentPollResponse.pollSlug ===
            pollState.userPoll?.slug
        ) {
          setModalComponent(
            <UserPollResponseModal elementName="UserPollResponseModal" />
          );
        } else {
          setModalComponent(<UserPollModal elementName="UserPollModal" />);
        }
      }}
      className={styles.main}
    >
      <PollIcon />
      <div className={styles.rightDiv}>
        <h3 className={`${font.className} ${styles.title}`}>POLL</h3>
        <p className={styles.body}>{pollState.userPoll.title}</p>
        <div className={styles.optionWrapper}>{mappedOptions.slice(0, 3)}</div>
      </div>
    </div>
  );
}
