import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";

import styles from "../../../styles/app/AppStories.module.css";
import "swiper/css";

import { storyColorMap } from "@/components/results/stories/storyColorMap";
import CloseButtonSummary from "@/components/shared/CloseButtonSummary";
import {
  replaceDescription,
  replaceTitle,
} from "@/components/results/stories/storiesTextFunctions";
import { useModalContext } from "@/context/ModalContext";
import ModalWrapper from "@/components/shared/ModalWrapper/ModalWrapper";

type Props = {
  storySlug: string;
};

export default function SwipableStoriesModal(props: Props) {
  const [mySwiperRef, setMySwiperRef] = useState<any>(null);
  const [index, setIndex] = useState<number>(0);

  const discoveredStories = useSelector(
    (state: RootState) => state.user.discoveredStories
  );
  const { setModalOpen } = useModalContext();

  const mappedStories = discoveredStories?.map((story) => {
    const title = story?.name.includes("|")
      ? replaceTitle(story?.name, false)
      : story?.name;

    const mappedDescription = story?.storyDetail?.listicle?.descriptors.map(
      (desc, index) => {
        return (
          <div className={styles.descDiv} key={index}>
            <div
              onClick={() => {
                setModalOpen(false);
              }}
              className={styles.xmark}
            >
              <CloseButtonSummary />
            </div>
            <div
              style={
                story.storySlug
                  ? {
                      backgroundColor:
                        storyColorMap[
                          story.storySlug as keyof typeof storyColorMap
                        ],
                    }
                  : {}
              }
              className={styles.circle}
            >
              <p
                style={{
                  backgroundImage: `url(/stories/${story.storySlug}.png)`,
                }}
              >
                {index + 1}
              </p>
            </div>
            <h4
              style={
                story.storySlug
                  ? {
                      color:
                        storyColorMap[
                          story.storySlug as keyof typeof storyColorMap
                        ],
                    }
                  : {}
              }
            >
              {desc.includes("|") ? replaceDescription(desc) : desc}
            </h4>
          </div>
        );
      }
    );

    return (
      <SwiperSlide key={story.storySlug}>
        <div
          style={{
            backgroundImage: `url(/stories/${story.storySlug}.png)`,
          }}
          className={styles.slugMain}
        >
          <h1
            style={
              story.storySlug
                ? {
                    color:
                      storyColorMap[
                        story.storySlug as keyof typeof storyColorMap
                      ],
                  }
                : {}
            }
          >
            {title}
          </h1>
          <div className={styles.slugMappedDiv}>{mappedDescription}</div>
        </div>
      </SwiperSlide>
    );
  });

  useEffect(() => {
    if (discoveredStories?.length) {
      for (let i = 0; i < discoveredStories?.length; i++) {
        if (discoveredStories[i].storySlug === props.storySlug) {
          setIndex(i);
        }
      }
    }
  }, [props.storySlug]);

  useEffect(() => {
    if (mySwiperRef) mySwiperRef?.slideTo(index);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index]);

  return (
    <ModalWrapper title="" noHeader>
      <div style={{ width: "100%" }}>
        <Swiper
          min-height={200}
          autoHeight={false}
          slidesPerView={1}
          touchReleaseOnEdges={true}
          scrollbar={{ draggable: true }}
          threshold={0}
          onSwiper={setMySwiperRef}
        >
          {mappedStories}
        </Swiper>
      </div>
    </ModalWrapper>
  );
}
