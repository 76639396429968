import { useRouter } from "next/router";

import styles from "./SecureYourAccountBlock.module.css";

import SecurityIcon from "./SecurityIcon";

export default function SecureYourAccountBlock(props: {
  onNotNow: () => void;
}) {
  const router = useRouter();

  function handleDismissal() {
    const now = Date.now();
    const oneDayFromNow = now + 1 * 24 * 60 * 60 * 1000;

    localStorage.setItem(
      "securityBlockDismissal",
      JSON.stringify(oneDayFromNow)
    );

    props.onNotNow();
  }

  return (
    <section className={styles.main}>
      <h2 className={styles.title}>Secure your account</h2>
      <div className={styles.iconDiv}>
        <SecurityIcon />
      </div>
      <p className={styles.midText}>
        Adding a phone will help you retrieve lost accounts and let friends find
        you in their contact list.
      </p>
      <button
        onClick={() => router.push("/app/settings/phone")}
        className={styles.button}
      >
        Add phone number
      </button>
      <button onClick={() => handleDismissal()} className={styles.notNowBtn}>
        Not now
      </button>
    </section>
  );
}
