import { DiscoveredCompatibilitySnippet } from "@dimensional-engineering/dimensional-models";

import styles from "../CompatibilitySnippets.module.css";

import { FriendsPublicProfile } from "@/models/sharedModels";
import { natureData } from "@/assets/traitInfo/natureData";

type Props = {
  snippet: DiscoveredCompatibilitySnippet | null;
  connection: FriendsPublicProfile | null;
};
export default function CompatibilityLlmNatureStatements(props: Props) {
  const mapped = props.snippet?.snippetDetail.llmNatureStatements?.map(
    (statement, index) => {
      const color = natureData?.find((n) => n.slug === statement.nature)?.color;
      return (
        <p
          className={styles.natureStatementUnordered}
          style={color ? { color: color } : { color: "#928e8c" }}
          key={index}
        >
          {statement.body}
        </p>
      );
    }
  );

  return (
    <div className={styles.spectrumsParent}>
      <div className={styles.unorderedNatureStatementWrapper}>{mapped}</div>
    </div>
  );
}
