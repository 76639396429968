import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { GPTConversation } from "@dimensional-engineering/dimensional-models";
import { ReadOnlyPrivateProfile } from "@/models/sharedModels";

type ChatbotSlice = {
  conversations: GPTConversation[];
  currentConversation: GPTConversation | null;
  conversationLoading: boolean;
  messageLoading: boolean;
  readOnlyPrivateProfile: ReadOnlyPrivateProfile | null;
  numberOfMessagesRemaining: number | "unlimited";
};

const initialState: ChatbotSlice = {
  conversations: [],
  currentConversation: null,
  conversationLoading: true,
  messageLoading: false,
  readOnlyPrivateProfile: null,
  numberOfMessagesRemaining: 0,
};

export const chatbotSlice = createSlice({
  name: "chatbot",
  initialState: initialState,
  reducers: {
    setConversations: (state, action: PayloadAction<GPTConversation[]>) => {
      return { ...state, conversations: action.payload };
    },
    setCurrentConversation: (
      state,
      action: PayloadAction<GPTConversation | null>
    ) => {
      // Update the current conversation
      state.currentConversation = action.payload;

      if (action.payload) {
        const index = state.conversations.findIndex(
          (convo) => convo.id === action.payload?.id
        );
        // If conversation exists, update it; otherwise, push a new one
        if (index !== -1) {
          state.conversations[index] = action.payload;
        } else {
          state.conversations.push(action.payload);
        }
      }
    },

    setConversationLoading: (state, action: PayloadAction<boolean>) => {
      return { ...state, conversationLoading: action.payload };
    },
    addConversation: (state, action: PayloadAction<GPTConversation>) => {
      state.conversations.unshift(action.payload);
    },
    setMessageLoading: (state, action: PayloadAction<boolean>) => {
      return { ...state, messageLoading: action.payload };
    },
    setReadOnlyPrivateProfile: (
      state,
      action: PayloadAction<ReadOnlyPrivateProfile | null>
    ) => {
      return { ...state, readOnlyPrivateProfile: action.payload };
    },
    setNumberOfMessagesRemaining: (
      state,
      action: PayloadAction<number | "unlimited">
    ) => {
      return { ...state, numberOfMessagesRemaining: action.payload };
    },
  },
});

export const {
  setConversations,
  setCurrentConversation,
  setConversationLoading,
  addConversation,
  setMessageLoading,
  setReadOnlyPrivateProfile,
  setNumberOfMessagesRemaining,
} = chatbotSlice.actions;

export default chatbotSlice.reducer;
