export const reportsData = [
  {
    slug: "you",
    name: "You Report",
    subtitle:
      "A guide to better understand yourself and live a more fulfilling life.",
    imageUrl: "/reports/small/you.jpg",
    requiredDimensionSlugs: ["personality", "personality-II", "values"],
    numFriendsRequired: null,
    peerRatingsRequired: null,
  },
  {
    slug: "love",
    name: "Love",
    subtitle:
      "A guide to understanding your personality in the world of remonatic love.",
    imageUrl: "/reports/small/love.jpg",
    requiredDimensionSlugs: [
      "personality",
      "personality-II",
      "love-style",
      "communication-style",
      "love-attitudes",
      "attachment-style",
      "interests",
      "lifestyle",
      "strengths",
      "sex-attitudes",
      "secondary-traits",
    ],
    numFriendsRequired: null,
    peerRatingsRequired: null,
  },
  {
    slug: "career",
    name: "Career Report",
    subtitle: "A guide to help you find a career that suits your personality.",
    imageUrl: "/reports/small/work.jpg",
    requiredDimensionSlugs: [
      "personality",
      "personality-II",
      "communication-style",
      "interests",
      "strengths",
      "secondary-traits",
      "values",
    ],
    numFriendsRequired: null,
    peerRatingsRequired: null,
  },
  // {
  //   slug: "blindspot",
  //   name: "Blindspot Report",
  //   subtitle: "How you see yourself vs. how others see you.",
  //   imageUrl: "/reports/small/blindspot.jpg",
  //   requiredDimensionSlugs: ["personality", "personality-II", "values"],
  //   numFriendsRequired: 3,
  //   peerRatingsRequired: 200,
  // },
  {
    slug: "therapist",
    name: "Therapist Reference",
    subtitle:
      "A report designed to be shared with your therapist, coach, or counsellor.",
    imageUrl: "/reports/small/therapist.jpg",
    requiredDimensionSlugs: [
      "personality",
      "personality-II",
      "values",
      "love-style",
      "communication-style",
      "love-attitudes",
      "attachment-style",
      "interests",
      "lifestyle",
      "strengths",
      "sex-attitudes",
      "secondary-traits",
    ],
    numFriendsRequired: null,
    peerRatingsRequired: null,
  },
];
