import { useSelector } from "react-redux";
import { Roboto_Mono } from "next/font/google";

import styles from "./CommunityQuestionsCTABlock.module.css";

import { RootState } from "@/redux/store";
import { useModalContext } from "@/context/ModalContext";
import CommunityQuestionsModal from "./CommunityQuestionsModal";
import { Mixpanel } from "@/helpers/mixpanel";

const font = Roboto_Mono({ subsets: ["latin"] });

export default function CommunityQuestionsCTABlock() {
  const { setModalComponent } = useModalContext();

  const communityQuestion = useSelector(
    (state: RootState) => state.communityQuestion
  ).communityQuestion;

  if (!communityQuestion) {
    return <></>;
  }

  return (
    <div
      onClick={() => {
        setModalComponent(
          <CommunityQuestionsModal elementName="CommunityQuestionsModal" />
        );
        Mixpanel?.track("Comment modal summoned", {
          source: "daily community question",
          slug: communityQuestion.slug,
        });
      }}
      className={styles.main}
    >
      <CommunityQuestionIcon />
      <div className={styles.rightDiv}>
        <h3 className={`${font.className} ${styles.title}`}>
          COMMUNITY QUESTION
        </h3>
        <p className={styles.body}>{communityQuestion.body}</p>
      </div>
    </div>
  );
}

export function CommunityQuestionIcon() {
  return (
    <svg
      width="53"
      height="52"
      viewBox="0 0 53 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ minWidth: "52px", minHeight: "52px" }}
    >
      <rect x="0.5" width="52" height="52" rx="26" fill="#55504F" />
      <g clipPath="url(#clip0_66972_194)">
        <path
          d="M32.8902 12.4184C33.5417 12.4184 34.0369 12.9451 34.0369 13.5651V20.7884C34.0369 21.4399 33.5102 21.9352 32.8902 21.9352H28.3648L27.7763 23.1134L26.908 24.8802L25.823 23.0202L25.2345 21.9667H21.0191C20.3676 21.9667 19.8724 21.4399 19.8724 20.8199L19.87 13.5653C19.87 12.9138 20.3967 12.4186 21.0167 12.4186L32.8902 12.4184ZM32.89 10.3103H21.0167C19.2185 10.3103 17.7617 11.7671 17.7617 13.5653V20.7886C17.7617 22.5869 19.2185 24.0436 21.0167 24.0436H24.0235L25.9767 27.422C26.1935 27.8252 26.5967 28.0105 27 28.0105C27.4335 28.0105 27.8367 27.7937 28.0535 27.359L29.6967 24.0423L32.89 24.0435C34.6883 24.0435 36.145 22.5868 36.145 20.7885V13.5652C36.145 11.767 34.6883 10.3103 32.89 10.3103Z"
          fill="#FFFFF2"
        />
        <path
          d="M17.7003 28.1351C18.7853 28.1351 19.6838 29.0336 19.6838 30.1186C19.6838 31.2036 18.7853 32.1021 17.7003 32.1021C16.6153 32.1021 15.7168 31.2036 15.7168 30.1186C15.7471 29.0033 16.6153 28.1351 17.7003 28.1351ZM17.7003 26.0269C15.437 26.0269 13.6387 27.8554 13.6387 30.0885C13.6387 32.3202 15.4684 34.1802 17.7003 34.1802C19.9635 34.1802 21.7619 32.3517 21.7619 30.1185C21.7619 27.8868 19.9637 26.0269 17.7003 26.0269Z"
          fill="#FFA0B9"
        />
        <path
          d="M22.7222 40.6902C22.1337 40.6902 21.6687 40.1937 21.6687 39.6367C21.6687 38.5832 20.8004 37.6834 19.8087 37.6834H15.7471C14.7553 37.6834 13.9488 38.5517 13.9488 39.5749C13.9488 40.1634 13.4838 40.6284 12.8953 40.6284C12.3068 40.6284 11.8418 40.1017 11.8418 39.5434C11.8418 37.3419 13.5783 35.5134 15.7168 35.5134H19.7784C21.9169 35.5134 23.7467 37.4049 23.7467 39.575C23.777 40.1636 23.3119 40.6902 22.7222 40.6902Z"
          fill="#FFA0B9"
        />
        <path
          d="M36.0831 28.1351C37.1681 28.1351 38.0666 29.0336 38.0666 30.1186C38.0666 31.2036 37.1681 32.1021 36.0831 32.1021C34.9981 32.1033 34.1299 31.2036 34.1299 30.1186C34.1299 29.0033 34.9981 28.1351 36.0831 28.1351ZM36.0831 26.0269C33.8199 26.0269 32.0215 27.8554 32.0215 30.0885C32.0215 32.3202 33.8512 34.1802 36.0831 34.1802C38.3464 34.1802 40.1447 32.3517 40.1447 30.1185C40.1435 27.8868 38.3465 26.0269 36.0831 26.0269Z"
          fill="#E0CC65"
        />
        <path
          d="M41.105 40.6902C40.5165 40.6902 40.0515 40.1937 40.0515 39.6367C40.0515 38.5832 39.1832 37.6834 38.1915 37.6834H34.1299C33.1381 37.6834 32.3316 38.5517 32.3316 39.5749C32.3316 40.1634 31.8666 40.6284 31.2781 40.6284C30.6896 40.6284 30.2246 40.1017 30.2246 39.5434C30.2246 37.3419 31.9611 35.5134 34.0996 35.5134H38.1612C40.2997 35.5134 42.1295 37.4049 42.1295 39.575C42.1586 40.1636 41.6935 40.6902 41.105 40.6902Z"
          fill="#E0CC65"
        />
      </g>
      <defs>
        <clipPath id="clip0_66972_194">
          <rect
            width="31"
            height="31"
            fill="white"
            transform="translate(11.5 10)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
