import { useEffect } from "react";
import { db } from "@/_firebase/firebaseConfig";
import { doc, updateDoc } from "firebase/firestore";

import { useAuth } from "@/context/AuthContext";

export function useGetFCMTokenHook() {
  const { user } = useAuth();
  const LSFcmToken = localStorage.getItem("fcmToken");

  useEffect(() => {
    console.log("Log from useGetFCMTokenHook. Token is: ", LSFcmToken);
    if (
      LSFcmToken &&
      user?.uid &&
      LSFcmToken !== "null" &&
      LSFcmToken !== "hello"
    ) {
      const ref = doc(db, `/members/${user?.uid}`);
      updateDoc(ref, { fcmToken: LSFcmToken })
        .then((res) => {
          console.log("Updated DB for FCM Token");
        })
        .catch((err: any) => {
          console.log("Error updating db for FCM token", err);
        });
    } else {
      console.log("Not able to update db", LSFcmToken, user?.uid);
    }
  }, [LSFcmToken, user?.uid]);
}
