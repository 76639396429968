import { useState, useEffect, useRef, RefObject } from "react";

function useScrolledUp(
  ref: RefObject<HTMLDivElement>,
  messagesLength: number
): boolean {
  const [isScrolledUp, setIsScrolledUp] = useState(true);
  const lastScrollTop = useRef(0);

  useEffect(() => {
    if (!ref.current) {
      setIsScrolledUp(false);
      return;
    }

    const handleScroll = () => {
      if (!ref.current) return;
      const currentScrollTop = ref.current.scrollTop + 100;
      const maxScrollTop = ref.current.scrollHeight - ref.current.clientHeight;

      if (currentScrollTop < lastScrollTop.current) {
        setIsScrolledUp(true);
      } else if (currentScrollTop >= maxScrollTop) {
        setIsScrolledUp(false);
      }

      lastScrollTop.current = currentScrollTop;
    };

    ref.current.addEventListener("scroll", handleScroll);

    return () => {
      ref.current?.removeEventListener("scroll", handleScroll);
    };
  }, [ref, messagesLength]);

  return isScrolledUp;
}

export default useScrolledUp;
