/* eslint-disable react/no-children-prop */
import { PatternDiscovery } from "@dimensional-engineering/dimensional-models";
import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";

import styles from "../../../../styles/app/AuthTraits/ElementModal.module.css";

import Loader from "@/components/shared/Loader";
import ModalWrapper from "@/components/shared/ModalWrapper/ModalWrapper";
import useGetPatternData from "./useGetPatternData";
import PatternTraitsPageLeftBarModal from "../../pattern/patternsLeftBar/PatternTraitsPageLeftBarModal";
import SwipeTabs from "@/components/shared/SwipeTabs/SwipeTabs";
import AboutThisTrait from "../../detailsTab/aboutThisTrait/AboutThisTrait";
import OppositePattern from "../../detailsTab/patterns/oppositePatterns/OppositePattern";
import PatternComponentOf from "../../detailsTab/patterns/patternComponentOf/PatternComponentOf";
import InteractionBar from "../../shared/InteractionBar/InteractionBar";
import useGetPublicProfile from "@/helpers/useGetPublicProfile";
import { callFirebaseFunctions } from "@/_firebase/callFirebaseFunctions";
import FriendSelfRatedScoreTable from "../../detailsTab/SelfRatedScoreTable/FriendSelfRatedScoreTable";
import FriendHistogram from "../../detailsTab/histogram/FriendHistogram";
import Pattern from "../../pattern/Pattern/Pattern";
import PatternComponents from "../../detailsTab/PatternComponents/PatternComponents";
import TraitDescription from "../../shared/TraitScoreTable/TraitDescription";
import { natureMap } from "@/assets/traitInfo/natureData";

export default function FriendPatternModal(props: {
  slug: string;
  uid: string | undefined;
}) {
  const pattern = useGetPatternData(props.slug);
  const [tabs, setTabs] = useState<string[]>([
    "summary",
    "details",
    "identity",
  ]);
  const [slicedMarkdown, setSlicedMarkdown] = useState<string[]>([]);
  const [markdownContent, setMarkdownContent] = useState<string[]>([]);
  const [friendScore, setFriendScore] = useState<number | null | undefined>(
    null
  );
  const [loading, setLoading] = useState<boolean>(true);

  const publicProfile = useGetPublicProfile(props.uid as string);

  useEffect(() => {
    setLoading(true);
    const fetchData = callFirebaseFunctions("fetchFriendPatternDiscovery");
    fetchData({
      memberUUID: props.uid,
      version: "2",
      traitSlug: pattern?.slug,
    })
      .then((res) => {
        setLoading(false);
        const data = res.data as PatternDiscovery;
        if (data) {
          setFriendScore(data.patternScore);
        } else {
          setFriendScore(null);
        }
      })
      .catch((err) => {});

    setTabs(["summary", "details", "identity"]);
    setMarkdownContent([]);
    setSlicedMarkdown([]);
    pattern?.traitContent.markdownSections?.forEach((section, index) => {
      if (!tabs.includes(section.traitSection as string)) {
        setTabs((current) => [...current, section.traitSection as string]);
      }
      setMarkdownContent((current) => [...current, section.markdownString]);
      if (index !== 0) {
        setSlicedMarkdown((current) => [...current, section.markdownString]);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pattern?.slug, props.uid]);

  if (pattern === null || loading === true) {
    return (
      <ModalWrapper title="">
        <Loader height="500px" mobileHeight="500px" />
      </ModalWrapper>
    );
  }

  return (
    <ModalWrapper noLine title={pattern.name}>
      <div className={styles.main}>
        <div className={styles.parent}>
          <div className={styles.rightBarMain}>
            <SwipeTabs
              sticky
              tabs={tabs}
              slicedContent={slicedMarkdown}
              content={[
                <PatternTraitsPageLeftBarModal
                  key={1}
                  nonOwnProfile
                  pattern={pattern}
                  publicProfile={publicProfile}
                  friendScore={friendScore}
                />,
                <div key={2}>
                  <div className={styles.archetypeDiv}>
                    <Pattern patternSlug={pattern.slug} score={friendScore} />
                  </div>
                  <PatternComponents pattern={pattern} />
                  <TraitDescription
                    type="Pattern"
                    childElementCount={
                      pattern.patternDetails.componentTraits.length
                    }
                    nature={
                      pattern.nature
                        ? natureMap[pattern.nature].name
                        : undefined
                    }
                    color={
                      pattern.nature
                        ? natureMap[pattern.nature].color
                        : undefined
                    }
                    dimension={
                      pattern.dimensionSlugs.length > 1
                        ? null
                        : pattern.dimensionSlugs[0]
                    }
                    plasticity={pattern.plasticity}
                    patternGroupSlug={pattern.patternGroupSlug}
                    averageUserScore={pattern.traitContent?.averagePercentScore}
                    aboutThisTrait
                  />
                  <FriendSelfRatedScoreTable
                    score={friendScore}
                    slug={pattern.slug}
                    type="pattern"
                    publicProfile={publicProfile}
                    connectionAvailability={pattern.connectionAvailabilities}
                  />
                  <FriendHistogram
                    // @ts-ignore
                    data={pattern.traitContent?.histogramData}
                    type="pattern"
                    averageUsrScore={pattern.traitContent?.averagePercentScore}
                    publicProfile={publicProfile}
                    score={friendScore}
                  />
                  {pattern.traitContent.oppositeDetails?.oppositeTrait.slug && (
                    <OppositePattern
                      uid={props.uid}
                      authenticated
                      element={pattern}
                    />
                  )}
                  {pattern.traitContent.patternDetails?.componentTraits && (
                    <PatternComponentOf
                      uid={props.uid}
                      modal
                      pattern={pattern}
                      authenticated
                    />
                  )}
                </div>,
                <div
                  style={{ pointerEvents: "none" }}
                  key={3}
                  className={styles.markdownDiv}
                >
                  <ReactMarkdown
                    transformLinkUri={(href: string) => {
                      return "";
                    }}
                    children={markdownContent[0]}
                  />
                </div>,
              ]}
              grow={tabs.length < 4 ? true : false}
            />
          </div>
        </div>
      </div>

      {props.uid && (
        <InteractionBar
          uid={props.uid}
          slug={pattern.slug}
          type="pattern"
          ownProfile={false}
          //@ts-ignore
          histogramData={pattern.traitContent?.histogramData}
          connectionAvailabilities={pattern.connectionAvailabilities}
        />
      )}
    </ModalWrapper>
  );
}
