import { Nature } from "@dimensional-engineering/dimensional-models";
import { useSelector } from "react-redux";

import styles from "./LatestResultsBar.module.css";

import SmallProfileImage from "@/components/shared/SmallProfileImage/SmallProfileImage";
import { RootState } from "@/redux/store";
import { useModalContext } from "@/context/ModalContext";
import NatureModal from "@/components/authenticatedProfile/NatureModal/NatureModal";
import { natureMap } from "@/assets/traitInfo/natureData";

export default function PrimaryNatureBlock(props: {
  nature: Nature | undefined;
}) {
  const userState = useSelector((state: RootState) => state.user);
  const { setModalComponent } = useModalContext();

  return (
    <div
      onClick={() => {
        setModalComponent(<NatureModal />);
      }}
      className={styles.block}
    >
      <p className={styles.topText}>Your nature:</p>
      <div className={styles.imageDiv}>
        <SmallProfileImage
          medium
          noLink
          imageUrl={userState.imageURL ?? "/default-image.png"}
          publicProfile={userState.publicProfileData}
        />
      </div>
      <p className={styles.bottomText}>
        {props.nature
          ? natureMap[props.nature as keyof typeof natureMap].name
          : null}
      </p>
    </div>
  );
}
