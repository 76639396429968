import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { RootState } from "@/redux/store";
import { isTimestampWithin1Hour } from "@/components/authenticatedProfile/utils";

export default function useSnippetsLLMRequestStatus() {
  const [enabled, setEnabled] = useState<boolean>(false);
  const [state, setState] = useState<"error" | "pending">("pending");
  const [alertMessage, setAlertMessage] = useState<string>("");
  const latestSnippet = useSelector(
    (state: RootState) => state.snippet.snippetslAllLLMRequest
  );
  const lastManualRequest = useSelector(
    (state: RootState) => state.snippet.snippetsLatestManualLlmRequest
  );

  const isWithin1hour = isTimestampWithin1Hour(lastManualRequest?.createdOn);

  useEffect(() => {
    const lastRequest = latestSnippet[0];
    //  if snippet exists
    // 	show snippet
    // if snippet gate is incomplete
    // 	show gate
    // if no manual manualRetry exists
    // 	show error state with recompute enabled
    // if last llm snippet request is processing (any type of generation):
    // 	show pending state
    // if there is a last manual retry AND createdOn < 24 hours ago
    // 	show error state with recompute enabled
    // else
    // 	show error state with recompute disabled

    if (latestSnippet.length === 0) {
      setEnabled(true);
      setState("error");
    } else if (latestSnippet.length && lastRequest?.status === "processing") {
      // if last llm snippet request is pending
      setEnabled(false);
      setState("pending");
      setAlertMessage("Computing. Check back soon!");
    } else if (lastManualRequest && isWithin1hour === false) {
      setEnabled(true);
      setState("error");
    } else {
      setEnabled(false);
      setState("error");
      setAlertMessage("Results computing. You can retry every 24 hours.");
    }
  }, [isWithin1hour, lastManualRequest, latestSnippet, latestSnippet.length]);

  return {
    enabled,
    state,
    alertMessage,
  };
}
