import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { RootState } from "@/redux/store";

export function useGetDiscoveredPersonalityAnalysisSlugs() {
  const [discoveredSlugs, setDiscoveredSlugs] = useState<string[]>([]);

  const personalityState = useSelector(
    (state: RootState) => state.personalityAnalyis
  );

  useEffect(() => {
    if (personalityState.discoveredPersonalAnalysis?.length) {
      personalityState.discoveredPersonalAnalysis.forEach((s) => {
        if (s.analysisDetail !== null) {
          setDiscoveredSlugs((current) => [...current, s.analysisSlug]);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personalityState.discoveredPersonalAnalysis?.length]);

  return discoveredSlugs;
}
