import { useState } from "react";
import { useSelector } from "react-redux";
import { Roboto_Mono } from "next/font/google";
import Link from "next/link";

import styles from "./LatestResultsBar.module.css";

import { dimensionData } from "@/assets/traitInfo/dimensionData";
import { RootState } from "@/redux/store";
import MoreButton from "@/components/shared/buttons/MoreButton/MoreButton";
import { onMoreClick } from "../utils";
import SnippetBlock, {
  SnippetBlockHighestScoringTraits,
} from "@/components/results/Snippets/SnippetBlock";
import {
  computableSnippetsObject,
  snippetsMap,
} from "@/components/results/Snippets/snippetsMap";
import PersonalitAnalysisBlockSmall from "@/components/results/personalityAnalysis/PersonalitAnalysisBlockSmall";
import { useModalContext } from "@/context/ModalContext";
import AnalysisModal from "@/components/results/personalityAnalysis/AnalysisModal/AnalysisModal";
import SnippetsModal from "@/components/results/Snippets/SnippetsModal";
import DimensionSummaryBlock from "@/components/results/DimensionSummaries/DimensionSummaryBlock";
import { LatestResultType } from "@dimensional-engineering/dimensional-models";
import CompatibilitySnippetBlock from "@/components/authenticatedProfile/tabs/AuthCompareTab/Compatibility/CompatibilitySnippetBlock";
import { useAuth } from "@/context/AuthContext";
import ReportsBlockResults from "@/components/results/ReportsMap/ReportsBlockResults";
import PrimaryNatureBlock from "./PrimaryNatureBlock";
import ArchetypeResultBlock from "./ArchetypeResultBlock";
import { StoryCardSmall } from "@/components/results/stories/StoryCard";
import { storyColorMap } from "@/components/results/stories/storyColorMap";
import ForwardArrow from "@/components/shared/buttons/ForwardArrow";
import Loader from "@/components/shared/Loader";
import { LatestGhostBox } from "@/components/shared/GhostBox/GhostBox";
import WarningIcon from "@/components/shared/GhostBox/WarningIcon";
import { useGetDiscoveredSnippetSlugs } from "@/components/results/Snippets/Snippets";
import SnippetDiscoveryGate from "@/components/shared/DiscoveryGate/SnippetDiscoveryGate";
import DiscoveryGate from "@/components/shared/DiscoveryGate/DiscoveryGate";
import { summonProfileLink } from "@/components/shared/utils";
import Shimmer from "@/components/shared/Shimmer/Shimmer";
import SwipableStoriesModal from "@/components/results/stories/SwipableStoriesModal";
import PeerStoryBlockLatestResults from "@/components/authenticatedProfile/tabs/AuthCompareTab/PeerStories/PeerStoryBlockLatestResults/PeerStoryBlockLatestResults";
import { peerRatedSnippetSlugs, valuesArchetypeSlugs } from "./utils";

const font = Roboto_Mono({ subsets: ["latin"] });

export default function LatestResultsBar() {
  const { setModalComponent, setModalOpen } = useModalContext();
  const { paginateLatestResults } = useAuth();
  const discoveredSnippetSlugs = useGetDiscoveredSnippetSlugs();
  const { user } = useAuth();

  const [loading, setLoading] = useState<boolean>(false);

  const latestResults = useSelector(
    (state: RootState) => state.latestResults.latestResults
  );
  const latestResultsLoaded = useSelector(
    (state: RootState) => state.latestResults.latestResultsLoaded
  );
  const personalityState = useSelector(
    (state: RootState) => state.personalityAnalyis
  );
  const discoverableStories = useSelector(
    (state: RootState) => state.user.discoverableStories
  );

  const userState = useSelector((state: RootState) => state.user);

  const mappedLatestResultsLoading = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(
    (num) => {
      return (
        <div className={styles.shimmerDiv} key={num}>
          <div className={styles.shimmerLine}>
            <Shimmer />
          </div>
          <div className={styles.shimmerImage}>
            <Shimmer />
          </div>
          <div className={styles.shimmerLine}>
            <Shimmer />
          </div>
        </div>
      );
    }
  );

  const mapped = latestResults
    .filter((result) => {
      if (result.resultType === LatestResultType.doppelganger) {
        return false;
      }
      if (
        result.resultDetail.resultSnippet?.contentIdentifier.contentSlug &&
        peerRatedSnippetSlugs.includes(
          result.resultDetail.resultSnippet?.contentIdentifier.contentSlug
        )
      ) {
        //filtering out peer rated snippets
        return false;
      }

      if (
        result.resultDetail.archetype?.contentIdentifier.contentSlug &&
        valuesArchetypeSlugs.includes(
          result.resultDetail.archetype?.contentIdentifier.contentSlug
        )
      ) {
        //filtering out values archetype
        return false;
      }
      return true;
    })
    .map((result, index) => {
      const dimension = dimensionData?.find(
        (d) => d.slug === result.resultDetail.dimensionSummary?.dimensionSlug
      );
      const discoverablePersonality =
        personalityState.discoverablePersonalAnalysis?.find(
          (p) =>
            p.analysisSlug ===
            result.resultDetail?.personalityAnalysis?.contentIdentifier
              ?.contentSlug
        );

      const discoverableSnippet = snippetsMap?.find(
        (s) =>
          s?.snippetSlug ===
          result.resultDetail?.resultSnippet?.contentIdentifier.contentSlug
      );

      const discoverableStory = discoverableStories?.find(
        (s) =>
          s.storySlug ===
          result.resultDetail.story?.contentIdentifier.contentSlug
      );

      const resultTypeMap: Record<LatestResultType, JSX.Element> = {
        resultSnippet:
          result.resultDetail?.resultSnippet?.contentIdentifier.contentSlug ===
          "snippet-highest-scoring-traits" ? (
            <SnippetBlockHighestScoringTraits />
          ) : (
            <SnippetBlock
              key={
                result.resultDetail?.resultSnippet?.contentIdentifier
                  .contentSlug
              }
              greyedOut={false}
              snippet={
                computableSnippetsObject[
                  result.resultDetail?.resultSnippet?.contentIdentifier
                    .contentSlug as string
                ]
              }
              onClick={() => {
                if (
                  discoveredSnippetSlugs.includes(
                    result.resultDetail?.resultSnippet?.contentIdentifier
                      .contentSlug as string
                  )
                ) {
                  //has snippet
                  setModalComponent(
                    <SnippetsModal
                      discoveredSnippetSlug={
                        result.resultDetail?.resultSnippet?.contentIdentifier
                          .contentSlug as string
                      }
                    />
                  );
                } else {
                  //discovery gate
                  discoverableSnippet &&
                    setModalComponent(
                      <SnippetDiscoveryGate
                        snippetSlug={discoverableSnippet?.snippetSlug}
                        snippetType={discoverableSnippet?.snippetType}
                        requiredDimensionSlugs={
                          discoverableSnippet?.discoveryGate
                            .requiredDimensionSlugs
                        }
                        numFriendsRequired={
                          discoverableSnippet?.discoveryGate.numFriendsRequired
                        }
                        numSuccessfulInvitesRequired={
                          discoverableSnippet?.discoveryGate
                            .numSuccessfulInvitesRequired
                        }
                        onClose={() => {
                          setModalOpen(false);
                        }}
                        onOpenShareLink={() => {
                          summonProfileLink(
                            user?.uid,
                            "dashboard",
                            "Invite to Dimensional"
                          );
                        }}
                        snippetConnection={
                          discoverableSnippet.discoveryGate.numFriendsRequired
                            ? true
                            : false
                        }
                        snippetDimension={
                          discoverableSnippet.discoveryGate
                            .requiredDimensionSlugs
                            ? true
                            : false
                        }
                      />
                    );
                }
              }}
            />
          ),
        compatibilitySnippet: (
          <CompatibilitySnippetBlock
            slug={
              result.resultDetail?.compatibilitySnippet?.contentIdentifier
                .contentSlug as string
            }
            publicProfile={
              result.resultDetail?.compatibilitySnippet?.publicProfile
            }
          />
        ),
        primaryNature: (
          <PrimaryNatureBlock
            nature={result.resultDetail?.primaryNature?.primaryNature.nature}
          />
        ),
        archetype: (
          <ArchetypeResultBlock
            slug={
              result.resultDetail?.archetype?.contentIdentifier.contentSlug ??
              ""
            }
          />
        ),
        story: (
          <StoryCardSmall
            title={result.primaryText}
            imageSlug={`${result.resultDetail?.story?.contentIdentifier.contentSlug}.png`}
            color={`${
              storyColorMap[
                result.resultDetail?.story?.contentIdentifier
                  .contentSlug as keyof typeof storyColorMap
              ] ?? "#fffff2"
            }`}
            onClick={() => {
              const discoveredStory = userState.discoveredStories?.find(
                (s) =>
                  s.storySlug ===
                  result.resultDetail?.story?.contentIdentifier.contentSlug
              );
              if (discoveredStory) {
                return setModalComponent(
                  <SwipableStoriesModal storySlug={discoveredStory.storySlug} />
                );
              } else {
                if (discoverableStory) {
                  setModalComponent(
                    <DiscoveryGate
                      {...discoverableStory.discoveryGate}
                      onClose={() => setModalOpen(false)}
                      onOpenShareLink={async () => {
                        await summonProfileLink(
                          user?.uid,
                          "dashboard",
                          "Invite to Dimensional"
                        );
                      }}
                      fromStory
                    />
                  );
                }
              }
            }}
          />
        ),
        dimensionSummary: dimension ? (
          <DimensionSummaryBlock summary={dimension!!} unknown={false} />
        ) : (
          <div style={{ width: "0" }}></div>
        ),
        personalityAnalysis: (
          <PersonalitAnalysisBlockSmall
            onClick={() => {
              setModalComponent(
                <AnalysisModal
                  slug={
                    result.resultDetail?.personalityAnalysis?.contentIdentifier
                      .contentSlug
                  }
                  onClose={() => setModalOpen(false)}
                />
              );
            }}
            analysis={discoverablePersonality}
          />
        ),
        report: (
          <ReportsBlockResults
            slug={result.resultDetail?.report?.contentIdentifier.contentSlug}
          />
        ),
        doppelganger: <div style={{ width: "0" }}></div>,
        peerStory: (
          <PeerStoryBlockLatestResults
            peerStory={result.resultDetail.peerStory}
          />
        ),
      };

      return <div key={index}>{resultTypeMap[result.resultType]}</div>;
    });

  return (
    <section className={styles.main}>
      <h2 className={styles.heading}>Latest results</h2>
      <div
        onScroll={(e) => {
          const container = e.target as HTMLElement;
          const scrollLeft = container.scrollLeft;
          const scrollWidth = container.scrollWidth;
          const clientWidth = container.clientWidth;
          const isEnd = scrollLeft + clientWidth >= scrollWidth - 20;
          if (isEnd == true) {
            console.log("At end");
            setLoading(true);
            setTimeout(() => {
              paginateLatestResults();
            }, 300);
            setTimeout(() => {
              setLoading(false);
            }, 1000);
          }
        }}
        id="resultsWrapper"
        className={styles.wrapper}
      >
        {!latestResultsLoaded ? (
          mappedLatestResultsLoading
        ) : mapped.length ? (
          mapped
        ) : (
          <LatestGhostBox
            darkText
            onlyMessage
            image={<WarningIcon active />}
            message="No new results"
          />
        )}
        {loading && <Loader mobileHeight="123px" height="123px" />}
      </div>
      {mapped.length ? (
        <div className={styles.moreBtn}>
          <MoreButton onClick={() => onMoreClick("resultsWrapper")} />
        </div>
      ) : null}
      <Link href={`/app/results`} className={styles.viewAllButtonDiv}>
        <p className={`${font.className} ${styles.viewAllResultsText}`}>
          View all results
        </p>
        <ForwardArrow />
      </Link>
    </section>
  );
}
