/* eslint-disable @next/next/no-img-element */
import Image from "next/image";
import {
  Nature,
  PublicProfile,
} from "@dimensional-engineering/dimensional-models";

import styles from "./SmallProfileImage.module.css";

import { ProfileColorMap } from "../ColorMap";
import DeepConnectionIcon from "../icons/DeepConnectionIcon";
import CloseConnectionIcon from "../icons/CloseConnectionIcon";
import { useModalContext } from "@/context/ModalContext";
import NatureModal from "@/components/authenticatedProfile/NatureModal/NatureModal";
import { useAuth } from "@/context/AuthContext";
import NatureModalConnection from "@/components/authenticatedProfile/NatureModal/NatureModalConnection";
import ProfileModalHandler from "@/components/authenticatedProfile/Abstracts/ProfileModal/ProfileModalHandler";
import Shimmer from "../Shimmer/Shimmer";

export default function LargeProfileImage(props: {
  publicProfile: PublicProfile | null;
  imageUrl: string;
  isClose?: boolean;
  isDeep?: boolean;
  noLink?: boolean;
}) {
  const { setModalComponent } = useModalContext();

  if (props.noLink) {
    return (
      <div
        className={styles.largeImageContainer}
        style={
          props.publicProfile?.primaryNature?.nature
            ? {
                border: `4px solid ${
                  ProfileColorMap[props.publicProfile.primaryNature?.nature]
                }`,
              }
            : {
                border: `4px solid #55504F`,
              }
        }
      >
        {props.imageUrl === "" && <Shimmer rounded />}
        <img
          style={props.imageUrl === "" ? { display: "none" } : {}}
          alt=""
          src={props.imageUrl}
          className={styles.largeImage}
        />
        {props.isClose && (
          <div className={styles.iconDiv}>
            <CloseConnectionIcon active />
          </div>
        )}
        {props.isDeep && (
          <div className={styles.iconDiv}>
            <DeepConnectionIcon active />
          </div>
        )}
      </div>
    );
  }

  return (
    <div
      onClick={(e) => {
        setModalComponent(
          <ProfileModalHandler
            uid={props.publicProfile?.ownerUUID}
            elementName="profileModal"
          />
        );
      }}
      className={styles.largeImageContainer}
      style={
        props.publicProfile?.primaryNature?.nature
          ? {
              border: `4px solid ${
                ProfileColorMap[props.publicProfile.primaryNature?.nature]
              }`,
            }
          : {
              border: `4px solid #55504F`,
            }
      }
    >
      {props.imageUrl === "" && <Shimmer rounded />}
      <img
        style={props.imageUrl === "" ? { display: "none" } : {}}
        alt=""
        src={props.imageUrl}
        className={styles.largeImage}
      />
      {props.isClose && (
        <div className={styles.iconDiv}>
          <CloseConnectionIcon active />
        </div>
      )}
      {props.isDeep && (
        <div className={styles.iconDiv}>
          <DeepConnectionIcon active />
        </div>
      )}
    </div>
  );
}

export function VeryLargeProfileImage(props: {
  publicProfile: PublicProfile | null;
  imageUrl: string;
  noLink?: boolean;
  showNature?: boolean;
}) {
  const { setModalComponent } = useModalContext();
  const { user } = useAuth();

  if (props.noLink) {
    return (
      <div
        className={styles.veryLargeImageContainer}
        style={
          props.publicProfile?.primaryNature?.nature
            ? {
                border: `8px solid ${
                  ProfileColorMap[props.publicProfile.primaryNature?.nature]
                }`,
              }
            : {
                border: `8px solid #55504F`,
              }
        }
        onClick={() => {
          if (props.showNature) {
            if (props.publicProfile?.ownerUUID === user?.uid) {
              return setModalComponent(<NatureModal />);
            } else {
              return setModalComponent(
                <NatureModalConnection
                  uid={props.publicProfile?.ownerUUID}
                  imageUrl={props.imageUrl}
                />
              );
            }
          }
        }}
      >
        {props.imageUrl === "" && <Shimmer rounded />}
        <img
          alt="Profile Image"
          src={props.imageUrl}
          className={styles.veryLargeImage}
          style={props.imageUrl === "" ? { display: "none" } : {}}
        />
      </div>
    );
  }

  return (
    <div
      onClick={(e) => {
        setModalComponent(
          <ProfileModalHandler
            uid={props.publicProfile?.ownerUUID}
            elementName="profileModal"
          />
        );
      }}
      className={styles.veryLargeImageContainer}
      style={
        props.publicProfile?.primaryNature?.nature
          ? {
              border: `8px solid ${
                ProfileColorMap[props.publicProfile.primaryNature?.nature]
              }`,
            }
          : {
              border: `8px solid #55504F`,
            }
      }
    >
      {props.imageUrl === "" && <Shimmer rounded />}
      <img
        alt="Profile Image"
        src={props.imageUrl}
        className={styles.veryLargeImage}
        style={props.imageUrl === "" ? { display: "none" } : {}}
      />
    </div>
  );
}

export function VeryLargeProfileImageWithNature(props: {
  nature: Nature | null | undefined;
  imageUrl: string;
}) {
  return (
    <div
      className={styles.veryLargeImageContainer}
      style={
        props.nature
          ? {
              border: `8px solid ${ProfileColorMap[props.nature]}`,
            }
          : {
              border: `8px solid #55504F`,
            }
      }
    >
      <img alt="" src={props.imageUrl} className={styles.veryLargeImage} />
    </div>
  );
}

export function ExtremelyLargeProfileImage(props: {
  publicProfile: PublicProfile | null;
  imageUrl: string;
  noLink?: boolean;
}) {
  const { setModalComponent } = useModalContext();

  if (props.noLink) {
    return (
      <div
        className={styles.extremelyLargeImageContainer}
        style={
          props.publicProfile?.primaryNature?.nature
            ? {
                border: `8px solid ${
                  ProfileColorMap[props.publicProfile.primaryNature?.nature]
                }`,
              }
            : {
                border: `8px solid #55504F`,
              }
        }
      >
        <Image
          alt="Profile Image"
          src={props.imageUrl}
          className={styles.extremelyLargeImage}
          fill
          priority
        />
      </div>
    );
  }

  return (
    <div
      onClick={(e) => {
        setModalComponent(
          <ProfileModalHandler
            uid={props.publicProfile?.ownerUUID}
            elementName="profileModal"
          />
        );
      }}
      className={styles.extremelyLargeImageContainer}
      style={
        props.publicProfile?.primaryNature?.nature
          ? {
              border: `8px solid ${
                ProfileColorMap[props.publicProfile.primaryNature?.nature]
              }`,
            }
          : {
              border: `8px solid #55504F`,
            }
      }
    >
      <Image
        alt="Profile Image"
        src={props.imageUrl}
        className={styles.extremelyLargeImage}
        fill
        priority
      />
    </div>
  );
}

export function ChatProfileImage(props: {
  publicProfile: PublicProfile | null;
  imageUrl: string;
}) {
  return (
    <div
      className={styles.chatImageContainer}
      style={
        props.publicProfile?.primaryNature?.nature
          ? {
              border: `2px solid ${
                ProfileColorMap[props.publicProfile.primaryNature?.nature]
              }`,
            }
          : {
              border: `2px solid #55504F`,
            }
      }
    >
      {props.imageUrl === "" && <Shimmer rounded />}
      <img
        alt="Profile Image"
        src={props.imageUrl}
        className={styles.chatImage}
        style={props.imageUrl === "" ? { display: "none" } : {}}
      />
    </div>
  );
}

export function ChatProfileImageWithLink(props: {
  publicProfile: PublicProfile | null;
  imageUrl: string;
}) {
  const { setModalComponent } = useModalContext();

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        setModalComponent(
          <ProfileModalHandler
            uid={props.publicProfile?.ownerUUID}
            elementName="profileModal"
          />
        );
      }}
      className={styles.chatImageContainer}
      style={
        props.publicProfile?.primaryNature?.nature
          ? {
              border: `2px solid ${
                ProfileColorMap[props.publicProfile.primaryNature?.nature]
              }`,
            }
          : {
              border: `2px solid #55504F`,
            }
      }
    >
      {props.imageUrl === "" && <Shimmer rounded />}
      <img
        alt="Profile Image"
        src={props.imageUrl}
        className={styles.chatImage}
        style={props.imageUrl === "" ? { display: "none" } : {}}
      />
    </div>
  );
}
