import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@/redux/store";
import {
  ContentType,
  PublicProfile,
} from "@dimensional-engineering/dimensional-models";
import { AnyAction } from "@reduxjs/toolkit";

import styles from "./PeerStoriesModal.module.css";
import "swiper/css";

import ModalWrapper from "@/components/shared/ModalWrapper/ModalWrapper";
import { useModalContext } from "@/context/ModalContext";
import { ExtendedPeerStoryGated } from "@/models/sharedModels";
import CloseButtonSummary from "@/components/shared/CloseButtonSummary";
import { callFirebaseFunctions } from "@/_firebase/callFirebaseFunctions";
import { useAlertContext } from "@/context/AlertContext";
import Alert from "@/components/shared/Alerts/Alert";
import DiscoveryGate from "@/components/shared/DiscoveryGate/DiscoveryGate";
import { computePeerStories } from "@/redux/slices/peerStoriesSlice";
import PeerStorySlide from "./PeerStorySlide";
import { Mixpanel } from "@/helpers/mixpanel";
import { handleGatingEnumForMixpanel } from "../utils";

type Props = {
  otherPublicProfile: PublicProfile | null;
  imageUrl: string | undefined;
  uid: string;
  connectionType:
    | "connected"
    | "close"
    | "deep"
    | "sent"
    | "unconnected"
    | "received"
    | null;
  peerStories: ExtendedPeerStoryGated[];
  storySlug: string;
  elementName: "PeerStoriesModal";
};

export default function PeerStoriesModal(props: Props) {
  const dispatch = useAppDispatch();

  const [mySwiperRef, setMySwiperRef] = useState<any>(null);
  const [index, setIndex] = useState<number>(0);

  const [nudged, setNudged] = useState<boolean>(false);

  const { setModalOpen, setModalComponent } = useModalContext();
  const { setAlertComponent } = useAlertContext();

  const discoverablePeerStories = useSelector(
    (state: RootState) => state.peerStories
  ).discoverablePeerStory;

  const requestTimestamps = useSelector(
    (state: RootState) => state.peerStories
  ).requestTimestamps;

  const mappedIndexBars = props.peerStories.map((s, i) => (
    <div
      key={i}
      style={{ opacity: i > index ? "0.2" : "1" }}
      className={styles.bar}
    ></div>
  ));

  async function handleNudge(storySlug: string) {
    setNudged(true);
    const nudgeCall = callFirebaseFunctions("nudgeConnection");
    return await nudgeCall(
      JSON.stringify({
        connectionUid: props.otherPublicProfile?.ownerUUID,
        contentIdentifier: {
          contentType: ContentType.peerStory,
          contentSlug: storySlug,
        },
      })
    )
      .then((res) => {
        return setAlertComponent(
          <Alert
            elementName="Alert"
            type="success"
            message={`Your nudge has been sent to @${props.otherPublicProfile?.userName}.`}
          />,
          3000
        );
      })
      .catch((err) => {
        setAlertComponent(
          <Alert
            elementName="Alert"
            type="fail"
            message={`Failed to send nudge to connection. Please try again.`}
          />,
          3000
        );
        return setNudged(false);
      });
  }

  function handleIncompleteAssessments(story: ExtendedPeerStoryGated) {
    return setModalComponent(
      <DiscoveryGate
        onOpenShareLink={() => {}}
        requiredDimensionSlugs={story.requiredDimensionSlugs}
        onClose={() => setModalOpen(false)}
      />
    );
  }

  function handleGeneration(story: ExtendedPeerStoryGated) {
    const currentTimestamp = Date.now();

    const lastRequestTimestamp =
      requestTimestamps[props.uid]?.[story.promptSlug] || 0;

    const isWithinLastHour =
      currentTimestamp - lastRequestTimestamp < 60 * 60 * 1000;

    if (isWithinLastHour) {
      return setAlertComponent(
        <Alert
          elementName="Alert"
          type="fail"
          message="You can only generate once an hour."
        />,
        3000
      );
    }

    const promptSlug = story.promptSlug;
    const connectionUid = props.uid;
    dispatch(
      computePeerStories({ promptSlug, connectionUid }) as unknown as AnyAction
    );
  }

  function handleRegenerate(story: ExtendedPeerStoryGated) {
    const currentTimestamp = Date.now();

    const lastRequestTimestamp =
      requestTimestamps[props.uid]?.[story.promptSlug] || 0;

    const isWithinLast24Hours =
      currentTimestamp - lastRequestTimestamp < 24 * 60 * 60 * 1000;

    if (isWithinLast24Hours) {
      return setAlertComponent(
        <Alert
          elementName="Alert"
          type="fail"
          message="You can only generate once a day."
        />,
        3000
      );
    }

    return handleGeneration(story);
  }

  const mappedStories = props.peerStories.map((story) => {
    return (
      <SwiperSlide key={story.slug}>
        <PeerStorySlide
          uid={props.uid}
          otherPublicProfile={props.otherPublicProfile}
          nudged={nudged}
          handleNudge={() => handleNudge(story.slug)}
          handleGeneration={(s) => handleGeneration(s)}
          handleIncompleteAssessments={(s) => handleIncompleteAssessments(s)}
          handleRegenerate={(s) => handleRegenerate(s)}
          handleSlideClick={(side) => {
            if (side === "left") {
              if (mySwiperRef) {
                mySwiperRef?.slideTo(index - 1);
              }
            } else {
              if (mySwiperRef) {
                mySwiperRef?.slideTo(index + 1);
              }
            }
          }}
          story={story}
        />
      </SwiperSlide>
    );
  });

  useEffect(() => {
    if (discoverablePeerStories?.length) {
      for (let i = 0; i < discoverablePeerStories?.length; i++) {
        if (discoverablePeerStories[i].slug === props.storySlug) {
          setIndex(i);
        }
      }
    }
  }, [props.storySlug]);

  useEffect(() => {
    if (mySwiperRef) mySwiperRef?.slideTo(index);

    const story = props.peerStories[index];
    if (story) {
      const state = handleGatingEnumForMixpanel(story?.gating);
      Mixpanel?.track("Peer Story Viewed", {
        slug: story?.slug,
        state: state,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index]);

  return (
    <ModalWrapper title="" noHeader noLine>
      <main className={styles.main}>
        <div className={styles.barWrapper}>{mappedIndexBars}</div>
        <div
          onClick={() => {
            setModalOpen(false);
          }}
          className={styles.xmark}
        >
          <CloseButtonSummary />
        </div>
        <Swiper
          min-height={200}
          autoHeight={false}
          slidesPerView={1}
          touchReleaseOnEdges={true}
          scrollbar={{ draggable: true }}
          threshold={0.5}
          onSwiper={setMySwiperRef}
          onSlideChange={(e) => {
            setIndex(e.activeIndex);
          }}
        >
          {mappedStories}
        </Swiper>
      </main>
    </ModalWrapper>
  );
}
