import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  DimensionEventStatus,
  PublicProfile,
} from "@dimensional-engineering/dimensional-models";

import styles from "./PatternTraitsPageLeftBar.module.css";

import { ExtendedPattern } from "@/models/sharedModels";
import { RootState } from "@/redux/store";
import TraitScoreBar from "../../shared/TraitScoreBar/TraitScoreBar";
import { getUserPatternScore, traitPermissionStatus } from "../../utils";
import useConnectionStatus from "@/helpers/useConnectionStatus";
import TraitAccessDeniedBar from "../../shared/TraitAccessDeniedBar/TraitAccessDeniedBar";
import TraitDescription from "../../shared/TraitScoreTable/TraitDescription";
import useGetIsPremium from "@/helpers/useGetIsPremium";
import PremiumTraitAccessDeniedBar from "@/components/premium/PremiumTraitAccessDeniedBar/PremiumTraitAccessDeniedBar";
import PrivateTraitAccessDeniedBar from "../../shared/TraitAccessDeniedBar/PrivateTraitAccessDeniedBar";
import LargePatternModal from "../Pattern/largePattern/LargePatternModal";
import { AvailableToComponentAvailibility } from "@/components/shared/AvailableToComponent/AvailableToComponent";
import PatternComputeBar from "./PatternComputeBar/PatternComputeBar";
import { natureMap } from "@/assets/traitInfo/natureData";

type LeftBarProps = {
  pattern: ExtendedPattern;
  nonOwnProfile?: boolean;
  friendScore?: number | null | undefined;
  publicProfile?: PublicProfile | null;
};

export default function PatternTraitsPageLeftBar(props: LeftBarProps) {
  const [score, setScore] = useState<number | null>();
  const userState = useSelector((state: RootState) => state.user);
  const friendStatus = useConnectionStatus(props.publicProfile?.ownerUUID);
  const isUserPremium = useGetIsPremium(
    props.nonOwnProfile
      ? props.publicProfile?.ownerUUID
      : userState.publicProfileData?.ownerUUID
  );
  const [requiresCompute, setRequiresCompute] = useState<boolean>(false);

  useEffect(() => {
    if (props.nonOwnProfile) {
      setScore(getUserPatternScore(props.friendScore, props.pattern.slug));
    } else {
      userState.patternsDiscovered?.forEach((patt) => {
        if (patt.patternSlug === props.pattern.slug) {
          setScore(getUserPatternScore(patt.percentScore, patt.patternSlug));
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.nonOwnProfile, props.friendScore, userState.patternsDiscovered]);

  useEffect(() => {
    setRequiresCompute(false);
    const requiredDimensions = props.pattern.dimensionSlugs;
    const finishedSlugs =
      userState.dimensionEvents
        ?.filter((d) => d.event === DimensionEventStatus.complete)
        .map((d) => d.dimensionSlug) || [];

    if (
      (score === null || score === undefined) &&
      requiredDimensions.every((dim) => finishedSlugs.includes(dim))
    ) {
      setRequiresCompute(true);
    }
  }, [score, props.pattern.dimensionSlugs, userState.dimensionEvents]);

  if (!props.nonOwnProfile) {
    // own profile
    return (
      <div className={styles.leftBarModal}>
        <LargePatternModal pattern={props.pattern} />
        <div className={styles.defDivModal}>
          <p>{props.pattern.shortDescription}</p>
        </div>
        {props.pattern.isPremium && !isUserPremium ? (
          <div className={styles.premiumDeniedDiv}>
            <PremiumTraitAccessDeniedBar />
          </div>
        ) : requiresCompute ? (
          <div className={styles.scoreDiv}>
            <PatternComputeBar />
          </div>
        ) : (
          <div className={styles.scoreDiv}>
            <TraitScoreBar score={score != null ? score * 100 : null} />
          </div>
        )}
        <TraitDescription
          type="Pattern"
          childElementCount={
            props.pattern.patternDetails.componentTraits.length
          }
          nature={
            props.pattern.nature
              ? natureMap[props.pattern.nature].name
              : undefined
          }
          color={
            props.pattern.nature
              ? natureMap[props.pattern.nature].color
              : undefined
          }
          dimension={
            props.pattern.dimensionSlugs.length > 1
              ? null
              : props.pattern.dimensionSlugs[0]
          }
          plasticity={props.pattern.plasticity}
          patternGroupSlug={props.pattern.patternGroupSlug}
          oppositeTraitSlug={
            props.pattern.traitContent.oppositeDetails?.oppositeTrait.slug
          }
          averageUserScore={props.pattern.traitContent?.averagePercentScore}
        />
        <div className={styles.availability}>
          <AvailableToComponentAvailibility
            traits
            connectionAvailability={props.pattern.connectionAvailabilities}
          />
        </div>
      </div>
    );
  } else {
    ///connected profile
    const status = traitPermissionStatus(
      friendStatus,
      props.pattern.connectionAvailabilities
    );
    return (
      <div className={styles.leftBarModal}>
        <LargePatternModal pattern={props.pattern} />
        <div className={styles.defDivModal}>
          <p>{props.pattern.shortDescription}</p>
        </div>
        <div className={styles.scoreDiv}>
          {status === "private" ? (
            <PrivateTraitAccessDeniedBar />
          ) : props.pattern.isPremium && !isUserPremium ? (
            <div className={styles.premiumDeniedDiv}>
              <PremiumTraitAccessDeniedBar
                notOwn
                traitName={props.pattern.name}
              />
            </div>
          ) : status === null ? (
            <TraitScoreBar
              friendPublicProfile={props.publicProfile}
              nonOwner
              score={score != null ? score * 100 : null}
            />
          ) : (
            <TraitAccessDeniedBar
              publicProfile={props.publicProfile}
              connectionTypeNeeded={status}
            />
          )}
        </div>
        <TraitDescription
          type="Pattern"
          childElementCount={
            props.pattern.patternDetails.componentTraits.length
          }
          nature={
            props.pattern.nature
              ? natureMap[props.pattern.nature].name
              : undefined
          }
          plasticity={props.pattern.plasticity}
          patternGroupSlug={props.pattern.patternGroupSlug}
        />
        <div className={styles.availability}>
          <AvailableToComponentAvailibility
            traits
            connectionAvailability={props.pattern.connectionAvailabilities}
          />
        </div>
      </div>
    );
  }
}
