/* eslint-disable @next/next/no-img-element */
import { useEffect, useState } from "react";
import {
  Archetype as ArchetypeModelFromNPM,
  PublicProfile,
} from "@dimensional-engineering/dimensional-models";

import styles from "./Archetypes.module.css";

import { AppArchetype } from "./Archetype";
import { ArchetypeUnknownApp } from "./ArchetypeUnknown";
import ArchetypeAccessDenied from "./ArchetypeAccessDenied";
import { useModalContext } from "@/context/ModalContext";
import ArchetypeModal from "../../TraitsModals/Archetype/ArchetypeModal";
import NudgeModal from "@/components/shared/NudgeModal/NudgeModal";
import ResultsHeading from "@/components/results/ResultsHeading/ResultsHeading";

type ArchetypeModel = {
  dimensionSlug: string;
  archetypeScore: number;
  archetypeSlug: string;
  archetype: ArchetypeModelFromNPM;
};

type ArchetypesProps = {
  archetypes: ArchetypeModel[];
  username: string | undefined;
  uid: string | null;
  connection:
    | "connected"
    | "close"
    | "deep"
    | "sent"
    | "unconnected"
    | "received"
    | null;
  publicProfile: PublicProfile | null;
};

export default function ConnectedArchetypes(props: ArchetypesProps) {
  const leftOutArchs = useLeftoutArchetypes({ archetypes: props.archetypes });
  const { setModalComponent } = useModalContext();

  function handleUndiscoveredClick(arch: string) {
    const archetypeGroupSlug = arch === "interaction" ? "work" : arch;
    setModalComponent(
      <NudgeModal
        publicProfile={props.publicProfile}
        archetypeGroupSlug={archetypeGroupSlug}
      />
    );
  }

  const mapped = props.archetypes
    .filter((a) => a.archetype.archetypeGroupSlug !== "spirirt")
    .map((arch, index) => {
      if (arch.dimensionSlug === "love") {
        if (props.connection !== "close" && props.connection !== "deep") {
          return (
            <ArchetypeAccessDenied
              key={index}
              publicProfile={props.publicProfile}
              name={"Love"}
            />
          );
        }
      }
      return (
        <div
          onClick={() =>
            setModalComponent(
              <ArchetypeModal
                slug={arch.archetypeSlug}
                uid={props.uid ?? undefined}
              />
            )
          }
          key={index}
        >
          <AppArchetype {...arch} />
        </div>
      );
    });

  const nonDiscoveredMap = leftOutArchs.map((l, i) => {
    if (l === "love") {
      if (props.connection !== "close" && props.connection !== "deep") {
        return (
          <div onClick={() => handleUndiscoveredClick(l)} key={i}>
            <ArchetypeAccessDenied
              publicProfile={props.publicProfile}
              name={l}
            />
          </div>
        );
      }
    }
    return (
      <div onClick={() => handleUndiscoveredClick(l)} key={i}>
        <ArchetypeUnknownApp locked={false} name={l} />
      </div>
    );
  });

  return (
    <div className={styles.archetypesMain}>
      <ResultsHeading title="Archetypes" />
      <div className={styles.archetypesParent}>
        {props.archetypes && mapped}
        {nonDiscoveredMap}
      </div>
    </div>
  );
}

function useLeftoutArchetypes(props: { archetypes: ArchetypeModel[] }) {
  const [leftOutArchs, setLeftOutArchs] = useState<string[]>([]);

  useEffect(() => {
    let has = [];
    let leftOut = [];

    for (let i = 0; i < props.archetypes.length; i++) {
      has.push(props.archetypes[i].dimensionSlug);
    }
    // if (!has.includes("values")) {
    //   leftOut.push("values");
    // }
    if (!has.includes("identity")) {
      leftOut.push("cognition");
    }
    if (!has.includes("love")) {
      leftOut.push("love");
    }
    if (!has.includes("work")) {
      leftOut.push("interaction");
    }
    if (!has.includes("career")) {
      leftOut.push("career");
    }

    setLeftOutArchs(leftOut);
  }, [props.archetypes]);

  return leftOutArchs;
}
