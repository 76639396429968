import { useEffect, useRef, useState } from "react";
import html2canvas from "html2canvas";
import {
  ContentType,
  DimensionSummary,
} from "@dimensional-engineering/dimensional-models";
import { useSelector } from "react-redux";
import { DM_Sans } from "next/font/google";

import styles from "./DimensionSummariesShareView.module.css";

import ModalWrapper from "@/components/shared/ModalWrapper/ModalWrapper";
import { RootState } from "@/redux/store";
import DimensionDichotomy from "../DimensionDichotomy/DimensionDichotomy";
import ScoreAndDescriptor from "../ScoreAndDescriptor/ScoreAndDescriptor";
import SignatureNonSignatureSummary from "../SignatureNonSignatureSummary/SignatureNonSignatureSummary";
import {
  dimensionData,
  dimensionIcons,
} from "@/assets/traitInfo/dimensionData";
import SmallProfileImage from "@/components/shared/SmallProfileImage/SmallProfileImage";
import PremiumGatedResults from "@/components/premium/PremiumGatedResults/PremiumGatedResults";
import { useModalContext } from "@/context/ModalContext";
import { TwoByTwoNewNoData } from "@/components/shared/TwoByTwoNew/TwoByTwoNew";
import LoadingScreen from "@/components/assessments-new/views/AsessmentOutroScreen/loadingScreen/LoadingScreen";

const fontSans = DM_Sans({ weight: "500", subsets: ["latin"] });

export default function DimensionSummariesShareView(props: {
  slug: string;
  data: DimensionSummary | undefined;
  type: "instagram" | "snapchat";
}) {
  const userState = useSelector((state: RootState) => state.user);
  const summary = userState.dimensionSummaries?.find(
    (s) => s.dimensionSlug === props.slug
  );
  const dimension = dimensionData?.find((d) => d.slug === props.slug);

  const { setModalOpen } = useModalContext();
  const imageRef = useRef<HTMLDivElement>(null);

  const [loading, setLoading] = useState<boolean | null>(null);

  useEffect(() => {
    props.data?.summaryViews.forEach((summ) => {
      if (
        summ.summaryType === "dichotomyArchetype" ||
        summ.summaryType === "twoByTwoGrid"
      ) {
        setLoading(true);
      } else {
        setLoading(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleDownloadImage = async () => {
      if (imageRef.current) {
        const canvas = await html2canvas(imageRef.current);
        // var a = document.createElement("a");
        // a.href = canvas
        //   .toDataURL("image/jpeg")
        //   .replace("image/jpeg", "image/octet-stream");
        // a.download = "somefilename.jpg";
        // a.click();

        const data = canvas.toDataURL("image/jpg");
        if (window.Android) {
          window.Android.onHaptic();

          if (props.type === "instagram") {
            window.Android.shareToInstagramQuiz(data);
          } else {
            window.Android.shareToSnapChat(data);
          }
        }
      }
    };

    setTimeout(() => {
      if (loading === false) {
        handleDownloadImage();
        setModalOpen(false);
      }
    }, 300);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const summaryMapper = props.data?.summaryViews.map((summ, index) => {
    if (summ.summaryType === "dichotomyArchetype") {
      return (
        <DimensionDichotomy
          key={index}
          summary={summ.summaryData}
          slug={props.slug}
          onLoad={() => setLoading(false)}
        />
      );
    }
    if (summ.summaryType === "scoreAndDescriptor") {
      return <ScoreAndDescriptor key={index} {...summ.summaryData} />;
    }
    if (summ.summaryType === "signatureNonSignature") {
      return (
        <SignatureNonSignatureSummary
          data={summ.summaryData}
          key={index}
          title={summary?.name ?? ""}
          icon={dimensionIcons[props.slug].icon as JSX.Element}
          isPremium={false}
          nonNumbered={props.slug === "secondary-traits"}
        />
      );
    }
    if (summ.summaryType === "twoByTwoGrid") {
      return (
        <TwoByTwoNewNoData
          key={index}
          imageUrl={userState.imageURL ?? ""}
          publicProfile={userState.publicProfileData}
          contentIdentifier={{
            contentSlug: props.slug,
            contentType: ContentType.dimensionSummary,
          }}
          onLoad={() => setLoading(false)}
        />
      );
    }
  });

  return (
    <ModalWrapper noHeader noLine title="">
      {loading ? (
        <div className={styles.loadingScreenDiv}>
          <LoadingScreen message="" />
        </div>
      ) : null}
      <main
        ref={imageRef}
        style={{
          backgroundImage: `url(/dimensions/backgrounds/${props.slug}.jpg)`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className={styles.main}
      >
        <DimensionSummariesWatermark />
        <div className={styles.top}>
          <SmallProfileImage
            imageUrl={userState.imageURL ?? ""}
            medium
            publicProfile={userState.publicProfileData}
          />
          <h1>{dimension?.title}</h1>
        </div>
        {dimension?.isPremium && !userState.isPremium && (
          <div className={styles.premiumGateDiv}>
            <PremiumGatedResults />
          </div>
        )}
        <div className={styles.summary}>{summaryMapper}</div>
      </main>
    </ModalWrapper>
  );
}

function DimensionSummariesWatermark() {
  return (
    <div className={styles.waterMarkDiv}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="44"
        height="14"
        viewBox="0 0 44 14"
        fill="none"
      >
        <rect
          x="15.2168"
          y="0.554688"
          width="3.75957"
          height="12.89"
          rx="1.87978"
          fill="#FFFFF2"
        />
        <rect
          x="19.7627"
          y="0.554688"
          width="3.75957"
          height="12.89"
          rx="1.87978"
          fill="#FFFFF2"
        />
        <rect
          x="24.3398"
          y="0.554688"
          width="3.75957"
          height="12.89"
          rx="1.87978"
          fill="#FFFFF2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.44498 13.4446C10.0044 13.4446 12.89 10.5591 12.89 6.99966C12.89 3.4402 10.0044 0.554688 6.44498 0.554688C2.88551 0.554688 0 3.4402 0 6.99966C0 10.5591 2.88551 13.4446 6.44498 13.4446ZM6.42258 8.04349C7.01141 8.04349 7.48876 7.56614 7.48876 6.97731C7.48876 6.38847 7.01141 5.91113 6.42258 5.91113C5.83374 5.91113 5.35639 6.38847 5.35639 6.97731C5.35639 7.56614 5.83374 8.04349 6.42258 8.04349Z"
          fill="#FFFFF2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M42.3145 13.1423C43.617 13.1423 44.4256 11.7259 43.7635 10.6041L38.5054 1.69552C37.8543 0.592378 36.2586 0.59238 35.6075 1.69552L30.3494 10.6041C29.6874 11.7259 30.4959 13.1423 31.7984 13.1423H42.3145ZM37.0679 9.28088C37.672 9.28088 38.1616 8.79117 38.1616 8.1871C38.1616 7.58302 37.672 7.09332 37.0679 7.09332C36.4639 7.09332 35.9743 7.58302 35.9743 8.1871C35.9743 8.79117 36.4639 9.28088 37.0679 9.28088Z"
          fill="#FFFFF2"
        />
      </svg>
      <p className={`${styles.waterMarkText} ${fontSans.className}`}>
        www.dimensional.me
      </p>
    </div>
  );
}
