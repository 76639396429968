/* eslint-disable react/no-children-prop */
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import styles from "../../../../styles/app/AuthTraits/ElementModal.module.css";

import ModalWrapper from "@/components/shared/ModalWrapper/ModalWrapper";
import useGetArcehtypeModalData from "./useGetArcehtypeModalData";
import Loader from "@/components/shared/Loader";
import ArchetypeTraitsPageLeftBarModal from "../../arcehtype/ArchetypeTraitsPage/ArchetypeTraitsPageLeftBarModal";
import OtherArchetypes from "@/components/traits/detailsTab/archetypes/otherArchetypes/OtherArchetypes.";
import OppositeArchetype from "@/components/traits/detailsTab/archetypes/opposite/OppositeArchetype";
import Dichotomies from "@/components/traits/detailsTab/archetypes/dichotomies/Dichotomies";
import ArchetypeComponentTree from "@/components/traits/detailsTab/archetypes/archetypeComponents/ArchetypeComponentTree";
import SwipeTabs from "@/components/shared/SwipeTabs/SwipeTabs";
import ReactMarkdown from "react-markdown";
import { ArchetypeConnectionsTab } from "../../connectionTab/ConnectionTab";
import { CartesianModalBreakdown } from "../../detailsTab/archetypes/cartesian/CartesianModal";
import { useAuth } from "@/context/AuthContext";
import InteractionBar from "../../shared/InteractionBar/InteractionBar";
import { RootState } from "@/redux/store";
import { ValuesArchetypeBreakdownDetailsTab } from "../../detailsTab/archetypes/ValuesArchetypeBreakdown/ValuesArchetypeBreakdown";
import LargeArchetypeModal from "../../arcehtype/Archetypes/LargeArchetype/LargeArchetypeModal";
import { CareerArchetypeBreakdownDetailsView } from "../../detailsTab/archetypes/CareerArchetypeBreakdown/CareerArchetypeBreakdown";
import TraitDescription from "../../shared/TraitScoreTable/TraitDescription";

export default function OwnArchetypeModal(props: { slug: string }) {
  const { archetype, otherArchetypes } = useGetArcehtypeModalData({
    slug: props.slug,
  });
  const [tabs, setTabs] = useState<string[]>([
    "summary",
    "details",
    "identity",
  ]);
  const [markdownContent, setMarkdownContent] = useState<string[]>([]);
  const [slicedMarkdown, setSlicedMarkdown] = useState<string[]>([]);
  const { user } = useAuth();
  const archetypeDiscovery = useSelector((state: RootState) =>
    state.user.archetypesDiscovered?.find(
      (a) => a.archetypeSlug === archetype?.slug
    )
  );
  const publicProfile = useSelector(
    (state: RootState) => state.user.publicProfileData
  );

  useEffect(() => {
    setTabs(["summary", "details", "identity"]);
    setMarkdownContent([]);
    setSlicedMarkdown([]);
    archetype?.traitContent.markdownSections?.forEach((section, index) => {
      if (!tabs.includes(section.traitSection as string)) {
        setTabs((current) => [...current, section.traitSection as string]);
      }
      setMarkdownContent((current) => [...current, section.markdownString]);
      if (index !== 0) {
        setSlicedMarkdown((current) => [...current, section.markdownString]);
      }
    });
    setTabs((current) => [...current, "Connections"]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [archetype?.slug]);

  if (archetype === null) {
    return (
      <ModalWrapper title="">
        <Loader height="500px" mobileHeight="500px" />
      </ModalWrapper>
    );
  }

  return (
    <ModalWrapper noLine title={archetype.name}>
      <div className={styles.main}>
        <div className={styles.parent}>
          <div className={styles.rightBarMain}>
            <SwipeTabs
              sticky
              content={[
                <ArchetypeTraitsPageLeftBarModal
                  key={1}
                  archetype={archetype}
                  archetypeDiscovery={archetypeDiscovery}
                  publicProfile={publicProfile}
                />,
                <div
                  style={
                    archetype.archetypeGroupSlug === "love"
                      ? { minHeight: "1900px" }
                      : {}
                  }
                  key={2}
                >
                  <div className={styles.archetypeDiv}>
                    <LargeArchetypeModal archetype={archetype} border />
                  </div>

                  <TraitDescription
                    type="Archetype"
                    archetypeGroupSlug={archetype.archetypeGroupSlug}
                    dimension={
                      archetype.dimensionSlugs.length > 1
                        ? null
                        : archetype.dimensionSlugs[0]
                    }
                    usersWithArchetypeNumber={
                      archetype.traitContent.signatureFrequency
                    }
                    plasticity={archetype.plasticity}
                    oppositeTraitSlug={
                      archetype.traitContent.oppositeDetails?.oppositeTrait.slug
                    }
                  />

                  {archetype.archetypeGroupSlug === "values" && (
                    <ValuesArchetypeBreakdownDetailsTab archetype={archetype} />
                  )}
                  {archetype.archetypeGroupSlug === "work" && (
                    <CartesianModalBreakdown archetype={archetype} />
                  )}
                  {archetype.traitContent.polarityArchetypeDetails && (
                    <Dichotomies
                      data={archetype.traitContent}
                      useSpectrum
                      slug={archetype.slug}
                    />
                  )}
                  {archetype.archetypeGroupSlug === "career" && (
                    <CareerArchetypeBreakdownDetailsView
                      archetype={archetype}
                    />
                  )}
                  {/* <Histogram
                    // @ts-ignore
                    data={archetype.traitContent.histogramData}
                    auth
                    type="archetype"
                    averageUsrScore={archetype.traitContent.averagePercentScore}
                    modal
                  /> */}
                  <OtherArchetypes archetypes={otherArchetypes} authenticated />
                  {archetype.traitContent.oppositeDetails?.oppositeTrait
                    .slug && (
                    <OppositeArchetype authenticated archetype={archetype} />
                  )}
                  {archetype.traitContent.archetypeDetails?.componentTraits && (
                    <ArchetypeComponentTree
                      archetype={archetype}
                      authenticated
                    />
                  )}
                </div>,
                <div
                  style={{ pointerEvents: "none" }}
                  key={3}
                  className={styles.markdownDiv}
                >
                  <ReactMarkdown
                    transformLinkUri={(href: string) => {
                      return "";
                    }}
                    children={markdownContent[0]}
                  />
                </div>,
              ]}
              slicedContent={slicedMarkdown}
              tabs={tabs}
              grow={tabs.length < 4 ? true : false}
              connectionTab={[
                <ArchetypeConnectionsTab
                  slug={props.slug}
                  signatureFrequency={archetype.traitContent.signatureFrequency}
                  key={3}
                />,
              ]}
            />
          </div>
        </div>
      </div>
      {user?.uid && (
        <InteractionBar
          uid={user?.uid}
          slug={archetype.slug}
          type="archetype"
          ownProfile
        />
      )}
    </ModalWrapper>
  );
}
