import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import {
  DiscoveredCompatibilitySnippet,
  TraitIdentifier,
} from "@dimensional-engineering/dimensional-models";

import styles from "../CompatibilitySnippets.module.css";

import {
  ExtendedPattern,
  ExtendedScale,
  FriendsPublicProfile,
} from "@/models/sharedModels";
import SimilarIcon, {
  NonSimilarIcon,
} from "@/components/shared/icons/SimilarityIcons";
import { ProfileColorMap } from "@/components/shared/ColorMap";
import { useModalContext } from "@/context/ModalContext";
import { getElementFromTraitIdentifier, toSentenceCase } from "../utils";
import ElementModal from "@/components/traits/TraitsModals/Element/ElementModal";
import PatternModal from "@/components/traits/TraitsModals/Pattern/PatternModal";
import { getConnectionProfileColor } from "@/components/shared/utils";
import { natureData } from "@/assets/traitInfo/natureData";

type Props = {
  snippet: DiscoveredCompatibilitySnippet | null;
  connection: FriendsPublicProfile | null;
};

export default function CompatibilityListView(props: Props) {
  const userState = useSelector((state: RootState) => state.user);
  const { setModalComponent } = useModalContext();
  const connectionColor = getConnectionProfileColor(
    userState.publicProfileData?.primaryNature?.nature,
    props.connection?.primaryNature?.nature
  );

  function handleClick(trait: TraitIdentifier) {
    if (trait.type === "element") {
      setModalComponent(<ElementModal slug={trait.slug} />);
    } else {
      setModalComponent(<PatternModal slug={trait.slug} />);
    }
  }

  const mappedIntersection =
    props.snippet?.snippetDetail.comparisonListDetail?.intersectTraitIdentifiers.map(
      (trait) => {
        const element = getElementFromTraitIdentifier(
          trait as TraitIdentifier
        ) as ExtendedScale | ExtendedPattern | null;
        const borderColor = natureData?.find(
          (n) => n.slug === element?.nature
        )?.color;

        if (element && element.isDiscoverable === true) {
          return (
            <p
              onClick={() => {
                handleClick(trait);
              }}
              className={styles.noun}
              key={trait.slug}
              style={borderColor ? { borderColor: borderColor } : {}}
            >
              {trait.descriptor
                ? toSentenceCase(trait.descriptor)
                : trait.descriptor}
            </p>
          );
        }
      }
    );

  const mappedUniqueMe =
    props.snippet?.snippetDetail.comparisonListDetail?.myTraitIdentifiers.map(
      (trait) => {
        const element = getElementFromTraitIdentifier(
          trait as TraitIdentifier
        ) as ExtendedScale | ExtendedPattern | null;
        const borderColor = natureData?.find(
          (n) => n.slug === element?.nature
        )?.color;

        if (element && element.isDiscoverable === true) {
          return (
            <p
              onClick={() => {
                handleClick(trait);
              }}
              className={styles.noun}
              style={borderColor ? { borderColor: borderColor } : {}}
              key={trait.slug}
            >
              {trait.descriptor
                ? toSentenceCase(trait.descriptor)
                : trait.descriptor}
            </p>
          );
        }
      }
    );

  const mappedUniqueConnection =
    props.snippet?.snippetDetail.comparisonListDetail?.connectionTraitIdentifiers.map(
      (trait) => {
        const element = getElementFromTraitIdentifier(
          trait as TraitIdentifier
        ) as ExtendedScale | ExtendedPattern | null;
        const borderColor = natureData?.find(
          (n) => n.slug === element?.nature
        )?.color;

        if (element && element.isDiscoverable === true) {
          return (
            <p
              onClick={() => {
                handleClick(trait);
              }}
              className={styles.noun}
              style={borderColor ? { borderColor: borderColor } : {}}
              key={trait.slug}
            >
              {trait.descriptor
                ? toSentenceCase(trait.descriptor)
                : trait.descriptor}
            </p>
          );
        }
      }
    );

  return (
    <div className={styles.spectrumsParent}>
      {props.snippet?.snippetDetail.comparisonListDetail?.subheading && (
        <h3 className={styles.spectrumHeading}>
          {props.snippet?.snippetDetail.comparisonListDetail?.subheading}
        </h3>
      )}
      {props.snippet?.snippetDetail.comparisonListDetail
        ?.subheadingDescription && (
        <h4 className={styles.spectrumSubHeading}>
          {
            props.snippet?.snippetDetail.comparisonListDetail
              ?.subheadingDescription
          }
        </h4>
      )}

      <div className={styles.listSection}>
        <div className={styles.listTitleDiv}>
          <h3 className={styles.listTitle}>BOTH OF YOU</h3>
          <SimilarIcon
            secondaryColor={connectionColor}
            primaryColor={
              userState.publicProfileData?.primaryNature?.nature
                ? ProfileColorMap[
                    userState.publicProfileData?.primaryNature
                      ?.nature as keyof typeof ProfileColorMap
                  ]
                : "#55504F"
            }
          />
        </div>
        <div className={styles.nounDiv}>{mappedIntersection}</div>
      </div>

      <div className={styles.listSection}>
        <div className={styles.listTitleDiv}>
          <h3 className={styles.listTitle}>
            UNIQUELY @{userState.publicProfileData?.userName?.toUpperCase()}
          </h3>
          <NonSimilarIcon
            secondaryColor={
              userState.publicProfileData?.primaryNature?.nature
                ? ProfileColorMap[
                    userState.publicProfileData?.primaryNature
                      ?.nature as keyof typeof ProfileColorMap
                  ]
                : "#55504F"
            }
            primaryColor={"#55504F"}
          />
        </div>
        <div className={styles.nounDiv}>{mappedUniqueMe}</div>
      </div>

      <div className={styles.listSection}>
        <div className={styles.listTitleDiv}>
          <h3 className={styles.listTitle}>
            UNIQUELY @
            {props.connection?.userName
              ? props.connection?.userName.toUpperCase()
              : ""}
          </h3>
          <NonSimilarIcon
            secondaryColor={"#55504F"}
            primaryColor={connectionColor}
          />
        </div>
        <div className={styles.nounDiv}>{mappedUniqueConnection}</div>
      </div>
    </div>
  );
}
