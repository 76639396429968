import { callFirebaseFunctions } from "@/_firebase/callFirebaseFunctions";
import { db } from "@/_firebase/firebaseConfig";
import {
  GPTConversation,
  GPTConversationType,
  GPTMessage,
  GPTMessageStatus,
  GPTRole,
} from "@dimensional-engineering/dimensional-models";
import {
  collection,
  doc,
  getDoc,
  runTransaction,
  setDoc,
  updateDoc,
} from "firebase/firestore";

export enum SuggestedMessageCategory {
  SelfAwareness = "selfAwareness",
  Love = "love",
  Purpose = "purpose",
  ForFun = "forFun",
  Life = "life",
  All = "all",
}

export const suggestedCategoryNameMapper: Record<
  SuggestedMessageCategory,
  string
> = {
  selfAwareness: "SELF-AWARENESS",
  love: "LOVE",
  purpose: "PURPOSE",
  forFun: "FOR FUN",
  life: "LIFE",
  all: "ALL",
};

export const suggestedMessagesMapper: Record<
  SuggestedMessageCategory,
  string[]
> = {
  [SuggestedMessageCategory.SelfAwareness]: [
    "What are the 5 deepest themes you see in my personality?",
    "What am I motivated by in life?",
    "What am I like at my best?",
    "What am I like at my worst?",
    "What are my 3 greatest fears?",
    "How do I relate to other people?",
    "In what ways are my personality traits in tension? List the internal conflicts I might be experiencing.",
  ],
  [SuggestedMessageCategory.Love]: [
    "What kinds of people should I date? What kinds of people am I likely incompatible with?",
    "What are 5 things I uniquely offer someone in romantic relationships?",
    "What are blindspots I might have in love and relationships? Focus on areas where my traits combine to form broader themes.",
    "What are obstacles I might face when it comes to finding love?",
    "What are 3 types of people that I would be a good romantic match for?",
  ],
  [SuggestedMessageCategory.Purpose]: [
    "What is my ideal work environment like?",
    "What are 10 careers I would like?",
    "Where do I belong in the world? What are communities or environments I'd thrive in?",
    "What are things I should watch out for in my life? What are bad habits I might form or environments that might trap me?",
    "How can I live a fulfilling and authentic life?",
    "What is my leadership style (according to Daniel Goleman's framework)?",
    "What are 3 types of bosses that I would work well with?",
  ],
  [SuggestedMessageCategory.ForFun]: [
    "What are 10 fiction books I'd like?",
    "What are 10 movies I'd like?",
    "What are 10 TV shows I'd like?",
    "What are 10 foreign language films that I'd like?",
    "What are 10 non-fiction books I'd like?",
    "What are 3 words you'd use to describe me in the bedroom?",
    "What dog breed am I most like?",
  ],
  [SuggestedMessageCategory.Life]: [
    "What is my parenting style (according to Diana Baumrind's framework)?",
    "What are 3 types of people I vibe with?",
    "What are 3 types of people I don't vibe with?",
    "I'm going through something.",
    "What therapy style would be most effective for me?",
  ],
  [SuggestedMessageCategory.All]: [
    "What are the 5 deepest themes you see in my personality?",
    "What am I motivated by in life?",
    "What am I like at my best?",
    "What am I like at my worst?",
    "What are my 3 greatest fears?",
    "How do I relate to other people?",
    "In what ways are my personality traits in tension? List the internal conflicts I might be experiencing.",
    "What kinds of people should I date? What kinds of people am I likely incompatible with?",
    "What are 5 things I uniquely offer someone in romantic relationships?",
    "What are blindspots I might have in love and relationships? Focus on areas where my traits combine to form broader themes.",
    "What are obstacles I might face when it comes to finding love?",
    "What are 3 types of people that I would be a good romantic match for?",
    "What is my ideal work environment like?",
    "What are 10 careers I would like?",
    "Where do I belong in the world? What are communities or environments I'd thrive in?",
    "What are things I should watch out for in my life? What are bad habits I might form or environments that might trap me?",
    "How can I live a fulfilling and authentic life?",
    "What is my leadership style (according to Daniel Goleman's framework)?",
    "What are 3 types of bosses that I would work well with?",
    "What are 10 fiction books I'd like?",
    "What are 10 movies I'd like?",
    "What are 10 TV shows I'd like?",
    "What are 10 foreign language films that I'd like?",
    "What are 10 non-fiction books I'd like?",
    "What are 3 words you'd use to describe me in the bedroom?",
    "What dog breed am I most like?",
    "What is my parenting style (according to Diana Baumrind's framework)?",
    "What are 3 types of people I vibe with?",
    "What are 3 types of people I don't vibe with?",
    "I'm going through something.",
    "What therapy style would be most effective for me?",
  ],
};

export async function createGptConversation(
  gPTConversationType?: GPTConversationType,
  deliveredDailyInsightId?: string,
  dimensionSlug?: string,
  userMessage?: string,
  title?: string
) {
  const payload = {
    gPTConversationType: gPTConversationType ?? GPTConversationType.generic,
    deliveredDailyInsightId: deliveredDailyInsightId,
    dimensionSlug: dimensionSlug,
    userMessage: userMessage,
    title: title ? title : userMessage ? userMessage.slice(0, 30) : undefined,
  };

  const call = callFirebaseFunctions("createGPTConversation");
  return call(payload).then((res) => {
    return res.data as GPTConversation;
  });
}

export async function createUserMessage(
  uid: string | undefined,
  conversationsId: string,
  content: string
) {
  const ref = collection(
    db,
    `/members/${uid}/gPTConversations/${conversationsId}/gPTMessages`
  );

  const docRef = doc(ref);
  const messageId = docRef.id;

  const payload: GPTMessage = {
    id: messageId,
    createdOn: Date.now() - 2000,
    role: GPTRole.user,
    status: GPTMessageStatus.processing,
    content: content,
  };

  return await setDoc(docRef, payload);
}

export function getLastUserMessageForRetry(
  messages: GPTMessage[]
): GPTMessage | undefined {
  return [...messages]
    .reverse()
    .find((message) => message.role === GPTRole.user);
}

export function getLastAssistantMessageForRegeneration(
  messages: GPTMessage[]
): GPTMessage | undefined {
  return [...messages]
    .reverse()
    .find((message) => message.role === GPTRole.assistant);
}

export async function retryLastUserMessage(
  message: GPTMessage,
  uid: string | undefined,
  conversationsId: string | undefined | null
) {
  const ref = doc(
    db,
    `/members/${uid}/gPTConversations/${conversationsId}/gPTMessages/${message.id}`
  );

  return await updateDoc(ref, { status: GPTMessageStatus.queued });
}

export async function regenerateAssistantMessage(
  uid: string | undefined,
  conversationsId: string | undefined | null,
  userMessage: GPTMessage | undefined,
  assistenMessage: GPTMessage | undefined
) {
  if (userMessage && assistenMessage && conversationsId) {
    const userMessageref = doc(
      db,
      `/members/${uid}/gPTConversations/${conversationsId}/gPTMessages/${userMessage.id}`
    );

    const assistenMessageRef = doc(
      db,
      `/members/${uid}/gPTConversations/${conversationsId}/gPTMessages/${assistenMessage.id}`
    );
    return await runTransaction(db, async (transaction) => {
      transaction.delete(assistenMessageRef);
      transaction.update(userMessageref, { status: GPTMessageStatus.queued });
    });
  }
  return null;
}

export async function handleMessageUpvoteDownvote(
  vote: number,
  message: GPTMessage,
  uid: string | undefined,
  conversationsId: string | undefined | null
) {
  const ref = doc(
    db,
    `/members/${uid}/gPTConversations/${conversationsId}/gPTMessages/${message.id}`
  );

  return await updateDoc(ref, { feedbackScore: vote });
}

export async function deleteConversation(
  conversationsId: string | undefined | null
) {
  if (conversationsId) {
    const call = callFirebaseFunctions("deleteGPTConversation");
    return call({ id: conversationsId });
  }

  return null;
}

export async function renameConversation(
  uid: string | undefined,
  conversationsId: string | undefined | null,
  title: string
) {
  if (uid && conversationsId) {
    const ref = doc(db, `/members/${uid}/gPTConversations/${conversationsId}`);
    return await updateDoc(ref, { title: title.slice(0, 30) });
  }

  return null;
}

export async function getCurrentConversationToUpdateStore(
  uid: string | undefined,
  conversationsId: string | undefined | null
) {
  if (uid && conversationsId) {
    const ref = doc(db, `/members/${uid}/gPTConversations/${conversationsId}`);
    return await getDoc(ref).then((res) => {
      return res.data() as GPTConversation | null;
    });
  }
  return null;
}

export async function submitOverallChatFeedback(
  uid: string | undefined,
  conversationsId: string | undefined | null,
  feedbackScore: number | null | undefined,
  feedbackText: string | null | undefined
) {
  if (uid && conversationsId) {
    const ref = doc(db, `/members/${uid}/gPTConversations/${conversationsId}`);
    if (feedbackScore) {
      return await updateDoc(ref, {
        "conversationFeedback.feedbackScore": feedbackScore,
      });
    }
    if (feedbackText) {
      return await updateDoc(ref, {
        "conversationFeedback.feedbackText": feedbackText,
      });
    }
  }

  return null;
}
