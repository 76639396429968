import styles from "./PatternComputeBar.module.css";

import { useModalContext } from "@/context/ModalContext";
import LoadingModal from "@/components/shared/LoadingModal/LoadingModal";
import { callFirebaseFunctions } from "@/_firebase/callFirebaseFunctions";

export default function PatternComputeBar() {
  const { setModalComponent, removeModalByName } = useModalContext();

  const callPatternDiscovery = async () => {
    setModalComponent(<LoadingModal elementName="LoadingModal" />);
    const call = callFirebaseFunctions("computeOutstandingPatterns");
    return await call({})
      .then((res) => {
        removeModalByName("LoadingModal");
      })
      .catch((err) => {
        removeModalByName("LoadingModal");
      });
  };

  return (
    <div className={styles.main}>
      <p className={styles.text}>Score for this trait needs to be computed.</p>
      <button onClick={callPatternDiscovery} className={styles.btn}>
        Compute
      </button>
    </div>
  );
}
