/* eslint-disable @next/next/no-img-element */
import {
  Nature,
  PublicProfile,
} from "@dimensional-engineering/dimensional-models";

import styles from "./SmallProfileImage.module.css";

import { ProfileColorMap } from "../ColorMap";
import DeepConnectionIcon from "../icons/DeepConnectionIcon";
import CloseConnectionIcon from "../icons/CloseConnectionIcon";
import { useModalContext } from "@/context/ModalContext";
import ProfileModalHandler from "@/components/authenticatedProfile/Abstracts/ProfileModal/ProfileModalHandler";
import Shimmer from "../Shimmer/Shimmer";

export default function SmallProfileImage(props: {
  publicProfile: PublicProfile | null;
  imageUrl: string | undefined;
  medium?: boolean;
  isClose?: boolean;
  isDeep?: boolean;
  noLink?: boolean;
}) {
  const { setModalComponent } = useModalContext();

  if (props.noLink) {
    return (
      <div
        className={
          props.medium
            ? styles.mediumImageContainer
            : styles.smallImageContainer
        }
        style={
          props.publicProfile?.primaryNature?.nature
            ? {
                border: `3px solid ${
                  ProfileColorMap[props.publicProfile.primaryNature?.nature]
                }`,
              }
            : {
                border: `3px solid #55504F`,
              }
        }
      >
        {props.imageUrl === "" && <Shimmer rounded />}
        <img
          alt=""
          src={props.imageUrl ?? "/default-image.png"}
          className={props.medium ? styles.mediumImage : styles.smallImage}
          style={props.imageUrl === "" ? { display: "none" } : {}}
        />
        {props.isClose && (
          <div className={styles.iconDiv}>
            <CloseConnectionIcon active />
          </div>
        )}
        {props.isDeep && (
          <div className={styles.iconDiv}>
            <DeepConnectionIcon active />
          </div>
        )}
      </div>
    );
  }

  return (
    <div
      onClick={(e) => {
        setModalComponent(
          <ProfileModalHandler
            uid={props.publicProfile?.ownerUUID}
            elementName="profileModal"
          />
        );
      }}
      className={
        props.medium ? styles.mediumImageContainer : styles.smallImageContainer
      }
      style={
        props.publicProfile?.primaryNature?.nature
          ? {
              border: `3px solid ${
                ProfileColorMap[props.publicProfile.primaryNature?.nature]
              }`,
            }
          : {
              border: `3px solid #55504F`,
            }
      }
    >
      {props.imageUrl === "" && <Shimmer rounded />}
      <img
        alt=""
        src={props.imageUrl ?? "/default-image.png"}
        className={props.medium ? styles.mediumImage : styles.smallImage}
        style={props.imageUrl === "" ? { display: "none" } : {}}
      />
      {props.isClose && (
        <div className={styles.iconDiv}>
          <CloseConnectionIcon active />
        </div>
      )}
      {props.isDeep && (
        <div className={styles.iconDiv}>
          <DeepConnectionIcon active />
        </div>
      )}
    </div>
  );
}

export function NotificationProfileImage(props: {
  nature?: Nature | null | undefined;
  peerId?: string | null;
  imageUrl: string;
}) {
  const { setModalComponent } = useModalContext();

  return (
    <div
      onClick={(e) => {
        if (props.peerId) {
          setModalComponent(
            <ProfileModalHandler
              uid={props.peerId}
              elementName="profileModal"
            />
          );
        }
      }}
      className={styles.mediumImageContainer}
      style={
        props.nature
          ? {
              border: `3px solid ${ProfileColorMap[props.nature]}`,
            }
          : {
              border: `3px solid #55504F`,
            }
      }
    >
      {props.imageUrl === "" && <Shimmer rounded />}
      <img
        style={props.imageUrl === "" ? { display: "none" } : {}}
        alt=""
        src={props.imageUrl}
        className={styles.mediumImage}
      />
    </div>
  );
}
