import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Roboto_Mono } from "next/font/google";
import moment from "moment";

import styles from "./DailyStories.module.css";

import { RootState } from "@/redux/store";
import { formatDailyStoryTime, splitStringIntoThreeLines } from "./utils";
import { useModalContext } from "@/context/ModalContext";
import DailyStoriesModal from "./DailyStoriesModal";
import DailyStoriesGhostbox from "./DailyStoriesGhostbox";
import DailyContentStatusBar from "../DailyContentStatusBar/DailyContentStatusBar";
import { Mixpanel } from "@/helpers/mixpanel";
import Shimmer from "@/components/shared/Shimmer/Shimmer";

const font = Roboto_Mono({ subsets: ["latin"] });

export default function DailyStoriesHomeCTA() {
  const { setModalComponent } = useModalContext();

  const dailyStory = useSelector((state: RootState) => state.dailyStory);

  const time = useGetDailyStoryTimer(dailyStory.dailyStory?.deliveredOn);

  if (!dailyStory.dailyStoryLoaded) {
    return (
      <section
        style={{ height: "350px", padding: "0" }}
        className={styles.mainCTA}
      >
        <Shimmer />
      </section>
    );
  }

  if (
    !dailyStory.dailyStory ||
    !dailyStory.dailyStory.assignmentRuleset?.body
  ) {
    return <DailyStoriesGhostbox />;
  }

  const bodySplit = splitStringIntoThreeLines(
    dailyStory.dailyStory.assignmentRuleset?.body,
    30
  );

  if (bodySplit.length === 0) {
    return <DailyStoriesGhostbox />;
  }

  const mappedBody = bodySplit.map((line, i) => {
    return (
      <p className={styles.storyBody} key={i}>
        {line}
      </p>
    );
  });

  const storyName = dailyStory.dailyStory.name.toUpperCase();
  const midIndex = Math.floor(storyName.length / 2);
  const nearestSpaceIndex = storyName.lastIndexOf(" ", midIndex);
  const splitIndex = nearestSpaceIndex !== -1 ? nearestSpaceIndex : midIndex;
  const firstHalf = storyName.substring(0, splitIndex).trim();
  const secondHalf = storyName.substring(splitIndex).trim();

  const storyFullName =
    storyName.length < 40 ? (
      <h5 className={`${styles.storyTitle} ${font.className}`}>{storyName}</h5>
    ) : (
      [firstHalf, secondHalf].map((s, i, a) => {
        return (
          <h5
            style={i === 1 ? { marginTop: "1px" } : {}}
            key={i}
            className={`${styles.storyTitle} ${font.className}`}
          >
            {i === a.length - 1 ? s + ":" : s}
          </h5>
        );
      })
    );

  const commentCountRoot = dailyStory.commentThread?.rootCommentCount ?? 0;
  const commentCountReplies = dailyStory.commentThread?.replyCount ?? 0;

  const LSDailyStoryViewed = localStorage.getItem("dailyStoryViewedCreatedOn");
  const isViewed = LSDailyStoryViewed
    ? LSDailyStoryViewed === dailyStory.dailyStory?.deliveredOn?.toString()
    : false;

  return (
    <section
      style={
        isViewed
          ? {
              backgroundImage: `linear-gradient(rgba(37, 0, 0, 0) 54.69%, rgba(6, 0, 0, 0.5) 100%), url(/dailyStories/${dailyStory.dailyStory.computeableStoryModelSlug}.jpg)`,
            }
          : {
              backgroundImage: `linear-gradient(rgba(37, 0, 0, 0) 54.69%, rgba(6, 0, 0, 0.5) 100%), url(/dailyStories/${dailyStory.dailyStory.computeableStoryModelSlug}.jpg)`,
              border: "1px solid #2CB0FF",
            }
      }
      className={styles.mainCTA}
      onClick={() => {
        if (dailyStory.dailyStory?.deliveredOn) {
          localStorage.setItem(
            "dailyStoryViewedCreatedOn",
            dailyStory.dailyStory.deliveredOn?.toString()
          );
        }
        setModalComponent(<DailyStoriesModal elementName="DailyStory" />);
      }}
    >
      <p className={styles.storyDay}>DAILY STORY</p>
      {storyFullName}
      <div className={styles.bodyDiv}>{mappedBody}</div>
      <div className={styles.bottom}>
        <DailyContentStatusBar
          inactive
          time={time === "0h:0m:0s" ? "Disappears soon!" : time}
          audienceNumber={dailyStory.commentThread?.audienceUids?.length ?? 0}
          commentCount={commentCountRoot + commentCountReplies}
          onCommentClick={() => {
            Mixpanel?.track("Comment modal summoned", {
              source: "daily story",
              audience_group_size:
                dailyStory.commentThread?.audienceUids?.length ?? 0,
              type: "from dashboard",
            });
            setModalComponent(
              <DailyStoriesModal showComment elementName="DailyStory" />
            );
          }}
        />
      </div>
    </section>
  );
}

export function useGetDailyStoryTimer(createdOn: number | undefined) {
  const [time, setTime] = useState<string>("");

  useEffect(() => {
    const updateTime = () => {
      const now = moment();
      const created = moment(createdOn);
      const totalTimeSpanInSeconds = 24 * 60 * 60;
      const elapsedTimeInSeconds = now.diff(created, "seconds");
      const remainingTimeInSeconds =
        totalTimeSpanInSeconds - elapsedTimeInSeconds;
      const clampedRemainingTimeInSeconds = Math.max(remainingTimeInSeconds, 0);
      const transformedTime = formatDailyStoryTime(
        clampedRemainingTimeInSeconds
      );
      setTime(transformedTime);
    };

    const intervalId = setInterval(updateTime, 1000);

    return () => clearInterval(intervalId);
  }, [createdOn]);

  return time;
}
