import { useSelector } from "react-redux";

import styles from "./Rewards.module.css";

import { RootState } from "@/redux/store";
import RewardsScoreBar from "./RewardsScoreBar";
import RewardsIcon from "@/components/shared/icons/RewardsIcon";
import { useModalContext } from "@/context/ModalContext";
import RewardsModal from "./RewardsModal";

export default function RewardsBar() {
  const { setModalComponent } = useModalContext();

  const userState = useSelector((state: RootState) => state.user);

  return (
    <section
      onClick={() => setModalComponent(<RewardsModal />)}
      className={styles.rewardsBarMain}
    >
      <p className={styles.rewardLevelTitle}>
        Invite reward level {userState.inviteLevel}
      </p>
      <div className={styles.rewardsLevelParent}>
        <RewardsScoreBar level={userState.inviteLevel} />
      </div>
      <div className={styles.rewardsMidDiv}>
        <RewardsIcon />
        <p className={styles.rewardsMidDivText}>
          SUCCESSFUL INVITES:{" "}
          {userState.memberProfile?.recommendeeUids?.length ?? 0}
        </p>
      </div>
      <button className={styles.rewardsBottomButton}>Learn more</button>
    </section>
  );
}
