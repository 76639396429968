import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import { DimensionEventStatus } from "@dimensional-engineering/dimensional-models";

export function useGetInsightPower() {
  const [power, setPower] = useState<number>(0);
  const [finishedSlugs, setfinishedSlugs] = useState<string[]>([]);

  const dimensionEvents = useSelector(
    (state: RootState) => state.user.dimensionEvents
  );

  const primaryFinished: boolean = useSelector(
    (state: RootState) => state.user.primaryFinished
  );
  const cognitiveFinished: boolean = useSelector(
    (state: RootState) => state.user.cognitiveFinished
  );
  const valuesFinished: boolean = useSelector(
    (state: RootState) => state.user.valueFinished
  );

  useEffect(() => {
    setfinishedSlugs([]);
    // 1. No dimensions complete = 0/5 bars (text says “None” with no arrows)
    // 2. Cognition complete = 1/5 bars (”Very low”)
    // 3. Core complete = 2/5 bars (”Low”)
    // 4. At least 6 dimensions complete (”Medium”)
    // 5. At least 10 dimensions complete = 4/5 bars (“High”)
    // 6. All dimensions complete (excluding coping styles) = 5/5 bars (“Very high”)

    setPower(0);
    if (!dimensionEvents) {
      return setPower(0);
    } else {
      let finishCount = 0;
      let nonFinishCount = 0;
      let tempPower = 0;

      for (let i = 0; i < dimensionEvents.length; i++) {
        if (dimensionEvents[i].event === DimensionEventStatus.complete) {
          finishCount++;
          setfinishedSlugs((current) => [
            ...current,
            dimensionEvents[i].dimensionSlug,
          ]);
        } else {
          if (dimensionEvents[i].dimensionSlug !== "coping-style") {
            nonFinishCount++;
          }
        }
      }

      if (finishCount === 0) {
        //none finished
        return setPower(0);
      }

      if (cognitiveFinished) {
        //cognition
        tempPower++;
      }

      if (cognitiveFinished && primaryFinished && valuesFinished) {
        //core finished
        tempPower++;
      }

      if (finishCount >= 6) {
        //at least 6 finished
        tempPower++;
      }
      if (finishCount >= 10) {
        //at least 10 finished
        tempPower++;
      }
      if (nonFinishCount === 0) {
        //all finished excluding copint styles
        tempPower++;
      }
      setPower(tempPower);
    }
  }, [dimensionEvents, primaryFinished, cognitiveFinished, valuesFinished]);

  return { power, finishedSlugs };
}
