import { useEffect, useState } from "react";
import {
  FriendRequest,
  PublicProfile,
} from "@dimensional-engineering/dimensional-models";
import { useSelector } from "react-redux";

import styles from "./ConnectionRequestModal.module.css";

import { useModalContext } from "@/context/ModalContext";
import { RootState } from "@/redux/store";
import useGetProfilePhoto from "@/helpers/useGetProfilePhoto";
import {
  acceptFriendRequest,
  declineFriendRequest,
  sendFriendRequest,
} from "../utils";
import { Mixpanel } from "@/helpers/mixpanel";
import SmallProfileImage from "@/components/shared/SmallProfileImage/SmallProfileImage";
import Alert from "@/components/shared/Alerts/Alert";
import BasicConnectionIcon from "@/components/shared/icons/BasicConnectionIcon";
import { useAlertContext } from "@/context/AlertContext";

type Props = {
  publicProfile: PublicProfile;
  slug: string;
};

export default function BasicConnectionBlock(props: Props) {
  const { removeModalByName } = useModalContext();
  const { setAlertComponent } = useAlertContext();

  const userState = useSelector((state: RootState) => state.user);
  const imageUrl = useGetProfilePhoto(props.publicProfile.ownerUUID);
  const [buttonText, setButtonText] = useState<string>(
    "Send connection request"
  );
  const [status, setStatus] = useState<"recieved" | "sent" | null>(null);

  const request = useBasicConnectionStatus({
    publicProfile: props.publicProfile,
  });

  useEffect(() => {
    let s: "recieved" | "sent" | null = null;
    if (
      !userState.isCloseRequestSentUidList.includes(
        props.publicProfile.ownerUUID
      ) &&
      !userState.isDeepRequestSentUidList.includes(
        props.publicProfile.ownerUUID
      ) &&
      userState.friendRequestsSentUidList.includes(
        props.publicProfile.ownerUUID
      )
    ) {
      s = "sent";
    }
    userState?.friendRequestsRecieved?.forEach((req) => {
      if (
        !req.isClose === true &&
        !req.isDeep &&
        req.sender.ownerUUID === props.publicProfile.ownerUUID
      ) {
        s = "recieved";
      }
    });
    setStatus(s);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.publicProfile.ownerUUID]);

  function handleClick() {
    setButtonText("Loading...");
    if (userState.publicProfileData) {
      sendFriendRequest(
        userState.publicProfileData,
        props.publicProfile,
        false,
        false
      )
        .then((res) => {
          removeModalByName("ConnectionModal");
          Mixpanel?.track("Friend request sent", {
            source: "peer story",
            type: "basic",
            slug: props.slug,
          });
          setTimeout(() => {
            setAlertComponent(
              <Alert
                type="success"
                message="Successfully sent connection request"
                elementName="Alert"
              />,
              5000
            );
          }, 200);
        })
        .catch((err) => {
          console.log(err);
          setButtonText("Send connection request");
          removeModalByName("ConnectionModal");
          setTimeout(() => {
            setAlertComponent(
              <Alert
                type="fail"
                message="Failed to send connection request."
                elementName="Alert"
              />,
              7000
            );
          }, 200);
        });
    }
  }

  //user received a request
  if (status === "recieved") {
    return (
      <main
        className={styles.wrapper}
        onClick={(e) => e.stopPropagation()}
        style={{ transform: "none" }}
      >
        <div className={styles.images}>
          <SmallProfileImage
            publicProfile={userState.publicProfileData as PublicProfile}
            imageUrl={userState.imageURL as string}
          />
          <div className={styles.svg}>
            <BasicConnectionIcon active />
          </div>
          <SmallProfileImage
            publicProfile={props.publicProfile}
            imageUrl={imageUrl as string}
          />
        </div>
        <h3 className={styles.title}>Requires a connection</h3>
        <p className={styles.unlock}>Unlocks messaging and basic content.</p>
        <button
          onClick={() => {
            if (request)
              acceptFriendRequest(request)
                .then(() => {
                  removeModalByName("ConnectionModal");
                })
                .catch((err) => {
                  console.log(err);
                });
          }}
          className={styles.button}
        >
          Accept
        </button>
        <button
          onClick={() => {
            if (request)
              declineFriendRequest(request)
                .then(() => {
                  removeModalByName("ConnectionModal");
                })
                .catch((err) => {
                  console.log(err);
                });
          }}
          className={styles.buttonPending}
        >
          Decline
        </button>
      </main>
    );
  }

  //user has sent a request already
  if (status === "sent") {
    return (
      <main
        className={styles.wrapper}
        onClick={(e) => e.stopPropagation()}
        style={{ transform: "none" }}
      >
        <div className={styles.images}>
          <SmallProfileImage
            publicProfile={userState.publicProfileData as PublicProfile}
            imageUrl={userState.imageURL as string}
          />
          <div className={styles.svg}>
            <BasicConnectionIcon active />
          </div>
          <SmallProfileImage
            publicProfile={props.publicProfile}
            imageUrl={imageUrl as string}
          />
        </div>
        <h3 className={styles.title}>Requires a connection</h3>
        <p className={styles.unlock}>Unlocks messaging and basic content.</p>
        <button disabled={true} className={styles.buttonPending}>
          Pending
        </button>
      </main>
    );
  }

  //regular request
  return (
    <main
      className={styles.wrapper}
      onClick={(e) => e.stopPropagation()}
      style={{ transform: "none" }}
    >
      <div className={styles.images}>
        <SmallProfileImage
          publicProfile={userState.publicProfileData as PublicProfile}
          imageUrl={userState.imageURL as string}
        />
        <div className={styles.svg}>
          <BasicConnectionIcon active />
        </div>
        <SmallProfileImage
          publicProfile={props.publicProfile}
          imageUrl={imageUrl as string}
        />
      </div>
      <h3 className={styles.title}>Requires a connection</h3>
      <p className={styles.unlock}>Unlocks messaging and basic content.</p>
      <button
        disabled={buttonText === "Loading..."}
        onClick={() => handleClick()}
        className={styles.button}
      >
        {buttonText}
      </button>
    </main>
  );
}

function useBasicConnectionStatus(props: { publicProfile: PublicProfile }) {
  const [request, setRequest] = useState<FriendRequest | null>(null);
  const userState = useSelector((state: RootState) => state.user);

  useEffect(() => {
    if (
      userState.friendRequestRecievedUidsList.includes(
        props.publicProfile.ownerUUID
      )
    ) {
      userState.friendRequestsRecieved?.forEach((req) => {
        if (!req.isClose && !req.isDeep) {
          setRequest(req);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.publicProfile.ownerUUID]);

  return request;
}
