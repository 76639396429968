/* eslint-disable @next/next/no-img-element */
import { SpectrumChart } from "@dimensional-engineering/dimensional-models";
import styles from "./AnalysisSpectrum.module.css";
import { ProfileColorMap } from "@/components/shared/ColorMap";
import { RootState } from "@/redux/store";
import { useSelector } from "react-redux";

export default function AnalysisSpectrum(props: {
  data: SpectrumChart;
  slug: string;
}) {
  const userState = useSelector((state: RootState) => state.user);

  return (
    <div className={styles.main}>
      <div className={styles.mainLine}>
        {props.data.score && (
          <div
            style={{ left: `${props.data.score * 100}%` }}
            className={styles.averageScoreDiv}
          >
            <div
              style={
                userState.publicProfileData?.primaryNature
                  ? {
                      backgroundColor: `${
                        ProfileColorMap[
                          userState.publicProfileData?.primaryNature
                            .nature as keyof typeof ProfileColorMap
                        ]
                      }`,
                    }
                  : { backgroundColor: "#55504F" }
              }
              className={styles.userScoreLine}
            ></div>
            <div
              style={
                userState.publicProfileData?.primaryNature
                  ? {
                      border: `3px solid ${
                        ProfileColorMap[
                          userState.publicProfileData?.primaryNature
                            .nature as keyof typeof ProfileColorMap
                        ]
                      }`,
                    }
                  : { border: "3px solid #55504F" }
              }
              className={styles.userScoreCircle}
            >
              <img src={userState.imageURL as string} alt="" />
            </div>
          </div>
        )}
        {props.data.averageUserScore && (
          <div
            style={{
              left: `${props.data.averageUserScore * 100}%`,
              top: "5px",
            }}
            className={styles.averageScoreDiv}
          >
            <div className={styles.averageScoreCircle}></div>
          </div>
        )}
        <div className={styles.dividerParent}>
          <div
            style={{ borderRight: "0" }}
            className={styles.dividerChild}
          ></div>
          <div className={styles.dividerChild}></div>
          <div
            style={{ borderLeft: "0" }}
            className={styles.dividerChild}
          ></div>
        </div>
      </div>
      <div className={styles.captionsDiv}>
        <p className={styles.caption}>
          {props.data.dividers?.firstSegment?.caption}
        </p>
        <p className={styles.caption}>
          {props.data.dividers?.middleSegment?.caption}
        </p>
        <p className={styles.caption}>
          {props.data.dividers?.lastSegment?.caption}
        </p>
      </div>
      <div className={styles.subTitleDiv}>
        <div className={styles.avScoreLabelCircle}></div>
        <h4>Average score of all users</h4>
      </div>
    </div>
  );
}
