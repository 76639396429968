import PrimaryTraitsIcon from "@/components/shared/dimensions/icons/PrimaryTraitsIcon";
import YouTwoSummarizedIcon from "./icons/YouTwoSummarizedIcon";
import VibeAsFriendsIcon from "./icons/VibeAsFriendsIcon";
import ComplementEachotherIcon from "./icons/ComplementEachotherIcon";
import QuestionsToAskIcon from "./icons/QuestionsToAskIcon";
import OutlooksWorldViewsIcon from "./icons/OutlooksWorldViewsIcon";
import PredictionOfFutureTogetherIcon from "./icons/PredictionOfFutureTogetherIcon";
import CompatibilityLoveStyleIcon from "./icons/CompatibilityLoveStyleIcon";
import CompatibilityCognitionIcon from "./icons/CompatibilityCognitionIcon";
import CompatibilitySexAttitudesIcon from "./icons/CompatibilitySexAttitudesIcon";
import CompatibilityInteractionStylesIcon from "./icons/CompatibilityInteractionStylesIcon";
import CompatibilityLoveAttitudesIcon from "./icons/CompatibilityLoveAttitudesIcon";
import CompatibilityPoliticalIdeologyIcon from "./icons/CompatibilityPoliticalIdeologyIcon";
import CompatibilityValuesIcon from "./icons/CompatibilityValuesIcon";
import CompatibilityAttachmentStyleIcon from "./icons/CompatibilityAttachmentStyleIcon";
import CompatibilityLifeStyleIcon from "./icons/CompatibilityLifeStyleIcon";
import CompatibilityVibeAsLoversIcon from "./icons/CompatibilityVibeAsLoversIcon";
import CompatibilityVibeAsFriendsIcon from "./icons/CompatibilityVibeAsFriendsIcon";
import CompatibilityLoveArchetypesIcon from "./icons/CompatibilityLoveArchetypesIcon";
import InSummarySnippetIcon from "@/components/results/Snippets/snippetsIcons/InSummarySnippetIcon";

export const compatibilityIconsMap = {
  "compatibility-snippet-ideology-compared": (
    <CompatibilityPoliticalIdeologyIcon />
  ),
  "compatibility-snippet-lifestyle-compared": <CompatibilityLifeStyleIcon />,
  "compatibility-snippet-love-style-compatibility-new": (
    <CompatibilityLoveStyleIcon />
  ),
  "compatibility-snippet-primary-traits-compared": <PrimaryTraitsIcon />,
  "compatibility-snippet-you-two-summarized": <YouTwoSummarizedIcon />,
  "compatibility-snippet-cognitive-compatibility": (
    <CompatibilityCognitionIcon />
  ),
  "compatibility-snippet-interaction-styles": (
    <CompatibilityInteractionStylesIcon />
  ),
  "compatibility-snippet-motivational-values": <CompatibilityValuesIcon />,
  "compatibility-snippet-love-attitudes-compared": (
    <CompatibilityLoveAttitudesIcon />
  ),
  "compatibility-snippet-sexual-compatibility": (
    <CompatibilitySexAttitudesIcon />
  ),
  "compatibility-snippet-attachment-styles": (
    <CompatibilityAttachmentStyleIcon />
  ),
  "compatibility-snippet-vibe-as-friends": <VibeAsFriendsIcon />,
  "compatibility-snippet-how-you-complement": <ComplementEachotherIcon />,
  "compatibility-snippet-questions-to-ask": <QuestionsToAskIcon />,
  "compatibility-snippet-outlooks-and-worldviews": <OutlooksWorldViewsIcon />,
  "compatibility-snippet-predictions-of-future": (
    <PredictionOfFutureTogetherIcon />
  ),
  "compatibility-snippet-romantic-compatibility-report": <></>,
  "compatibility-snippet-work-compatibility-report": <></>,
  "compatibility-snippet-emoji-lovers": <CompatibilityVibeAsLoversIcon />,
  "compatibility-snippet-emoji-friends": <CompatibilityVibeAsFriendsIcon />,
  "compatibility-snippet-love-archetypes-compared": (
    <CompatibilityLoveArchetypesIcon />
  ),
  "compatibility-snippet-vibe-5-statements": <InSummarySnippetIcon />,
};

export const compatibilityIconsMapMaxWidth = {
  "compatibility-snippet-ideology-compared": (
    <CompatibilityPoliticalIdeologyIcon maxWidth />
  ),
  "compatibility-snippet-lifestyle-compared": (
    <CompatibilityLifeStyleIcon maxWidth />
  ),
  "compatibility-snippet-love-style-compatibility-new": (
    <CompatibilityLoveStyleIcon maxWidth />
  ),
  "compatibility-snippet-primary-traits-compared": (
    <PrimaryTraitsIcon maxWidth />
  ),
  "compatibility-snippet-you-two-summarized": <YouTwoSummarizedIcon maxWidth />,
  "compatibility-snippet-cognitive-compatibility": (
    <CompatibilityCognitionIcon maxWidth />
  ),
  "compatibility-snippet-interaction-styles": (
    <CompatibilityInteractionStylesIcon maxWidth />
  ),
  "compatibility-snippet-motivational-values": (
    <CompatibilityValuesIcon maxWidth />
  ),
  "compatibility-snippet-love-attitudes-compared": (
    <CompatibilityLoveAttitudesIcon maxWidth />
  ),
  "compatibility-snippet-sexual-compatibility": (
    <CompatibilitySexAttitudesIcon maxWidth />
  ),
  "compatibility-snippet-attachment-styles": (
    <CompatibilityAttachmentStyleIcon maxWidth />
  ),
  "compatibility-snippet-vibe-as-friends": <VibeAsFriendsIcon maxWidth />,
  "compatibility-snippet-how-you-complement": (
    <ComplementEachotherIcon maxWidth />
  ),
  "compatibility-snippet-questions-to-ask": <QuestionsToAskIcon />,
  "compatibility-snippet-outlooks-and-worldviews": <OutlooksWorldViewsIcon />,
  "compatibility-snippet-predictions-of-future": (
    <PredictionOfFutureTogetherIcon />
  ),
  "compatibility-snippet-romantic-compatibility-report": <></>,
  "compatibility-snippet-work-compatibility-report": <></>,
  "compatibility-snippet-emoji-lovers": (
    <CompatibilityVibeAsLoversIcon maxWidth />
  ),
  "compatibility-snippet-emoji-friends": (
    <CompatibilityVibeAsFriendsIcon maxWidth />
  ),
  "compatibility-snippet-love-archetypes-compared": (
    <CompatibilityLoveArchetypesIcon maxWidth />
  ),
  "compatibility-snippet-vibe-5-statements": <InSummarySnippetIcon />,
};

export const compatibilityDescriptionMap = {
  "compatibility-snippet-ideology-compared":
    "Comparing your ideological perspectives. Note: similarity does not imply compatibility.",
  "compatibility-snippet-lifestyle-compared":
    "Comparing what you're into and how you spend your time.",
  "compatibility-snippet-love-style-compatibility-new":
    "The alignment between how you both desire and communicate love.",
  "compatibility-snippet-primary-traits-compared":
    "Comparing your most fundamental traits, basic instincts, and patterns of behavior.",
  "compatibility-snippet-you-two-summarized":
    "Comparing your basic identities, shared traits, and unique qualities.",
  "compatibility-snippet-cognitive-compatibility":
    "How you both process reality and make decisions.",
  "compatibility-snippet-interaction-styles":
    "How you socialize and interact with each other.",
  "compatibility-snippet-motivational-values":
    "Comparing your underlying motivations in life. Note: similarity does not imply compatibility.",
  "compatibility-snippet-love-attitudes-compared":
    "How you experience and approach love, and what you want from a romantic connection.",
  "compatibility-snippet-sexual-compatibility":
    "The alignment between your sex attitudes.",
  "compatibility-snippet-attachment-styles":
    "Comparing how you behave in adult relationships and how you relate to your partners.",
  "compatibility-snippet-vibe-as-friends":
    "A quick summary of how your personalities vibe as friends.",
  "compatibility-snippet-how-you-complement":
    "How each of your unique strengths and weaknesses complement each other.",
  "compatibility-snippet-questions-to-ask":
    "10 questions to get to know each other better and connect on a deeper level.",
  "compatibility-snippet-outlooks-and-worldviews":
    "Comparing how you see the world.",
  "compatibility-snippet-predictions-of-future":
    "7 predictions about what a relationship between you two might look like.",
  "compatibility-snippet-emoji-lovers": "The way(s) you'll connect as lovers.",
  "compatibility-snippet-emoji-friends":
    "The way(s) you'll connect as friends.",
  "compatibility-snippet-overall-similarity":
    "The similarity of your core personality traits.",
  "compatibility-snippet-love-archetypes-compared":
    "Comparing how you conceptualize love and act in romantic relationships.",
  "compatibility-snippet-dark-sides-similarity":
    "Comparing your mental wellbeing, challenges, and emotional regulation.",
  "compatibility-snippet-vibe-5-statements":
    "How your personalities vibe together, summarized in 5 statements.",
};

export const largeSnippets = [
  "compatibility-snippet-romantic-compatibility-report",
  "compatibility-snippet-work-compatibility-report",
];

export const wideSnippets = ["compatibility-snippet-overall-similarity"];
