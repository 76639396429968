import styles from "./PeerStoriesErrorScreen.module.css";

import WarningIcon from "@/components/shared/GhostBox/WarningIcon";

export default function PeerStoriesErrorScreen(props: { onRetry: () => void }) {
  return (
    <div className={styles.main}>
      <div className={styles.warningDiv}>
        <WarningIcon />
      </div>
      <h2 className={styles.text}>Something went wrong.</h2>

      <button className={styles.btn} onClick={() => props.onRetry()}>
        Retry
      </button>
    </div>
  );
}
