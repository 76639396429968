import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import styles from "./ConsumableBoxes.module.css";
import "swiper/css";

import { GoDeeperConsumableIcon } from "../../GoDeeperIcon";
import { useAlertContext } from "@/context/AlertContext";
import Alert from "@/components/shared/Alerts/Alert";
import { Mixpanel } from "@/helpers/mixpanel";

export default function ConsumableBoxes() {
  const [mySwiperRef, setMySwiperRef] = useState<any>(null);
  const [index, setIndex] = useState<number>(0);

  const { setAlertComponent } = useAlertContext();

  function onClick() {
    return setAlertComponent(
      <Alert
        type="warning"
        buttonText="Notify me"
        onButtonClick={() => {
          Mixpanel?.track("Chatbot | Notify me CTA clicked", {});
        }}
        message="Coming soon! Get notified when it's available?"
        elementName="Alert"
      />,
      3000
    );
  }

  const mapped = [
    <ConsumableBox
      key={0}
      numberOfMessagesText="20 messages"
      pricePerMessage="$0.20/message"
      priceText="Get for $3.99"
    />,
    <ConsumableBox
      key={1}
      numberOfMessagesText="100 messages"
      pricePerMessage="$0.12/message"
      priceText="Get for $11.99"
      stickerText="SAVE 40%"
    />,
    <ConsumableBox
      key={2}
      numberOfMessagesText="1 Year"
      pricePerMessage="Includes unlimited messages"
      priceText="Get for $69.99/year"
      stickerText="Unlimited messages"
    />,
  ].map((c, i) => {
    return (
      <SwiperSlide
        onClick={() => {
          onClick();
        }}
        key={i}
      >
        {c}
      </SwiperSlide>
    );
  });

  const mappedCircles = [0, 1, 2].map((c) => {
    return (
      <div
        onClick={() => {
          setIndex(c);
          mySwiperRef?.slideTo(c);
        }}
        style={index === c ? { opacity: "1" } : {}}
        className={styles.circle}
        key={c}
      ></div>
    );
  });

  return (
    <div className={styles.wrapperMain}>
      <Swiper
        min-height={200}
        autoHeight={true}
        slidesPerView={1}
        touchReleaseOnEdges={true}
        scrollbar={{ draggable: true }}
        threshold={1}
        onSwiper={setMySwiperRef}
        onSlideChange={(e) => {
          setIndex(e.activeIndex);
        }}
      >
        {mapped}
      </Swiper>
      <div className={styles.circlesWrapper}>{mappedCircles}</div>
    </div>
  );
}

type Props = {
  numberOfMessagesText: string;
  pricePerMessage: string;
  priceText: string;
  stickerText?: string;
};

export function ConsumableBox(props: Props) {
  return (
    <div className={styles.main}>
      {props.stickerText && (
        <div className={styles.stickerDiv}>
          <p className={styles.sticketText}>{props.stickerText}</p>
        </div>
      )}
      <div className={styles.wrapper}>
        <h3 className={styles.title}>{props.numberOfMessagesText}</h3>
        {props.numberOfMessagesText === "1 Year" && (
          <p className={styles.dimensionalPlusText}>
            Dimensional <span className={styles.dimensionalPlus}>Plus</span>
          </p>
        )}
        <div className={styles.iconDiv}>
          <GoDeeperConsumableIcon />
        </div>
        <p className={styles.pricePerMessage}>{props.pricePerMessage}</p>
        <button className={styles.btn}>{props.priceText}</button>
      </div>
    </div>
  );
}
