import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  ContentType,
  PublicProfile,
  TraitIdentifier,
} from "@dimensional-engineering/dimensional-models";

import styles from "./CompatibilitySnippets.module.css";

import ModalWrapper from "@/components/shared/ModalWrapper/ModalWrapper";
import {
  FriendsPublicProfile,
  LlmVennDiagram,
  RomanticCompatibilityReport,
} from "@/models/sharedModels";
import LargeProfileImage from "@/components/shared/SmallProfileImage/LargeProfileImage";
import { RootState } from "@/redux/store";
import ComparedMediumProfileImage from "@/components/shared/SmallProfileImage/ComparedMediumProfileImage";
import { compatibilityIconsMap } from "./CompatibilityData";
import SexualCompatibilityBars from "./views/SexualCompatibilityBars";
import SimilarIcon, {
  NonSimilarIcon,
} from "@/components/shared/icons/SimilarityIcons";
import { ProfileColorMap } from "@/components/shared/ColorMap";
import VibeWithIcon from "@/components/results/Snippets/snippetsIcons/VibeWithIcon";
import { LlmTitleAndContentMapper } from "./views/CompatibilityLlmTitleAndContentView";
import DontVibeWithIcon from "@/components/results/Snippets/snippetsIcons/DontVibeWithIcon";
import BlackHoleImage from "./BlackHoleImage";
import SexAttitudesIcon from "@/components/shared/dimensions/icons/SexAttitudesIcon";
import { CompatibilitySpectrumChart } from "./views/CompatibilitySpectrum";
import EmotionsAndIntellectIcon from "./icons/EmotionsAndIntellectIcon";
import EarthCoreImage from "./views/EarthCoreImage";
import LifeStyleIcon from "@/components/shared/dimensions/icons/LifeStyleIcon";
import { useModalContext } from "@/context/ModalContext";
import {
  computeCompatibilitySnippets,
  getRomanticCompatibilityFeedbackScore,
  getRomanticCompatibilitySnippetLLMRequest,
  toSentenceCase,
  updateRomanticCompatibilitFeedback,
} from "./utils";
import { getElementFromTraitIdentifier } from "../utils";
import LightningStrikeIcon from "./icons/LightningStrikeIcon";
import OrganizationalIcon from "./icons/OrganizationalIcon";
import LoveStylesAndAttitudesIcon from "./icons/LoveStylesAndAttitudesIcon";
import ChatImage from "./views/ChatImage";
import AdviceIcon from "./icons/AdviceIcon";
import AnalysisFeedback from "@/components/results/personalityAnalysis/AnalysisFeedback/AnalysisFeedback";
import { useAuth } from "@/context/AuthContext";
import CompatibilityConversionCTA from "./CompatibilityConversionCTA";
import ElementModal from "@/components/traits/TraitsModals/Element/ElementModal";
import PatternModal from "@/components/traits/TraitsModals/Pattern/PatternModal";
import SnippetInterractionBar from "@/components/results/Snippets/SnippetInterractionBar/SnippetInterractionBar";
import { isTimestampWithin1Day } from "@/components/authenticatedProfile/utils";
import Alert from "@/components/shared/Alerts/Alert";
import ConversionModal from "@/components/premium/ConversionModal/ConversionModal";
import LoadingScreen from "@/components/assessments-new/views/AsessmentOutroScreen/loadingScreen/LoadingScreen";
import { Mixpanel } from "@/helpers/mixpanel";
import InfoModal from "@/components/shared/InfoModal/InfoModal";
import PremiumPillIcon from "@/components/premium/PremiumPillIcon/PremiumPillIcon";
import { useAlertContext } from "@/context/AlertContext";
import { getConnectionProfileColor } from "@/components/shared/utils";

type Props = {
  romanticCompatibilityReport: RomanticCompatibilityReport | null;
  publicProfile: PublicProfile | null;
};

export default function RomanticCompatibilityModal(props: Props) {
  const userState = useSelector((state: RootState) => state.user);
  const connection = useConnectionList(props.publicProfile);
  const feedbackScore = useGetRomanticFeedbackScore(connection?.ownerUUID);
  const { setModalComponent } = useModalContext();
  const { setAlertComponent } = useAlertContext();

  const { user } = useAuth();
  const connectionColor = getConnectionProfileColor(
    userState.publicProfileData?.primaryNature?.nature,
    connection?.primaryNature?.nature
  );

  const [loading, setLoading] = useState<boolean>(false);

  const { disabled, errorType } = useRegenerateStatus(
    props.publicProfile?.ownerUUID
  );

  useEffect(() => {
    Mixpanel?.track("Compatibility Snippet Viewed", {
      triggerer: userState.publicProfileData?.userName,
      triggeree: connection?.userName,
      compatibility_snippet_slug:
        "compatibility-snippet-romantic-compatibility-report",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleRegenerate() {
    if (!disabled) {
      if (props.publicProfile) {
        setModalComponent(
          <InfoModal
            title="Regenerate content?"
            body="You cannot undo this. Are you sure you want to regenerate this content?"
            secondaryText="Cancel"
            primaryText="Regenerate"
            onAccept={() => {
              setLoading(true);
              computeCompatibilitySnippets(
                "compatibility-snippet-romantic-compatibility-report",
                props.publicProfile?.ownerUUID as unknown as string
              )
                .then(() => {
                  Mixpanel?.track("Regenerate CTA Clicked", {
                    content_type: "MP snippet",
                    slug: "compatibility-snippet-romantic-compatibility-report",
                  });
                })
                .catch((err) => {
                  setAlertComponent(
                    <Alert
                      type="fail"
                      message={
                        "Results can be regenerated once a day. Try again later."
                      }
                      elementName="Alert"
                    />,
                    7000
                  );
                  setLoading(false);
                });
            }}
            elementName="InfoModal"
          />
        );
      }
    } else {
      if (errorType === "premium") {
        return setModalComponent(<ConversionModal initialSlide={3} />);
      }
      setAlertComponent(
        <Alert
          type="fail"
          message={
            errorType === "time"
              ? "Results can be regenerated once a day. Try again later."
              : "Regenerating snippets is only available to Plus accounts"
          }
          elementName="Alert"
        />,
        7000
      );
    }
  }

  function handleClick(trait: TraitIdentifier) {
    if (trait.type === "element") {
      setModalComponent(<ElementModal slug={trait.slug} />);
    } else {
      setModalComponent(<PatternModal slug={trait.slug} />);
    }
  }

  const mappedLifestyleIntersection =
    props.romanticCompatibilityReport?.lifestyleAndInterestsIntersect.map(
      (trait) => {
        const el = getElementFromTraitIdentifier(trait);
        return (
          <p
            onClick={() => {
              handleClick(trait);
            }}
            className={styles.noun}
            key={trait.slug}
          >
            {el?.name ? toSentenceCase(el?.name) : el?.name}
          </p>
        );
      }
    );

  const lifestyleListView = (
    <div className={styles.listSection}>
      <div className={styles.listTitleDiv}>
        <h3 className={styles.listTitle}>BOTH OF YOU</h3>
        <SimilarIcon
          secondaryColor={connectionColor}
          primaryColor={
            userState.publicProfileData?.primaryNature?.nature
              ? ProfileColorMap[
                  userState.publicProfileData?.primaryNature
                    ?.nature as keyof typeof ProfileColorMap
                ]
              : "#55504F"
          }
        />
      </div>
      <div className={styles.nounDiv}>{mappedLifestyleIntersection}</div>
    </div>
  );

  const maxLength = 13;
  const myDisplayNAme = userState.publicProfileData?.userName || "";
  const thierDisplayNAme = connection?.userName || "";

  if (loading) {
    return (
      <ModalWrapper title="">
        <LoadingScreen message="Computing. We'll notify you when it's ready." />
      </ModalWrapper>
    );
  }

  return (
    <ModalWrapper
      title={"Romantic compatibility"}
      subTitle="Compatibility Snippet"
    >
      <section
        style={{
          backgroundImage: `url(/compatibility-snippets/large/compatibility-snippet-romantic-compatibility-report.jpg)`,
        }}
        className={styles.reportTop}
      >
        <div className={styles.premiumIconReport}>
          <PremiumPillIcon />
        </div>
        <div className={styles.reportTopImagesDiv}>
          <div
            style={{ alignItems: "center" }}
            className={styles.generatePersonDiv}
          >
            <LargeProfileImage
              publicProfile={userState.publicProfileData}
              imageUrl={userState.imageURL ?? "/default-image.png"}
            />
            <p className={styles.generateUsername}>
              {myDisplayNAme.length <= maxLength
                ? `@${myDisplayNAme}`
                : `@${myDisplayNAme.slice(0, 11)}...`}
            </p>
          </div>

          <div className={styles.snippetIconSmallModal}>
            {
              compatibilityIconsMap[
                "compatibility-snippet-romantic-compatibility-report"
              ]
            }
          </div>

          <div
            style={{ alignItems: "center" }}
            className={styles.generatePersonDiv}
          >
            <ComparedMediumProfileImage
              large
              publicProfile={props.publicProfile}
              imageUrl={connection?.imageUrl ?? "/default-image.png"}
            />
            <p className={styles.generateUsername}>
              {thierDisplayNAme.length <= maxLength
                ? `@${thierDisplayNAme}`
                : `@${thierDisplayNAme.slice(0, 11)}...`}
            </p>
          </div>
        </div>
      </section>

      <section className={styles.modalContent}>
        <h1 className={styles.reportHeader}>Romantic compatibility</h1>
        <SexualCompatibilityBars peerUid={props.publicProfile?.ownerUUID} />
        <RomanticReportHeader part={1} title="Overview" />
        <div className={styles.reportContentDiv}>
          <h3 className={styles.llmTitle}>Summary</h3>
          <p className={styles.llmParagraph}>
            {props.romanticCompatibilityReport?.summaryLlmString}
          </p>
          <h3 className={styles.llmTitle}>Likely dynamic</h3>
          <p className={styles.llmParagraph}>
            {props.romanticCompatibilityReport?.likelyDynamicLlmString}
          </p>
          <h3 className={styles.llmTitle}>Your adjectives</h3>
          <ListView
            data={props.romanticCompatibilityReport?.llmVennDiagram}
            publicProfile={props.publicProfile}
          />

          <RomanticReportChapterHeader
            publicProfile={props.publicProfile}
            icon={<VibeWithIcon />}
          />

          <RomanticReportHeader part={2} title="How you vibe" />
          <div className={styles.llmContentDivReport}>
            <LlmTitleAndContentMapper
              data={props.romanticCompatibilityReport?.howYouVibeLlmContent}
            />
          </div>

          <RomanticReportChapterHeader
            publicProfile={props.publicProfile}
            icon={<DontVibeWithIcon />}
          />
          <RomanticReportHeader part={3} title="How you clash" />
          <div className={styles.llmContentDivReport}>
            <LlmTitleAndContentMapper
              data={props.romanticCompatibilityReport?.howYouClashLlmContent}
            />
          </div>
          <BlackHoleImage />

          <RomanticReportChapterHeader
            publicProfile={props.publicProfile}
            icon={<SexAttitudesIcon />}
          />
          <RomanticReportHeader part={4} title="Sex and sensuality" />
          <CompatibilitySpectrumChart
            connection={connection}
            spectrumSlug="compatibility-report-sex-and-sensuality"
            contentType={ContentType.compatibilitySnippet}
          />
          <div className={styles.llmContentDivReport}>
            <LlmTitleAndContentMapper
              data={
                props.romanticCompatibilityReport?.sexAndSensualityLlmContent
              }
            />
          </div>

          <RomanticReportChapterHeader
            publicProfile={props.publicProfile}
            icon={<EmotionsAndIntellectIcon />}
          />
          <RomanticReportHeader part={5} title="Emotions and intellect" />
          <CompatibilitySpectrumChart
            connection={connection}
            spectrumSlug="compatibility-report-emotions-and-intellect"
            contentType={ContentType.compatibilitySnippet}
          />
          <div className={styles.llmContentDivReport}>
            <LlmTitleAndContentMapper
              data={
                props.romanticCompatibilityReport
                  ?.emotionsAndIntellectLlmContent
              }
            />
          </div>
          <div className={styles.coreImage}>
            <EarthCoreImage />
          </div>

          <RomanticReportChapterHeader
            publicProfile={props.publicProfile}
            icon={<LifeStyleIcon />}
          />
          <RomanticReportHeader part={6} title="Lifestyle and interests" />
          <CompatibilitySpectrumChart
            connection={connection}
            spectrumSlug="compatibility-report-lifestyle-and-interests"
            contentType={ContentType.compatibilitySnippet}
          />
          {lifestyleListView}
          <div className={styles.llmContentDivReport}>
            <LlmTitleAndContentMapper
              data={
                props.romanticCompatibilityReport
                  ?.lifestyleAndInterestsLlmContent
              }
            />
          </div>

          <RomanticReportChapterHeader
            publicProfile={props.publicProfile}
            icon={<LightningStrikeIcon />}
          />
          <RomanticReportHeader part={7} title="Energy and excitement" />
          <CompatibilitySpectrumChart
            connection={connection}
            spectrumSlug="compatibility-report-energy-and-excitement"
            contentType={ContentType.compatibilitySnippet}
          />
          <div className={styles.llmContentDivReport}>
            <LlmTitleAndContentMapper
              data={
                props.romanticCompatibilityReport?.energyAndExcitementLlmContent
              }
            />
          </div>

          <RomanticReportChapterHeader
            publicProfile={props.publicProfile}
            icon={<OrganizationalIcon />}
          />
          <RomanticReportHeader part={8} title="Planning and organization" />
          <CompatibilitySpectrumChart
            connection={connection}
            spectrumSlug="compatibility-report-planning-and-organization"
            contentType={ContentType.compatibilitySnippet}
          />
          <div className={styles.llmContentDivReport}>
            <LlmTitleAndContentMapper
              data={
                props.romanticCompatibilityReport
                  ?.planningAndOrganizationLlmContent
              }
            />
          </div>

          <RomanticReportChapterHeader
            publicProfile={props.publicProfile}
            icon={<LoveStylesAndAttitudesIcon />}
          />
          <RomanticReportHeader part={9} title="Love Styles and Attitudes" />
          <div className={styles.llmContentDivReport}>
            <LlmTitleAndContentMapper
              data={
                props.romanticCompatibilityReport
                  ?.loveStylesAndAttitudesLlmContent
              }
            />
          </div>
          <div className={styles.coreImage}>
            <ChatImage />
          </div>

          <RomanticReportChapterHeader
            publicProfile={props.publicProfile}
            icon={<AdviceIcon />}
          />
          <RomanticReportHeader part={10} title="Tips for success" />
          <div className={styles.llmContentDivReport}>
            <LlmTitleAndContentMapper
              data={props.romanticCompatibilityReport?.myTipsForSuccess}
            />
          </div>
          <div className={styles.llmContentDivReport}>
            <LlmTitleAndContentMapper
              data={props.romanticCompatibilityReport?.connectionTipsForSuccess}
            />
          </div>

          <div className={styles.feedbackDiv}>
            <AnalysisFeedback
              onAnswer={(value) => {
                if (user?.uid && connection?.ownerUUID) {
                  updateRomanticCompatibilitFeedback(
                    user?.uid,
                    value,
                    connection?.ownerUUID
                  );
                }
              }}
              title="How did you like this content?"
              subtitle="Feedback helps us improve"
              currentAnswer={feedbackScore}
            />
          </div>
        </div>
      </section>
      <div className={styles.coreImage}>
        <img
          style={{ width: "100%", height: "100%" }}
          width={367}
          height={800}
          alt="End image"
          src={`/compatibility-snippets/end.png`}
        />
      </div>
      <div className={styles.interactionBarDiv}>
        {!userState.isPremium && (
          <div className={styles.conversionDiv}>
            <CompatibilityConversionCTA username={connection?.userName} />
          </div>
        )}
        <SnippetInterractionBar
          hideShare
          onRegenerate={() => handleRegenerate()}
          disabledRegenerate={disabled}
          type="report"
          slug={"compatibility-snippet-romantic-compatibility-report"}
        />
      </div>
    </ModalWrapper>
  );
}

function useConnectionList(profile: PublicProfile | null) {
  const connectionListWithFriendDetails = useSelector(
    (state: RootState) => state.connections.publicProfiles
  );
  const [connection, setConnection] = useState<FriendsPublicProfile | null>(
    null
  );
  useEffect(() => {
    connectionListWithFriendDetails.forEach((f) => {
      if (f.ownerUUID === profile?.ownerUUID) {
        setConnection(f);
      }
    });
  }, [connectionListWithFriendDetails, profile?.ownerUUID]);

  return connection;
}

export function RomanticReportHeader(props: { title: string; part: number }) {
  return (
    <div style={{ marginTop: "60px" }}>
      <p className={styles.partText}>PART {props.part}</p>
      <h2 style={{ marginTop: "10px" }} className={styles.headeredListTitle}>
        {props.title}
      </h2>
      <div className={styles.headerdListLine}></div>
    </div>
  );
}

export function ListView(props: {
  data: LlmVennDiagram | null | undefined;
  publicProfile: PublicProfile | null;
}) {
  const userState = useSelector((state: RootState) => state.user);
  const connectionColor = getConnectionProfileColor(
    userState.publicProfileData?.primaryNature?.nature,
    props.publicProfile?.primaryNature?.nature
  );

  const mappedIntersection = props?.data?.intersectAdjectives.map(
    (trait, i, a) => {
      if (i === a.length - 1) {
        return ` ${trait}`;
      } else if (i !== 0) {
        return ` ${trait},`;
      } else {
        return ` ${trait},`;
      }
    }
  );

  const mappedUniqueMe = props?.data?.myAdjectives.map((trait, i, a) => {
    if (i === a.length - 1) {
      return ` ${trait}`;
    } else if (i !== 0) {
      return ` ${trait},`;
    } else {
      return ` ${trait},`;
    }
  });

  const mappedUniqueConnection = props?.data?.connectionAdjectives.map(
    (trait, i, a) => {
      if (i === a.length - 1) {
        return ` ${trait}`;
      } else if (i !== 0) {
        return ` ${trait},`;
      } else {
        return ` ${trait},`;
      }
    }
  );

  return (
    <>
      <div className={styles.listSection}>
        <div className={styles.listTitleDiv}>
          <h3 className={styles.listTitle}>BOTH OF YOU</h3>
          <SimilarIcon
            secondaryColor={connectionColor}
            primaryColor={
              userState.publicProfileData?.primaryNature?.nature
                ? ProfileColorMap[
                    userState.publicProfileData?.primaryNature
                      ?.nature as keyof typeof ProfileColorMap
                  ]
                : "#55504F"
            }
          />
        </div>
        <div className={styles.nounDiv}>
          <p className={styles.nounAdjective}>{mappedIntersection}</p>
        </div>
      </div>
      <div className={styles.listSection}>
        <div className={styles.listTitleDiv}>
          <h3 className={styles.listTitle}>
            UNIQUELY @{userState.publicProfileData?.userName?.toUpperCase()}
          </h3>
          <NonSimilarIcon
            secondaryColor={"#55504F"}
            primaryColor={
              userState.publicProfileData?.primaryNature?.nature
                ? ProfileColorMap[
                    userState.publicProfileData?.primaryNature
                      ?.nature as keyof typeof ProfileColorMap
                  ]
                : "#55504F"
            }
          />
        </div>
        <div className={styles.nounDiv}>
          <p className={styles.nounAdjective}>{mappedUniqueMe}</p>
        </div>
      </div>
      <div className={styles.listSection}>
        <div className={styles.listTitleDiv}>
          <h3 className={styles.listTitle}>
            UNIQUELY @
            {props.publicProfile?.userName
              ? props.publicProfile?.userName.toUpperCase()
              : ""}
          </h3>
          <NonSimilarIcon
            secondaryColor={getConnectionProfileColor(
              userState.publicProfileData?.primaryNature?.nature,
              props.publicProfile?.primaryNature?.nature
            )}
            primaryColor={"#55504F"}
          />
        </div>
        <div className={styles.nounDiv}>
          <p className={styles.nounAdjective}>{mappedUniqueConnection}</p>
        </div>
      </div>
    </>
  );
}

export function RomanticReportChapterHeader(props: {
  icon: JSX.Element;
  publicProfile: PublicProfile | null;
}) {
  const userState = useSelector((state: RootState) => state.user);
  const connection = useConnectionList(props.publicProfile);

  const maxLength = 13;
  const myDisplayNAme = userState.publicProfileData?.userName || "";
  const thierDisplayNAme = connection?.userName || "";
  return (
    <div style={{ marginTop: "60px" }} className={styles.modalTop}>
      <div
        style={{ alignItems: "center" }}
        className={styles.generatePersonDiv}
      >
        <LargeProfileImage
          publicProfile={userState.publicProfileData}
          imageUrl={userState.imageURL ?? "/default-image.png"}
        />
        <p className={styles.generateUsername}>
          {myDisplayNAme.length <= maxLength
            ? `@${myDisplayNAme}`
            : `@${myDisplayNAme.slice(0, 11)}...`}
        </p>
      </div>

      <div className={styles.snippetIconSmallModal}>{props.icon}</div>

      <div
        style={{ alignItems: "center" }}
        className={styles.generatePersonDiv}
      >
        <ComparedMediumProfileImage
          large
          publicProfile={props.publicProfile}
          imageUrl={connection?.imageUrl ?? "/default-image.png"}
        />
        <p className={styles.generateUsername}>
          {thierDisplayNAme.length <= maxLength
            ? `@${thierDisplayNAme}`
            : `@${thierDisplayNAme.slice(0, 11)}...`}
        </p>
      </div>
    </div>
  );
}

function useGetRomanticFeedbackScore(connectionUid: string | undefined) {
  const [feedbackScore, setFeedbackScore] = useState<number | null>(null);
  const { user } = useAuth();

  useEffect(() => {
    if (user?.uid && connectionUid) {
      getRomanticCompatibilityFeedbackScore(user?.uid, connectionUid)
        .then((res) => {
          setFeedbackScore(res);
        })
        .catch(() => {
          setFeedbackScore(null);
        });
    }
  }, [connectionUid, user?.uid]);

  return feedbackScore;
}

function useRegenerateStatus(connectionUid: string | undefined) {
  const [disabled, setDisabled] = useState<boolean>(false);
  const [errorType, setErrorType] = useState<"premium" | "time">("premium");

  const { user } = useAuth();
  const isPremium = useSelector((state: RootState) => state.user.isPremium);

  useEffect(() => {
    if (!isPremium) {
      setDisabled(true);
      setErrorType("premium");
    } else {
      if (connectionUid) {
        getRomanticCompatibilitySnippetLLMRequest(user?.uid, connectionUid)
          .then((res) => {
            const isWithin24Hours = isTimestampWithin1Day(res.createdOn);
            if (isWithin24Hours === true) {
              setDisabled(true);
              setErrorType("time");
            }
          })
          .catch((err) => {
            setDisabled(false);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPremium, user?.uid]);

  return { disabled, errorType };
}
