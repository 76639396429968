import styles from "./AuthRightBar.module.css";

import { DynamicProfileResultData } from "@/models/sharedModels";
import AuthAboutTab from "../tabs/AuthAboutTab/AuthAboutTab";

type Props = {
  natureColor: string | null;
  ownProfile: boolean;
  data: DynamicProfileResultData;
  imageUrl?: string;
};

export default function AuthRightBar(props: Props) {
  return (
    <div id="rightBarMain" className={styles.main}>
      <AuthAboutTab />
    </div>
  );
}
