import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "@/_firebase/firebaseConfig";
import {
  ConnectionAvailability,
  ContentType,
  DiscoverableCompatibilitySnippet,
  DiscoverablePeerStory,
  DiscoveredPeerStory,
  PublicProfile,
  SnapshotScore,
  TraitIdentifier,
} from "@dimensional-engineering/dimensional-models";
import { RootState } from "@/redux/store";

import {
  ExtendedArchetype,
  ExtendedPattern,
  ExtendedPeerStory,
  ExtendedPeerStoryGated,
  ExtendedScale,
  SnapshotSummary,
} from "@/models/sharedModels";

import elementJsonData from "../../../../assets/traitInfo/elements.json";
import patternJsonData from "../../../../assets/traitInfo/patterns.json";
import archetypesJsonData from "../../../../assets/traitInfo/archetypes.json";

import { callFirebaseFunctions } from "@/_firebase/callFirebaseFunctions";
import { useAuth } from "@/context/AuthContext";
import {
  checkConnectionDimensionCompletion,
  getRequiredConnectionLevel,
  hasCompletedRequiredDimensions,
} from "./PeerStories/utils";
import {
  resetCurrentPeerStories,
  setCurrentPeerStories,
} from "@/redux/slices/peerStoriesSlice";

export function getSimilarityDescription(score: number): string {
  if (score <= 16) {
    return "Polar opposites";
  } else if (score <= 36 && score > 16) {
    return "Very different";
  } else if (score <= 51 && score > 36) {
    return "Somewhat different";
  } else if (score <= 66 && score > 51) {
    return "Somewhat similar";
  } else if (score <= 86 && score > 66) {
    return "Very similar";
  } else if (score < 100 && score > 86) {
    return "Nearly identical";
  } else {
    return "Identical";
  }
}

export function sortSpectrumData(data: SnapshotSummary) {
  const identityOrder = [
    "(I)ntroverted",
    "(S)ensing",
    "(F)eeling",
    "(J)udging",
  ];
  const workOrder = [
    "Accommodating",
    "Instinctual",
    "Scattered",
    "Self-directed",
  ];
  const loveOrder = ["Independent", "Romantic Idealist", "Entropic", "Daring"];
  const motivationOrder = [
    "Belonging",
    "Conservation",
    "Content",
    "Seeks calm",
  ];

  // Sorting function
  function sortByOrder(order: string[], data: SnapshotScore[]) {
    const orderMap: Record<string, number> = {};
    order.forEach((item, index) => {
      orderMap[item] = index;
    });

    return data.sort(
      (a, b) =>
        orderMap[a.profileSnapshot.lowDescriptor] -
        orderMap[b.profileSnapshot.lowDescriptor]
    );
  }

  // Sort each theme
  data.snapshotSummary.identity = sortByOrder(
    identityOrder,
    data.snapshotSummary.identity
  );
  data.snapshotSummary.work = sortByOrder(workOrder, data.snapshotSummary.work);
  data.snapshotSummary.love = sortByOrder(loveOrder, data.snapshotSummary.love);
  data.snapshotSummary.motivation = sortByOrder(
    motivationOrder,
    data.snapshotSummary.motivation
  );

  return data;
}

export function manipulateScorebarValue(score: number) {
  let newScore = score;

  if (newScore < 10) {
    newScore = score + 13;
  } else if (newScore < 20) {
    newScore = score + 12;
  } else if (newScore < 30) {
    newScore = score + 11;
  } else if (newScore < 40) {
    newScore = score + 10;
  } else if (newScore < 50) {
    newScore = score + 9;
  } else if (newScore < 60) {
    newScore = score + 7;
  } else if (newScore < 70) {
    newScore = score + 6;
  } else if (newScore < 80) {
    newScore = score + 4;
  } else if (newScore < 90) {
    newScore = score + 2;
  } else {
    newScore = score;
  }

  return newScore;
}

export function manipulateLargeScorebarValue(score: number) {
  let newScore = score;

  if (newScore < 10) {
    newScore = score + 7;
  } else if (newScore < 20) {
    newScore = score + 6;
  } else if (newScore < 30) {
    newScore = score + 5;
  } else if (newScore < 40) {
    newScore = score + 4;
  } else if (newScore < 50) {
    newScore = score + 3;
  } else if (newScore < 60) {
    newScore = score + 2;
  } else if (newScore < 70) {
    newScore = score + 1;
  } else if (newScore < 80) {
    newScore = score + 0;
  } else {
    newScore = score;
  }

  return newScore;
}

export function getElementFromTraitIdentifier(
  trait: TraitIdentifier
): ExtendedArchetype | ExtendedPattern | ExtendedScale | null {
  let element: ExtendedScale | null = null;
  let archetype: ExtendedArchetype | null = null;
  let pattern: ExtendedPattern | null = null;
  if (trait.type === "element") {
    elementJsonData.forEach((el) => {
      if (el.slug === trait.slug) {
        element = el as ExtendedScale;
      }
    });
    return element;
  } else if (trait.type === "archetype") {
    archetypesJsonData.forEach((arch) => {
      if (arch.slug === trait.slug) {
        archetype = arch as unknown as ExtendedArchetype;
      }
    });
    return archetype;
  } else {
    patternJsonData.forEach((patt) => {
      if (patt.slug === trait.slug) {
        pattern = patt as unknown as ExtendedPattern;
      }
    });
    return pattern;
  }
}

export async function getFriendScoreOnElement(uid: string, slug: string) {
  const fetchData = callFirebaseFunctions("fetchFriendElementDiscovery");
  return fetchData({
    memberUUID: uid,
    version: "2",
    traitSlug: slug,
  });
}

export async function getFriendScoreOnPattern(uid: string, slug: string) {
  const fetchData = callFirebaseFunctions("fetchFriendPatternDiscovery");
  return fetchData({
    memberUUID: uid,
    version: "2",
    traitSlug: slug,
  });
}

export async function getDiscoverableCompatibilitySnippets() {
  let tempDiscoverableSnippets: DiscoverableCompatibilitySnippet[] = [];
  const ref = collection(db, "discoverableCompatibilitySnippets");
  await getDocs(ref).then((querysnap) => {
    querysnap.forEach((doc) => {
      tempDiscoverableSnippets.push(
        doc.data() as DiscoverableCompatibilitySnippet
      );
    });
  });
  return tempDiscoverableSnippets;
}

export type CompareTabSectionDetail = {
  type:
    | "wideCompatibilitySnippet"
    | "compatibilitySnippet"
    | "largeCompatibilitySnippet";
  slug: string;
};

export const corePersonalitySectionDataCompareTab: CompareTabSectionDetail[] = [
  {
    type: "wideCompatibilitySnippet",
    slug: "compatibility-snippet-overall-similarity",
  },
  {
    type: "compatibilitySnippet",
    slug: "compatibility-snippet-dark-sides-similarity",
  },
  {
    type: "compatibilitySnippet",
    slug: "compatibility-snippet-vibe-5-statements",
  },
  {
    type: "compatibilitySnippet",
    slug: "compatibility-snippet-motivational-values",
  },
  {
    type: "compatibilitySnippet",
    slug: "compatibility-snippet-cognitive-compatibility",
  },
  {
    type: "compatibilitySnippet",
    slug: "compatibility-snippet-primary-traits-compared",
  },
];

export const loveAndRelationshipsSectionDataCompareTab: CompareTabSectionDetail[] =
  [
    {
      type: "largeCompatibilitySnippet",
      slug: "compatibility-snippet-romantic-compatibility-report",
    },
    {
      type: "compatibilitySnippet",
      slug: "compatibility-snippet-sexual-compatibility",
    },
    {
      type: "compatibilitySnippet",
      slug: "compatibility-snippet-love-style-compatibility-new",
    },
    {
      type: "compatibilitySnippet",
      slug: "compatibility-snippet-love-archetypes-compared",
    },
    {
      type: "compatibilitySnippet",
      slug: "compatibility-snippet-attachment-styles",
    },
    {
      type: "compatibilitySnippet",
      slug: "compatibility-snippet-love-attitudes-compared",
    },
    {
      type: "compatibilitySnippet",
      slug: "compatibility-snippet-emoji-lovers",
    },
    {
      type: "compatibilitySnippet",
      slug: "compatibility-snippet-predictions-of-future",
    },
    {
      type: "compatibilitySnippet",
      slug: "compatibility-snippet-how-you-complement",
    },
    {
      type: "compatibilitySnippet",
      slug: "compatibility-snippet-questions-to-ask",
    },
  ];

export const friendshipSectionDataCompareTab: CompareTabSectionDetail[] = [
  {
    type: "compatibilitySnippet",
    slug: "compatibility-snippet-lifestyle-compared",
  },
  {
    type: "compatibilitySnippet",
    slug: "compatibility-snippet-ideology-compared",
  },
  {
    type: "compatibilitySnippet",
    slug: "compatibility-snippet-emoji-friends",
  },
];

export const workSectionDataCompareTab: CompareTabSectionDetail[] = [
  {
    type: "largeCompatibilitySnippet",
    slug: "compatibility-snippet-work-compatibility-report",
  },
  {
    type: "compatibilitySnippet",
    slug: "compatibility-snippet-interaction-styles",
  },
];

export async function getSimilarityScore(slug: string, uid: string) {
  const call = callFirebaseFunctions("computeSimilarity");
  return call({
    contentIdentifier: {
      contentType: ContentType.compatibilitySnippet,
      contentSlug: slug,
    },
    peerUid: uid,
  })
    .then((res) => {
      const score = res.data as { score: number };
      return { slug: slug, score: score.score * 100 };
    })
    .catch((err) => {
      console.log("similarity error", err);
      return { slug: slug, score: null };
    });
}

export async function getCompatibilityScore(slug: string, uid: string) {
  const call = callFirebaseFunctions("computeCompatibility");
  return call({
    contentIdentifier: {
      contentType: ContentType.compatibilitySnippet,
      contentSlug: slug,
    },
    peerUid: uid,
  })
    .then((res) => {
      const score = res.data as { score: number };
      return { slug: slug, score: score.score * 100 };
    })
    .catch((err) => {
      console.log("compatibility error", err);
      return { slug: slug, score: null };
    });
}

async function getPeerStoryData(
  uid: string | undefined,
  connectionUid: string
) {
  const ref = collection(db, `/members/${uid}/discoveredPeerStories`);
  const q = query(ref, where("connectionUid", "==", connectionUid));

  return await getDocs(q).then((res) => {
    const foundStories: DiscoveredPeerStory[] = [];
    res.forEach((d) => {
      const data = d.data() as DiscoveredPeerStory;
      foundStories.push(data);
    });

    return foundStories;
  });
}

export function useGetPeerStoryData(props: {
  connectionUid: string;
  discoverablePeerStories: DiscoverablePeerStory[];
  connectionType:
    | "connected"
    | "close"
    | "deep"
    | "sent"
    | "unconnected"
    | "received"
    | null;
  otherPublicProfile: PublicProfile | null;
}) {
  const dispatch = useDispatch();

  const { user } = useAuth();
  const dimensionEvents = useSelector(
    (state: RootState) => state.user.dimensionEvents
  );
  const profileModalUidState = useSelector(
    (state: RootState) => state.profileModalUid
  );

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    //for when another profile modal gets added tot he modal stack
    setLoading(true);
  }, [profileModalUidState.currentUserUid]);

  useEffect(() => {
    if (
      props.connectionUid &&
      props.connectionType &&
      props.discoverablePeerStories &&
      props.otherPublicProfile &&
      loading &&
      profileModalUidState.currentUserUid === props.connectionUid
    ) {
      getPeerStoryData(user?.uid, props.connectionUid).then(
        async (discoveredStories) => {
          // Merge discovered stories with discoverable stories
          const mergedStories = props.discoverablePeerStories.map(
            (discoverableStory) => {
              const discoveredMatch = discoveredStories.find(
                (discovered) => discovered.slug === discoverableStory.slug
              );
              return {
                ...discoverableStory,
                ...discoveredMatch,
              } as ExtendedPeerStory;
            }
          );

          // Process gating asynchronously for each story
          const mergedGatingStories = await Promise.all(
            mergedStories.map(async (story) => {
              const connectionStatus = getRequiredConnectionLevel(
                story.connectionAvailabilities,
                props.connectionType
              );
              const hasUserCompletedDimensions = hasCompletedRequiredDimensions(
                story.requiredDimensionSlugs,
                dimensionEvents
              );

              const hasConnectionCompletedDimensionEvents =
                await checkConnectionDimensionCompletion(
                  props.otherPublicProfile,
                  story.requiredDimensionSlugs
                );

              let gating: ExtendedPeerStoryGated["gating"] = false;
              if (connectionStatus !== null) {
                gating = connectionStatus;
              } else if (!hasUserCompletedDimensions) {
                gating = "discoveryGate";
              } else if (
                hasConnectionCompletedDimensionEvents === "Nudge view"
              ) {
                gating = "nudge";
              }

              return {
                ...story,
                gating,
              } as ExtendedPeerStoryGated;
            })
          );

          dispatch(setCurrentPeerStories(mergedGatingStories));
          setLoading(false);
        }
      );
    }

    return () => {
      dispatch(resetCurrentPeerStories());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.connectionUid,
    props.connectionType,
    props.discoverablePeerStories,
    props.otherPublicProfile,
    profileModalUidState.currentUserUid,
  ]);

  return { storiesLoading: loading };
}

export function getConnectionRequiredFromConnectionAvailablity(
  connectionAvailabilities: ConnectionAvailability[]
): ConnectionAvailability {
  if (connectionAvailabilities.includes(ConnectionAvailability.private)) {
    return ConnectionAvailability.private;
  }

  if (connectionAvailabilities.includes(ConnectionAvailability.unconnected)) {
    return ConnectionAvailability.unconnected;
  }

  if (connectionAvailabilities.includes(ConnectionAvailability.basic)) {
    return ConnectionAvailability.basic;
  }
  if (
    connectionAvailabilities.includes(ConnectionAvailability.close) &&
    !connectionAvailabilities.includes(ConnectionAvailability.basic)
  ) {
    return ConnectionAvailability.close;
  }
  if (
    connectionAvailabilities.includes(ConnectionAvailability.deep) &&
    !connectionAvailabilities.includes(ConnectionAvailability.close) &&
    !connectionAvailabilities.includes(ConnectionAvailability.basic)
  ) {
    return ConnectionAvailability.deep;
  }

  return ConnectionAvailability.basic;
}

export function sortPeerStories(
  a: DiscoverablePeerStory,
  b: DiscoverablePeerStory
): number {
  const connectionAvailSortMap: Record<ConnectionAvailability, number> = {
    basic: 0,
    close: 1,
    deep: 2,
    private: 3,
    unconnected: 4,
  };

  const promptMap: Record<string, number> = {
    "prompt-peer-story-friendship-core": 0,
    "prompt-peer-story-friendship-motherload": 1,
    "prompt-peer-story-love-medium-load": 2,
    "prompt-peer-story-love-big-load": 3,
  };

  const aAvailability =
    connectionAvailSortMap[
      getConnectionRequiredFromConnectionAvailablity(a.connectionAvailabilities)
    ];
  const bAvailability =
    connectionAvailSortMap[
      getConnectionRequiredFromConnectionAvailablity(b.connectionAvailabilities)
    ];

  const aPrompt = promptMap[a.promptSlug as keyof typeof promptMap] ?? Infinity;
  const bPrompt = promptMap[b.promptSlug as keyof typeof promptMap] ?? Infinity;

  // Primary sorting by availability
  if (aAvailability !== bAvailability) {
    return aAvailability - bAvailability;
  }

  // Secondary sorting by prompt
  if (aPrompt !== bPrompt) {
    return aPrompt - bPrompt;
  }

  // Tertiary sorting by shortName
  return a.shortName.toLowerCase().localeCompare(b.shortName.toLowerCase());
}
