import { useEffect, useState } from "react";
import {
  DiscoveredSnippet,
  PublicProfile,
} from "@dimensional-engineering/dimensional-models";

import styles from "./AnalysisSection.module.css";

import {
  SnippetBlockHighestScoringTraits,
  SnippetBlockHighestScoringTraitsNonOwner,
  SnippetBlockNonOwner,
  SnippetBlockResultsTab,
} from "@/components/results/Snippets/SnippetBlock";
import NutshellSnippetPreviewOwn, {
  NutshellSnippetPreviewNonOwner,
} from "@/components/results/Snippets/NutshellSnippet/NutshellSnippet";
import { fetchProfileResultSnippets } from "@/components/authenticatedProfile/utils";
import Loader from "@/components/shared/Loader";
import Shimmer from "@/components/shared/Shimmer/Shimmer";

export function AnalysisSectionOwn() {
  return (
    <section>
      <AnalaysisSectionRenderer
        state="own"
        data={[
          { type: "nutshell", slug: "" },
          { type: "snippet", slug: "snippet-higher-all-users" },
          { type: "snippet", slug: "snippet-lower-all-users" },
          { type: "snippet", slug: "snippet-in-summary" },
          {
            type: "highestScoringTraits",
            slug: "snippet-highest-scoring-traits",
          },
        ]}
        discoveredSnippets={[]}
        publicProfile={null}
      />
    </section>
  );
}

type FetchProfileSnippetsResult = {
  discoveredSnippets: DiscoveredSnippet[];
};

export function AnalysisSectionConnected(props: {
  publicProfile: PublicProfile | null;
  imageUrl: string;
}) {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<DiscoveredSnippet[]>([]);
  const [slugs, setSlugs] = useState<string[]>([]);

  useEffect(() => {
    if (props.publicProfile?.ownerUUID) {
      fetchProfileResultSnippets(props.publicProfile?.ownerUUID)
        .then((res) => {
          const data = res.data as FetchProfileSnippetsResult;
          setLoading(false);
          setData(data.discoveredSnippets);
          let discoveredSlugs: string[] = [];
          data.discoveredSnippets.forEach((d) => {
            discoveredSlugs.push(d?.snippetSlug);
          });
          setSlugs(discoveredSlugs);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [props.publicProfile?.ownerUUID]);

  if (loading) {
    return (
      <div style={{ width: "100%", padding: "0 2%" }}>
        <Shimmer height={350} />
      </div>
    );
  }

  return (
    <AnalaysisSectionRenderer
      state="connected"
      data={[
        { type: "nutshell", slug: "" },
        { type: "snippet", slug: "snippet-higher-all-users" },
        { type: "snippet", slug: "snippet-lower-all-users" },
        { type: "snippet", slug: "snippet-in-summary" },
        {
          type: "highestScoringTraits",
          slug: "snippet-highest-scoring-traits",
        },
      ]}
      discoveredSnippets={data}
      uid={props.publicProfile?.ownerUUID}
      publicProfile={props.publicProfile}
      imageUrl={props.imageUrl}
    />
  );
}

export function AnalysisSectionUnConnected(props: {
  publicProfile: PublicProfile | null;
  imageUrl: string;
}) {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<DiscoveredSnippet[]>([]);
  const [slugs, setSlugs] = useState<string[]>([]);

  useEffect(() => {
    if (props.publicProfile?.ownerUUID) {
      fetchProfileResultSnippets(props.publicProfile?.ownerUUID)
        .then((res) => {
          const data = res.data as FetchProfileSnippetsResult;
          setLoading(false);
          setData(data.discoveredSnippets);
          let discoveredSlugs: string[] = [];
          data.discoveredSnippets.forEach((d) => {
            discoveredSlugs.push(d?.snippetSlug);
          });
          setSlugs(discoveredSlugs);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [props.publicProfile?.ownerUUID]);

  if (loading) {
    return (
      <div style={{ width: "100%", padding: "0 2%" }}>
        <Shimmer height={350} />
      </div>
    );
  }

  return (
    <AnalaysisSectionRenderer
      state="unconnected"
      data={[
        { type: "nutshell", slug: "" },
        { type: "snippet", slug: "snippet-higher-all-users" },
        { type: "snippet", slug: "snippet-lower-all-users" },
        { type: "snippet", slug: "snippet-in-summary" },
        {
          type: "highestScoringTraits",
          slug: "snippet-highest-scoring-traits",
        },
      ]}
      discoveredSnippets={data}
      uid={props.publicProfile?.ownerUUID}
      publicProfile={props.publicProfile}
      imageUrl={props.imageUrl}
    />
  );
}

type AnalysisSectionDataType = {
  type: "nutshell" | "snippet" | "highestScoringTraits";
  slug: string;
};

function AnalaysisSectionRenderer(props: {
  state: "own" | "connected" | "unconnected";
  data: AnalysisSectionDataType[];
  discoveredSnippets: DiscoveredSnippet[];
  uid?: string | undefined;
  publicProfile: PublicProfile | null;
  imageUrl?: string;
}) {
  const mapped = props.data.map((content) => {
    switch (content.type) {
      case "nutshell":
        return (
          <div className={styles.nushellDiv} key={content.slug}>
            <NutshellSnippetPreviewOwn />
          </div>
        );

      case "snippet":
        return (
          <div className={styles.snippetDiv} key={content.slug}>
            <SnippetBlockResultsTab slug={content.slug} />
          </div>
        );

      case "highestScoringTraits":
        return (
          <div className={styles.snippetDiv} key={content.slug}>
            <SnippetBlockHighestScoringTraits noWidth />
          </div>
        );

      default:
        break;
    }
  });

  const mappedConnected = props.data.map((content) => {
    switch (content.type) {
      case "nutshell":
        return (
          <div className={styles.nushellDiv} key={content.slug}>
            <NutshellSnippetPreviewNonOwner
              discoveredSnippet={props.discoveredSnippets?.find(
                (s) => s?.snippetSlug === "snippet-nutshell"
              )}
              connectionState="connected"
              publicProfile={props.publicProfile}
              imageUrl={props.imageUrl ?? ""}
            />
          </div>
        );

      case "snippet":
        if (props.publicProfile) {
          return (
            <div className={styles.snippetDiv} key={content.slug}>
              <SnippetBlockNonOwner
                discoveredSnippet={props.discoveredSnippets?.find(
                  (s) => s?.snippetSlug === content.slug
                )}
                publicProfile={props.publicProfile}
                slug={content.slug}
                connectionState="connected"
                imageUrl={props.imageUrl ?? ""}
                noWidth
              />
            </div>
          );
        }

      case "highestScoringTraits":
        const found = props.discoveredSnippets?.find(
          (s) => s?.snippetSlug === "snippet-highest-scoring-traits"
        )
          ? true
          : false;
        return (
          <div className={styles.snippetDiv} key={content.slug}>
            <SnippetBlockHighestScoringTraitsNonOwner
              uid={props.uid}
              connectionStatus="connected"
              discovered={found}
              publicProfile={props.publicProfile}
              noWidth
            />
          </div>
        );

      default:
        break;
    }
  });

  const mappedUnConnected = props.data.map((content) => {
    switch (content.type) {
      case "nutshell":
        return (
          <div className={styles.nushellDiv} key={content.slug}>
            <NutshellSnippetPreviewNonOwner
              discoveredSnippet={props.discoveredSnippets?.find(
                (s) => s?.snippetSlug === "snippet-nutshell"
              )}
              connectionState="unconnected"
              publicProfile={props.publicProfile}
              imageUrl={props.imageUrl ?? ""}
            />
          </div>
        );

      case "snippet":
        if (props.publicProfile) {
          return (
            <div className={styles.snippetDiv} key={content.slug}>
              <SnippetBlockNonOwner
                discoveredSnippet={props.discoveredSnippets?.find(
                  (s) => s?.snippetSlug === content.slug
                )}
                publicProfile={props.publicProfile}
                slug={content.slug}
                connectionState="unconnected"
                imageUrl={props.imageUrl ?? ""}
              />
            </div>
          );
        }

      case "highestScoringTraits":
        const found = props.discoveredSnippets?.find(
          (s) => s?.snippetSlug === "snippet-highest-scoring-traits"
        )
          ? true
          : false;
        return (
          <div className={styles.snippetDiv} key={content.slug}>
            <SnippetBlockHighestScoringTraitsNonOwner
              uid={props.uid}
              connectionStatus="unconnected"
              discovered={found}
              publicProfile={props.publicProfile}
              noWidth
            />
          </div>
        );

      default:
        break;
    }
  });

  return (
    <div className={styles.wrapper}>
      {props.state === "own"
        ? mapped
        : props.state === "connected"
        ? mappedConnected
        : mappedUnConnected}
    </div>
  );
}
