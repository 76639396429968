import { useState } from "react";

import styles from "./SystemDialog.module.css";

type Props = {
  title?: string;
  body: string;
  cancelText: string;
  approveText: string;
  onCancel: () => void;
  onApprove: () => void;
};

type PropsModal = {
  title?: string;
  body: string;
  cancelText: string;
  approveText: string;
  onCancel: () => void;
  onApprove: () => void;
};

type PropsModalRename = {
  title: string;
  cancelText: string;
  approveText: string;
  onCancel: () => void;
  onApprove: (text: string) => void;
};

export default function SystemDialog(props: Props) {
  return (
    <main onClick={() => props.onCancel()} className={styles.main}>
      <div onClick={(e) => e.stopPropagation()} className={styles.wrapper}>
        {props.title && <h1 className={styles.title}>{props.title}</h1>}
        <p className={styles.body}>{props.body}</p>
        <div className={styles.btnWrapper}>
          <button onClick={() => props.onCancel()} className={styles.btnCancel}>
            {props.cancelText}
          </button>
          <button
            onClick={() => props.onApprove()}
            className={styles.btnApprove}
          >
            {props.approveText}
          </button>
        </div>
      </div>
    </main>
  );
}

export function SystemDialogModal(props: PropsModal) {
  return (
    <main onClick={() => props.onCancel()} className={styles.main}>
      <div
        id="dialog"
        onClick={(e) => e.stopPropagation()}
        className={styles.wrapper}
      >
        {props.title && <h1 className={styles.title}>{props.title}</h1>}
        <p className={styles.body}>{props.body}</p>
        <div className={styles.btnWrapper}>
          <button onClick={() => props.onCancel()} className={styles.btnCancel}>
            {props.cancelText}
          </button>
          <button
            onClick={() => props.onApprove()}
            className={styles.btnApprove}
          >
            {props.approveText}
          </button>
        </div>
      </div>
    </main>
  );
}

export function SystemDialogModalRenameChat(props: PropsModalRename) {
  const [text, setText] = useState<string>("");

  return (
    <main onClick={() => props.onCancel()} className={styles.main}>
      <div
        id="dialog"
        onClick={(e) => e.stopPropagation()}
        className={styles.wrapper}
      >
        <h1 className={styles.title}>{props.title}</h1>
        <input
          onChange={(e) => {
            setText(e.target.value);
          }}
          className={styles.input}
        />
        <div className={styles.btnWrapper}>
          <button onClick={() => props.onCancel()} className={styles.btnCancel}>
            {props.cancelText}
          </button>
          <button
            onClick={() => props.onApprove(text)}
            className={styles.btnApprove}
          >
            {props.approveText}
          </button>
        </div>
      </div>
    </main>
  );
}
