import { useSelector } from "react-redux";

import styles from "./ReportsMap.module.css";

import { reportsData } from "./reportsData";
import { RootState } from "@/redux/store";
import PremiumPillIcon from "@/components/premium/PremiumPillIcon/PremiumPillIcon";
import { useAuth } from "@/context/AuthContext";
import { useModalContext } from "@/context/ModalContext";
import DiscoveryGate from "@/components/shared/DiscoveryGate/DiscoveryGate";
import { openInNewTab, summonProfileLink } from "@/components/shared/utils";
import ReportRetryGate from "@/components/shared/DiscoveryGate/ReportRetryGate";
import { Mixpanel } from "@/helpers/mixpanel";
import ConversionModal from "@/components/premium/ConversionModal/ConversionModal";

export default function ReportsBlockResults(props: {
  slug: string | null | undefined;
}) {
  const shareHash = useSelector(
    (state: RootState) => state.user.memberProfile?.shareHash
  );

  const report = reportsData?.find((r) => r.slug === props.slug);

  function handleClick() {
    if (props.slug === "you_report_slug") {
      return openInNewTab(`${window.location.origin}/reports/you/${shareHash}`);
    }
    if (props.slug === "love") {
      return openInNewTab(
        `${window.location.origin}/reports/love/${shareHash}`
      );
    }
    if (props.slug === "career") {
      return openInNewTab(
        `${window.location.origin}/reports/career/${shareHash}`
      );
    }
    if (props.slug === "blindspot_report_slug") {
      return openInNewTab(
        `${window.location.origin}/reports/blindspot/${shareHash}`
      );
    }
    if (props.slug === "therapist") {
      return openInNewTab(
        `${window.location.origin}/reports/therapist/${shareHash}`
      );
    }
  }

  return (
    <div
      onClick={() => {
        handleClick();
      }}
      style={
        report
          ? {
              backgroundImage: ` linear-gradient(
            180deg,
            rgba(37, 0, 0, 0) 54.69%,
            rgba(6, 0, 0, 0.5) 100%
          ), url(${report.imageUrl})`,
            }
          : {}
      }
      className={styles.reportBoxResults}
    >
      <h3 className={styles.titleResults}>{report?.name}</h3>
    </div>
  );
}

export function ReportsBlockResultsTab(props: {
  slug: string | null | undefined;
}) {
  const userState = useSelector((state: RootState) => state.user);
  const reportState = useSelector((state: RootState) => state.report);
  const { setModalComponent, setModalOpen } = useModalContext();
  const { user } = useAuth();

  const report = reportsData?.find((r) => r.slug === props.slug);

  if (!report) {
    return <></>;
  }

  async function handleClick(
    reportName: string,
    requiredSlugs: string[],
    slug: string
  ) {
    if (!userState.isPremium) {
      ///premium check
      Mixpanel?.track("Premium Conversion Modal Summoned", {
        source: reportName,
      });

      return setModalComponent(<ConversionModal initialSlide={5} />);
    }

    let core = ["personality", "personality-II", "values"];
    if (reportName === "You Report") {
      //you report
      if (
        userState.primaryFinished &&
        userState.cognitiveFinished &&
        userState.valueFinished
      ) {
        return openInNewTab(
          `${window.location.origin}/reports/you/${userState.memberProfile?.shareHash}`
        );
      } else {
        return setModalComponent(
          <DiscoveryGate
            onClose={() => setModalOpen(false)}
            onOpenShareLink={() => {
              summonProfileLink(
                user?.uid,
                "gating modal",
                "Invite to Dimensional"
              );
            }}
            requiredDimensionSlugs={core}
          />
        );
      }
    } else if (reportName === "Love") {
      //love report
      if (reportState.hasLoveReportLlmData) {
        return openInNewTab(
          `${window.location.origin}/reports/love/${userState.memberProfile?.shareHash}`
        );
      } else {
        const allFinishedDimensionSlugs: string[] = [];
        userState.dimensionEvents?.forEach((e) => {
          if (e.event === "complete") {
            allFinishedDimensionSlugs.push(e.dimensionSlug);
          }
        });
        const allFinished = requiredSlugs.every((item) =>
          allFinishedDimensionSlugs.includes(item)
        );

        if (!allFinished) {
          return setModalComponent(
            <DiscoveryGate
              onClose={() => setModalOpen(false)}
              onOpenShareLink={() => {}}
              requiredDimensionSlugs={requiredSlugs}
            />
          );
        } else {
          return setModalComponent(
            <ReportRetryGate onClose={() => setModalOpen(false)} slug={slug} />
          );
        }
      }
    } else if (reportName === "Career Report") {
      //career report
      if (reportState.hasCareerReportLlmData) {
        return openInNewTab(
          `${window.location.origin}/reports/career/${userState.memberProfile?.shareHash}`
        );
      } else {
        const allFinishedDimensionSlugs: string[] = [];
        userState.dimensionEvents?.forEach((e) => {
          if (e.event === "complete") {
            allFinishedDimensionSlugs.push(e.dimensionSlug);
          }
        });
        const allFinished = requiredSlugs.every((item) =>
          allFinishedDimensionSlugs.includes(item)
        );

        if (!allFinished) {
          return setModalComponent(
            <DiscoveryGate
              onClose={() => setModalOpen(false)}
              onOpenShareLink={() => {}}
              requiredDimensionSlugs={requiredSlugs}
            />
          );
        } else {
          return setModalComponent(
            <ReportRetryGate onClose={() => setModalOpen(false)} slug={slug} />
          );
        }
      }
    } else {
      //therapist report
      if (reportState.hasTherapistReportLlmData) {
        return openInNewTab(
          `${window.location.origin}/reports/therapist/${userState.memberProfile?.shareHash}`
        );
      } else {
        const allFinishedDimensionSlugs: string[] = [];
        userState.dimensionEvents?.forEach((e) => {
          if (e.event === "complete") {
            allFinishedDimensionSlugs.push(e.dimensionSlug);
          }
        });
        const allFinished = requiredSlugs.every((item) =>
          allFinishedDimensionSlugs.includes(item)
        );

        if (!allFinished) {
          return setModalComponent(
            <DiscoveryGate
              onClose={() => setModalOpen(false)}
              onOpenShareLink={() => {}}
              requiredDimensionSlugs={requiredSlugs}
            />
          );
        } else {
          return setModalComponent(
            <ReportRetryGate onClose={() => setModalOpen(false)} slug={slug} />
          );
        }
      }
    }
  }

  return (
    <div
      onClick={() => {
        handleClick(report.name, report.requiredDimensionSlugs, report.slug);
      }}
      style={
        report
          ? {
              backgroundImage: `linear-gradient(
    180deg,
    rgba(37, 0, 0, 0) 54.69%,
    rgba(6, 0, 0, 0.5) 100%
  ), url(${report.imageUrl})`,
            }
          : {}
      }
      className={styles.reportBoxResultsTab}
    >
      <div className={styles.premiumIcon}>
        <PremiumPillIcon />
      </div>
      <h3 className={styles.titleResultsTab}>{report?.name}</h3>
      <p className={styles.subTitleResultsTab}>{report?.subtitle}</p>
    </div>
  );
}
