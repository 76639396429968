import styles from "./NotificationWarningPill.module.css";

import { NotificationLevel } from "@/models/sharedModels";

export default function NotificationWarningPill(props: {
  onClick: () => void;
  notificationStatus:
    | NotificationLevel
    | "systemOff"
    | "noSelection"
    | undefined;
  active: boolean;
}) {
  if (props.notificationStatus === "systemOff") {
    return (
      <div
        onClick={() => {
          if (window.Android) {
            window.Android.onHaptic();
          }
          props.onClick();
        }}
        className={styles.main}
        style={props.active ? {} : { background: "rgb(45, 41, 39)" }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="16"
          viewBox="0 0 15 16"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.66063 0.0800487C6.69453 0.0800487 5.93234 0.923097 5.93234 1.92145C5.93234 2.03887 5.94288 2.15349 5.96328 2.26456C3.8166 3.16667 2.09688 5.81741 2.09688 10.0377C2.09688 10.8624 2.035 11.2492 1.87188 11.5712C1.70875 11.8932 1.39797 12.2103 0.853039 12.8038V12.8031C0.756009 12.9085 0.730695 13.0611 0.788351 13.1919C0.846007 13.3227 0.974687 13.4071 1.11811 13.4071H6.31849C6.14623 13.6637 6.04498 13.9724 6.04498 14.3036C6.04498 15.1909 6.77202 15.92 7.66147 15.92C8.54952 15.92 9.27796 15.1916 9.27796 14.3036C9.27796 13.9724 9.17671 13.6644 9.00445 13.4071H14.2027H14.202C14.3447 13.4071 14.4741 13.3227 14.5317 13.1919C14.5894 13.0611 14.5641 12.9086 14.467 12.8031C13.9214 12.2096 13.6106 11.8925 13.4482 11.5705C13.2851 11.2485 13.2232 10.8624 13.2232 10.037C13.2232 5.81544 11.5076 3.16746 9.35806 2.26458C9.37846 2.15279 9.389 2.03818 9.389 1.92075C9.389 0.921602 8.62611 0.0793457 7.66071 0.0793457L7.66063 0.0800487Z"
            fill="#FFFFF2"
          />
        </svg>
        <p>!</p>
      </div>
    );
  }

  return (
    <div
      onClick={() => {
        if (window.Android) {
          window.Android.onHaptic();
        }
        props.onClick();
      }}
      className={styles.main}
      style={
        props.active
          ? { background: "#4CBC86" }
          : { background: "rgb(45, 41, 39)" }
      }
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="16"
        viewBox="0 0 15 16"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.66063 0.0800487C6.69453 0.0800487 5.93234 0.923097 5.93234 1.92145C5.93234 2.03887 5.94288 2.15349 5.96328 2.26456C3.8166 3.16667 2.09688 5.81741 2.09688 10.0377C2.09688 10.8624 2.035 11.2492 1.87188 11.5712C1.70875 11.8932 1.39797 12.2103 0.853039 12.8038V12.8031C0.756009 12.9085 0.730695 13.0611 0.788351 13.1919C0.846007 13.3227 0.974687 13.4071 1.11811 13.4071H6.31849C6.14623 13.6637 6.04498 13.9724 6.04498 14.3036C6.04498 15.1909 6.77202 15.92 7.66147 15.92C8.54952 15.92 9.27796 15.1916 9.27796 14.3036C9.27796 13.9724 9.17671 13.6644 9.00445 13.4071H14.2027H14.202C14.3447 13.4071 14.4741 13.3227 14.5317 13.1919C14.5894 13.0611 14.5641 12.9086 14.467 12.8031C13.9214 12.2096 13.6106 11.8925 13.4482 11.5705C13.2851 11.2485 13.2232 10.8624 13.2232 10.037C13.2232 5.81544 11.5076 3.16746 9.35806 2.26458C9.37846 2.15279 9.389 2.03818 9.389 1.92075C9.389 0.921602 8.62611 0.0793457 7.66071 0.0793457L7.66063 0.0800487Z"
          fill="#FFFFF2"
        />
      </svg>
      {props.notificationStatus === "noSelection" && <p>?</p>}
    </div>
  );
}
