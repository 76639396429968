import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";

import styles from "./PeerStoryBlockLatestResults.module.css";

import useGetPublicProfile from "@/helpers/useGetPublicProfile";
import useGetProfilePhoto from "@/helpers/useGetProfilePhoto";
import { ChatProfileImage } from "@/components/shared/SmallProfileImage/LargeProfileImage";
import {
  checkConnectionDimensionCompletion,
  getRequiredConnectionLevel,
  getSinglePeerStoryForNotificationAndLatestResults,
  hasCompletedRequiredDimensions,
} from "../utils";
import { useAuth } from "@/context/AuthContext";
import { useModalContext } from "@/context/ModalContext";
import {
  ExtendedPeerStory,
  ExtendedPeerStoryGated,
} from "@/models/sharedModels";
import useConnectionStatus from "@/helpers/useConnectionStatus";
import PeerStoriesModalSingle from "../PeerStoriesModal/PeerStoriesModalSingle";
import LoadingModal from "@/components/shared/LoadingModal/LoadingModal";

export default function PeerStoryBlockLatestResults(props: {
  peerStory:
    | {
        peerUid: string;
        slug: string;
      }
    | null
    | undefined;
}) {
  const { user } = useAuth();

  const { setModalComponent, removeModalByName } = useModalContext();

  const discoverablePeerStories = useSelector(
    (state: RootState) => state.peerStories
  ).discoverablePeerStory;

  const story = discoverablePeerStories.find(
    (s) => s.slug === props.peerStory?.slug
  );

  const dimensionEvents = useSelector(
    (state: RootState) => state.user.dimensionEvents
  );

  const publicProfile = useGetPublicProfile(props.peerStory?.peerUid);
  const profileImage = useGetProfilePhoto(props.peerStory?.peerUid);
  const connectionType = useConnectionStatus(props.peerStory?.peerUid);

  if (!props.peerStory || !story) {
    return <></>;
  }

  const dispayUsername =
    publicProfile?.userName && publicProfile?.userName.length > 13
      ? `@${publicProfile?.userName.slice(0, 13)}...`
      : publicProfile?.userName;

  async function handleClick() {
    setModalComponent(<LoadingModal elementName="LoadingModal" />);
    const storyData = await getSinglePeerStoryForNotificationAndLatestResults(
      user?.uid,
      props.peerStory?.peerUid,
      props.peerStory?.slug
    );
    if (storyData) {
      //story available
      if (story && publicProfile) {
        const mergedStory: ExtendedPeerStory = {
          ...story,
          ...storyData,
        };
        const connectionStatus = getRequiredConnectionLevel(
          story.connectionAvailabilities,
          connectionType
        );
        const hasUserCompletedDimensions = hasCompletedRequiredDimensions(
          story.requiredDimensionSlugs,
          dimensionEvents
        );

        const hasConnectionCompletedDimensionEvents =
          await checkConnectionDimensionCompletion(
            publicProfile,
            story.requiredDimensionSlugs
          );

        let gating: ExtendedPeerStoryGated["gating"] = false;

        if (connectionStatus !== null) {
          gating = connectionStatus;
        } else if (!hasUserCompletedDimensions) {
          gating = "discoveryGate";
        } else if (hasConnectionCompletedDimensionEvents === "Nudge view") {
          gating = "nudge";
        }
        const extendedPeerStoryGated: ExtendedPeerStoryGated = {
          ...mergedStory,
          gating,
        };

        setModalComponent(
          <PeerStoriesModalSingle
            publicProfile={publicProfile}
            story={extendedPeerStoryGated}
            discoverableStory={story}
          />
        );
        return removeModalByName("LoadingModal");
      }
    } else {
      if (story && publicProfile && props.peerStory) {
        //deleted story
        const mergedStory: ExtendedPeerStory = {
          ...story,
          connectionUid: props.peerStory?.peerUid,
          //@ts-ignore
          peerStoryContent: null,
        };
        const connectionStatus = getRequiredConnectionLevel(
          story.connectionAvailabilities,
          connectionType
        );
        const hasUserCompletedDimensions = hasCompletedRequiredDimensions(
          story.requiredDimensionSlugs,
          dimensionEvents
        );

        const hasConnectionCompletedDimensionEvents =
          await checkConnectionDimensionCompletion(
            publicProfile,
            story.requiredDimensionSlugs
          );

        let gating: ExtendedPeerStoryGated["gating"] = false;

        if (connectionStatus !== null) {
          gating = connectionStatus;
        } else if (!hasUserCompletedDimensions) {
          gating = "discoveryGate";
        } else if (hasConnectionCompletedDimensionEvents === "Nudge view") {
          gating = "nudge";
        }
        const extendedPeerStoryGated: ExtendedPeerStoryGated = {
          ...mergedStory,
          gating,
        };

        setModalComponent(
          <PeerStoriesModalSingle
            publicProfile={publicProfile}
            story={extendedPeerStoryGated}
            discoverableStory={story}
          />
        );
        return removeModalByName("LoadingModal");
      }
    }
    return removeModalByName("LoadingModal");
  }

  return (
    <div
      style={{
        backgroundImage: `linear-gradient(360deg, rgba(24, 23, 22, 0.50) 0%, rgba(24, 23, 22, 0.00) 100%), url(/peer-stories/${props.peerStory.slug}.jpg)`,
      }}
      className={styles.main}
      onClick={() => {
        handleClick();
      }}
    >
      <p className={styles.username}>{dispayUsername}</p>
      <div className={styles.imageDiv}>
        <ChatProfileImage
          publicProfile={publicProfile}
          imageUrl={profileImage}
        />
      </div>
      <p className={styles.storyName}>{story.shortName}</p>
    </div>
  );
}
