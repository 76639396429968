export default function EarthCoreImage() {
  return (
    <img
      width={367}
      height={367}
      alt="Earth's Core"
      src={`/compatibility-snippets/earth-core.png`}
    />
  );
}
