import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type DeepSeekMessage = {
  role: "user" | "assistant" | "system";
  content: string;
};

export type DeepSeekTemperature = "0.0" | "1.0" | "1.3" | "1.5";

export type DeepSeekModel = "deepseek-chat" | "deepseek-reasoner";

type DeepSeekPlaygroundSlice = {
  systemMessage: string;
  messages: DeepSeekMessage[];
  currentMessage: string;
  loading: boolean;
};

const initialState: DeepSeekPlaygroundSlice = {
  systemMessage: "",
  messages: [],
  currentMessage: "",
  loading: false,
};

export const deepSeekPlaygroundSlice = createSlice({
  name: "deepSeekPlayground",
  initialState: initialState,
  reducers: {
    setPlaygroundSystemMessage: (state, action: PayloadAction<string>) => {
      return { ...state, systemMessage: action.payload };
    },
    addMessage: (state, action: PayloadAction<DeepSeekMessage>) => {
      state.messages.push(action.payload);
    },
    updateMessageAtIndex: (
      state,
      action: PayloadAction<{ index: number; message: string }>
    ) => {
      const { index, message } = action.payload;
      if (index >= 0 && index < state.messages.length) {
        state.messages[index].content = message;
      }
    },
    deleteMessageAtIndex: (state, action: PayloadAction<number>) => {
      state.messages.splice(action.payload, 1);
    },
    setCurrentMessage: (state, action: PayloadAction<string>) => {
      return { ...state, currentMessage: action.payload };
    },
    setDeepSeekLoading: (state, action: PayloadAction<boolean>) => {
      return { ...state, loading: action.payload };
    },
    resetDeepSeekState: () => {
      return initialState;
    },
  },
});

export const {
  setPlaygroundSystemMessage,
  addMessage,
  updateMessageAtIndex,
  setCurrentMessage,
  setDeepSeekLoading,
  resetDeepSeekState,
  deleteMessageAtIndex,
} = deepSeekPlaygroundSlice.actions;

export default deepSeekPlaygroundSlice.reducer;
