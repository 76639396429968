import { useEffect, useState } from "react";
import {
  PublicProfile,
  DiscoverableCompatibilitySnippet,
} from "@dimensional-engineering/dimensional-models";
import { useDispatch, useSelector } from "react-redux";

import styles from "./AuthCompareTab.module.css";

import useConnectionStatus from "@/helpers/useConnectionStatus";
import { DynamicProfileResultData } from "@/models/sharedModels";
import { RootState } from "@/redux/store";
import {
  CompareTabSectionDetail,
  corePersonalitySectionDataCompareTab,
  friendshipSectionDataCompareTab,
  getCompatibilityScore,
  getSimilarityScore,
  loveAndRelationshipsSectionDataCompareTab,
  useGetPeerStoryData,
  workSectionDataCompareTab,
} from "./utils";
import { CompatibilitySnippetBlockCompareTab } from "./Compatibility/CompatibilitySnippetBlock";
import { useGetCompatibilityInsightDatModal } from "@/helpers/useGetCompatibilityInsightData";
import { useModalContext } from "@/context/ModalContext";
import CompatibilityModal from "./Compatibility/CompatibilityModal";
import {
  setSimilarityAndCompatibilityScores,
  setSimilarityAndCompatibilityScoresLoading,
} from "@/redux/slices/connectionSlice";
import PeerStoriesModal from "./PeerStories/PeerStoriesModal/PeerStoriesModal";
import PeerStoriesShimmer from "./PeerStories/PeerStoriesShimmer/PeerStoriesShimmer";
import LoadingModal from "@/components/shared/LoadingModal/LoadingModal";

type PropsModal = {
  otherPublicProfile: PublicProfile | null;
  imageUrl: string | undefined;
  data: DynamicProfileResultData;
  uid: string;
  connectionType:
    | "connected"
    | "close"
    | "deep"
    | "sent"
    | "unconnected"
    | "received"
    | null;
  peerStorySlug?: string;
  isConnectionPremium: boolean;
};

export function AuthCompareTabModal(props: PropsModal) {
  const friendStatus = useConnectionStatus(props.otherPublicProfile?.ownerUUID);
  const compatibilityState = useSelector(
    (state: RootState) => state.compatibilitySnippets
  );
  const discoverablePeerStories = useSelector(
    (state: RootState) => state.peerStories
  ).discoverablePeerStory;

  const peerStories = useSelector(
    (state: RootState) => state.peerStories
  ).currentPeerStories;

  const { storiesLoading } = useGetPeerStoryData({
    connectionUid: props.uid,
    discoverablePeerStories: discoverablePeerStories,
    connectionType: props.connectionType,
    otherPublicProfile: props.otherPublicProfile,
  });

  const { setModalComponent, emptyModal, removeModalByName } =
    useModalContext();

  const { loading, similarityData } = useGetSimilarityValues(props.uid);

  useGetSimilarityValues(props.uid);

  useGetCompatibilityInsightDatModal({
    friendStatus: friendStatus,
    connectionPublicProfile: props.otherPublicProfile,
    profileData: props.data,
    uid: props.uid,
  });

  useRenderModalFromLocalStorage({
    discoverableCompatibilitySnippets:
      compatibilityState.discoverableCompatibilitySnippets,
    onHanlde: (discoverableSnippet) => {
      emptyModal();
      setModalComponent(
        <CompatibilityModal
          discoverableSnippet={discoverableSnippet}
          discoveredSnippets={
            compatibilityState.discoveredCompatibilitySnippets
          }
          publicProfile={props.otherPublicProfile}
          archetypeData={props.data.archetypeData}
        />
      );
    },
  });

  const mappedDiscoverablePeerStories = peerStories.map((story) => {
    return (
      <div
        style={{
          backgroundImage: `linear-gradient(360deg, rgba(24, 23, 22, 0.50) 0%, rgba(24, 23, 22, 0.00) 100%), url(/peer-stories/${story.slug}.jpg)`,
          filter:
            story.peerStoryContent && story.gating === false
              ? "none"
              : "grayscale(100%)",
        }}
        onClick={() =>
          setModalComponent(
            <PeerStoriesModal
              otherPublicProfile={props.otherPublicProfile}
              connectionType={props.connectionType}
              imageUrl={props.imageUrl}
              uid={props.uid}
              peerStories={peerStories}
              storySlug={story.slug}
              elementName="PeerStoriesModal"
            />
          )
        }
        className={styles.storyBody}
        key={story.slug}
      >
        <p className={styles.storyText}>{story.shortName}</p>
      </div>
    );
  });

  useEffect(() => {
    //for notifications
    if (storiesLoading && props.peerStorySlug) {
      setTimeout(() => {
        setModalComponent(<LoadingModal elementName="LoadingModal" />);
      }, 200);
    }
    if (!storiesLoading && props.peerStorySlug) {
      setModalComponent(
        <PeerStoriesModal
          otherPublicProfile={props.otherPublicProfile}
          connectionType={props.connectionType}
          imageUrl={props.imageUrl}
          uid={props.uid}
          peerStories={peerStories}
          storySlug={props.peerStorySlug ?? ""}
          elementName="PeerStoriesModal"
        />
      );

      setTimeout(() => {
        removeModalByName("LoadingModal");
      }, 500);

      return removeModalByName("LoadingModal");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storiesLoading, props.peerStorySlug]);

  return (
    <section className={styles.main}>
      <h2 className={styles.header} style={{ marginTop: "0" }}>
        Stories
      </h2>
      <div className={styles.storyWrapper}>
        {storiesLoading ? (
          <PeerStoriesShimmer />
        ) : (
          mappedDiscoverablePeerStories
        )}
      </div>
      <h2 className={styles.header}>Core personality</h2>
      <div className={styles.wrapper}>
        <RenderCompareTab
          details={corePersonalitySectionDataCompareTab}
          friendStatus={friendStatus}
          similarityLoading={loading}
          scores={similarityData}
          isConnectionPremium={props.isConnectionPremium}
        />
      </div>
      <h2 className={styles.header}>Love & relationships</h2>
      <div className={styles.wrapper}>
        <RenderCompareTab
          details={loveAndRelationshipsSectionDataCompareTab}
          friendStatus={friendStatus}
          similarityLoading={loading}
          scores={similarityData}
          isConnectionPremium={props.isConnectionPremium}
        />
      </div>
      <h2 className={styles.header}>Friendship & outlook</h2>
      <div className={styles.wrapper}>
        <RenderCompareTab
          details={friendshipSectionDataCompareTab}
          friendStatus={friendStatus}
          similarityLoading={loading}
          scores={similarityData}
          isConnectionPremium={props.isConnectionPremium}
        />
      </div>
      <h2 className={styles.header}>Work</h2>
      <div className={styles.wrapper}>
        <RenderCompareTab
          details={workSectionDataCompareTab}
          friendStatus={friendStatus}
          similarityLoading={loading}
          scores={similarityData}
          isConnectionPremium={props.isConnectionPremium}
        />
      </div>
    </section>
  );
}

function RenderCompareTab(props: {
  details: CompareTabSectionDetail[];
  friendStatus:
    | "connected"
    | "close"
    | "deep"
    | "sent"
    | "unconnected"
    | "received"
    | null;
  similarityLoading: boolean;
  scores: { slug: string; score: number | null }[];
  isConnectionPremium: boolean;
}) {
  const mapped = props.details.map((detail) => {
    switch (detail.type) {
      case "compatibilitySnippet":
        return (
          <div key={detail.slug} className={styles.smallBlock}>
            <CompatibilitySnippetBlockCompareTab
              friendStatus={props.friendStatus}
              slug={detail.slug}
              loading={props.similarityLoading}
              isConnectionPremium={props.isConnectionPremium}
            />
          </div>
        );

      case "largeCompatibilitySnippet":
        return (
          <div key={detail.slug} className={styles.longBlock}>
            <CompatibilitySnippetBlockCompareTab
              friendStatus={props.friendStatus}
              slug={detail.slug}
              loading={props.similarityLoading}
              isConnectionPremium={props.isConnectionPremium}
            />
          </div>
        );

      case "wideCompatibilitySnippet":
        return (
          <div key={detail.slug} className={styles.largeBlock}>
            <CompatibilitySnippetBlockCompareTab
              friendStatus={props.friendStatus}
              slug={detail.slug}
              loading={props.similarityLoading}
              isConnectionPremium={props.isConnectionPremium}
            />
          </div>
        );

      default:
        <></>;
        break;
    }
  });

  return <>{mapped}</>;
}

function useRenderModalFromLocalStorage(props: {
  discoverableCompatibilitySnippets: DiscoverableCompatibilitySnippet[];
  onHanlde: (snippet: DiscoverableCompatibilitySnippet) => void;
}) {
  const snippetSlug = localStorage.getItem("compatibilitySnippetSlug");

  useEffect(() => {
    if (snippetSlug) {
      const discoverableSnippet = props.discoverableCompatibilitySnippets?.find(
        (s) => s?.snippetSlug === snippetSlug
      );

      if (discoverableSnippet) {
        // handleOpenCompatibilityModal(discoverableSnippet);
        props.onHanlde(discoverableSnippet);
        localStorage.removeItem("compatibilitySnippetSlug");
      }
    }
  }, [props, props.discoverableCompatibilitySnippets, snippetSlug]);
}

export function useGetSimilarityValues(uid: string | undefined) {
  const [loading, setLoading] = useState<boolean>(true);
  const [similarityData, setSimilarityData] = useState<
    { slug: string; score: number | null }[]
  >([]);
  const [called, setCalled] = useState<boolean>(false);

  const dispatch = useDispatch();

  const profileModalUidState = useSelector(
    (state: RootState) => state.profileModalUid
  );

  const compatibilityState = useSelector(
    (state: RootState) => state.compatibilitySnippets
  );

  async function handlePromises(
    promises: Promise<
      | {
          slug: string;
          score: number;
        }
      | {
          slug: string;
          score: null;
        }
    >[]
  ) {
    return await Promise.all(promises);
  }

  useEffect(() => {
    dispatch(setSimilarityAndCompatibilityScoresLoading(loading));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    //for when another profile modal gets added tot he modal stack
    setLoading(true);
    setCalled(false);
  }, [profileModalUidState.currentUserUid]);

  useEffect(() => {
    if (
      uid &&
      compatibilityState.discoverableCompatibilitySnippets.length &&
      loading &&
      !called &&
      profileModalUidState.currentUserUid === uid
    ) {
      let similaritySlugList: string[] = [];
      let compatibilitySlugList: string[] = [];
      compatibilityState.discoverableCompatibilitySnippets.forEach((s) => {
        if (s.similarityComputePoolSlug) {
          similaritySlugList.push(s?.snippetSlug);
        }
        if (s.compatibilityComputePoolSlug) {
          compatibilitySlugList.push(s?.snippetSlug);
        }
      });
      let promises: Promise<
        | {
            slug: string;
            score: number;
          }
        | {
            slug: string;
            score: null;
          }
      >[] = [];
      similaritySlugList.forEach((slug) => {
        promises.push(getSimilarityScore(slug, uid));
      });
      compatibilitySlugList.forEach((slug) => {
        promises.push(getCompatibilityScore(slug, uid));
      });
      setCalled(true);
      handlePromises(promises)
        .then((results) => {
          dispatch(setSimilarityAndCompatibilityScores(results));
          setSimilarityData(results);
          setLoading(false);
        })
        .catch((err) => {
          setCalled(false);
          console.log("Error getting scores", err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    uid,
    compatibilityState.discoverableCompatibilitySnippets.length,
    profileModalUidState.currentUserUid,
  ]);

  return { loading, similarityData };
}
