import { DM_Sans } from "next/font/google";

import styles from "./PremiumGatedResults.module.css";

import ConversionModal from "../ConversionModal/ConversionModal";
import { useModalContext } from "@/context/ModalContext";

import { Mixpanel } from "@/helpers/mixpanel";

const font = DM_Sans({ weight: "500", subsets: ["latin"] });

export default function PremiumGatedResults() {
  const { setModalComponent } = useModalContext();
  return (
    <div className={styles.main}>
      <p className={styles.requires}>Requires</p>
      <div className={styles.dividedTextDiv}>
        <p className={`${font.className} ${styles.dimensionalText}`}>
          Dimensional
        </p>
        <p className={`${font.className} ${styles.plusText}`}>Plus</p>
      </div>
      <button
        className={styles.button}
        onClick={() => {
          setModalComponent(<ConversionModal />);
          Mixpanel?.track("Premium Conversion Modal Summoned", {
            source: "Dimension Results",
          });
        }}
      >
        Learn More
      </button>
    </div>
  );
}
