import { GenericModalCTA } from "@dimensional-engineering/dimensional-models";
import { useSelector } from "react-redux";

import styles from "./GeneralModal.module.css";

import { useModalContext } from "@/context/ModalContext";
import { RootState } from "@/redux/store";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import { openInNewTab } from "../utils";
type Props = {
  title: string;
  body: string;
  modalCta?: GenericModalCTA | null | "viewAmbassador";
};

export default function GeneralModal(props: Props) {
  const { setModalOpen } = useModalContext();
  const userName = useSelector(
    (state: RootState) => state.user.publicProfileData
  )?.userName;

  const ctaButtonTextMap: Record<GenericModalCTA | "viewAmbassador", string> = {
    viewPublicProfile: "View public profile",
    viewAmbassador: "Learn more and apply",
  };

  function ctaHandler(cta: GenericModalCTA | "viewAmbassador") {
    switch (cta) {
      case GenericModalCTA.viewPublicProfile:
        return openInNewTab(`${window.location.origin}/${userName}`);

      case "viewAmbassador":
        openInNewTab("https://about.dimensional.me/ambassadors");

      default:
        break;
    }
  }

  return (
    <ModalWrapper noLine title="">
      <div className={styles.main}>
        <h1 className={styles.title}>{props.title}</h1>
        <p className={styles.body}>{props.body}</p>
        <div className={styles.buttonsDiv}>
          {props.modalCta && (
            <button
              onClick={() => props.modalCta && ctaHandler(props.modalCta)}
              className={styles.optionalCta}
            >
              {ctaButtonTextMap[props.modalCta]}
            </button>
          )}
          <button
            onClick={() => setModalOpen(false)}
            className={styles.dismissBtn}
          >
            Dismiss
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
}

type PropsArrayParagraph = {
  title: string;
  body: string[];
  modalCta?: GenericModalCTA | null | "viewAmbassador";
};

export function GeneralModalArrayParagraph(props: PropsArrayParagraph) {
  const { setModalOpen } = useModalContext();
  const userName = useSelector(
    (state: RootState) => state.user.publicProfileData
  )?.userName;

  const ctaButtonTextMap: Record<GenericModalCTA | "viewAmbassador", string> = {
    viewPublicProfile: "View public profile",
    viewAmbassador: "Learn more and apply",
  };

  function ctaHandler(cta: GenericModalCTA | "viewAmbassador") {
    switch (cta) {
      case GenericModalCTA.viewPublicProfile:
        return openInNewTab(`${window.location.origin}/${userName}`);

      case "viewAmbassador":
        openInNewTab("https://about.dimensional.me/ambassadors");

      default:
        break;
    }
  }

  const mapped = props.body.map((p, i) => {
    ///the second split is checking for italics using *blah blah blah*
    return (
      <p style={{ marginTop: "0" }} className={styles.body} key={i}>
        {p.split("\n").map((line, index) => (
          <span key={index}>
            {line
              .split(/(\*[^*]+\*)/g)
              .map((part, idx) =>
                part.startsWith("*") && part.endsWith("*") ? (
                  <i key={idx}>{part.slice(1, -1)}</i>
                ) : (
                  part
                )
              )}
            <br />
          </span>
        ))}
      </p>
    );
  });

  return (
    <ModalWrapper noLine title="">
      <div className={styles.main}>
        <h1 className={styles.title}>{props.title}</h1>
        <div className={styles.bodyDiv}>{mapped}</div>
        <div className={styles.buttonsDiv}>
          {props.modalCta && (
            <button
              onClick={() => props.modalCta && ctaHandler(props.modalCta)}
              className={styles.optionalCta}
            >
              {ctaButtonTextMap[props.modalCta]}
            </button>
          )}
          <button
            onClick={() => setModalOpen(false)}
            className={styles.dismissBtn}
          >
            Dismiss
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
}
