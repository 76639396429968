import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import { Roboto_Mono } from "next/font/google";
import {
  ConnectionAvailability,
  ListicleEmojiReaction,
  ListicleStatement,
  PeerStoryContent,
  PublicProfile,
} from "@dimensional-engineering/dimensional-models";

import styles from "./PeerStoriesModal.module.css";

import { ExtendedPeerStoryGated } from "@/models/sharedModels";
import CloseConnectionBlock from "@/components/connections/ConnectionRequestModal/CloseConnectionBlock";
import BasicConnectionBlock from "@/components/connections/ConnectionRequestModal/BasicConnectionBlock";
import DeepConnectionBlock from "@/components/connections/ConnectionRequestModal/DeepConnectionBlock";
import { SmallLoader } from "@/components/shared/Loader";
import { useAuth } from "@/context/AuthContext";
import ReactionIcon, {
  ConnectionReactionIcon,
} from "@/components/shared/icons/ReactionIcon";
import {
  emojiMap,
  handleClosureOfEmojiDiv,
  handleUiChangesOnEmoji,
  handleWhichSideClickHappened,
  updatePeerStoriesEmojiInDatabase,
  updateStoryFeedback,
  useFetchPeerStoryContent,
  useRegeneratePeerStoryContent,
} from "../utils";
import {
  updateEmojiOfCurrentStories,
  updateStoryFeedback as updateStoryFeedbackDispatch,
} from "@/redux/slices/peerStoriesSlice";
import { PeerStoriesInterractionBar } from "@/components/results/Snippets/SnippetInterractionBar/SnippetInterractionBar";
import { DailyStoryFeedbackScoreBar } from "@/components/results/personalityAnalysis/AnalysisFeedback/AnalysisFeedback";
import SystemDialog from "@/components/shared/SystemDialog/SystemDialog";
import { useModalContext } from "@/context/ModalContext";
import PeerStoriesShareView from "./PeerStoriesShareView";
import PeerStoriesErrorScreen from "./PeerStoriesErrorScreen/PeerStoriesErrorScreen";
import { Mixpanel } from "@/helpers/mixpanel";
import { useAlertContext } from "@/context/AlertContext";
import Alert from "@/components/shared/Alerts/Alert";
import { BetaStickerFeature } from "@/components/shared/BetaSticker/BetaSticker";

const font = Roboto_Mono({ subsets: ["latin"] });

type PropsStorySlide = {
  otherPublicProfile: PublicProfile | null;
  uid: string;
  story: ExtendedPeerStoryGated;
  nudged: boolean;
  handleNudge: () => void;
  handleIncompleteAssessments: (story: ExtendedPeerStoryGated) => void;
  handleGeneration: (story: ExtendedPeerStoryGated) => void;
  handleRegenerate: (story: ExtendedPeerStoryGated) => void;
  handleSlideClick: (value: "left" | "right") => void;
};

export default function PeerStorySlide(props: PropsStorySlide) {
  const dispatch = useDispatch();

  const { user } = useAuth();

  const { setModalComponent } = useModalContext();
  const { setAlertComponent } = useAlertContext();

  const [body, setBody] = useState<PeerStoryContent | null>(
    props.story.peerStoryContent
  );
  const [regenerateLoading, setRegenerateLoading] = useState<boolean>(false);
  const [emojiSelectionIndex, setEmojiSelectionIndex] = useState<number | null>(
    null
  );
  const [showFeedback, setShowFeedback] = useState<boolean>(false);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [feedbackScore, setFeedbackScore] = useState<number | null>(null);

  const isUserPremium = useSelector((state: RootState) => state.user).isPremium;

  const loadingMap = useSelector(
    (state: RootState) => state.peerStories
  ).loadingMap;

  const isLoading = loadingMap[props.uid]?.[props.story.promptSlug] ?? false;

  const errorMap = useSelector(
    (state: RootState) => state.peerStories
  ).errorMap;

  const isInErrorState = errorMap[props.uid]?.[props.story.promptSlug] ?? false;

  useEffect(() => {
    if (isInErrorState === true) {
      Mixpanel?.track("Error Modal Summoned", {
        content_type: "peer story",
        content_slug: props.story.slug,
      });
    }
  }, [isInErrorState]);

  useEffect(() => {
    setBody(props.story.peerStoryContent);
  }, [props.story.peerStoryContent]);

  useEffect(() => {
    setFeedbackScore(props.story.feedbackScore ?? null);
  }, [props.story.feedbackScore]);

  useFetchPeerStoryContent({
    uid: user?.uid,
    connectionUid: props.uid,
    story: props.story,
    connectionUsername: props.otherPublicProfile?.userName,
    setBody: (content) => setBody(content),
  });

  useRegeneratePeerStoryContent({
    uid: user?.uid,
    connectionUid: props.uid,
    story: props.story,
    regenerateLoading: regenerateLoading,
    isLoading: isLoading,
    connectionUsername: props.otherPublicProfile?.userName,
    setBody: (content) => setBody(content),
    setRegenerateLoading: (value) => setRegenerateLoading(value),
  });

  function selectEmoji(
    index: number,
    reaction: ListicleEmojiReaction,
    id: string
  ) {
    try {
      dispatch(
        updateEmojiOfCurrentStories({
          slug: props.story.slug,
          index: index,
          reaction: reaction,
        })
      );
    } catch (error) {}

    handleClosureOfEmojiDiv(id);

    setBody((prevBody) => {
      if (!prevBody) return null;
      const updatedStatements = prevBody.listicleStatements.map((s, i) =>
        i === index ? { ...s, myEmojiReaction: reaction } : s
      );
      updatePeerStoriesEmojiInDatabase(
        user?.uid,
        props.uid,
        props.story.slug,
        updatedStatements
      );

      return { ...prevBody, listicleStatements: updatedStatements };
    });

    setTimeout(() => {
      setEmojiSelectionIndex(null);
    }, 300);

    Mixpanel?.track("Peer Story Item Reaction Selected", {
      slug: props.story.slug,
      body: body?.listicleStatements[index].body,
      reaction:
        reaction === ListicleEmojiReaction.thumbsDown ? "dislike" : reaction,
    });
  }

  function handleEmojiSelectionOpen(
    index: number,
    statement: ListicleStatement
  ) {
    setEmojiSelectionIndex(index);
    setTimeout(() => {
      handleUiChangesOnEmoji(`${statement.body}-${index}`);
    }, 200);
  }

  const mappedBody = body?.listicleStatements?.map((statement, index) => {
    return (
      <div
        // onClick={(e) => {
        //   e.stopPropagation();
        // }}
        key={statement.body}
        className={styles.statementDiv}
      >
        <p className={styles.mappedBody}>
          <span
            onClick={(e) => {
              e.stopPropagation();
              handleEmojiSelectionOpen(index, statement);
            }}
            className={styles.span}
          >
            {statement.body}
          </span>
        </p>
        <div className={styles.emojiDiv}>
          {emojiSelectionIndex === index && (
            <div
              id={`${statement.body}-${index}`}
              className={styles.emojiSelectorDiv}
            >
              <div
                className={
                  statement.myEmojiReaction === ListicleEmojiReaction.laugh
                    ? styles.emojiTextDivSelected
                    : styles.emojiTextDiv
                }
              >
                <p
                  onClick={(e) => {
                    e.stopPropagation();
                    selectEmoji(
                      index,
                      ListicleEmojiReaction.laugh,
                      `${statement.body}-${index}`
                    );
                  }}
                  className={styles.emojiSelectionText}
                >
                  😂
                </p>
              </div>

              <div
                className={
                  statement.myEmojiReaction === ListicleEmojiReaction.heart
                    ? styles.emojiTextDivSelected
                    : styles.emojiTextDiv
                }
              >
                <p
                  onClick={(e) => {
                    e.stopPropagation();
                    selectEmoji(
                      index,
                      ListicleEmojiReaction.heart,
                      `${statement.body}-${index}`
                    );
                  }}
                  className={styles.emojiSelectionText}
                >
                  ❤️️
                </p>
              </div>

              <div
                className={
                  statement.myEmojiReaction === ListicleEmojiReaction.thumbsDown
                    ? styles.emojiTextDivSelected
                    : styles.emojiTextDiv
                }
              >
                <p
                  onClick={(e) => {
                    e.stopPropagation();
                    selectEmoji(
                      index,
                      ListicleEmojiReaction.thumbsDown,
                      `${statement.body}-${index}`
                    );
                  }}
                  className={styles.emojiSelectionText}
                >
                  👎️
                </p>
              </div>
            </div>
          )}
          {statement.myEmojiReaction ? (
            <p
              onClick={(e) => {
                e.stopPropagation();
                handleEmojiSelectionOpen(index, statement);
              }}
              className={styles.selectedEmoji}
            >
              {emojiMap[statement.myEmojiReaction]}
            </p>
          ) : (
            <ReactionIcon
              onClick={(e) => {
                e.stopPropagation();
                handleEmojiSelectionOpen(index, statement);
              }}
            />
          )}
          {statement.connectionEmojiReaction ? (
            <p className={styles.selectedEmoji}>
              {emojiMap[statement.connectionEmojiReaction]}
            </p>
          ) : (
            <ConnectionReactionIcon />
          )}
        </div>
      </div>
    );
  });

  if (isInErrorState) {
    return (
      <PeerStoriesErrorScreen
        onRetry={() => props.handleGeneration(props.story)}
      />
    );
  }

  return (
    <>
      {showDialog && (
        <SystemDialog
          body="Regenerating this story will also re-generate your other stories with this connection. Are you sure?"
          cancelText="Cancel"
          approveText="Regenerate"
          onApprove={() => {
            setRegenerateLoading(true);
            props.handleRegenerate(props.story);
            setShowDialog(false);
            Mixpanel?.track("Regenerate CTA Clicked", {
              content_type: "peer story",
              slug: props.story.slug,
            });
          }}
          onCancel={() => setShowDialog(false)}
        />
      )}
      <div
        style={{
          backgroundImage: `linear-gradient(rgba(37, 0, 0, 0) 54.69%, rgba(6, 0, 0, 0.5) 100%), url(/peer-stories/${props.story.slug}.jpg)`,
        }}
        className={styles.storyMain}
        onClick={(e) => {
          props.handleSlideClick(
            handleWhichSideClickHappened(e as unknown as MouseEvent)
          );
        }}
      >
        <div className={styles.betaDiv}>
          <BetaStickerFeature />
        </div>
        {emojiSelectionIndex !== null && (
          <div
            onClick={(e) => {
              e.stopPropagation();
              setEmojiSelectionIndex(null);
            }}
            className={styles.opacityScreen}
          ></div>
        )}
        <h3
          onClick={(e) => {
            e.stopPropagation();
          }}
          className={`${styles.name} ${font.className}`}
        >
          {props.story.longName.toUpperCase()}:
        </h3>
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          className={styles.gatingDiv}
        >
          {props.story.gating === ConnectionAvailability.basic &&
            props.otherPublicProfile && (
              //Needs basic connection
              <BasicConnectionBlock
                slug={props.story.slug}
                publicProfile={props.otherPublicProfile}
              />
            )}

          {props.story.gating === ConnectionAvailability.close &&
            props.otherPublicProfile && (
              //Needs close connection
              <CloseConnectionBlock
                slug={props.story.slug}
                publicProfile={props.otherPublicProfile}
              />
            )}

          {props.story.gating === ConnectionAvailability.deep &&
            props.otherPublicProfile && (
              //Needs deep connection
              <DeepConnectionBlock
                slug={props.story.slug}
                publicProfile={props.otherPublicProfile}
              />
            )}

          {props.story.gating === "nudge" && (
            //Nudging connections
            <div className={styles.nudgeWrapper}>
              <div className={styles.nudgeDiv}>
                <div className={styles.nudgeInnerDiv}>
                  <p
                    className={styles.nudgeText}
                  >{`@${props.otherPublicProfile?.userName} has not completed the necessary Dimensions.`}</p>
                  {!props.nudged ? (
                    <button
                      onClick={() => {
                        props.handleNudge();
                      }}
                      className={styles.nudgeButton}
                    >
                      Nudge
                    </button>
                  ) : (
                    <button className={styles.nudgeButtonSent}>Sent</button>
                  )}
                </div>
              </div>
            </div>
          )}

          {props.story.gating === "discoveryGate" && (
            //Discovery gate for dimensions
            <div className={styles.nudgeWrapper}>
              <div className={styles.nudgeDiv}>
                <div className={styles.nudgeInnerDiv}>
                  <p className={styles.nudgeText}>
                    You must complete more Dimensions to unlock this.
                  </p>
                  <button
                    onClick={() => {
                      props.handleIncompleteAssessments(props.story);
                      Mixpanel?.track("Gating Modal Summoned", {
                        "content type": "peer story",
                        slug: props.story.slug,
                      });
                    }}
                    className={styles.nudgeButton}
                  >
                    View Dimensions
                  </button>
                </div>
              </div>
            </div>
          )}

          {props.story.gating === false && !body && !regenerateLoading && (
            //Needs to be generated
            <div className={styles.nudgeWrapper}>
              <div className={styles.nudgeDiv}>
                <div className={styles.nudgeInnerDiv}>
                  <p className={styles.nudgeText}>
                    This content needs to be generated
                  </p>
                  <button
                    onClick={() => {
                      if (isLoading) {
                        return;
                      }
                      props.handleGeneration(props.story);
                      Mixpanel?.track("Generate CTA Clicked", {
                        content_type: "peer story",
                        content_slug: props.story.slug,
                      });
                    }}
                    className={styles.nudgeButton}
                  >
                    {!isLoading ? "✨ Generate ✨" : <SmallLoader />}
                  </button>
                </div>
              </div>
            </div>
          )}

          {regenerateLoading && (
            //regeneration loading
            <div className={styles.nudgeWrapper}>
              <div className={styles.nudgeDiv}>
                <div className={styles.nudgeInnerDiv}>
                  <p className={styles.nudgeText}>
                    This content needs to be generated
                  </p>
                  <button className={styles.nudgeButton}>
                    <SmallLoader />
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className={styles.bodyWrapper}>
          {props.story.gating === false &&
            body &&
            !regenerateLoading &&
            mappedBody}
        </div>

        {showFeedback && (
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className={styles.feedbackDiv}
          >
            <DailyStoryFeedbackScoreBar
              onAnswer={async (value) => {
                setFeedbackScore(value);
                setTimeout(() => {
                  setShowFeedback(false);
                }, 300);
                await updateStoryFeedback(
                  user?.uid,
                  props.uid,
                  props.story.slug,
                  value
                ).then(() => {
                  const joinedBody = body?.listicleStatements
                    .map((statement) => statement.body)
                    .join(" | ")
                    .slice(0, 250);

                  Mixpanel?.track("Feedback given", {
                    content_type: "peer_story",
                    slug: props.story.slug,
                    score: value,
                    body: joinedBody,
                  });
                  dispatch(
                    updateStoryFeedbackDispatch({
                      slug: props.story.slug,
                      value: value,
                    })
                  );
                });
              }}
              title="How good was this content?"
              subtitle="Feedback improves your results"
              currentAnswer={feedbackScore}
              onClose={() => setShowFeedback(false)}
            />
          </div>
        )}
        {props.story.gating === false && body && !regenerateLoading && (
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className={styles.bottom}
          >
            <PeerStoriesInterractionBar
              onRateClick={() => {
                setShowFeedback(true);
              }}
              onRegenerate={() => {
                if (!isUserPremium) {
                  return setAlertComponent(
                    <Alert
                      elementName="Alert"
                      type="warning"
                      message="Regenerating stories is only available to Plus accounts."
                    />,
                    3000
                  );
                }
                setShowDialog(true);
              }}
              onShareClick={() => {
                setModalComponent(
                  <PeerStoriesShareView story={props.story} body={body} />
                );
                const joinedBody = body?.listicleStatements
                  .map((statement) => statement.body)
                  .join(" | ")
                  .slice(0, 250);
                Mixpanel?.track("System Share Modal Opened", {
                  source: "peer story",
                  type: "peer story social share",
                  slug: props.story.slug,
                  body: joinedBody,
                });
              }}
            />
          </div>
        )}
      </div>
    </>
  );
}
