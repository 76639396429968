import styles from "./InsightPowerArrows.module.css";

export function HighArrow() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
    >
      <path
        d="M1.37401 4.95325L4.74645 2.14419L8.11889 4.95325C8.44065 5.20309 8.94221 5.19586 9.25376 4.93665C9.5648 4.67702 9.57348 4.25906 9.27368 3.99092L5.32411 0.699616V0.69919C5.00489 0.433603 4.48802 0.433603 4.16881 0.69919L0.219238 3.9905V3.99092C-0.0805725 4.25906 -0.0718907 4.67702 0.239156 4.93665C0.550708 5.19585 1.05224 5.20309 1.37401 4.95325Z"
        fill="#FFFFF2"
      />
    </svg>
  );
}

export function VeryHighArrows() {
  return (
    <div className={styles.wrapper}>
      <HighArrow />
      <HighArrow />
    </div>
  );
}

export function LowArrow() {
  return (
    <div className={styles.wrapperReversed}>
      <HighArrow />
    </div>
  );
}

export function VeryLowArrows() {
  return (
    <div className={styles.wrapperReversed}>
      <HighArrow />
      <HighArrow />
    </div>
  );
}

export function MediumArrow() {
  return <div className={styles.mediumArror}></div>;
}
