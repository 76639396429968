import styles from "./PeerStoriesShimmer.module.css";

import Shimmer from "@/components/shared/Shimmer/Shimmer";

export default function PeerStoriesShimmer() {
  const mapped = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14].map((v) => {
    return (
      <div key={v} className={styles.main}>
        <Shimmer />
      </div>
    );
  });

  return <div className={styles.wrapper}>{mapped}</div>;
}
