import styles from "./GoDeeperModal.module.css";

export default function SuggestedMessage(props: {
  suggestedMessage: string;
  onSelect: () => void;
}) {
  return (
    <p onClick={() => props.onSelect()} className={styles.suggestedMessage}>
      {props.suggestedMessage}
    </p>
  );
}
