/* eslint-disable react/no-children-prop */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ReactMarkdown from "react-markdown";
import { doc, getDoc, updateDoc } from "firebase/firestore";

import styles from "./AnalysisModal.module.css";

import { RootState } from "@/redux/store";
import AnalysisHistogram from "../AnalysisHistogram/AnalysisHistogram";
import AnalysisSpectrum from "../AnalysisSpectrum/AnalysisSpectrum";
import AnalysisAssociatedTraits from "../AnalysisAssociatedTraits/AnalysisAssociatedTraits";
import AnalysisFeedback from "../AnalysisFeedback/AnalysisFeedback";
import { db } from "@/_firebase/firebaseConfig";
import { useAuth } from "@/context/AuthContext";
import ModalWrapper from "@/components/shared/ModalWrapper/ModalWrapper";
import { Mixpanel } from "@/helpers/mixpanel";
import PremiumPillIcon from "@/components/premium/PremiumPillIcon/PremiumPillIcon";
import LoadingScreen from "@/components/assessments-new/views/AsessmentOutroScreen/loadingScreen/LoadingScreen";
import PersonalityAnalyisIcon from "../PersonalityAnalyisIcon";
import { TwoByTwoNewNoData } from "@/components/shared/TwoByTwoNew/TwoByTwoNew";
import { ContentType } from "@dimensional-engineering/dimensional-models";

export default function AnalysisModal(props: {
  slug: string | undefined;
  onClose: () => void;
}) {
  const userState = useSelector((state: RootState) => state.user);
  const analysisState = useSelector(
    (state: RootState) => state.personalityAnalyis
  );
  const analysis = analysisState.discoveredPersonalAnalysis?.find(
    (a) => a.analysisSlug === props.slug
  );
  const discoverableAnalysis = analysisState.discoverablePersonalAnalysis?.find(
    (a) => a.analysisSlug === props.slug
  );
  const { user } = useAuth();
  const [feedback, setFeedback] = useState<number | null>(null);

  useEffect(() => {
    async function getFeedback() {
      if (props.slug) {
        const docRef = doc(
          db,
          "members",
          `${user?.uid}`,
          "discoveredPersonalityAnalyses",
          props.slug
        );
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setFeedback(docSnap.data().feedbackScore);
        }
      }
    }
    getFeedback();

    Mixpanel?.track("Results Snippet Viewed", {
      slug: analysis?.analysisSlug,
      type: "personality analysis",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function onFeeback(value: number) {
    if (props.slug) {
      const ref = doc(
        db,
        "members",
        `${user?.uid}`,
        "discoveredPersonalityAnalyses",
        props.slug
      );
      Mixpanel?.track("Personality Analysis Feedback Given", {
        slug: props.slug,
        score: value,
        username: userState.publicProfileData?.userName,
      });
      await updateDoc(ref, {
        feedbackScore: value,
      });
    }
  }

  if (analysis === undefined || discoverableAnalysis === undefined) {
    return (
      <ModalWrapper title="" noHeader>
        <LoadingScreen message="" />
      </ModalWrapper>
    );
  }

  const analysisTitle =
    discoverableAnalysis.name.length < 31
      ? discoverableAnalysis.name.slice(0, 31) + "..."
      : discoverableAnalysis.name;

  return (
    <ModalWrapper title={analysisTitle} subTitle="Trait Analysis">
      <div className={styles.top}>
        {analysis.isPremium && (
          <div className={styles.premiumIconDiv}>
            <PremiumPillIcon />
          </div>
        )}
        <div className={styles.iconDiv}>
          <PersonalityAnalyisIcon />
        </div>
        <h1 className={styles.title}>{discoverableAnalysis.name}</h1>
        <h3 className={styles.subtitle}>{discoverableAnalysis.description}</h3>
      </div>
      <div className={styles.body}>
        <section className={styles.container}>
          <h3 className={styles.sectionTitle}>Short Summary</h3>
          <p className={styles.paragraph}>
            {analysis.analysisDetail?.shortSummary}
          </p>
        </section>
        {analysis.twoByTwoChart && (
          <div className={styles.chartContainer}>
            <div className={styles.chartDiv}>
              <div className={styles.chartHeader}>
                <h2 className={styles.chartTitle}>Chart</h2>
              </div>
              <div className={styles.twoByTwoDiv}>
                <TwoByTwoNewNoData
                  contentIdentifier={{
                    contentType: ContentType.personalityAnalysis,
                    contentSlug: analysis.analysisSlug,
                  }}
                  imageUrl={userState.imageURL ?? ""}
                  publicProfile={userState.publicProfileData}
                />
              </div>
            </div>
          </div>
        )}
        {analysis.histogramChart && (
          <div className={styles.chartContainer}>
            <div className={styles.chartDiv}>
              <div className={styles.chartHeader}>
                <h2 className={styles.chartTitle}>Chart</h2>
              </div>
              <AnalysisHistogram
                averageUsrScore={analysis.histogramChart.averageUserScore}
                data={analysis.histogramChart.histogramData}
                score={analysis.histogramChart.score}
                name={analysis.histogramChart.scoreName}
                caption={analysis.histogramChart.caption}
              />
            </div>
          </div>
        )}
        {analysis.spectrumChart && (
          <div className={styles.chartContainer}>
            <div className={styles.chartDiv}>
              <div className={styles.chartHeader}>
                <h2 className={styles.chartTitle}>Chart</h2>
              </div>
              <AnalysisSpectrum
                data={analysis.spectrumChart}
                slug={analysis.analysisSlug}
              />
            </div>
          </div>
        )}
        {analysis.analysisDetail?.analysisMarkdownString && (
          <section className={styles.container}>
            <h3 className={styles.sectionTitle}>Analysis</h3>
            <ReactMarkdown
              transformLinkUri={(href: string) => {
                return `/app/profile/${user?.uid}${href
                  .replace("element", "elements")
                  .replace("pattern", "patterns")
                  .replace("archetype", "archetypes")
                  .replace("/traits", "")}`;
              }}
              linkTarget="_blank"
              children={analysis.analysisDetail.analysisMarkdownString}
            />
          </section>
        )}
        {analysis.analysisDetail?.frameworkMarkdownString && (
          <section className={styles.container}>
            <h3 className={styles.sectionTitle}>Framework</h3>
            <ReactMarkdown
              transformLinkUri={(href: string) => {
                return `/app/profile/${user?.uid}${href
                  .replace("element", "elements")
                  .replace("pattern", "patterns")
                  .replace("archetype", "archetypes")
                  .replace("/traits", "")}`;
              }}
              linkTarget="_blank"
              children={analysis.analysisDetail.frameworkMarkdownString}
            />
          </section>
        )}
        {discoverableAnalysis?.associatedTraits?.length ? (
          <section className={styles.traitsContainer}>
            <h3 className={styles.sectionTitle}>Associated Traits</h3>
            <AnalysisAssociatedTraits
              data={discoverableAnalysis?.associatedTraits}
            />
          </section>
        ) : null}
        <section className={styles.feedbackContainer}>
          <AnalysisFeedback
            title="How did you like this content?"
            subtitle="Feedback helps us improve"
            onAnswer={(value) => {
              onFeeback(value);
            }}
            currentAnswer={feedback}
          />
        </section>
      </div>
    </ModalWrapper>
  );
}
