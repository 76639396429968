import styles from "./HalfWayModal.module.css";

type Props = {
  title: string;
  message: string;
  buttonText: string;
  onClick: (action: boolean) => void;
  middle?: boolean;
  hideCancel?: boolean;
  centerAlignText?: boolean;
};

export default function HalfWayModal(props: Props) {
  return (
    <dialog onClick={() => props.onClick(false)} className={styles.dialogue}>
      <div
        id="halfway"
        className={props.middle ? styles.mainMiddle : styles.main}
      >
        <div className={styles.wrapper}>
          <h1 className={styles.title}>{props.title}</h1>
          <p
            style={props.centerAlignText ? { textAlign: "center" } : {}}
            className={styles.message}
          >
            {props.message}
          </p>
          <button
            className={styles.acceptButton}
            onClick={() => props.onClick(true)}
            style={props.hideCancel ? { marginBottom: "30px" } : {}}
          >
            {props.buttonText}
          </button>
          {!props.hideCancel && (
            <button
              className={styles.cancelButton}
              onClick={() => props.onClick(false)}
            >
              Cancel
            </button>
          )}
        </div>
      </div>
    </dialog>
  );
}
