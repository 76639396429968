import "@/styles/globals.css";
import type { AppProps } from "next/app";
import { useRouter } from "next/router";
import { useEffect } from "react";

import { PublicProfileProvider } from "@/context/PublicProfileContext";
import { AuthProvider } from "@/context/AuthContext";
import { Provider as ReduxProvider } from "react-redux";
import { store, persistor } from "../redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { ModalContextProvider } from "@/context/ModalContext";
import { AlertContextProvider, useAlertContext } from "@/context/AlertContext";
import ErrorBoundary from "@/components/ErrorBoundry";
import { NotificationClickHandlersProvider } from "@/context/NotificationClickHandlersProvider";
import useHapticFeedback from "@/helpers/useHapticFeedback";
import { InAppNotificationHandler } from "@/context/InAppNotificationHandler";
// import { useBranch } from "@/_branch/branch";

export default function App({ Component, pageProps }: AppProps) {
  const router = useRouter();
  // useBranch();

  useHapticFeedback();

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      try {
        const data = JSON.parse(event.data);

        console.log("Message from native layer app root:", event.data);

        if (
          data.isNotificationGranted ||
          data.isNotificationGranted === false
        ) {
          console.log("isNotificationGranted", data.isNotificationGranted);
          localStorage.setItem(
            "isNotificationGranted",
            data.isNotificationGranted
          );
        }

        if (data.screenshotTaken) {
          if (!localStorage.getItem("screenshotTaken")) {
            localStorage.setItem("screenshotTaken", "true");
          }
        }

        if (data.peerAssessmentLinkId && data.peerAssessmentLinkId !== "null") {
          if (window.localStorage) {
            localStorage.setItem(
              "peerAssessmentLinkId",
              data.peerAssessmentLinkId
            );
            if (window.Android?.clearPeerAssessmentLink) {
              window.Android?.clearPeerAssessmentLink();
            }
          }
        }

        if (data.referrer_type) {
          if (window.localStorage) {
            localStorage.setItem("referrer_type", data.referrer_type);
          }
        }

        if (data.recommenderUid) {
          if (window.localStorage) {
            localStorage.setItem("recommenderUid", data.recommenderUid);
          }
        }

        if (data.goToUrl) {
          router.push(data.goToUrl);
        }

        if (data.notificationType) {
          if (window.localStorage) {
            localStorage.setItem("notificationType", JSON.stringify(data));
            console.log(
              "Sent notification to local storage. Data: ",
              JSON.stringify(data)
            );
          } else {
            console.log("Did not save notification to local storage");
          }
        }

        if (data.inAppNotificationType) {
          if (window.localStorage) {
            localStorage.setItem(
              "inAppNotificationType",
              JSON.stringify(data.inAppNotificationType)
            );
            console.log(
              "Sent in app notification to local storage. Data: ",
              JSON.stringify(data.inAppNotificationType)
            );
          } else {
            console.log("Did not save notification to local storage");
          }
        }

        if (window.localStorage) {
          try {
            if (data.fcmToken) {
              localStorage.setItem("fcmToken", data.fcmToken);
              console.log("Sent to local storage", data.fcmToken);
            }

            if (data.isAppLinksVerified) {
              console.log(
                "isAppLinksVerified is: ",
                JSON.stringify(data.isAppLinksVerified)
              );
              localStorage.setItem(
                "isAppLinksVerified",
                JSON.stringify(data.isAppLinksVerified)
              );
            }
          } catch (error) {
            console.log("Error parsing data:", error);
            // Handle data parsing errors as needed
          }
        } else {
          console.log("No local storage available");
        }
      } catch (error: any) {
        // console.log("Error handling message:", error);
        if (
          error.message.includes(
            "Failed to execute 'postMessage' on 'DOMWindow'"
          )
        ) {
          console.log("Post message failed: Ensure target origin matches.");
          console.log("Failed message data:", event.data); // Log the data causing the error
        }
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  return (
    <ErrorBoundary>
      <ReduxProvider store={store}>
        <PersistGate
          loading={
            router.asPath.includes("/app") ? null : <Component {...pageProps} />
          }
          persistor={persistor}
        >
          <ModalContextProvider>
            <AlertContextProvider>
              <InAppNotificationHandler>
                <NotificationClickHandlersProvider>
                  <AuthProvider>
                    <PublicProfileProvider>
                      <Component {...pageProps} />
                    </PublicProfileProvider>
                  </AuthProvider>
                </NotificationClickHandlersProvider>
              </InAppNotificationHandler>
            </AlertContextProvider>
          </ModalContextProvider>
        </PersistGate>
      </ReduxProvider>
    </ErrorBoundary>
  );
}
