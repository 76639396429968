/* eslint-disable react/no-children-prop */
import { useEffect, useState } from "react";
import { useRouter } from "next/router";

import styles from "../../../../styles/app/AuthTraits/ElementModal.module.css";

import ModalWrapper from "@/components/shared/ModalWrapper/ModalWrapper";
import useGetElementModalData from "./useGetElementModalData";
import Loader from "@/components/shared/Loader";
import useGetPublicProfile from "@/helpers/useGetPublicProfile";
import { callFirebaseFunctions } from "@/_firebase/callFirebaseFunctions";
import { ElementDiscovery } from "@dimensional-engineering/dimensional-models";
import { useAuth } from "@/context/AuthContext";
import ElementTraitsPageLeftBarModal from "../../element/elementsLeftBar/ElementTraitsPageLeftBarModal";
import SwipeTabs from "@/components/shared/SwipeTabs/SwipeTabs";
import FriendSelfRatedScoreTable from "../../detailsTab/SelfRatedScoreTable/FriendSelfRatedScoreTable";
import FriendHistogram from "../../detailsTab/histogram/FriendHistogram";
import OtherElements from "../../detailsTab/elements/otherElements/OtherElements";
import OppositeElement from "../../detailsTab/elements/opposite/OppositeElement";
import ElementComponentOf from "../../detailsTab/elements/componentOf/ElementComponentOf";
import ParentOf from "../../detailsTab/elements/parentOf/ParentOf";
import ReactMarkdown from "react-markdown";
import InteractionBar from "../../shared/InteractionBar/InteractionBar";
import Element from "../../element/Element/Element";
import TraitDescription from "../../shared/TraitScoreTable/TraitDescription";
import { natureMap } from "@/assets/traitInfo/natureData";

export default function FriendElementModal(props: {
  slug: string;
  uid: string | undefined;
}) {
  const { element, otherElements } = useGetElementModalData(props.slug);
  const router = useRouter();
  const { slug } = router.query;
  const { user } = useAuth();

  const [tabs, setTabs] = useState<string[]>([
    "summary",
    "details",
    "identity",
  ]);
  const [slicedMarkdown, setSlicedMarkdown] = useState<string[]>([]);
  const [markdownContent, setMarkdownContent] = useState<string[]>([]);
  const [friendScore, setFriendScore] = useState<number | null | undefined>(
    null
  );
  const [loading, setLoading] = useState<boolean>(true);

  const publicProfile = useGetPublicProfile(props.uid ?? (slug as string));

  useEffect(() => {
    setLoading(true);
    const fetchData = callFirebaseFunctions("fetchFriendElementDiscovery");
    fetchData({
      memberUUID: props.uid,
      version: "2",
      traitSlug: element?.slug,
    })
      .then((res) => {
        setLoading(false);
        const data = res.data as ElementDiscovery;
        if (data) {
          setFriendScore(data.scaleScore?.percentScore);
        } else {
          setFriendScore(null);
        }
      })
      .catch((err) => {
        setLoading(true);
      });

    setTabs(["summary", "details", "identity"]);
    setMarkdownContent([]);
    setSlicedMarkdown([]);
    element?.traitContent?.markdownSections?.forEach((section, index) => {
      if (!tabs.includes(section.traitSection as string)) {
        setTabs((current) => [...current, section.traitSection as string]);
      }
      setMarkdownContent((current) => [
        ...current,
        section.markdownString.replace(
          "/element",
          `/app/profile/${user?.uid}/elements`
        ),
      ]);
      if (index !== 0) {
        setSlicedMarkdown((current) => [...current, section.markdownString]);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element?.slug, slug]);

  if (element === null || loading === true) {
    return (
      <ModalWrapper title="">
        <Loader height="500px" mobileHeight="500px" />
      </ModalWrapper>
    );
  }

  return (
    <ModalWrapper noLine title={element.alias ?? element.name}>
      <div className={styles.main}>
        <div className={styles.parent}>
          <div className={styles.rightBarMain}>
            <SwipeTabs
              tabs={tabs}
              sticky
              content={[
                <ElementTraitsPageLeftBarModal
                  key={1}
                  nonOwnProfile
                  element={element}
                  publicProfile={publicProfile}
                  friendScore={friendScore}
                />,
                <div key={2}>
                  <div className={styles.archetypeDiv}>
                    <Element slug={element.slug} score={friendScore} />
                  </div>
                  <FriendSelfRatedScoreTable
                    score={friendScore}
                    slug={element.slug}
                    type="element"
                    publicProfile={publicProfile}
                    connectionAvailability={element.connectionAvailabilities}
                  />
                  <FriendHistogram
                    // @ts-ignore
                    data={element.traitContent?.histogramData}
                    type="archetype"
                    averageUsrScore={element.traitContent?.averagePercentScore}
                    publicProfile={publicProfile}
                    score={friendScore}
                  />
                  <TraitDescription
                    type="Element"
                    aboutThisTrait
                    dimension={element.dimensionSlug}
                    nature={
                      element.nature
                        ? natureMap[element.nature].name
                        : undefined
                    }
                    color={
                      element.nature
                        ? natureMap[element.nature].color
                        : undefined
                    }
                    plasticity={element.plasticity}
                    oppositeTraitSlug={
                      element.traitContent.oppositeDetails?.oppositeTrait.slug
                    }
                    averageUserScore={element.traitContent?.averagePercentScore}
                  />
                  {otherElements.length > 0 && (
                    <OtherElements
                      authenticated
                      elements={otherElements}
                      uid={props.uid}
                    />
                  )}
                  {element.traitContent?.oppositeTrait?.traitSlug && (
                    <OppositeElement
                      uid={props.uid}
                      authenticated
                      element={element}
                    />
                  )}

                  {(element.traitContent?.componentOfDetails?.componentTraits
                    ?.length ?? 0) > 0 && (
                    <ElementComponentOf scale={element} authenticated />
                  )}

                  {element.traitContent?.parentDetails?.childTraits.length && (
                    <ParentOf uid={props.uid} element={element} auth />
                  )}
                </div>,
                <div
                  style={{ pointerEvents: "none" }}
                  key={1}
                  className={styles.markdownDiv}
                >
                  <ReactMarkdown
                    transformLinkUri={(href: string) => {
                      return "";
                    }}
                    children={markdownContent[0]}
                  />
                </div>,
              ]}
              slicedContent={slicedMarkdown}
              grow={tabs.length < 4 ? true : false}
            />
          </div>
        </div>
      </div>
      {props.uid && (
        <InteractionBar
          friendScore={friendScore}
          type="element"
          ownProfile={false}
          //@ts-ignore
          histogramData={element.traitContent?.histogramData}
          connectionAvailabilities={element.connectionAvailabilities}
          isPremium={element.isPremium}
          slug={element.slug}
          uid={props.uid}
        />
      )}
    </ModalWrapper>
  );
}
