import { useEffect, useState } from "react";

import styles from "./Elements.module.css";

import PremiumPillIcon from "@/components/premium/PremiumPillIcon/PremiumPillIcon";
import TraitScore from "../../shared/TraitScore/TraitScore";

type ElementProps = {
  name: string;
  color: string;
  icon?: JSX.Element;
  score?: number | null;
  number: number;
  isPremium: boolean | null | undefined;
};

export default function NumberedElement(props: ElementProps) {
  const [color, setColor] = useState<string>(props.color);

  useEffect(() => {
    if (!props.color.includes("#")) {
      setColor("#" + props.color);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.color]);

  return (
    <div
      style={{ color: "#fffff2", margin: "5px", marginLeft: "0" }}
      className={styles.elementContainer}
    >
      <div className={styles.elementTop}>
        <div className={styles.iconDiv}>{props.icon && props.icon}</div>
        <p style={{ fontSize: "14px" }}>#{props.number}</p>
      </div>
      <div className={styles.wrapperCircle}>
        <div
          className={styles.mainCircle}
          style={{ border: `${color} 15px solid` }}
        ></div>
        {props.isPremium && (
          <div className={styles.premiumIcon}>
            <PremiumPillIcon />
          </div>
        )}
      </div>
      <div className={styles.elementNameContainer}>
        <p className={styles.elName}>{props.name}</p>
      </div>
      {props.score ? <TraitScore score={props.score * 100} /> : null}
    </div>
  );
}
