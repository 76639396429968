export const peerRatedSnippetSlugs = [
  "snippet-peer-rated-interaction-style",
  "snippet-peer-rated-nature",
  "snippet-peer-rated-primary-traits",
  "snippet-peer-rated-cognition",
  "snippet-peer-rated-strengths",
  "snippet-overestimated-traits",
  "snippet-underestimated-traits",
  "snippet-your-blindspots",
  "snippet-how-others-see-you",
];

export const valuesArchetypeSlugs = [
  "values-ascender",
  "values-giver",
  "values-individualist",
  "values-traditionalist",
];
