export default function ShareIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0834 5.32884V15.7096C14.0834 16.3068 13.602 16.7913 12.9997 16.7913C12.4015 16.7913 11.916 16.3068 11.916 15.7096V5.32494L10.6932 6.54876C10.2738 6.96822 9.59331 6.96721 9.16776 6.54165C8.74425 6.11814 8.74729 5.43057 9.16065 5.01618L12.2391 1.93882C12.4473 1.7296 12.7205 1.625 12.9947 1.625L13.0028 1.62805C13.278 1.62906 13.5512 1.73266 13.7574 1.93883L16.8347 5.01619C17.2542 5.43564 17.2542 6.11612 16.8287 6.54166C16.4051 6.96517 15.7166 6.96213 15.3032 6.54877L14.0834 5.32884ZM4.3334 21.6662H21.666V14.0784C21.666 13.4822 22.1475 12.9998 22.7497 12.9998C23.3479 12.9998 23.8334 13.4863 23.8334 14.0784V22.7548C23.8334 23.0524 23.7125 23.3215 23.5176 23.5165C23.3195 23.7126 23.0493 23.8334 22.7508 23.8334H3.24868C2.9501 23.8334 2.68095 23.7136 2.48493 23.5186C2.2879 23.3195 2.16602 23.0504 2.16602 22.7549V14.0784C2.16602 13.4822 2.64743 12.9998 3.2497 12.9998C3.8479 12.9998 4.33338 13.4863 4.33338 14.0784L4.3334 21.6662Z"
        fill="#E6E1DF"
      />
    </svg>
  );
}

export function ShareIconDark() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8337 4.09911V12.0843C10.8337 12.5437 10.4634 12.9163 10.0001 12.9163C9.53997 12.9163 9.16653 12.5437 9.16653 12.0843V4.09611L8.22591 5.03751C7.90325 5.36017 7.37981 5.35939 7.05247 5.03204C6.72669 4.70626 6.72903 4.17736 7.047 3.8586L9.415 1.4914C9.57515 1.33046 9.78532 1.25 9.99624 1.25L10.0025 1.25234C10.2142 1.25313 10.4244 1.33281 10.5829 1.4914L12.9502 3.8586C13.2728 4.18126 13.2728 4.7047 12.9455 5.03204C12.6197 5.35782 12.09 5.35548 11.772 5.03751L10.8337 4.09911ZM3.33372 16.6663H16.6665V10.8295C16.6665 10.3709 17.0369 9.99983 17.5001 9.99983C17.9603 9.99983 18.3337 10.374 18.3337 10.8295V17.5037C18.3337 17.7326 18.2408 17.9396 18.0908 18.0896C17.9384 18.2404 17.7306 18.3334 17.5009 18.3334H2.49932C2.26964 18.3334 2.0626 18.2412 1.91182 18.0912C1.76026 17.9381 1.6665 17.7311 1.6665 17.5037V10.8295C1.6665 10.3709 2.03682 9.99985 2.5001 9.99985C2.96026 9.99985 3.3337 10.3741 3.3337 10.8295L3.33372 16.6663Z"
        fill="black"
      />
    </svg>
  );
}

export function ShareIconSmall() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
    >
      <g filter="url(#filter0_d_56893_30934)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.77169 5.62606V10.7416C8.77169 11.0359 8.54024 11.2746 8.25068 11.2746C7.96307 11.2746 7.72966 11.0359 7.72966 10.7416V5.62414L7.14175 6.22723C6.94008 6.43393 6.61292 6.43343 6.40833 6.22372C6.20471 6.01502 6.20617 5.67619 6.40491 5.47198L7.88496 3.95549C7.98506 3.85239 8.11642 3.80084 8.24825 3.80084L8.25215 3.80234C8.38448 3.80284 8.51584 3.85389 8.61495 3.95549L10.0945 5.47199C10.2962 5.67869 10.2962 6.01402 10.0916 6.22373C9.88795 6.43243 9.55689 6.43093 9.35815 6.22723L8.77169 5.62606ZM4.08404 13.677H12.4173V9.93774C12.4173 9.64395 12.6488 9.40623 12.9383 9.40623C13.2259 9.40623 13.4593 9.64596 13.4593 9.93774V14.2134C13.4593 14.3601 13.4012 14.4927 13.3075 14.5888C13.2123 14.6854 13.0824 14.745 12.9388 14.745H3.56252C3.41897 14.745 3.28956 14.6859 3.19532 14.5898C3.10059 14.4917 3.04199 14.3591 3.04199 14.2134V9.93776C3.04199 9.64396 3.27345 9.40624 3.56301 9.40624C3.85062 9.40624 4.08403 9.64598 4.08403 9.93776L4.08404 13.677Z"
          fill="#FFFFF2"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_56893_30934"
          x="-1"
          y="0"
          width="18.5"
          height="18.8126"
          filterUnits="userSpaceOnUse"
          colorInterpolation-Filters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_56893_30934"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_56893_30934"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
