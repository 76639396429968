import { useEffect, useState } from "react";
import {
  ConnectionAvailability,
  PublicProfile,
} from "@dimensional-engineering/dimensional-models";

import styles from "./SelfRatedScoreTable.module.css";

// import elementJsonData from "../../../../assets/traitInfo/elements.json";
// import patternsJsonData from "../../../../assets/traitInfo/patterns.json";

import {
  getUserPatternScore,
  getUserScaleScore,
  traitPermissionStatus,
} from "../../utils";
import PeerIcon from "@/components/premium/reports/blindspot/PeerIcon";
import { Roboto_Mono } from "next/font/google";
import DownArrowIcon from "@/components/shared/icons/DownArrowIcon";
import UpArrowIcon from "@/components/shared/icons/UpArrowIcon";
import UknownQuestionMarkIcon from "@/components/shared/icons/UknownQuestionMarkIcon";
import useConnectionStatus from "@/helpers/useConnectionStatus";
import TraitAccessDeniedBar from "../../shared/TraitAccessDeniedBar/TraitAccessDeniedBar";
import useIsTraitPremium from "@/components/premium/helpers/useIsTraitPremium";
import useGetIsPremium from "@/helpers/useGetIsPremium";
import PremiumTraitAccessDeniedBar from "@/components/premium/PremiumTraitAccessDeniedBar/PremiumTraitAccessDeniedBar";

type Props = {
  slug: string;
  type: "element" | "pattern" | "archetype";
  score: number | null | undefined;
  publicProfile: PublicProfile | null;
  connectionAvailability: ConnectionAvailability[] | null | undefined;
};

const font = Roboto_Mono({ subsets: ["latin"] });

export default function FriendSelfRatedScoreTable(props: Props) {
  const [score, setScore] = useState<number | null | undefined>();
  const [scoreText, setScoreText] = useState<
    "Very low" | "Low" | "Average" | "High" | "Very high"
  >();
  const [rawScore, setRawScore] = useState<number | null | undefined>();
  // const [averageRawScore, setAverageRawScore] = useState<
  //   number | null | undefined
  // >(null);

  const friendStatus = useConnectionStatus(props.publicProfile?.ownerUUID);
  const isConnectionPremium = useGetIsPremium(props.publicProfile?.ownerUUID);
  const isTraitPremium = useIsTraitPremium(props.type, props.slug);
  const status = traitPermissionStatus(
    friendStatus,
    props.connectionAvailability,
    undefined
  );

  useEffect(() => {
    if (props.type === "archetype") {
      setRawScore(props.score ? props.score * 100 : null);
      setScore(props.score ? props.score * 100 : null);
    }

    if (props.type === "element") {
      setRawScore(props.score ? props.score * 100 : null);
      const elPercScore = getUserScaleScore(props.score, props.slug);
      setScore(elPercScore ? elPercScore * 100 : null);

      // const average = elementJsonData.find((e) => e.slug === props.slug)
      //   ?.traitContent.averagePercentScore;
      // setAverageRawScore(average ? average * 100 : null);
    }

    if (props.type === "pattern") {
      setRawScore(props.score ? props.score * 100 : null);
      const patPercScore = getUserPatternScore(props.score, props.slug);
      setScore(
        patPercScore !== undefined && patPercScore !== null
          ? patPercScore * 100
          : null
      );

      // const average = patternsJsonData.find((e) => e.slug === props.slug)
      //   ?.traitContent.averagePercentScore;
      // setAverageRawScore(average ? average * 100 : null);
    }
  }, [props.slug, props.type, props.score]);

  useEffect(() => {
    if (score !== null && score !== undefined) {
      if (score <= 12) {
        setScoreText("Very low");
      } else if (score > 12 && score <= 29) {
        setScoreText("Low");
      } else if (score > 29 && score <= 69) {
        setScoreText("Average");
      } else if (score > 69 && score <= 88) {
        setScoreText("High");
      } else {
        setScoreText("Very high");
      }
    }
  }, [score]);

  if (status === "private") {
    return (
      <div className={styles.main}>
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <h1>Score details</h1>
          </div>
          <TraitAccessDeniedBar
            publicProfile={props.publicProfile}
            connectionTypeNeeded={status}
            noPadding
          />
        </div>
      </div>
    );
  }

  if (isTraitPremium && !isConnectionPremium) {
    return (
      <div className={styles.main}>
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <h1>Score details</h1>
          </div>
          <PremiumTraitAccessDeniedBar
            publicProfile={props.publicProfile}
            notOwn
          />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.main}>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <h1>Score details</h1>
        </div>
        <div className={`${styles.row} ${font.className}`}>
          <h3 className={styles.rowText}>Score</h3>
          {score !== null && score !== undefined && (
            <>
              <div className={styles.scoreWrapper}>
                <div
                  style={{ marginLeft: "4" }}
                  className={styles.selected}
                ></div>
                <div
                  className={score > 12 ? styles.selected : styles.unselected}
                ></div>
                <div
                  className={score > 30 ? styles.selected : styles.unselected}
                ></div>
                <div
                  className={score > 70 ? styles.selected : styles.unselected}
                ></div>
                <div
                  className={score > 88 ? styles.selected : styles.unselected}
                ></div>
              </div>
              <div className={styles.scoreDescDiv}>
                <h4>{scoreText}</h4>
                <div className={styles.iconDiv}>
                  {scoreText === "Very low" && (
                    <>
                      <div className={styles.arrow}>
                        <DownArrowIcon />
                      </div>
                      <div className={styles.arrow}>
                        <DownArrowIcon />
                      </div>
                    </>
                  )}
                  {scoreText === "Low" && (
                    <div className={styles.arrow}>
                      <DownArrowIcon />
                    </div>
                  )}
                  {scoreText === "Average" && (
                    <div className={styles.neutral}></div>
                  )}
                  {scoreText === "High" && (
                    <div className={styles.arrow}>
                      <UpArrowIcon />
                    </div>
                  )}
                  {scoreText === "Very high" && (
                    <>
                      <div className={styles.arrow}>
                        <UpArrowIcon />
                      </div>
                      <div className={styles.arrow}>
                        <UpArrowIcon />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
          {score === null ||
            (score === undefined && (
              <>
                <div className={styles.scoreWrapper}>
                  <div
                    style={{ marginLeft: "4" }}
                    className={styles.unselected}
                  ></div>
                  <div className={styles.unselected}></div>
                  <div className={styles.unselected}></div>
                  <div className={styles.unselected}></div>
                  <div className={styles.unselected}></div>
                  <div className={styles.restrictIcon}>
                    <UknownQuestionMarkIcon />
                  </div>
                </div>
                <div className={styles.scoreDescDiv}>
                  <h4>Unknown</h4>
                </div>
              </>
            ))}
        </div>
        <div className={`${styles.row} ${font.className}`}>
          <h3 className={styles.rowText}>Raw score</h3>
          <h3 className={styles.rowText}>
            {rawScore ? rawScore.toFixed(0) : "?"}
          </h3>
        </div>
        <div className={`${styles.row} ${font.className}`}>
          <h3 className={styles.rowText}>Percentile score</h3>
          {props.type !== "archetype" && (
            <h3 className={styles.rowText}>{score ? score.toFixed(0) : "?"}</h3>
          )}
          {props.type === "archetype" && (
            <h3 className={styles.rowText}>Not available</h3>
          )}
        </div>
        {/* <div
          style={{ border: "0" }}
          className={`${styles.row} ${font.className}`}
        >
          <h3 className={styles.rowText}>Average raw score</h3>
          <h3 className={styles.rowText}>
            {averageRawScore ? averageRawScore.toFixed(0) : "?"}
          </h3>
        </div> */}
      </div>
    </div>
  );
}
