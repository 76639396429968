/* eslint-disable react/no-children-prop */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import styles from "../../../../styles/app/AuthTraits/ElementModal.module.css";

import ModalWrapper from "@/components/shared/ModalWrapper/ModalWrapper";
import useGetElementModalData from "./useGetElementModalData";
import Loader from "@/components/shared/Loader";
import { useAuth } from "@/context/AuthContext";
import ElementTraitsPageLeftBarModal from "../../element/elementsLeftBar/ElementTraitsPageLeftBarModal";
import SwipeTabs from "@/components/shared/SwipeTabs/SwipeTabs";
import SelfRatedScoreTable from "../../detailsTab/SelfRatedScoreTable/SelfRatedScoreTable";
import Histogram from "../../detailsTab/histogram/Histogram";
import OtherElements from "../../detailsTab/elements/otherElements/OtherElements";
import OppositeElement from "../../detailsTab/elements/opposite/OppositeElement";
import ConnectionTab from "../../connectionTab/ConnectionTab";
import ElementComponentOf from "../../detailsTab/elements/componentOf/ElementComponentOf";
import ParentOf from "../../detailsTab/elements/parentOf/ParentOf";
import ReactMarkdown from "react-markdown";
import InteractionBar from "../../shared/InteractionBar/InteractionBar";
import Element from "../../element/Element/Element";
import { RootState } from "@/redux/store";
import { getUserScaleScore } from "../../utils";
import TraitDescription from "../../shared/TraitScoreTable/TraitDescription";
import { natureMap } from "@/assets/traitInfo/natureData";

export default function OwnElementModal(props: { slug: string }) {
  const { element, otherElements } = useGetElementModalData(props.slug);
  const [tabs, setTabs] = useState<string[]>([
    "summary",
    "details",
    "identity",
  ]);
  const [slicedMarkdown, setSlicedMarkdown] = useState<string[]>([]);
  const [markdownContent, setMarkdownContent] = useState<string[]>([]);
  const [score, setScore] = useState<number | null | undefined>(null);
  const userState = useSelector((state: RootState) => state.user);

  const { user } = useAuth();

  useEffect(() => {
    setTabs(["summary", "details", "identity"]);
    setMarkdownContent([]);
    setSlicedMarkdown([]);
    element?.traitContent?.markdownSections?.forEach((section, index) => {
      if (!tabs.includes(section.traitSection as string)) {
        setTabs((current) => [...current, section.traitSection as string]);
      }
      setMarkdownContent((current) => [
        ...current,
        section.markdownString.replace(
          "/element",
          `/app/profile/${user?.uid}/elements`
        ),
      ]);
      if (index !== 0) {
        setSlicedMarkdown((current) => [...current, section.markdownString]);
      }
    });
    setTabs((current) => [...current, "Connections"]);

    userState.elementsDiscovered?.forEach((el) => {
      if (el.scaleSlug === props.slug) {
        const elPercScore = getUserScaleScore(
          el.scaleScore?.percentScore,
          el.scaleSlug
        );
        setScore(elPercScore);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element?.slug]);

  if (element === null) {
    return (
      <ModalWrapper title="">
        <Loader height="500px" mobileHeight="500px" />
      </ModalWrapper>
    );
  }

  return (
    <ModalWrapper noLine title={element.alias ?? element.name}>
      <div className={styles.main}>
        <div className={styles.parent}>
          <div className={styles.rightBarMain}>
            <SwipeTabs
              sticky
              tabs={tabs}
              content={[
                <ElementTraitsPageLeftBarModal key={1} element={element} />,
                <div key={2}>
                  <div className={styles.archetypeDiv}>
                    <Element slug={element.slug} score={score} />
                  </div>
                  <SelfRatedScoreTable slug={element.slug} type="element" />
                  {/* {element.isRateable ? (
                    <PeerRatedScoreTable
                      slug={element.slug}
                      isPremium={element.isPremium}
                    />
                  ) : (
                    <NonRateablePeerRatedScoreTable />
                  )} */}
                  <Histogram
                    // @ts-ignore
                    data={element.traitContent?.histogramData}
                    auth
                    type="element"
                    averageUsrScore={element.traitContent?.averagePercentScore}
                    modal
                  />
                  <TraitDescription
                    type="Element"
                    aboutThisTrait
                    dimension={element.dimensionSlug}
                    nature={
                      element.nature
                        ? natureMap[element.nature].name
                        : undefined
                    }
                    color={
                      element.nature
                        ? natureMap[element.nature].color
                        : undefined
                    }
                    plasticity={element.plasticity}
                    oppositeTraitSlug={
                      element.traitContent.oppositeDetails?.oppositeTrait.slug
                    }
                    averageUserScore={element.traitContent?.averagePercentScore}
                  />
                  {otherElements.length > 0 && (
                    <OtherElements authenticated elements={otherElements} />
                  )}
                  {element.traitContent?.oppositeTrait?.traitSlug && (
                    <OppositeElement authenticated element={element} />
                  )}

                  {(element.traitContent?.componentOfDetails?.componentTraits
                    ?.length ?? 0) > 0 && (
                    <ElementComponentOf scale={element} authenticated />
                  )}

                  {element.traitContent?.parentDetails?.childTraits.length && (
                    <ParentOf element={element} auth />
                  )}
                </div>,
                <div
                  key={3}
                  style={{ pointerEvents: "none" }}
                  className={styles.markdownDiv}
                >
                  <ReactMarkdown
                    transformLinkUri={(href: string) => {
                      return "";
                    }}
                    children={markdownContent[0]}
                  />
                </div>,
              ]}
              slicedContent={slicedMarkdown}
              grow={tabs.length < 4 ? true : false}
              connectionTab={[
                <ConnectionTab
                  // @ts-ignore
                  histogramData={element.traitContent.histogramData}
                  slug={element.slug}
                  type="element"
                  averageUserScore={element.traitContent?.averagePercentScore}
                  key={3}
                  connectionAvailabilities={element.connectionAvailabilities}
                  traitSlug={element.slug}
                />,
              ]}
            />
          </div>
        </div>
      </div>
      {user?.uid && (
        <InteractionBar
          uid={user?.uid}
          slug={element.slug}
          type="element"
          ownProfile
          score={score}
        />
      )}
    </ModalWrapper>
  );
}
