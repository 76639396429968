import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Roboto_Mono } from "next/font/google";

import styles from "./SelfRatedScoreTable.module.css";

// import elementJsonData from "../../../../assets/traitInfo/elements.json";
// import patternsJsonData from "../../../../assets/traitInfo/patterns.json";

import { RootState } from "@/redux/store";
import { getUserPatternScore, getUserScaleScore } from "../../utils";
import PeerIcon from "@/components/premium/reports/blindspot/PeerIcon";
import DownArrowIcon from "@/components/shared/icons/DownArrowIcon";
import UpArrowIcon from "@/components/shared/icons/UpArrowIcon";
import UknownQuestionMarkIcon from "@/components/shared/icons/UknownQuestionMarkIcon";
import useIsTraitPremium from "@/components/premium/helpers/useIsTraitPremium";
import PremiumTraitAccessDeniedBar from "@/components/premium/PremiumTraitAccessDeniedBar/PremiumTraitAccessDeniedBar";
import { dimensionData } from "@/assets/traitInfo/dimensionData";

type Props = {
  slug: string;
  type: "element" | "pattern" | "archetype";
};

const font = Roboto_Mono({ subsets: ["latin"] });

export default function SelfRatedScoreTable(props: Props) {
  const [score, setScore] = useState<number | null | undefined>();
  const [scoreText, setScoreText] = useState<
    "Very low" | "Low" | "Average" | "High" | "Very high"
  >();
  const [rank, setRank] = useState<number[] | null | undefined>(null);
  const [rawScore, setRawScore] = useState<number | null | undefined>();
  // const [averageRawScore, setAverageRawScore] = useState<
  //   number | null | undefined
  // >(null);

  const userState = useSelector((state: RootState) => state.user);
  const isTraitPremium = useIsTraitPremium(props.type, props.slug);

  useEffect(() => {
    if (props.type === "archetype") {
      userState.archetypesDiscovered?.forEach((arch) => {
        if (arch.archetypeSlug === props.slug) {
          setRawScore(arch.archetypeScore ? arch.archetypeScore * 100 : null);
          setScore(arch.archetypeScore ? arch.archetypeScore * 100 : null);
        }
      });
    }

    if (props.type === "element") {
      userState.elementsDiscovered?.forEach((el) => {
        if (el.scaleSlug === props.slug) {
          const dimension = dimensionData?.find(
            (d) => d.slug === el.dimensionSlug
          );
          setRawScore(
            el.scaleScore?.percentScore
              ? el.scaleScore?.percentScore * 100
              : null
          );

          const elPercScore = getUserScaleScore(
            el.scaleScore?.percentScore,
            el.scaleSlug
          );

          setScore(elPercScore ? elPercScore * 100 : null);

          if (
            el.scaleScore?.intraRawRank &&
            dimension &&
            dimension.intraRawRankDenominator
          ) {
            setRank([
              el.scaleScore.intraRawRank,
              dimension.intraRawRankDenominator,
            ]);
          }
        }
      });

      // const average = elementJsonData.find((e) => e.slug === props.slug)
      //   ?.traitContent.averagePercentScore;
      // setAverageRawScore(average ? average * 100 : null);
    }

    if (props.type === "pattern") {
      userState.patternsDiscovered?.forEach((patt) => {
        if (patt.patternSlug === props.slug) {
          setRawScore(patt.percentScore ? patt.percentScore * 100 : null);
          const patPercScore = getUserPatternScore(
            patt.percentScore,
            patt.patternSlug
          );
          setScore(
            patPercScore !== undefined && patPercScore !== null
              ? patPercScore * 100
              : null
          );
        }
      });
      // const average = patternsJsonData.find((e) => e.slug === props.slug)
      //   ?.traitContent.averagePercentScore;
      // setAverageRawScore(average ? average * 100 : null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.slug, props.type]);

  useEffect(() => {
    if (score !== null && score !== undefined) {
      if (score <= 12) {
        setScoreText("Very low");
      } else if (score > 12 && score <= 29) {
        setScoreText("Low");
      } else if (score > 29 && score <= 69) {
        setScoreText("Average");
      } else if (score > 69 && score <= 88) {
        setScoreText("High");
      } else {
        setScoreText("Very high");
      }
    }
  }, [score]);

  if (isTraitPremium && !userState.isPremium) {
    return (
      <div className={styles.main}>
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <h1>Score details</h1>
          </div>
          <PremiumTraitAccessDeniedBar />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.main}>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <h1>Score details</h1>
        </div>
        <div className={`${styles.row} ${font.className}`}>
          <h3 className={styles.rowText}>Score</h3>
          {score !== null && score !== undefined && (
            <>
              <div className={styles.scoreWrapper}>
                <div
                  style={{ marginLeft: "4" }}
                  className={styles.selected}
                ></div>
                <div
                  className={score > 12 ? styles.selected : styles.unselected}
                ></div>
                <div
                  className={score > 30 ? styles.selected : styles.unselected}
                ></div>
                <div
                  className={score > 70 ? styles.selected : styles.unselected}
                ></div>
                <div
                  className={score > 88 ? styles.selected : styles.unselected}
                ></div>
              </div>
              <div className={styles.scoreDescDiv}>
                <h4>{scoreText}</h4>
                <div className={styles.iconDiv}>
                  {scoreText === "Very low" && (
                    <>
                      <div className={styles.arrow}>
                        <DownArrowIcon />
                      </div>
                      <div className={styles.arrow}>
                        <DownArrowIcon />
                      </div>
                    </>
                  )}
                  {scoreText === "Low" && (
                    <div className={styles.arrow}>
                      <DownArrowIcon />
                    </div>
                  )}
                  {scoreText === "Average" && (
                    <div className={styles.neutral}></div>
                  )}
                  {scoreText === "High" && (
                    <div className={styles.arrow}>
                      <UpArrowIcon />
                    </div>
                  )}
                  {scoreText === "Very high" && (
                    <>
                      <div className={styles.arrow}>
                        <UpArrowIcon />
                      </div>
                      <div className={styles.arrow}>
                        <UpArrowIcon />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
          {score === null ||
            (score === undefined && (
              <>
                <div className={styles.scoreWrapper}>
                  <div
                    style={{ marginLeft: "4" }}
                    className={styles.unselected}
                  ></div>
                  <div className={styles.unselected}></div>
                  <div className={styles.unselected}></div>
                  <div className={styles.unselected}></div>
                  <div className={styles.unselected}></div>
                  <div className={styles.restrictIcon}>
                    <UknownQuestionMarkIcon />
                  </div>
                </div>
                <div className={styles.scoreDescDiv}>
                  <h4>Unknown</h4>
                </div>
              </>
            ))}
        </div>
        <div className={`${styles.row} ${font.className}`}>
          <h3 className={styles.rowText}>Self-rated raw score</h3>
          <h3 className={styles.rowText}>
            {rawScore ? rawScore.toFixed(0) : "?"}
          </h3>
        </div>
        <div className={`${styles.row} ${font.className}`}>
          <h3 className={styles.rowText}>Self-rated percentile score</h3>
          {props.type !== "archetype" && (
            <h3 className={styles.rowText}>{score ? score.toFixed(0) : "?"}</h3>
          )}
          {props.type === "archetype" && (
            <h3 className={styles.rowText}>Not available</h3>
          )}
        </div>
        {props.type === "element" && rank && (
          <div className={`${styles.row} ${font.className}`}>
            <h3 className={styles.rowText}>Rank in group</h3>
            <h3 className={styles.rowText}>{`#${rank[0]} of ${rank[1]}`}</h3>
          </div>
        )}
        {/* <div
          style={{ border: "0" }}
          className={`${styles.row} ${font.className}`}
        >
          <h3 className={styles.rowText}>Average raw score</h3>
          <h3 className={styles.rowText}>
            {averageRawScore ? averageRawScore.toFixed(0) : "?"}
          </h3>
        </div> */}
      </div>
    </div>
  );
}
