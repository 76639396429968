import { GPTMessage } from "@dimensional-engineering/dimensional-models";

import styles from "./GoDeeperModal.module.css";

export default function UserMessage(props: GPTMessage) {
  return (
    <div className={styles.userMessageDiv}>
      <p className={styles.userMessage}>{props.content}</p>
    </div>
  );
}
