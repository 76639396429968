import { useEffect, useState } from "react";
import {
  ConnectionLlmRequest,
  DimensionEvent,
  DiscoverableCompatibilitySnippet,
  DiscoveredCompatibilitySnippet,
  PublicProfile,
} from "@dimensional-engineering/dimensional-models";
import {
  collection,
  doc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";

import { db } from "@/_firebase/firebaseConfig";
import { useAuth } from "@/context/AuthContext";
import {
  DynamicProfileResultData,
  RomanticCompatibilityReport,
  WorkCompatibilityReport,
} from "@/models/sharedModels";
import { useDispatch } from "react-redux";
import {
  resetCompatibilitySnippetsState,
  setcompatibilitySnippetsData,
} from "@/redux/slices/compatibilitySnippetsSlice";
import { getDiscoverableCompatibilitySnippets } from "@/components/authenticatedProfile/tabs/AuthCompareTab/utils";

export function useGetCompatibilityInsightDatModal(props: {
  friendStatus:
    | "connected"
    | "close"
    | "deep"
    | "sent"
    | "unconnected"
    | "received"
    | null;
  connectionPublicProfile: PublicProfile | null;
  profileData: DynamicProfileResultData;
  uid: string;
}) {
  const [
    discoverableCompatibilitySnippets,
    setDiscoverableCompatibilitySnippets,
  ] = useState<DiscoverableCompatibilitySnippet[]>([]);
  const [discoveredCompatibilitySnippets, setDiscoveredCompatibilitySnippets] =
    useState<DiscoveredCompatibilitySnippet[]>([]);
  const [discoveredSlugs, setDiscoveredSlugs] = useState<string[]>([]);
  const [dimensionComplete, setDimensionComplete] = useState<string[]>([]);
  const [llmRequests, setLlmRequests] = useState<ConnectionLlmRequest[]>([]);
  const [romanticCompatibilityReport, setRomanticCompatibilityReport] =
    useState<RomanticCompatibilityReport | null>(null);
  const [workCompatibilityReport, setWorkCompatibilityReport] =
    useState<WorkCompatibilityReport | null>(null);

  const { user } = useAuth();

  const dispatch = useDispatch();

  useEffect(() => {
    //get discovered snippets
    const ref = query(
      collection(db, `members/${user?.uid}/discoveredCompatibilitySnippets`),
      where("connectionUid", "==", props.uid)
    );
    const unsubscribeSnippets = onSnapshot(ref, (querySnapshot) => {
      let snippets: DiscoveredCompatibilitySnippet[] = [];
      let slugs: string[] = [];
      querySnapshot.forEach((doc) => {
        const d = doc.data() as DiscoveredCompatibilitySnippet;
        snippets.push(d);
        slugs.push(d?.snippetSlug);
      });
      setDiscoveredCompatibilitySnippets(snippets);
      setDiscoveredSlugs(slugs);
    });

    //get romantic report snippet /members/<memberId>/romanticCompatibilityReports/<connectionUid>

    const romaticReportRef = doc(
      db,
      `members/${user?.uid}/romanticCompatibilityReports/${props.uid}`
    );

    const unsubscribeRomanticSnippet = onSnapshot(
      romaticReportRef,
      (querySnapshot) => {
        const d = querySnapshot.data();
        if (d) {
          setDiscoveredSlugs((current) => [
            ...current,
            "compatibility-snippet-romantic-compatibility-report",
          ]);
          setRomanticCompatibilityReport(
            querySnapshot.data() as RomanticCompatibilityReport
          );
        }
      }
    );

    //get work report snippet /members/<memberId>/workCompatibilityReports/<connectionUid>

    const workReportRef = doc(
      db,
      `members/${user?.uid}/workCompatibilityReports/${props.uid}`
    );

    const unsubscribeWorkSnippet = onSnapshot(
      workReportRef,
      (querySnapshot) => {
        const d = querySnapshot.data();
        if (d) {
          setDiscoveredSlugs((current) => [
            ...current,
            "compatibility-snippet-work-compatibility-report",
          ]);
          setWorkCompatibilityReport(
            querySnapshot.data() as WorkCompatibilityReport
          );
        }
      }
    );

    //get connection dimension events
    let dimensionEventRef = collection(
      db,
      `members/${props.uid}/dimensionEvents`
    );
    getDocs(dimensionEventRef).then((querySnap) => {
      let dimEvents: string[] = [];
      querySnap.forEach((doc) => {
        const d = doc.data() as DimensionEvent;
        if (d.event === "complete") {
          dimEvents.push(d.dimensionSlug);
        }
      });
      setDimensionComplete(dimEvents);
    });

    //get llm requests data
    let requestRef = query(
      collection(db, `connectionLlmRequests/${user?.uid}/compatibilitySnippet`),
      where("requesteeUid", "==", props.uid as string),
      orderBy("createdOn", "desc")
    );
    const unsubscribeLlmRequests = onSnapshot(requestRef, (querySnap) => {
      let llmSlugs: string[] = [];
      let llmRequests: ConnectionLlmRequest[] = [];
      querySnap.forEach((doc) => {
        const data = doc.data() as ConnectionLlmRequest;
        if (!llmSlugs.includes(data.contentIdentifier.contentSlug)) {
          llmRequests.push(data);
          llmSlugs.push(data.contentIdentifier.contentSlug);
        }
      });
      setLlmRequests(llmRequests);
    });

    return () => {
      unsubscribeSnippets;
      unsubscribeLlmRequests;
      unsubscribeRomanticSnippet;
      unsubscribeWorkSnippet;
    };
  }, [props.uid, user?.uid]);

  useEffect(() => {
    getDiscoverableCompatibilitySnippets().then((tempDiscoverableSnippets) => {
      setDiscoverableCompatibilitySnippets(tempDiscoverableSnippets);
    });
  }, []);

  useEffect(() => {
    dispatch(
      setcompatibilitySnippetsData({
        dimensionComplete: dimensionComplete,
        discoverableCompatibilitySnippets: discoverableCompatibilitySnippets,
        discoveredCompatibilitySnippets: discoveredCompatibilitySnippets,
        discoveredSlugs: discoveredSlugs,
        llmRequests: llmRequests,
        romanticCompatibilityReport: romanticCompatibilityReport,
        workCompatibilityReport: workCompatibilityReport,
        friendStatus: props.friendStatus,
        publicProfile: props.connectionPublicProfile,
        profileData: props.profileData,
      })
    );

    return () => {
      dispatch(resetCompatibilitySnippetsState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dimensionComplete,
    discoverableCompatibilitySnippets,
    discoveredCompatibilitySnippets,
    discoveredSlugs,
    llmRequests,
    props.connectionPublicProfile,
    props.friendStatus,
    props.profileData,
    romanticCompatibilityReport,
    workCompatibilityReport,
  ]);
}
