import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";

import styles from "./PeriodicQuizHomeCta.module.css";

import { RootState } from "@/redux/store";

import { formatPeriodicQuizTime } from "../utils";
import { useModalContext } from "@/context/ModalContext";
import PeriodicQuizModal from "../PeriodicQuizModal/PeriodicQuizModal";
import PeriodicQuizResultModal from "../PeriodicQuizResultModal/PeriodicQuizResultModal";
import { DailyContentStatusBarDailyInsights } from "../../DailyContentStatusBar/DailyContentStatusBar";
import { useGetDailyInsightTimerText } from "../../DailyInsights/DailyInsightsCTABlock/DailyInsightsCTABlock";
import { Mixpanel } from "@/helpers/mixpanel";
import Shimmer from "@/components/shared/Shimmer/Shimmer";

export default function PeriodicQuizHomeCta() {
  const { setModalComponent } = useModalContext();

  const quizState = useSelector((state: RootState) => state.periodicQuiz);

  const time = useGetDailyInsightTimerText(quizState.availableQuiz?.validUntil);

  const currentQuizScore = quizState.periodicQuizResults?.find(
    (q) => q.periodicQuizSlug === quizState.availableQuiz?.slug
  )?.percentScore;

  const commentCountRoot = quizState.commentThread?.rootCommentCount ?? 0;
  const commentCountReplies = quizState.commentThread?.replyCount ?? 0;

  if (!quizState.periodicQuizLoaded) {
    return (
      <section style={{ height: "350px", padding: "0" }} className={styles.box}>
        <Shimmer />
      </section>
    );
  }

  if (!quizState.availableQuiz) {
    return <></>;
  }

  const LSPeriodicQuizViewed = localStorage.getItem(
    "periodicQuizViewedCreatedOn"
  );
  const isViewed = LSPeriodicQuizViewed
    ? LSPeriodicQuizViewed === quizState.availableQuiz?.deliveredOn?.toString()
    : false;

  return (
    <section
      onClick={() => {
        if (quizState.availableQuiz?.deliveredOn) {
          localStorage.setItem(
            "periodicQuizViewedCreatedOn",
            quizState.availableQuiz?.deliveredOn?.toString()
          );
        }
        const foundQuizResult = quizState.periodicQuizResults?.find(
          (r) => r.periodicQuizSlug === quizState.availableQuiz?.slug
        );

        if (foundQuizResult && quizState.availableQuiz) {
          return setModalComponent(
            <PeriodicQuizResultModal
              periodicQuiz={quizState.availableQuiz}
              periodicQuizResult={foundQuizResult}
              elementName="PeriodicQuizResultModal"
            />
          );
        }
        return setModalComponent(
          <PeriodicQuizModal elementName="PeriodicQuizModal" />
        );
      }}
      className={styles.main}
    >
      <div
        style={isViewed ? {} : { border: "1px solid #2CB0FF" }}
        className={styles.box}
      >
        <h3 className={styles.quizTitle}>
          Quiz: {quizState.availableQuiz.title}
        </h3>
        {currentQuizScore !== undefined ? (
          <h4 className={styles.quizScore}>
            {(currentQuizScore * 100).toFixed(0)}
            <span className={styles.spanScore}>/100</span>
          </h4>
        ) : (
          <h4 className={styles.quizScore}>??</h4>
        )}
        <p className={styles.yourScoreText}>Your score</p>
        <div className={styles.bottom}>
          <DailyContentStatusBarDailyInsights
            time={time === "0h:0m:0s" ? "Disappears soon!" : time}
            audienceNumber={"everyone"}
            commentCount={commentCountRoot + commentCountReplies}
            onCommentClick={() => {
              const foundQuizResult = quizState.periodicQuizResults?.find(
                (r) => r.periodicQuizSlug === quizState.availableQuiz?.slug
              );

              if (foundQuizResult && quizState.availableQuiz) {
                Mixpanel?.track("Comment modal summoned", {
                  source: "daily quiz",
                  audience_group_size: "all",
                  type: "from dashboard",
                });
                return setModalComponent(
                  <PeriodicQuizResultModal
                    periodicQuiz={quizState.availableQuiz}
                    periodicQuizResult={foundQuizResult}
                    elementName="PeriodicQuizResultModal"
                    showComment
                  />
                );
              }
              return setModalComponent(
                <PeriodicQuizModal elementName="PeriodicQuizModal" />
              );
            }}
          />
        </div>
      </div>
    </section>
  );
}

export function useGetPeriodicQuizTimer(validUntil: number | undefined | null) {
  const [time, setTime] = useState<string>("");

  useEffect(() => {
    const updateTime = () => {
      const now = moment();
      const created = moment(validUntil);
      const elapsedTimeInSeconds = created.diff(now, "seconds");
      const remainingTimeInSeconds = elapsedTimeInSeconds;
      const clampedRemainingTimeInSeconds = Math.max(remainingTimeInSeconds, 0);
      const transformedTime = formatPeriodicQuizTime(
        clampedRemainingTimeInSeconds
      );
      setTime(transformedTime);
    };

    const intervalId = setInterval(updateTime, 1000);

    return () => clearInterval(intervalId);
  }, [validUntil]);

  return time;
}
