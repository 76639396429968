export default function ReactionIcon(props: {
  onClick: (e: MouseEvent) => void;
}) {
  return (
    <svg
      width="25"
      height="26"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={(e) => props.onClick(e as unknown as MouseEvent)}
    >
      <path
        d="M18.0911 4.24809L18.0911 5.69195L16.6473 5.69195C16.0088 5.69195 15.4922 6.20859 15.4922 6.84704C15.4922 7.48548 16.0088 8.00213 16.6473 8.00213L18.0911 8.00213L18.0911 9.44599C18.0911 10.0844 18.6078 10.6011 19.2462 10.6011C19.8847 10.6011 20.4013 10.0844 20.4013 9.44599L20.4013 8.00213L21.8452 8.00213C22.4836 8.00213 23.0003 7.48548 23.0003 6.84704C23.0003 6.20859 22.4836 5.69195 21.8452 5.69195L20.4013 5.69195L20.4013 4.24809C20.4013 3.60964 19.8847 3.093 19.2462 3.093C18.6078 3.093 18.0911 3.60964 18.0911 4.24809Z"
        fill="#FFFFF2"
      />
      <path
        d="M10.048 11.2422C10.048 12.7819 7.73779 12.7819 7.73779 11.2422C7.73779 9.70247 10.048 9.70247 10.048 11.2422Z"
        fill="#FFFFF2"
      />
      <path
        d="M16.401 11.2422C16.401 12.7819 14.0908 12.7819 14.0908 11.2422C14.0908 9.70247 16.401 9.70247 16.401 11.2422Z"
        fill="#FFFFF2"
      />
      <path
        d="M14.3794 14.4186C14.3794 13.9403 13.9914 13.5522 13.5131 13.5522H10.6254C10.1471 13.5522 9.75908 13.9403 9.75908 14.4186V14.6182C9.7399 15.8636 10.6863 16.9115 11.9282 17.0175C12.5644 17.057 13.1894 16.8303 13.6541 16.3926C14.1177 15.9549 14.3806 15.3458 14.3794 14.7073V14.4186Z"
        fill="#FFFFF2"
      />
      <path
        d="M14.8925 4.16265C15.1083 3.48859 14.7372 2.75855 14.0414 2.62919C12.3816 2.32064 10.664 2.42924 9.04548 2.95469C7.01892 3.61263 5.2504 4.89084 3.98988 6.60866C2.72935 8.32648 2.04059 10.397 2.02094 12.5276C2.00129 14.6582 2.65174 16.7411 3.88037 18.4819C5.10899 20.2226 6.85364 21.5332 8.86771 22.2284C10.8818 22.9237 13.0634 22.9683 15.1042 22.356C17.1451 21.7438 18.9419 20.5056 20.2407 18.8166C21.2779 17.4676 21.9556 15.8856 22.2209 14.2183C22.3321 13.5194 21.7686 12.9252 21.0612 12.9027V12.9027C20.3537 12.8803 19.7729 13.44 19.6321 14.1336C19.4028 15.2632 18.9182 16.3316 18.2088 17.2542C17.2376 18.5173 15.8939 19.4432 14.3677 19.901C12.8416 20.3589 11.2101 20.3255 9.704 19.8056C8.19786 19.2857 6.89319 18.3057 5.97442 17.0039C5.05564 15.7021 4.56922 14.1445 4.58392 12.5512C4.59861 10.958 5.11368 9.40959 6.05631 8.12499C6.99894 6.84039 8.32146 5.88453 9.83694 5.39252C10.9439 5.03314 12.1129 4.93451 13.254 5.09681C13.9548 5.19647 14.6766 4.83671 14.8925 4.16265V4.16265Z"
        fill="#FFFFF2"
      />
    </svg>
  );
}

export function ConnectionReactionIcon() {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.048 10.7422C10.048 12.2819 7.73779 12.2819 7.73779 10.7422C7.73779 9.20247 10.048 9.20247 10.048 10.7422Z"
        fill="#928E8C"
      />
      <path
        d="M16.401 10.7422C16.401 12.2819 14.0908 12.2819 14.0908 10.7422C14.0908 9.20247 16.401 9.20247 16.401 10.7422Z"
        fill="#928E8C"
      />
      <path
        d="M14.3794 13.9186C14.3794 13.4403 13.9914 13.0522 13.5131 13.0522H10.6254C10.1471 13.0522 9.75908 13.4403 9.75908 13.9186V14.1182C9.7399 15.3636 10.6863 16.4115 11.9282 16.5175C12.5644 16.557 13.1894 16.3303 13.6541 15.8926C14.1177 15.4549 14.3806 14.8458 14.3794 14.2073V13.9186Z"
        fill="#928E8C"
      />
      <path
        d="M22.3472 12.1213C22.3472 17.7344 17.7969 22.2847 12.1838 22.2847C6.57078 22.2847 2.02051 17.7344 2.02051 12.1213C2.02051 6.50828 6.57078 1.95801 12.1838 1.95801C17.7969 1.95801 22.3472 6.50828 22.3472 12.1213ZM4.58359 12.1213C4.58359 16.3188 7.98634 19.7216 12.1838 19.7216C16.3813 19.7216 19.7841 16.3188 19.7841 12.1213C19.7841 7.92384 16.3813 4.52109 12.1838 4.52109C7.98634 4.52109 4.58359 7.92384 4.58359 12.1213Z"
        fill="#928E8C"
      />
    </svg>
  );
}
