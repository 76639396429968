import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import {
  GPTRole,
  GPTConversationType,
  GPTMessage,
  GPTMessageStatus,
  DeliveredDailyInsight,
} from "@dimensional-engineering/dimensional-models";

import styles from "./GoDeeperModal.module.css";

import { useModalContext } from "@/context/ModalContext";
import BackButton from "@/components/shared/buttons/BackButton";
import {
  GoDeeperMenuIcon,
  GoDeeperMessageLoadingIcon,
  GoDeeperModalIcon,
  ScrollToBottomIcon,
} from "../GoDeeperIcon";
import ModalWrapper from "@/components/shared/ModalWrapper/ModalWrapper";
import DimensionalAssistantModal from "../DimensionalAssistantModal/DimensionalAssistantModal";
import ChatbotSideBar, {
  NewConversationIcon,
} from "../ChatbotSideBar/ChatbotSideBar";
import { useListenForCurrentConversation } from "./useListenForCurrentConversation";
import SystemMessage from "./SystemMessage";
import UserMessage from "./UserMessage";
import SuggestedMessage from "./SuggestedMessage";
import ChatBotTextBox from "../ChatBotTextBox/ChatBotTextBox";
import { useAuth } from "@/context/AuthContext";
import {
  createGptConversation,
  createUserMessage,
  getCurrentConversationToUpdateStore,
  getLastAssistantMessageForRegeneration,
  getLastUserMessageForRetry,
  regenerateAssistantMessage,
  renameConversation,
  retryLastUserMessage,
} from "../utils";
import {
  addConversation,
  setConversationLoading,
  setCurrentConversation,
  setMessageLoading,
} from "@/redux/slices/chatbotSlice";
import LoadingModal from "@/components/shared/LoadingModal/LoadingModal";
import MappedUserSuggestedMessages from "../MappedUserSuggestedMessages/MappedUserSuggestedMessages";
import useGetNumberOfMessagesRemaining from "../useGetNumberOfMessagesRemaining";
import { useAlertContext } from "@/context/AlertContext";
import Alert from "@/components/shared/Alerts/Alert";
import useIsScrolledUp from "../useIsScrolledUp";
import { Mixpanel } from "@/helpers/mixpanel";

export default function GoDeeperModal(props: {
  insightData?: DeliveredDailyInsight | null;
  dimensionSummaryData?: {
    dimensionSlug: string;
    title: string;
    suggestedMessage?: string;
  };
}) {
  const dispatch = useDispatch();

  const { user } = useAuth();
  const { setModalOpen, setModalComponent, removeModalByName } =
    useModalContext();
  const { setAlertComponent } = useAlertContext();

  const messagesDivRef = useRef<HTMLDivElement | null>(null);

  const currentConversation = useSelector(
    (state: RootState) => state.chatbot
  ).currentConversation;

  const messageLoading = useSelector(
    (state: RootState) => state.chatbot
  ).messageLoading;

  const conversations = useSelector(
    (state: RootState) => state.chatbot
  ).conversations;

  const lifetimeGPTTokensConsumed = useSelector(
    (state: RootState) => state.user.memberProfile?.lifetimeGPTTokensConsumed
  );

  const numberOfMessagesRemaining = useSelector(
    (state: RootState) => state.chatbot
  ).numberOfMessagesRemaining;

  const [text, setText] = useState<string>("");

  const [isAssistantOpen, setIsAssistantOpen] = useState<boolean>(false);
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);

  const [isStreaming, setIsStreaming] = useState<boolean>(false);

  const { messages, removeLastMessage } = useListenForCurrentConversation();
  useListenForMessageLoading(messages);
  useGetNumberOfMessagesRemaining();

  const isScrolledUp = useIsScrolledUp(messagesDivRef, messages.length);

  const mappedMessages = messages.map((message, i, a) => {
    if (message.role === GPTRole.assistant || message.role === GPTRole.system) {
      return (
        <SystemMessage
          conversationId={currentConversation?.id}
          message={message}
          onRetry={async () => {
            const lastUserMessage = getLastUserMessageForRetry(messages);
            if (lastUserMessage?.content) {
              ///figure out retry
              await retryLastUserMessage(
                lastUserMessage,
                user?.uid,
                currentConversation?.id
              );
            }
          }}
          hasFeedbackBar={i === a.length - 1}
          onRegnerate={() => {
            const lastUserMessage = getLastUserMessageForRetry(messages);
            const lastAssistantMessage =
              getLastAssistantMessageForRegeneration(messages);
            removeLastMessage(lastAssistantMessage);
            dispatch(setMessageLoading(true));

            return regenerateAssistantMessage(
              user?.uid,
              currentConversation?.id,
              lastUserMessage,
              lastAssistantMessage
            );
          }}
          hasConversationFeedback={i === 16}
          key={message.id}
          onStreamStart={() => {
            setIsStreaming(true);
          }}
          onStreamComplete={() => {
            setIsStreaming(false);
          }}
          previousMessageContent={
            a.length > 1 ? messages[i - 1]?.content : undefined
          }
        />
      );
    }
    return <UserMessage {...message} key={message.id} />;
  });

  const suggestedMessages = messages[messages.length - 1]?.suggestions;

  const mappedSuggestedMessages = suggestedMessages?.map(
    (suggestedMessage, i) => (
      <SuggestedMessage
        onSelect={() => handleSend(suggestedMessage, true)}
        key={i}
        suggestedMessage={suggestedMessage}
      />
    )
  );

  const showSuggestedMessagesSection = useShowSuggestedMessagesSection({
    mappedSuggestedMessagesLength: mappedSuggestedMessages?.length,
    isStreaming: isStreaming,
    messageLoading,
  });

  async function handleSend(content?: string, isSuggested?: boolean) {
    if (
      currentConversation?.cumulativeGPTTokensConsumed &&
      currentConversation.cumulativeGPTTokensConsumed >= 128000
    ) {
      //can't go over 128k token limit per convo
      Mixpanel?.track("Error alert summoned", {
        body: "Sorry this conversation has reached its limit. Please delete an existing conversation or start a new one.",
        insight_slug: props.insightData?.computeableInsightModelSlug,
      });
      Mixpanel?.track("Chatbot | Conversation limit reached", {
        "user message": content ?? text,
      });
      return setAlertComponent(
        <Alert
          type="fail"
          message="Sorry this conversation has reached its limit. Please delete an existing conversation or start a new one."
          elementName="Alert"
        />,
        3000
      );
    }

    if (lifetimeGPTTokensConsumed && lifetimeGPTTokensConsumed >= 100000000) {
      //can't go over 100 million token limit per lifetime
      Mixpanel?.track("Error alert summoned", {
        body: "Something went wrong!",
        insight_slug: props.insightData?.computeableInsightModelSlug,
        dimension_slug: props.dimensionSummaryData?.dimensionSlug,
      });
      return setAlertComponent(
        <Alert
          type="fail"
          message="Something went wrong!"
          elementName="Alert"
        />,
        3000
      );
    }

    if (numberOfMessagesRemaining === 0) {
      Mixpanel?.track("Error alert summoned", {
        body: "You are out of messages.!",
        insight_slug: props.insightData?.computeableInsightModelSlug,
        dimension_slug: props.dimensionSummaryData?.dimensionSlug,
      });
      return setIsAssistantOpen(true);
    }

    if (currentConversation?.id) {
      const previousUserMessage =
        messages.length >= 3
          ? messages[messages.length - 3].content?.slice(0, 250)
          : null;
      const previousAssistantMessage =
        messages.length >= 2
          ? messages[messages.length - 2].content?.slice(0, 250)
          : null;

      Mixpanel?.track("User message sent", {
        is_initial_message: messages.length < 3,
        body: content ?? text,
        "previous assistant message": previousAssistantMessage,
        "previous user message": previousUserMessage,
        is_suggested_message: isSuggested,
        insight_slug: props.insightData?.computeableInsightModelSlug,
        dimension_slug: props.dimensionSummaryData?.dimensionSlug,
      });
      await createUserMessage(
        user?.uid,
        currentConversation?.id,
        content ?? text
      ).then(async (res) => {
        if (
          !currentConversation.title ||
          currentConversation.title === "Generic Conversation Title"
        ) {
          await renameConversation(
            user?.uid,
            currentConversation.id,
            content ?? text
          );
        }
        setText("");
        getCurrentConversationToUpdateStore(
          user?.uid,
          currentConversation.id
        ).then((res) => {
          if (res) {
            dispatch(setCurrentConversation(res));
          }
        });
      });
    } else {
      dispatch(setMessageLoading(true));
      await handleNewConversations(
        undefined,
        undefined,
        undefined,
        content ?? text
      ).then(() => {
        setText("");
      });
    }
  }

  async function handleNewConversations(
    gPTConversationType?: GPTConversationType,
    deliveredDailyInsightId?: string,
    dimensionSlug?: string,
    userMessage?: string,
    title?: string
  ) {
    if (lifetimeGPTTokensConsumed && lifetimeGPTTokensConsumed >= 100000000) {
      //can't go over 100 million token limit per lifetime
      return setAlertComponent(
        <Alert
          type="fail"
          message="Something went wrong!"
          elementName="Alert"
        />,
        3000
      );
    }

    if (numberOfMessagesRemaining === 0) {
      Mixpanel?.track("Error alert summoned", {
        body: "You are out of messages.!",
        insight_slug: props.insightData?.computeableInsightModelSlug,
        dimension_slug: props.dimensionSummaryData?.dimensionSlug,
      });
      return setIsAssistantOpen(true);
    }

    dispatch(setConversationLoading(true));
    setModalComponent(
      <LoadingModal
        message="Reviewing your personality data"
        elementName="LoadingModal"
      />
    );
    return await createGptConversation(
      gPTConversationType,
      deliveredDailyInsightId,
      dimensionSlug,
      userMessage,
      title
    )
      .then((res) => {
        dispatch(addConversation(res));
        dispatch(setCurrentConversation(res));
        dispatch(setConversationLoading(false));
        removeModalByName("LoadingModal");
      })
      .catch((err) => {
        dispatch(setConversationLoading(false));
        removeModalByName("LoadingModal");
      });
  }

  function scrollToBottom() {
    if (messagesDivRef.current) {
      requestAnimationFrame(() => {
        messagesDivRef.current!.scrollTo({
          top: messagesDivRef.current!.scrollHeight - 100,
          behavior: "smooth",
        });
      });
    }
  }

  useEffect(() => {
    //scrolls at every new message
    scrollToBottom();
    try {
      if (window.Android?.onHaptic) {
        window.Android.onHaptic();
      }
    } catch (error) {
      console.log(error);
    }
  }, [messages]);

  useEffect(() => {
    //calls scroll to bottom when the message div wrapper gets extended
    if (!messagesDivRef.current) {
      return;
    }

    const observer = new MutationObserver(() => {
      scrollToBottom();
    });

    observer.observe(messagesDivRef.current, {
      childList: true,
      subtree: true,
    });

    return () => observer.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messagesDivRef.current]);

  useEffect(() => {
    //handles daily insight go deeper
    if (props.insightData?.deliveryId) {
      const found = conversations.find(
        (c) => c.id === props.insightData?.deliveryId
      );
      if (found) {
        dispatch(setCurrentConversation(found));
      } else {
        handleNewConversations(
          GPTConversationType.dailyInsight,
          props.insightData?.deliveryId,
          undefined,
          props.insightData.assignmentRuleset.body
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.insightData?.deliveryId]);

  useEffect(() => {
    //handles Dimension Summary go deeper
    if (props.dimensionSummaryData?.dimensionSlug) {
      const found = conversations.find(
        (c) => c.id === props.dimensionSummaryData?.dimensionSlug
      );
      if (found) {
        dispatch(setCurrentConversation(found));
        if (props.dimensionSummaryData.suggestedMessage) {
          handleSend(props.dimensionSummaryData.suggestedMessage, true);
        }
        return;
      } else {
        handleNewConversations(
          GPTConversationType.dimensionSummary,
          undefined,
          props.dimensionSummaryData.dimensionSlug,
          props.dimensionSummaryData.suggestedMessage ??
            "Tell me more about this Dimension and what my results say about me",
          props.dimensionSummaryData.title
        );
        return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dimensionSummaryData?.dimensionSlug]);

  return (
    <>
      {isAssistantOpen && (
        <DimensionalAssistantModal onClose={() => setIsAssistantOpen(false)} />
      )}
      {sidebarOpen && (
        <ChatbotSideBar
          onClose={() => {
            setSidebarOpen(false);
          }}
          onNew={() => {
            handleNewConversations(undefined, undefined, undefined, undefined);
          }}
        />
      )}
      <ModalWrapper noHeader noLine title="">
        <section className={styles.nav}>
          <div className={styles.backButtonDiv}>
            <BackButton step onBack={() => setModalOpen(false)} />
          </div>
          <div className={styles.goDeeperIconDiv}>
            <GoDeeperModalIcon
              onClick={() => {
                setIsAssistantOpen(true);
              }}
              empty={numberOfMessagesRemaining === 0}
            />
          </div>
          <div className={styles.rightSideButtonDiv}>
            <NewConversationIcon
              onClick={() => {
                handleNewConversations(
                  undefined,
                  undefined,
                  undefined,
                  undefined
                );
              }}
            />
            <GoDeeperMenuIcon
              onClick={() => {
                setSidebarOpen(true);
              }}
            />
          </div>
        </section>
        {messages.length < 2 ? (
          <MappedUserSuggestedMessages
            onSelect={(m) => {
              handleSend(m, true);
            }}
          />
        ) : (
          <section
            style={
              //If just one message or not showing suggested messages
              //AND it's not loading, add more marginBottom
              mappedMessages.length === 1 || !showSuggestedMessagesSection
                ? { marginBottom: !messageLoading ? "100px" : "20px" }
                : {}
            }
            className={styles.messageWrapper}
            ref={messagesDivRef}
          >
            {mappedMessages}
            {showSuggestedMessagesSection && (
              <div className={styles.suggestedMessageDiv}>
                {mappedSuggestedMessages}
              </div>
            )}
          </section>
        )}

        {messageLoading && (
          <div className={styles.loadingDiv}>
            <GoDeeperMessageLoadingIcon />
          </div>
        )}

        {isScrolledUp && (
          <div
            onClick={() => scrollToBottom()}
            className={styles.scrollToBottomIcon}
          >
            <ScrollToBottomIcon />
          </div>
        )}
        <section className={styles.bottom}>
          <ChatBotTextBox
            onSend={() => {
              handleSend();
            }}
            setText={(message) => setText(message)}
            disabled={messageLoading}
            focus={showSuggestedMessagesSection}
          />
        </section>
      </ModalWrapper>
    </>
  );
}

function useListenForMessageLoading(messages: GPTMessage[]) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (messages.length) {
      if (
        messages[messages.length - 1].status !== GPTMessageStatus.processing
      ) {
        dispatch(setMessageLoading(false));
      } else {
        dispatch(setMessageLoading(true));
      }
    } else {
      dispatch(setMessageLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);
}

function useShowSuggestedMessagesSection(props: {
  mappedSuggestedMessagesLength: number | undefined;
  isStreaming: boolean;
  messageLoading: boolean;
}) {
  const [showSuggestedMessagesSection, setshowSuggestedMessagesSection] =
    useState<boolean>(false);

  useEffect(() => {
    if (
      props.mappedSuggestedMessagesLength &&
      !props.messageLoading &&
      !props.isStreaming
    ) {
      //if theres are suggested messages, last message is streaming, and not loading assistant message
      //we show the suggestions
      setshowSuggestedMessagesSection(true);
    } else {
      setshowSuggestedMessagesSection(false);
    }
  }, [
    props.mappedSuggestedMessagesLength,
    props.messageLoading,
    props.isStreaming,
  ]);

  return showSuggestedMessagesSection;
}
