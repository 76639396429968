import styles from "./LoadingScreen.module.css";

export default function LoadingScreen(props: {
  message: string;
  lightMode?: boolean;
  top?: boolean;
  backgroundColor?: string;
}) {
  return (
    <div
      style={
        props.lightMode
          ? { backgroundColor: "#FFFFFA" }
          : props.backgroundColor
          ? { backgroundColor: props.backgroundColor }
          : {}
      }
      className={props.top ? styles.mainTop : styles.main}
    >
      <img
        width={50}
        height={50}
        alt=""
        className="loader"
        src={"/loader.png"}
      />
      <p style={props.lightMode ? { color: "#181716" } : {}}>{props.message}</p>
    </div>
  );
}
