import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Roboto_Mono } from "next/font/google";
import removeMarkdown from "markdown-to-text";
import {
  ContentType,
  RuleDirection,
} from "@dimensional-engineering/dimensional-models";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "@/_firebase/firebaseConfig";

import elementsJsonData from "../../../../assets/traitInfo/elements.json";
import archetypesJsonData from "../../../../assets/traitInfo/archetypes.json";

import styles from "./DailyInsightsModal.module.css";

import ModalWrapper from "@/components/shared/ModalWrapper/ModalWrapper";
import { RootState } from "@/redux/store";
import BackButton from "@/components/shared/buttons/BackButton";
import { useModalContext } from "@/context/ModalContext";
import {
  formatDeliveredOnDate,
  getCountOfTraitRulesForMixpanel,
  getListOfTraitRulesForMixpanel,
  saveDailyInsightSharedToScoial,
  updateInsightsFeedback,
} from "../utils";
import InformationIcon from "@/components/premium/reports/blindspot/ReportAccuracy/InformationIcon";
import {
  DailyInsightIconActiveVariant,
  HighDailyInsightScaleScoreIcon,
  LowDailyInsightScaleScoreIcon,
  NeutralDailyInsightScaleScoreIcon,
} from "../DailyInsightsIcon";
import { useGetDailyInsightIconColors } from "../DailyInsightsCTABlock/DailyInsightsCTABlock";
import { natureData } from "@/assets/traitInfo/natureData";
import ElementModal from "@/components/traits/TraitsModals/Element/ElementModal";
import ArchetypeModal from "@/components/traits/TraitsModals/Archetype/ArchetypeModal";
import { DailyInsightsInterractionBar } from "@/components/results/Snippets/SnippetInterractionBar/SnippetInterractionBar";
import { Mixpanel } from "@/helpers/mixpanel";
import { AnalysisFeedbackSnippets } from "@/components/results/personalityAnalysis/AnalysisFeedback/AnalysisFeedback";
import { useAuth } from "@/context/AuthContext";
import DailyInsightsModalShareView from "./DailyInsightsModalShareView";
import GeneralModal from "@/components/shared/GeneralModal/GeneralModal";
import CommentModal from "../../CommentModal/CommentModal";
import useUpdateCommentThreadData from "../../CommentModal/useUpdateCommentThreadData";
import ShareCaptionModal from "../../ABSTRACTS/AppHomeSharedTab/ShareCaptionModal/ShareCaptionModal";
import { SharedPostWithCommentThread } from "@/models/sharedModels";
import SharedPostModal from "../../ABSTRACTS/AppHomeSharedTab/SharedPostModal/SharedPostModal";
import PostToConnectionsButton from "../../ABSTRACTS/AppHomeSharedTab/PostToConnectionsButton/PostToConnectionsButton";
import GoDeeperModal from "@/components/GoDeeper/GoDeeperModal/GoDeeperModal";

const font = Roboto_Mono({ subsets: ["latin"] });

export default function DailyInsightsModal(props: {
  elementName: "DailyInsightsModal";
  showComment?: boolean;
  commentId?: string;
}) {
  const { setModalOpen, setModalComponent } = useModalContext();
  const { user } = useAuth();

  const [showCaptionModal, setShowCaptionModal] = useState<boolean>(false);
  const [caption, setCaption] = useState<string>("");

  const [showAnlaysis, setShowAnalysis] = useState<boolean>(false);

  const dailyInsight = useSelector(
    (state: RootState) => state.dailyInsight
  ).deliveredDailyInsight;
  const insightDelivery = useSelector(
    (state: RootState) => state.dailyInsight
  ).insightDelivery;
  const commentThread = useSelector(
    (state: RootState) => state.dailyInsight
  ).commentThread;
  const userPublicProfile = useSelector(
    (state: RootState) => state.user
  ).publicProfileData;
  const profileImage = useSelector((state: RootState) => state.user).imageURL;

  const { showComments, setShowComments } = useUpdateCommentThreadData(
    dailyInsight?.assignmentRuleset.commentThreadId,
    props.showComment
  );

  const foundPost = useIsDailyInsightShared({ userUid: user?.uid });

  const colors = useGetDailyInsightIconColors();
  // const time = useGetDailyInsightTimerText(dailyInsight?.validUntil);
  const body = removeMarkdown(dailyInsight?.assignmentRuleset?.body ?? "");

  const commentCountRoot = commentThread?.rootCommentCount ?? 0;
  const commentCountReplies = commentThread?.replyCount ?? 0;

  const feedbackScore = useGetDailyInsightFeedback();

  const mappedScales = dailyInsight?.assignmentRuleset.scaleRules.map(
    (scale) => {
      const icon =
        scale.direction === RuleDirection.up ? (
          <HighDailyInsightScaleScoreIcon />
        ) : scale.direction === RuleDirection.middle ? (
          <NeutralDailyInsightScaleScoreIcon />
        ) : (
          <LowDailyInsightScaleScoreIcon />
        );

      const element = elementsJsonData.find((el) => el.slug === scale?.slug);
      const foundNature = natureData?.find((n) => n.slug === element?.nature);
      let scaleColor = foundNature?.color;

      if (element && foundNature) {
        return (
          <div
            className={styles.scale}
            style={{ borderColor: scaleColor }}
            key={scale.slug}
            onClick={() =>
              setModalComponent(<ElementModal slug={scale.slug} />)
            }
          >
            {icon}
            <p className={styles.scaleName}>{element.alias ?? element.name}</p>
          </div>
        );
      }
    }
  );

  const identityArchetypeFound = archetypesJsonData.find(
    (a) => a.slug === dailyInsight?.assignmentRuleset.identityArchetypeSlug
  );

  const interactionArchetypeFound = archetypesJsonData.find(
    (a) => a.slug === dailyInsight?.assignmentRuleset.interactionArchetypeSlug
  );

  const identityArchetype = identityArchetypeFound ? (
    <div
      onClick={() =>
        setModalComponent(<ArchetypeModal slug={identityArchetypeFound.slug} />)
      }
      className={styles.scale}
    >
      <p className={styles.scaleName}>
        {identityArchetypeFound.acronym ?? identityArchetypeFound.name}
      </p>
    </div>
  ) : null;

  const interactionArchetype = interactionArchetypeFound ? (
    <div
      onClick={() =>
        setModalComponent(
          <ArchetypeModal slug={interactionArchetypeFound.slug} />
        )
      }
      className={styles.scale}
    >
      <p className={styles.scaleName}>
        {interactionArchetypeFound.acronym ?? interactionArchetypeFound.name}
      </p>
    </div>
  ) : null;

  function checkScroll(e: React.UIEvent<HTMLElement>) {
    const target = e.currentTarget;
    const isScrollable = target.scrollHeight > target.clientHeight;
    const isScrolledToBottom =
      target.scrollTop + target.clientHeight >= target.scrollHeight - 2;

    if (!isScrollable || isScrolledToBottom) {
      if (feedbackScore === null) {
        setShowAnalysis(true);
      }
    }
  }

  useEffect(() => {
    if (dailyInsight && insightDelivery?.computeableInsightModelSlug) {
      Mixpanel?.track("Daily Insight Viewed", {
        slug: insightDelivery?.computeableInsightModelSlug,
        body: dailyInsight?.assignmentRuleset?.body.slice(0, 250),
        trait_rules: getListOfTraitRulesForMixpanel(dailyInsight),
        is_novel_ruleset:
          dailyInsight?.assignmentRuleset.isNovelGenerated ?? false,
        trait_count: getCountOfTraitRulesForMixpanel(dailyInsight),
      });
    }
  }, [dailyInsight, insightDelivery?.computeableInsightModelSlug]);

  function onCopy() {
    if (window.Android && window.Android.copyToClipboard) {
      window.Android.copyToClipboard(body);
      Mixpanel?.track("Content copied", {
        slug: insightDelivery?.computeableInsightModelSlug,
        body: dailyInsight?.assignmentRuleset?.body.slice(0, 250),
        trait_rules: getListOfTraitRulesForMixpanel(dailyInsight),
        is_novel_ruleset:
          dailyInsight?.assignmentRuleset.isNovelGenerated ?? false,
        trait_count: getCountOfTraitRulesForMixpanel(dailyInsight),
      });
    }
  }

  function handlePost() {
    if (foundPost) {
      Mixpanel?.track("Post viewed", {
        source: "from own results modal",
        content_type: "daily insight",
        content_type_slug:
          foundPost.postContent.dailyInsightData?.insightDelivery
            .computeableInsightModelSlug,
        caption_text: foundPost.caption,
      });
      return setModalComponent(
        <SharedPostModal
          profileImage={profileImage ?? ""}
          publicProfile={userPublicProfile}
          sharedPost={foundPost}
        />
      );
    }
    return setShowCaptionModal(true);
  }

  return (
    <>
      {showComments && (
        <CommentModal
          onClose={() => setShowComments(false)}
          commentId={props.commentId}
          slug={insightDelivery?.computeableInsightModelSlug ?? ""}
          audienceGroupSize={commentThread?.audienceUids?.length ?? 0}
          source="daily insight"
          content={dailyInsight?.assignmentRuleset.body ?? ""}
        />
      )}
      {showCaptionModal && dailyInsight?.deliveryId && (
        <ShareCaptionModal
          contentIdentifier={{
            contentSlug: dailyInsight?.deliveryId,
            contentType: ContentType.deliveredDailyInsight,
          }}
          onClose={(captionText) => {
            setCaption(captionText);
            setShowCaptionModal(false);
          }}
          savedCaption={caption}
        />
      )}
      <ModalWrapper
        onScroll={(e) => {
          checkScroll(e);
        }}
        backgroundColor="#181716"
        noHeader
        noLine
        title=""
      >
        <div className={styles.header}>
          <div className={styles.headerTitlesDiv}>
            <h2 className={styles.headerTitle}>Personal Insight</h2>
            <h3 className={styles.headerSubTitle}>
              {formatDeliveredOnDate(dailyInsight?.deliveredOn)}
            </h3>
          </div>
          <div className={styles.backArrowDiv}>
            <BackButton step onBack={() => setModalOpen(false)} />
          </div>
        </div>
        <main className={styles.main}>
          <div
            onClick={() => {
              if (dailyInsight?.assignmentRuleset.isNovelGenerated) {
                setModalComponent(
                  <GeneralModal
                    title="Experimental Insight"
                    body="Sometimes we sprinkle some extra chaos into our insights so we can experiment and improve. Your feedback on these insights is particularly important."
                  />
                );
              }
            }}
            style={
              dailyInsight?.assignmentRuleset.isNovelGenerated
                ? {}
                : { opacity: "0" }
            }
            className={styles.experimentalDiv}
          >
            <p className={`${styles.experimentalText} ${font.className}`}>
              EXPERIMENTAL INSIGHT
            </p>
            <div className={styles.infoIcon}>
              <InformationIcon />
            </div>
          </div>
          <div className={styles.iconDiv}>
            <DailyInsightIconActiveVariant colors={colors} />
          </div>
          <h2 className={`${styles.insightTitle} ${font.className}`}>
            PERSONAL INSIGHT
          </h2>
          <h3 className={styles.title}>{dailyInsight?.name}</h3>
          <p onClick={() => onCopy()} className={styles.body}>
            {body}
          </p>
          {/* <p className={`${styles.ruleSetText} ${font.className}`}>
            BECAUSE OF YOUR SCORES ON:
          </p>
          <div className={styles.scalesDiv}>
            {mappedScales}
            {identityArchetype}
            {interactionArchetype}
          </div> */}
          {/* <div className={styles.commentInfoDiv}>
            <CommentAndAudiencePreviewsInsights
              audienceNumber={commentThread?.audienceUids?.length ?? 0}
              commentCount={
                commentCountRoot + commentCountReplies === 99
                  ? 99
                  : commentCountRoot + commentCountReplies
              }
            />
          </div> */}
          <div className={styles.sharedPostButtonDiv}>
            <PostToConnectionsButton
              posted={foundPost ? true : false}
              onClick={() => {
                Mixpanel?.track("Post to connections CTA pressed", {
                  source: "modal body",
                  content_type: "daily insight",
                  content_type_slug: dailyInsight?.computeableInsightModelSlug,
                });
                handlePost();
              }}
            />
          </div>
          {showAnlaysis && (
            <div className={styles.feedbackDiv}>
              <AnalysisFeedbackSnippets
                onAnswer={(value) => {
                  if (user?.uid) {
                    updateInsightsFeedback(dailyInsight, user.uid, value).then(
                      () => {
                        Mixpanel?.track("Feedback given", {
                          slug: insightDelivery?.computeableInsightModelSlug,
                          body: dailyInsight?.assignmentRuleset?.body.slice(
                            0,
                            250
                          ),
                          score: value,
                          content_type: "daily_insight",
                          trait_rules:
                            getListOfTraitRulesForMixpanel(dailyInsight),
                          is_novel_ruleset:
                            dailyInsight?.assignmentRuleset.isNovelGenerated ??
                            false,
                          trait_count:
                            getCountOfTraitRulesForMixpanel(dailyInsight),
                        });
                      }
                    );
                  }
                }}
                title="How good was this insight?"
                subtitle="Feedback improves your results"
                currentAnswer={feedbackScore}
                onClose={() => setShowAnalysis(false)}
              />
            </div>
          )}
          <div className={styles.interactionBarDiv}>
            <DailyInsightsInterractionBar
              onRateClick={() => {
                setShowAnalysis(!showAnlaysis);
              }}
              onGoDeeper={() => {
                Mixpanel?.track("Go deeper” CTA pressed", {
                  content_type: "insight",
                  slug: insightDelivery?.computeableInsightModelSlug,
                  trait_rules: getListOfTraitRulesForMixpanel(dailyInsight),
                  is_novel_ruleset:
                    dailyInsight?.assignmentRuleset.isNovelGenerated ?? false,
                });
                setModalComponent(<GoDeeperModal insightData={dailyInsight} />);
              }}
              onShare={(value) => {
                saveDailyInsightSharedToScoial(
                  dailyInsight?.deliveryId,
                  user?.uid,
                  value
                )
                  .then((res) => {
                    console.log(res);
                  })
                  .catch((err) => {
                    console.log(err);
                  });

                Mixpanel?.track("Shared to Social", {
                  platform: value === "instagram" ? "Instagram" : "Snapchat",
                  slug: insightDelivery?.computeableInsightModelSlug,
                  type: "dailyInsight",
                  body: dailyInsight?.assignmentRuleset?.body.slice(0, 250),
                  trait_rules: getListOfTraitRulesForMixpanel(dailyInsight),
                  is_novel_ruleset:
                    dailyInsight?.assignmentRuleset.isNovelGenerated ?? false,
                  trait_count: getCountOfTraitRulesForMixpanel(dailyInsight),
                });
                setModalComponent(
                  <DailyInsightsModalShareView
                    type={value}
                    deliveredDailyInsight={dailyInsight}
                  />
                );
              }}
              onCommentClick={() => {
                Mixpanel?.track("Comment modal summoned", {
                  source: "daily insight",
                  audience_group_size: commentThread?.audienceUids?.length ?? 0,
                  type: "from Discuss CTA",
                });
                setShowComments(true);
              }}
              commentCount={
                commentCountRoot + commentCountReplies === 99
                  ? 99
                  : commentCountRoot + commentCountReplies
              }
              onPostClick={() => {
                handlePost();
                Mixpanel?.track("Post to connections CTA pressed", {
                  source: "interaction bar",
                  content_type: "daily insight",
                  content_type_slug: dailyInsight?.computeableInsightModelSlug,
                });
              }}
              posted={foundPost ? true : false}
              inactiveComments
            />
          </div>
        </main>
      </ModalWrapper>
    </>
  );
}

function useGetDailyInsightFeedback() {
  const dailyInsight = useSelector(
    (state: RootState) => state.dailyInsight
  ).deliveredDailyInsight;

  const [feedbackScore, setFeedbackScore] = useState<number | null>(null);
  const { user } = useAuth();

  useEffect(() => {
    if (dailyInsight && user?.uid) {
      const docRef = doc(
        db,
        "members",
        `${user.uid}`,
        "deliveredDailyInsights",
        dailyInsight.deliveryId
      );
      const unsubscribe = onSnapshot(docRef, (querySnapshot) => {
        if (querySnapshot.exists()) {
          if (
            querySnapshot.data().feedbackScore !== null &&
            querySnapshot.data().feedbackScore !== undefined
          ) {
            setFeedbackScore(querySnapshot.data().feedbackScore as number);
          } else {
            setFeedbackScore(null);
          }
        } else {
          setFeedbackScore(null);
        }
      });

      return () => {
        unsubscribe;
      };
    }
  }, [dailyInsight, user?.uid]);

  return feedbackScore;
}

function useIsDailyInsightShared(props: { userUid: string | undefined }) {
  const sharedPosts = useSelector(
    (state: RootState) => state.sharedPosts
  ).sharedPosts;
  const dailyInsight = useSelector(
    (state: RootState) => state.dailyInsight
  ).deliveredDailyInsight;

  const [foundPost, setFoundPost] =
    useState<SharedPostWithCommentThread | null>(null);

  useEffect(() => {
    const slug = `${props.userUid}-deliveredDailyInsight-${dailyInsight?.deliveryId}`;
    const isFound = sharedPosts.find((post) => post.slug === slug);
    if (isFound) {
      setFoundPost(isFound);
    } else {
      setFoundPost(null);
    }
  }, [dailyInsight?.deliveryId, props.userUid, sharedPosts.length]);

  return foundPost;
}
