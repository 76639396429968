import { useEffect, useRef, useState, ReactNode } from "react";

import styles from "./DraggableModalWrapper.module.css";

type Props = {
  onClose: () => void;
  children: ReactNode;
  backgroundColor?: string;
};

export default function DraggableModalWrapper(props: Props) {
  const ref = useRef<HTMLDivElement>(null);

  const [startY, setStartY] = useState<number>(0);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [yPosition, setYPosition] = useState<number>(0);
  const [diffY, setDiffY] = useState(0);

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    setStartY(e.touches[0].clientY);
    setIsDragging(true);
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    if (!isDragging) return;
    const currentY = e.touches[0].clientY;
    const newDiffY = currentY - startY;
    if (newDiffY > 0) {
      setDiffY(newDiffY);
      setYPosition(newDiffY);
    }
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
    setYPosition(0);

    if (diffY > window.screen.height / 2) {
      props.onClose();
    }

    setDiffY(0);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (ref.current) {
        ref.current.style.marginTop = "20px";
      }
    }, 200);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <main
      style={yPosition !== 0 ? { top: yPosition } : {}}
      className={styles.main}
      ref={ref}
    >
      <section
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        className={styles.header}
      >
        <div className={styles.line}></div>
      </section>
      {props.children}
    </main>
  );
}
