import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";

import styles from "./CommunityChatter.module.css";

import CommunityQuestionsCTABlock from "../CommunityQuestions/CommunityQuestionsCTABlock";
import UserPollsHomeCTA from "../UserPolls/UserPollsHomeCTA/UserPollsHomeCTA";
import Shimmer from "@/components/shared/Shimmer/Shimmer";

export default function CommunityChatter() {
  const communityQuestionLoaded = useSelector(
    (state: RootState) => state.communityQuestion
  ).communityQuestionLoaded;
  const pollLoaded = useSelector(
    (state: RootState) => state.userPoll
  ).userPollLoaded;

  if (!pollLoaded && !communityQuestionLoaded) {
    return (
      <section className={styles.main}>
        <div className={styles.mainShimmer}>
          <div className={styles.shimmerCircle}>
            <Shimmer />
          </div>
          <div className={styles.shimmerRect}>
            <Shimmer />
          </div>
        </div>

        <div className={styles.mainShimmer}>
          <div className={styles.shimmerCircle}>
            <Shimmer />
          </div>
          <div className={styles.shimmerRect}>
            <Shimmer />
          </div>
        </div>
      </section>
    );
  }

  return (
    <section className={styles.main}>
      <CommunityQuestionsCTABlock />
      <UserPollsHomeCTA />
    </section>
  );
}
