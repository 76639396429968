import { callFirebaseFunctions } from "@/_firebase/callFirebaseFunctions";
import { sortPeerStories } from "@/components/authenticatedProfile/tabs/AuthCompareTab/utils";
import { ExtendedPeerStoryGated } from "@/models/sharedModels";
import {
  DiscoverablePeerStory,
  ListicleEmojiReaction,
} from "@dimensional-engineering/dimensional-models";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface PeerLoadingData {
  [connectionUid: string]: {
    [promptKey: string]: boolean;
  };
}

interface PeerErrorData {
  [connectionUid: string]: {
    [promptKey: string]: boolean;
  };
}

interface PeerStoryRequestTimestampData {
  [connectionUid: string]: {
    [promptSlug: string]: number;
  };
}

interface ConnectionsSlice {
  discoverablePeerStory: DiscoverablePeerStory[];
  currentPeerStories: ExtendedPeerStoryGated[];
  loadingMap: PeerLoadingData;
  errorMap: PeerErrorData;
  requestTimestamps: PeerStoryRequestTimestampData;
}

const initialState: ConnectionsSlice = {
  discoverablePeerStory: [],
  currentPeerStories: [],
  loadingMap: {},
  errorMap: {},
  requestTimestamps: {},
};

export const computePeerStories = createAsyncThunk<
  void,
  { promptSlug: string; connectionUid: string }
>(
  "peerStories/computePeerStories",
  async ({ promptSlug, connectionUid }, thunkAPI) => {
    try {
      const call = callFirebaseFunctions("computePeerStories");
      await call({ promptSlug, connectionUid });
    } catch (err) {
      // Do nothing on error
      console.log(err);
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const peerStoriesSlice = createSlice({
  name: "peerStories",
  initialState: initialState,
  reducers: {
    setDiscoverablePeerStory: (
      state,
      action: PayloadAction<DiscoverablePeerStory[]>
    ) => {
      return {
        ...state,
        discoverablePeerStory: action.payload.sort(sortPeerStories),
      };
    },
    setCurrentPeerStories: (
      state,
      action: PayloadAction<ExtendedPeerStoryGated[]>
    ) => {
      return { ...state, currentPeerStories: action.payload };
    },
    updateEmojiOfCurrentStories: (
      state,
      action: PayloadAction<{
        slug: string;
        index: number; // The index of the statement to update
        reaction: ListicleEmojiReaction; // The new emoji reaction
      }>
    ) => {
      const { slug, index, reaction } = action.payload;

      // Find the story by slug
      const updatedStories = state.currentPeerStories.map((story) =>
        story.slug === slug
          ? {
              ...story,
              peerStoryContent: {
                ...story.peerStoryContent,
                listicleStatements:
                  story.peerStoryContent.listicleStatements.map(
                    (statement, i) =>
                      i === index
                        ? { ...statement, myEmojiReaction: reaction }
                        : statement
                  ),
              },
            }
          : story
      );

      return { ...state, currentPeerStories: updatedStories };
    },
    updateStoryFeedback: (
      state,
      action: PayloadAction<{
        slug: string;
        value: number;
      }>
    ) => {
      const { slug, value } = action.payload;

      const updatedStories = state.currentPeerStories.map((story) =>
        story.slug === slug
          ? {
              ...story,
              feedbackScore: value,
            }
          : story
      );
      return { ...state, currentPeerStories: updatedStories };
    },
    resetCurrentPeerStories: (state) => {
      return { ...state, currentPeerStories: [] };
    },
    cleanupRequestTimestamps(state) {
      const now = Date.now();
      const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000;

      // Iterate through the `requestTimestamps` object
      for (const connectionUid in state.requestTimestamps) {
        const promptTimestamps = state.requestTimestamps[connectionUid];

        // Remove prompts that are older than one day
        for (const promptSlug in promptTimestamps) {
          if (now - promptTimestamps[promptSlug] > ONE_DAY_IN_MS) {
            delete promptTimestamps[promptSlug];
          }
        }

        // If all prompts are deleted, remove the connectionUid
        if (Object.keys(promptTimestamps).length === 0) {
          delete state.requestTimestamps[connectionUid];
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(computePeerStories.pending, (state, action) => {
        const { promptSlug, connectionUid } = action.meta.arg;
        if (!state.loadingMap[connectionUid]) {
          state.loadingMap[connectionUid] = {};
        }
        state.loadingMap[connectionUid][promptSlug] = true;

        if (!state.requestTimestamps[connectionUid]) {
          state.requestTimestamps[connectionUid] = {};
        }
        state.requestTimestamps[connectionUid][promptSlug] = Date.now();

        if (!state.errorMap[connectionUid]) {
          state.errorMap[connectionUid] = {};
        }
        state.errorMap[connectionUid][promptSlug] = false;
      })
      .addCase(computePeerStories.fulfilled, (state, action) => {
        const { promptSlug, connectionUid } = action.meta.arg;
        if (state.loadingMap[connectionUid]) {
          state.loadingMap[connectionUid][promptSlug] = false;
        }

        if (!state.errorMap[connectionUid]) {
          state.errorMap[connectionUid] = {};
        }
        state.errorMap[connectionUid][promptSlug] = false;
      })
      .addCase(computePeerStories.rejected, (state, action) => {
        const { promptSlug, connectionUid } = action.meta.arg;
        if (state.loadingMap[connectionUid]) {
          state.loadingMap[connectionUid][promptSlug] = false;
        }
        if (!state.errorMap[connectionUid]) {
          state.errorMap[connectionUid] = {};
        }
        state.errorMap[connectionUid][promptSlug] = true;
      });
  },
});

export const {
  setDiscoverablePeerStory,
  setCurrentPeerStories,
  resetCurrentPeerStories,
  updateEmojiOfCurrentStories,
  updateStoryFeedback,
  cleanupRequestTimestamps,
} = peerStoriesSlice.actions;

export default peerStoriesSlice.reducer;
