import { useEffect, useState } from "react";
import {
  FriendRequest,
  PublicProfile,
} from "@dimensional-engineering/dimensional-models";
import { useSelector } from "react-redux";

import styles from "./ConnectionRequestModal.module.css";

import { useModalContext } from "@/context/ModalContext";
import { RootState } from "@/redux/store";
import useGetProfilePhoto from "@/helpers/useGetProfilePhoto";
import {
  acceptFriendRequest,
  declineFriendRequest,
  sendFriendRequest,
} from "../utils";
import { Mixpanel } from "@/helpers/mixpanel";
import SmallProfileImage from "@/components/shared/SmallProfileImage/SmallProfileImage";
import Alert from "@/components/shared/Alerts/Alert";
import BasicConnectionIcon from "@/components/shared/icons/BasicConnectionIcon";
import { useAlertContext } from "@/context/AlertContext";

type Props = {
  publicProfile: PublicProfile;
  friendRequest?: FriendRequest;
  text?: string;
  noMessage?: boolean;
  elementName: "ConnectionModal";
};

export default function ConnectionRequestModal(props: Props) {
  const { removeModalByName } = useModalContext();
  const { setAlertComponent } = useAlertContext();

  const userState = useSelector((state: RootState) => state.user);
  const imageUrl = useGetProfilePhoto(props.publicProfile.ownerUUID);
  const [message, setMessage] = useState<string>();
  const [buttonText, setButtonText] = useState<string>(
    "Send connection request"
  );
  const [status, setStatus] = useState<"recieved" | "sent" | null>(null);

  useSlideUpFriendRequestWrapper();

  useEffect(() => {
    let s: "recieved" | "sent" | null = null;
    if (
      !userState.isCloseRequestSentUidList.includes(
        props.publicProfile.ownerUUID
      ) &&
      !userState.isDeepRequestSentUidList.includes(
        props.publicProfile.ownerUUID
      ) &&
      userState.friendRequestsSentUidList.includes(
        props.publicProfile.ownerUUID
      )
    ) {
      s = "sent";
    }
    userState?.friendRequestsRecieved?.forEach((req) => {
      if (
        !req.isClose === true &&
        !req.isDeep &&
        req.sender.ownerUUID === props.publicProfile.ownerUUID
      ) {
        s = "recieved";
      }
    });
    setStatus(s);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.publicProfile.ownerUUID]);

  function handleClick() {
    setButtonText("Loading...");
    if (userState.publicProfileData) {
      sendFriendRequest(
        userState.publicProfileData,
        props.publicProfile,
        false,
        false,
        message
      )
        .then((res) => {
          removeModalByName("ConnectionModal");
          Mixpanel?.track("Friend request sent", {
            source: "Profile",
            type: "basic",
          });
          setTimeout(() => {
            setAlertComponent(
              <Alert
                type="success"
                message="Successfully sent connection request"
                elementName="Alert"
              />,
              5000
            );
          }, 200);
        })
        .catch((err) => {
          console.log(err);
          setButtonText("Send connection request");
          removeModalByName("ConnectionModal");
          setTimeout(() => {
            setAlertComponent(
              <Alert
                type="fail"
                message="Failed to send connection request. Note: You're limited to 150 connections."
                elementName="Alert"
              />,
              7000
            );
          }, 200);
        });
    }
  }

  //user received a request
  if (status === "recieved") {
    return (
      <dialog
        onClick={() => {
          removeModalByName("ConnectionModal");
        }}
        className={styles.main}
      >
        <main
          id="dialogconnection"
          className={styles.wrapper}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={styles.images}>
            <SmallProfileImage
              publicProfile={userState.publicProfileData as PublicProfile}
              imageUrl={userState.imageURL as string}
            />
            <div className={styles.svg}>
              <BasicConnectionIcon active />
            </div>
            <SmallProfileImage
              publicProfile={props.publicProfile}
              imageUrl={imageUrl as string}
            />
          </div>
          <h3 className={styles.title}>
            {props.text ? props.text : "Basic connection request"}
          </h3>
          <p className={styles.unlock}>Unlocks messaging and basic content</p>
          {props.friendRequest?.requestBody && (
            <textarea disabled className={styles.textAreaMessage}>
              {props.friendRequest?.requestBody}
            </textarea>
          )}

          <button
            onClick={() => {
              if (props.friendRequest)
                acceptFriendRequest(props.friendRequest)
                  .then(() => {
                    removeModalByName("ConnectionModal");
                  })
                  .catch((err) => {
                    console.log(err);
                  });
            }}
            className={styles.button}
          >
            Accept
          </button>
          <button
            onClick={() => {
              if (props.friendRequest)
                declineFriendRequest(props.friendRequest)
                  .then(() => {
                    removeModalByName("ConnectionModal");
                  })
                  .catch((err) => {
                    console.log(err);
                  });
            }}
            className={styles.buttonPending}
          >
            Decline
          </button>
        </main>
      </dialog>
    );
  }

  //user has sent a request already
  if (status === "sent") {
    return (
      <dialog
        onClick={() => {
          removeModalByName("ConnectionModal");
        }}
        className={styles.main}
      >
        <main
          id="dialogconnection"
          className={styles.wrapper}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={styles.images}>
            <SmallProfileImage
              publicProfile={userState.publicProfileData as PublicProfile}
              imageUrl={userState.imageURL as string}
            />
            <div className={styles.svg}>
              <BasicConnectionIcon active />
            </div>
            <SmallProfileImage
              publicProfile={props.publicProfile}
              imageUrl={imageUrl as string}
            />
          </div>
          <h3 className={styles.title}>
            {props.text ? props.text : "Basic connection request"}
          </h3>
          <p className={styles.unlock}>Unlocks messaging and basic content</p>
          <button disabled={true} className={styles.buttonPending}>
            Pending
          </button>
        </main>
      </dialog>
    );
  }

  //regular request
  return (
    <dialog
      onClick={() => {
        removeModalByName("ConnectionModal");
      }}
      className={styles.main}
    >
      <main
        id="dialogconnection"
        className={styles.wrapper}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={styles.images}>
          <SmallProfileImage
            publicProfile={userState.publicProfileData as PublicProfile}
            imageUrl={userState.imageURL as string}
          />
          <div className={styles.svg}>
            <BasicConnectionIcon active />
          </div>
          <SmallProfileImage
            publicProfile={props.publicProfile}
            imageUrl={imageUrl as string}
          />
        </div>
        <h3 className={styles.title}>
          {props.text ? props.text : "Basic connection request"}
        </h3>
        <p className={styles.unlock}>Unlocks messaging and basic content</p>
        {!props.noMessage && (
          <>
            <textarea
              placeholder="Personalize your request (optional)"
              className={styles.textArea}
              maxLength={180}
              onChange={(e) => setMessage(e.target.value)}
            />
            <p className={styles.counter}>
              {message?.length ?? 0}/180 characters
            </p>
          </>
        )}
        <button
          disabled={buttonText === "Loading..."}
          onClick={() => handleClick()}
          className={styles.button}
        >
          {buttonText}
        </button>
      </main>
    </dialog>
  );
}

export function useSlideUpFriendRequestWrapper() {
  useEffect(() => {
    const dialogue = document.getElementById("dialogconnection");

    if (dialogue) {
      setTimeout(() => {
        dialogue.style.transform = "translateY(30%)";
      }, 150);
    }
  }, []);
}
